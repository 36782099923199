import React, { FC } from 'react';

import styles from './Alert.module.scss';

type AlertProps = React.HTMLAttributes<HTMLDivElement>;

const Alert: FC<AlertProps> = ({ className, children, ...props }) => (
    <div
        className={[styles.container, className].join(' ')}
        {...props}
    >
        {children}
    </div>
);

export default Alert;
