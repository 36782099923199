import * as React from 'react';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useMutation } from 'react-query';

import { Button, ErrorModal } from '../../../../../../components';
import { useToast } from '../../../../../../customHooks/useToast';

import { newPicklistRowValueAtom, attributeSettingsAtom } from '../../../../../../atons/AttributeManagement/atoms';

import { queryClient } from '../../../../../../react-query/queryClient';
import { queryKeys } from '../../../../../../react-query/constants';

import CustomFieldsAdapter from '../../../../../../adapters/CustomFieldsAdapter';
import { patchCustomFieldPickList } from '../../../../../../services/attributeManagementApis';

import styles from './IsDefaultColumn.module.scss';
import { customerAtom } from 'atons/Customer/atoms';

const IsDefaultColumn = ({ dataItem, id }) => {
    const [error, setError] = React.useState(null);
    const [newPicklistRowValue, setNewPicklistRowValue] = useAtom(newPicklistRowValueAtom);
    const { id: customFieldId } = useAtomValue(attributeSettingsAtom);
    const toast = useToast();
    const customer = useAtomValue(customerAtom);
    const mode = !!dataItem.id ? 'edit' : 'create';
    const isDeleted = dataItem.isDeleted === 'Yes';
    /**
     * Function that change the value of the new row default option
     */
    const handleChangeNewRowDefaultValue = isDefault =>
        setNewPicklistRowValue({
            ...newPicklistRowValue,
            isDefault,
        });
    /**
     * Function that shows a generic toasty error
     */
    const showGenericToastError = () => toast.error('Something went wrong, try again later.');
    /**
     * Function that handles the BadRequest error
     */
    const handleBadRequestError = ({ errors, title }) => {
        function errObjectHasHeaderAndDescriptionProperties(err) {
            return Object.hasOwn(err, 'header') && Object.hasOwn(err, 'description');
        }
        if (Object.hasOwn(errors, 'length') && errors.every(errObjectHasHeaderAndDescriptionProperties)) {
            return setError({ title, errors });
        }
        if (typeof errors === 'object' && Object.keys(errors).length > 0) {
            return setError({
                title,
                errors: Object.keys(errors).map(key => ({
                    header: '',
                    description: errors[key].join('\b'),
                })),
            });
        }
        showGenericToastError();
    };
    /**
     * Function that submit user change to picklist
     */
    const { mutate: changeDefaultValueTo } = useMutation(
        isDefault =>
            patchCustomFieldPickList(
                customer.clientId,
                customFieldId,
                dataItem.id,
                CustomFieldsAdapter.patchPicklistItem({
                    value: dataItem.value,
                    color: dataItem.colorCode,
                    isDefault,
                    isDeleted: dataItem.isDeleted === 'Yes',
                    sortOrder: dataItem.sortOrder,
                })
            ),
        {
            onSuccess: () => queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]),
            onError: error => {
                if (error?.status === 400 && error.errors) {
                    return handleBadRequestError(error);
                }
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) });
            },
        }
    );

    if (mode === 'create')
        return (
            <td
                id={id}
                style={{ top: 0, bottom: 0 }}
                className="k-grid-row-sticky"
            >
                <Button
                    id="new-item-is-default"
                    className={styles.buttonBaseStyle}
                    text={newPicklistRowValue.isDefault === 'Yes' ? 'Yes' : 'No'}
                    onClick={() =>
                        newPicklistRowValue.isDefault === 'Yes'
                            ? handleChangeNewRowDefaultValue('No')
                            : handleChangeNewRowDefaultValue('Yes')
                    }
                />
            </td>
        );

    return (
        <td id={id}>
            {dataItem.isDefault === 'Yes' ? (
                <Button
                    text="Yes"
                    title={
                        isDeleted
                            ? 'Only when it is active can it be edited'
                            : 'Click here if you want to deselect this item as default'
                    }
                    className={styles.buttonBaseStyle}
                    onClick={() => changeDefaultValueTo('No')}
                    disabled={isDeleted}
                />
            ) : (
                <Button
                    text="No"
                    title={
                        isDeleted
                            ? 'Only when it is active can it be edited'
                            : 'Click here if you want to mark this item as default'
                    }
                    className={styles.buttonBaseStyle}
                    onClick={() => changeDefaultValueTo('Yes')}
                    disabled={isDeleted}
                />
            )}

            <ErrorModal
                show={!!error}
                title={error?.title}
                closeBtn
                closeBtnFunc={() => setError(null)}
                errors={error?.errors}
                className={styles.errorModal}
            />
        </td>
    );
};

export default IsDefaultColumn;
