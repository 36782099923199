import * as React from 'react';

import AutoCompleteOptions from './AutoCompleteOptions';
import { useAutoCompleteContext } from './AutoCompleteContext';
import styles from './AutoCompleteSelector.module.scss';
import { a11yKeyBoardEventHandler } from '../../../shared/KeyboradKey';

/**
 * Component that returns the search input to filter on options
 * @param {React.InputHTMLAttributes<HTMLInputElement> & { error?: string; }} props Component props
 * @returns {JSX.Element}
 */
function AutoCompleteSelector(
    { id, placeholder = 'Select an option', autoComplete, readOnly, disabled, className, style, ...props },
    ref
) {
    const {
        isOptionsVisible,
        openAutoCompleteOptions,
        onChangeText,
        selectedValue,
        focousFirstElement,
        submitValueInput,
        closeAutoCompleteOptions,
        blurSearchInput,
    } = useAutoCompleteContext();
    const autoCompleteRef = React.useRef(null);
    const autoCompleteInputRef = React.useRef(null);
    const ulRef = React.useRef(null);
    /**
     * Hook that provide functions to the fowardRef provided by parent.
     */
    React.useImperativeHandle(ref, () => ({
        focusInput: () => autoCompleteInputRef.current?.focus(),
        blurInput: () => autoCompleteInputRef.current?.blur(),
        focusUlFirstChild: () => ulRef.current?.focusUlFirstChild(),
    }));

    /**
     * @returns {string} Component container css className
     */
    const getContainerClassName = () => {
        const containerClasses = [styles.inputWrapper];
        if (isOptionsVisible) containerClasses.push(styles.focused);
        if (disabled) containerClasses.push(styles.disabled);
        if (!!props.error || autoCompleteInputRef.current?.validity?.valid === false)
            containerClasses.push(styles.invalid);
        return containerClasses.join(' ');
    };

    /**
     * Function that returns the preventDiv className
     * @returns {string}
     */
    const getPreventDivClassName = () => {
        const preventDivClasses = [styles.preventDiv];
        if (!readOnly && autoComplete) preventDivClasses.push(styles.autoCompleteField);
        return preventDivClasses.join(' ');
    };

    /**
     * @returns {string} Arrow icon css className
     */
    const getIconClassName = () => {
        const containerClasses = ['k-icon', 'k-i-arrow-chevron-right', styles.autoCompleteIcon];
        if (isOptionsVisible) containerClasses.push(styles.iconFocused);
        return containerClasses.join(' ');
    };

    const cancelInteractions = () => {
        closeAutoCompleteOptions();
        blurSearchInput();
    };

    /**
     * Function that listen to user keyDown event
     * @param {React.KeyboardEvent<HTMLInputElement>} event
     * @returns {void}
     */
    const onKeyDown = event => {
        const ArrowUp = ['ArrowUp', 38];
        const ArrowDown = ['ArrowDown', 40];
        const Enter = ['Enter', 13];
        const Escape = ['Escape', 27];
        if (event.key === ArrowUp[0] || event.which === ArrowUp[1]) {
            event.preventDefault();
            return focousFirstElement();
        }
        if (event.key === ArrowDown[0] || event.which === ArrowDown[1]) {
            event.preventDefault();
            return focousFirstElement();
        }
        if (event.key === Enter[0] || event.which === Enter[1]) return submitValueInput(event);
        if (event.key === Escape[0] || event.which === Escape[1]) return cancelInteractions();
    };

    return (
        <div
            className={[styles.autoCompleteContainer, className].join(' ')}
            onClick={openAutoCompleteOptions}
            onKeyUp={a11yKeyBoardEventHandler(openAutoCompleteOptions)}
            role="button"
            tabIndex={0}
            style={style}
        >
            <div
                className={getContainerClassName()}
                ref={autoCompleteRef}
            >
                <span className={styles.autoCompleteLabel}>
                    <div className={getPreventDivClassName()} />

                    <input
                        {...props}
                        id={id}
                        placeholder={placeholder}
                        ref={autoCompleteInputRef}
                        value={selectedValue?.label || ''}
                        onChange={onChangeText}
                        disabled={disabled}
                        readOnly={readOnly || !autoComplete}
                        onKeyDown={onKeyDown}
                        onFocus={openAutoCompleteOptions}
                    />
                </span>

                <span
                    className={styles.autoCompleteIconWrapper}
                    title="Expand options"
                >
                    <i className={getIconClassName()} />
                </span>
            </div>

            {!!props.error && <small className={styles.errorMessage}>{props.error}</small>}

            <AutoCompleteOptions
                autoCompleteRef={autoCompleteRef}
                ref={ulRef}
            />
        </div>
    );
}

export default React.forwardRef(AutoCompleteSelector);
