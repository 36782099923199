import * as React from 'react';

import { IconMenuLink } from 'components';
import User from 'domain/model/User';

import styles from './Integrations.module.scss';

const Integrations: React.FC = ({ children }) => {
    const Content = (
        <>
            <div className={'pageTitleCont'}>
                <h2 className={'title-main'}>
                    Integrations
                    <span className={'title-section-marker'}> Dashboard </span>
                </h2>
            </div>

            <div className={[styles.presentText, 'wrapper-cont'].join(' ')}>
                {User.isSuperAdmin() && (
                    <IconMenuLink
                        to="/integrations/customer-integrations"
                        icon="k-i-user"
                        title="Go to the Customer Integrations"
                    >
                        Customer Integration
                    </IconMenuLink>
                )}

                <IconMenuLink
                    to="/integrations/integration-setup"
                    icon="k-i-grid-layout"
                    title="Go to the Integration Setup"
                >
                    Integration Setup
                </IconMenuLink>
            </div>
        </>
    );
    return <div>{children ? children : Content}</div>;
};

export default Integrations;
