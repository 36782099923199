import * as React from 'react';
import { NotAuthorizedModal } from '../../components';

const NotAuthorized = props => {
    const Content = (
        <>
            <NotAuthorizedModal show={true} />
            <div className="container-fluid">
                <div className="row">
                    <h2 className="mx-5 mt-5 mb-3 col-10 offset-2 title-main">
                        Not Authorized
                        <span className="title-section-marker"> Page </span>
                    </h2>
                    <div className="mx-5 col-10 offset-2">
                        <div className="text-center my-5">
                            <span className="k-icon k-i-warning icon-alert"></span>
                            <p className="mt-3">
                                You can not acesses this content! We will send you back to home page.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    return <div>{props.children ? props.children : Content}</div>;
};

export default NotAuthorized;
