import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { queryClient } from '../../../../react-query/queryClient';
import { queryKeys } from '../../../../react-query/constants';
import {
    getMarketoV2IntegrationParameters,
    getMarketoV2IntegrationActivityTypes,
    getIntegrationSetupByIdMarketo,
    editIntegrationSetupSelectedMarketo,
    createIntegrationSetupMarketo,
    getNotificationsEmailList,
} from '../../../../services/apiIntegration';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Checkbox as KendoCheckBox } from '@progress/kendo-react-inputs';
import { Modal, TabList, Checkbox, Alert, AutoComplete } from '../../../../components';
import { removeEspecialCharacField } from '../../../../shared/helpers';

import styles from './IntegrationSetupModals.module.scss';

const TabBars = {
    GeneralSettings: 'general-settings',
    Notifications: 'notifications',
};

const MarketoV2Modal = props => {
    const { visible, setVisible, mode, instance, setInstance, customer } = props;
    //Form Model
    //=====================
    const inicialForm = React.useMemo(
        () => ({
            integrationId: instance?.integrationId,
            clientId: customer?.clientId,
            name: '',
            apiClientId: '',
            apiClientSecret: '',
            host: '',
            hourOptionId: 1,
            minuteOptionId: 1,
            activityTypes: [],
            active: true,
            // timeZone: new Date().getTimezoneOffset()
            timeZone: 300,
            notificationEmails: [],
        }),
        [customer, instance]
    );
    const [selectedTab, setSelectedTab] = React.useState(TabBars.GeneralSettings);
    const [form, setForm] = React.useState(inicialForm);
    const [notificationsState, setNotificationsState] = React.useState();
    // const [httpProtocol, setHttpProtocol] = React.useState('');
    const [validation, setValidation] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [email, setEmail] = React.useState(0);
    /**
     * Function that verify if the user put a valid info inside the fields
     */
    const invalidateFields = () => {
        const fieldsId = ['api-client-secretId', 'api-client-id', 'host-url'];
        setErrorMsg('Please check the highlighted fields and confirm if they are right or try again later');
        for (const id in fieldsId) {
            if (Object.hasOwnProperty.call(fieldsId, id)) {
                const elementId = fieldsId[id];
                const element = document.getElementById(elementId);
                element.setCustomValidity('Invalid element');
            }
        }
    };
    /**
     * Function that removes the warnings of invalid input field
     */
    const removeErrorMessages = React.useCallback(() => {
        const fieldsId = ['api-client-secretId', 'api-client-id', 'host-url'];
        setErrorMsg('');
        for (const id in fieldsId) {
            if (Object.hasOwnProperty.call(fieldsId, id)) {
                const elementId = fieldsId[id];
                const element = document.getElementById(elementId);
                element.setCustomValidity('');
            }
        }
    }, []);
    /**
     * Call the editIntegrationSetupSelected API using the react-query with useQuery
     */
    const { mutate: mutatePut } = useMutation(
        mutationParameters => {
            return editIntegrationSetupSelectedMarketo(mutationParameters);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                setVisible(false);
                setInstance();
                toast.success('Save Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                setVisible(false);
                setInstance();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the createIntegrationSetup API using the react-query with useQuery
     */
    const { mutate: mutatePost } = useMutation(
        mutationParameters => {
            return createIntegrationSetupMarketo(mutationParameters);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                setVisible(false);
                setInstance();
                toast.success('Save Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                setVisible(false);
                setInstance();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the getMarketoV2IntegrationParameters API using the react-query with useQuery
     */
    const { data: marketoV2IntegrationParameters } = useQuery(
        queryKeys.marketoV2IntegrationParameters,
        getMarketoV2IntegrationParameters,
        {
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                setInstance();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Call the getMarketoV2IntegrationActivityTypes API using the react-query with useQuery
     */
    const { data: marketoV2IntegrationActivityTypes, refetch: getMarketoV2ActivityTypes } = useQuery(
        [
            queryKeys.marketoV2IntegrationActivityTypes,
            {
                host: form.host,
                apiClientId: form.apiClientId,
                apiClientSecret: form.apiClientSecret,
            },
        ],
        getMarketoV2IntegrationActivityTypes,
        {
            enabled: false,
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            notifyOnChangeProps: ['isFetching', 'data', 'error'],
            onError: invalidateFields,
            onSuccess: removeErrorMessages,
        }
    );
    /**
     * Call the getIntegrationsList API using the react-query with useQuery
     */
    const { data: integrationSetupByIdMarketo } = useQuery(
        [queryKeys.integrationSetupByIdMarketo, instance, { timeZone: inicialForm.timeZone }],
        getIntegrationSetupByIdMarketo,
        {
            enabled: instance !== null && mode === 'edit' && typeof marketoV2IntegrationParameters !== 'undefined',
            retry: 3,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            onError: error => {
                console.log(error);
                setVisible(false);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Call the getNotificationsEmailList API using the react-query with useQuery
     */
    const { data: notificationsEmailListReturn } = useQuery(
        [queryKeys.notificationsEmailsList, customer],
        getNotificationsEmailList,
        {
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Function that closes the modal
     */
    const handleClose = () => {
        setVisible(false);
        setInstance();
    };
    /**
     * Function that returns the HTML of the check colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the check colunm per line on the grid
     */
    const checkColunm = props => {
        return (
            <td>
                <Checkbox
                    value={props.dataItem.id}
                    id={props.dataItem.id}
                    checked={form.activityTypes?.some(i => i === props.dataItem.id)}
                    onChange={evt => checkboxClick(evt, props.dataItem.id)}
                />
            </td>
        );
    };
    /**
     * Function that add a selected item to the selected list
     * @param {*} id Propertie that passes the id to add to the list
     */
    const addToSelectedList = React.useCallback(
        id => setForm({ ...form, activityTypes: [...form.activityTypes, id] }),
        [form]
    );
    /**
     * Function that remove a selected item to the selected list
     * @param {*} id Propertie that passes the id to remove from the list
     */
    const removeToSelectedList = React.useCallback(
        id => setForm({ ...form, activityTypes: form.activityTypes.filter(sl => sl !== id) }),
        [form]
    );
    /**
     * Function that reset the activity types selected list
     */
    const resetSelectedList = React.useCallback(() => setForm({ ...form, activityTypes: [] }), [form]);
    /**
     * Function that controls all checkbox change events to add or remove the item into selected list of itens
     * @param {*} event Property that passes the checkbox element to get the check info
     * @param {*} value Property that passes the id
     */
    const checkboxClick = React.useCallback(
        (e, value) => {
            e.target.checked ? addToSelectedList(value) : removeToSelectedList(value);
        },
        [addToSelectedList, removeToSelectedList]
    );
    /**
     * Function that do a GET request for activity types when user have filled up 3 required fields.
     */
    const searchForActivityTypes = React.useCallback(() => {
        const canSearchForActivityTypes = !!form.host && !!form.apiClientId && !!form.apiClientSecret;
        if (canSearchForActivityTypes && !marketoV2IntegrationActivityTypes) {
            getMarketoV2ActivityTypes();
        }
    }, [form, getMarketoV2ActivityTypes, marketoV2IntegrationActivityTypes]);
    /**
     * UseEffect Function that call the activity types on edit mode
     */
    React.useEffect(() => {
        mode === 'edit' && !marketoV2IntegrationActivityTypes && searchForActivityTypes();
    }, [mode, searchForActivityTypes, form, marketoV2IntegrationActivityTypes]);
    /**
     * UseEffect that observe the return of integrationSetupById API
     */
    React.useEffect(() => {
        if (integrationSetupByIdMarketo) {
            //temporary solution for put passing a default valur always
            //==========================================================
            integrationSetupByIdMarketo.hourOptionId = 1;
            integrationSetupByIdMarketo.minuteOptionId = 1;
            integrationSetupByIdMarketo.timeZone = 300;
            setForm({
                ...inicialForm,
                ...integrationSetupByIdMarketo,
            });
        }
    }, [integrationSetupByIdMarketo, inicialForm]);
    /**
     * UseEffect that observe validation form
     */
    React.useEffect(() => {
        if (
            form.clientId === '' ||
            form.integrationId === '' ||
            form.name === '' ||
            form.apiClientId === '' ||
            form.apiClientSecret === '' ||
            form.host === '' ||
            form.hourOptionId === '' ||
            form.minuteOptionId === '' ||
            form.activityTypes?.length === 0
        ) {
            setValidation(true);
        } else {
            setValidation(false);
        }
    }, [form, mode]);
    /**
     * UseEffect that clear the error message when necessary.
     */
    React.useEffect(() => {
        !!marketoV2IntegrationActivityTypes &&
            !!marketoV2IntegrationActivityTypes.length &&
            !!errorMsg &&
            removeErrorMessages();
    }, [marketoV2IntegrationActivityTypes, removeErrorMessages, errorMsg]);
    /**
     * UseEffect that observe the return of notificationsEmailListReturn API
     */
    React.useEffect(() => {
        if (integrationSetupByIdMarketo) {
            if (notificationsEmailListReturn) {
                setNotificationsState(() => {
                    let stateList = notificationsEmailListReturn;
                    integrationSetupByIdMarketo.notificationEmails &&
                        integrationSetupByIdMarketo.notificationEmails.forEach(el => {
                            if (notificationsEmailListReturn?.some(res => res.email === el)) {
                                stateList = stateList.filter(res => res.email !== el);
                            }
                        });
                    return stateList;
                });
            }
        } else {
            notificationsEmailListReturn && setNotificationsState(notificationsEmailListReturn);
        }
    }, [notificationsEmailListReturn, integrationSetupByIdMarketo]);
    /**
     * The HTML content of the IntegrationModal Component
     */
    const tabs = [
        {
            id: TabBars.GeneralSettings,
            label: 'General Settings',
        },
        {
            id: TabBars.Notifications,
            label: 'Notifications',
        },
    ];
    /**
     * Function that return the modal className
     * @returns {string}
     */
    const getModalClassNames = () => {
        const baseClassNames = [styles.modal, styles.wrapperDialog];
        if (!!marketoV2IntegrationActivityTypes) baseClassNames.push(styles.displayMaxContent);
        return baseClassNames.join(' ');
    };
    return (
        <div>
            {marketoV2IntegrationParameters && (
                <Modal
                    show={visible}
                    closeBtnFunc={handleClose}
                    closeBtn={handleClose}
                    tabModal={true}
                    className={getModalClassNames()}
                    title={
                        mode === 'create' ? 'Create External Service Integration' : 'Edit External Service Integration'
                    }
                >
                    <TabList
                        selectedTabId={selectedTab}
                        tabs={tabs}
                        onChange={({ id }) => setSelectedTab(id)}
                    />
                    <div className={styles.tab}>
                        {/* TAB 1 - General Settings*/}
                        {selectedTab === TabBars.GeneralSettings && (
                            <div className={styles.tabCont}>
                                <form>
                                    {/* Instance Name */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="instance-name"
                                        >
                                            Instance Name: *
                                        </label>
                                        <input
                                            id="instance-name"
                                            className={styles.fieldIntegration}
                                            maxLength={'1000'}
                                            value={form?.name}
                                            onChange={evt => {
                                                form.name = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                    {/* Client Secret Id */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="api-client-secretId"
                                        >
                                            Client Secret: *
                                        </label>
                                        <input
                                            id="api-client-secretId"
                                            className={styles.fieldIntegration}
                                            maxLength={'1000'}
                                            value={form?.apiClientSecret}
                                            onChange={evt => {
                                                form.apiClientSecret = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                                if (form.activityTypes?.length > 0) resetSelectedList();
                                            }}
                                            onBlur={searchForActivityTypes}
                                            type="text"
                                        />
                                    </div>
                                    {/* Client Id */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="api-client-id"
                                        >
                                            Client Id: *
                                        </label>
                                        <input
                                            id="api-client-id"
                                            value={form?.apiClientId}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.apiClientId = evt.target.value;
                                                setForm({ ...form });
                                                if (form.activityTypes?.length > 0) resetSelectedList();
                                            }}
                                            onBlur={searchForActivityTypes}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* Host */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="host-url"
                                        >
                                            Host Address: *
                                        </label>
                                        <input
                                            id="host-url"
                                            value={form?.host}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.host = evt.target.value;
                                                setForm({ ...form });
                                                if (form.activityTypes?.length > 0) resetSelectedList();
                                            }}
                                            onBlur={searchForActivityTypes}
                                            placeholder="Host URL"
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* Integration Status */}
                                    {mode === 'edit' && (
                                        <div className={styles.wrapperFields}>
                                            <label
                                                className={styles.labelField}
                                                htmlFor="integration-status-checkbox"
                                            >
                                                Integration Status:
                                            </label>
                                            <div
                                                className={
                                                    form?.active
                                                        ? [styles.checkFix, styles.wrapperCheckbox, styles.green].join(
                                                              ' '
                                                          )
                                                        : [styles.checkFix, styles.wrapperCheckbox, styles.red].join(
                                                              ' '
                                                          )
                                                }
                                            >
                                                <KendoCheckBox
                                                    id="integration-status-checkbox"
                                                    className={styles.checkIntegrationSetup}
                                                    value={form?.active}
                                                    onChange={evt => {
                                                        form.active = evt.target.value;
                                                        setForm({ ...form });
                                                    }}
                                                    label={form?.active ? 'Active' : 'Inactive'}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </form>
                                {props.customer && marketoV2IntegrationActivityTypes?.length > 0 && (
                                    <>
                                        <div
                                            className={
                                                !marketoV2IntegrationActivityTypes?.length > 0
                                                    ? [styles.headerTitleFull, styles.headerTitleFullDisabled].join(' ')
                                                    : styles.headerTitleFull
                                            }
                                        >
                                            <span>Activity Types*</span>
                                        </div>
                                        <div className={styles.gridCont}>
                                            <Grid
                                                style={{}}
                                                className={'grid'}
                                                data={marketoV2IntegrationActivityTypes}
                                            >
                                                <GridColumn
                                                    field=""
                                                    title=""
                                                    className={'alignCenter'}
                                                    width="42px"
                                                    cell={checkColunm}
                                                />
                                                <GridColumn
                                                    field="description"
                                                    title="Name"
                                                    width="200px"
                                                />
                                                <GridColumn
                                                    field="note"
                                                    title="Description"
                                                />
                                            </Grid>
                                        </div>
                                    </>
                                )}
                                {errorMsg && <Alert className={styles.errorAlert}>{errorMsg}</Alert>}
                            </div>
                        )}
                        {/* TAB 2 - Notifications*/}
                        {selectedTab === TabBars.Notifications && (
                            <div
                                title="Notifications"
                                className={styles.tacticMapping}
                            >
                                <>
                                    <div className={styles.headerTab}>
                                        <div className={[styles.headerTitle, styles.fullWidth].join(' ')}>
                                            <span>E-mail</span>
                                        </div>
                                        <div style={{ width: '35px', height: '35px' }} />
                                    </div>
                                    {form?.notificationEmails?.length > 0 &&
                                        form?.notificationEmails?.map((stage, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={[styles.wrapperFields, styles.tactic].join(' ')}
                                                >
                                                    <input
                                                        type="text"
                                                        disabled={true}
                                                        className={[
                                                            styles.fieldIntegration,
                                                            styles.toolTipInput,
                                                            styles.tactic,
                                                        ].join(' ')}
                                                        value={form.notificationEmails[index]}
                                                    />
                                                    <div className={styles.wrapperBtnPlus}>
                                                        {form?.notificationEmails[index] && (
                                                            <button
                                                                title={'To remove this e-mail'}
                                                                onClick={() => {
                                                                    notificationsState.push(
                                                                        notificationsEmailListReturn.find(
                                                                            el =>
                                                                                el.email ===
                                                                                form.notificationEmails[index]
                                                                        )
                                                                    );
                                                                    form.notificationEmails =
                                                                        form.notificationEmails?.filter(
                                                                            el => el !== form.notificationEmails[index]
                                                                        );
                                                                    setForm({ ...form });
                                                                }}
                                                            >
                                                                <i className="k-icon k-i-delete" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {notificationsState.length > 0 ? (
                                        <div className={[styles.wrapperFields, styles.tactic].join(' ')}>
                                            <AutoComplete
                                                id="select-notification-email"
                                                className={[styles.dropdownFieldFull, styles.fieldHolderToolTip].join(
                                                    ' '
                                                )}
                                                defaultValue={{ value: 0, label: 'Select E-mail' }}
                                                value={email}
                                                onChange={value => setEmail(value)}
                                                options={notificationsState?.map(el => ({
                                                    value: el.email,
                                                    label: el.email,
                                                }))}
                                                autoComplete={false}
                                            />
                                            <div className={styles.wrapperBtnPlus}>
                                                <button
                                                    className={email === 0 ? styles.disabled : ''}
                                                    title={'Add e-mail'}
                                                    disabled={email === 0}
                                                    onClick={() => {
                                                        form.notificationEmails?.push(email);
                                                        setNotificationsState(
                                                            notificationsState.filter(i => i.email !== email)
                                                        );
                                                        setForm({ ...form });
                                                        setEmail(0);
                                                    }}
                                                >
                                                    <i className="k-icon k-i-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        form?.notificationEmails?.length === 0 && (
                                            <div className={styles.warningMsg}>
                                                <span className={'k-icon k-i-warning .k-i-exception'}></span>
                                                <p>
                                                    There is no <b>E-mail list</b> to show to the customer.
                                                </p>
                                            </div>
                                        )
                                    )}
                                    <div className={styles.warnningMessage}>
                                        You can receive error or partial error messages in your e-mail to understand
                                        what happened and run the process again.
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                    {/* Action Buttons */}
                    <div className={styles.wrapperActions}>
                        <span className={styles.warningText}>
                            <b>Attention:</b> Fill in all required <b>(*)</b> fields to proceed and at least one
                            Activity Type
                        </span>
                        <button
                            className={validation ? [styles.confirmBtn, styles.disabled].join(' ') : styles.confirmBtn}
                            title={validation ? 'Still have some fields to fill to be able to save!' : ''}
                            onClick={mode === 'create' ? () => mutatePost(form) : () => mutatePut(form)}
                            disabled={validation}
                        >
                            Save Integration
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default MarketoV2Modal;
