import { useAuth } from 'oidc-react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';

import { isDrawerSideBarExpandedAtom } from 'atons/components/drawerAtoms';

import DrawerMenu from './DrawerMenu/DrawerMenu';

import User from '../../../domain/model/User';

import styles from './DrawerSideBar.module.scss';

interface DrawerSideBarProps {}

const DrawerSideBar: React.FC<DrawerSideBarProps> = () => {
    const [isSideBarExpanded, setIsSideBarExpanded] = useAtom(isDrawerSideBarExpandedAtom);
    const location = useLocation();

    const auth = useAuth();

    const isMenuActive = (path: string | string[]) => {
        if (typeof path === 'string') return location.pathname === path;
        return path.some(url => url === location.pathname);
    };

    /**
     * Effect that observes the url to menu expand or not
     */
    React.useEffect(() => {
        const menusWithSubMenus: string[] = [
            '/integrations',
            '/customfields',
            '/integrations/integration-setup',
            '/integrations/customer-integrations',
            '/customfields/attributes',
            '/customfields/groups',
        ];
        if (menusWithSubMenus.some(menu => menu === location.pathname)) {
            setIsSideBarExpanded(true);
        } else {
            setIsSideBarExpanded(false);
        }
    }, [location, setIsSideBarExpanded]);

    return (
        <div className={isSideBarExpanded ? styles.sideBar : [styles.sideBar, styles.retractSideBar].join(' ')}>
            <ul>
                <li>
                    <DrawerMenu
                        to="/"
                        selectedClassName={styles.selectedLinkHome}
                        active={isMenuActive('/')}
                        iconClassName="k-i-home"
                    >
                        Home
                    </DrawerMenu>
                </li>
                <li>
                    <DrawerMenu
                        to="/integrations"
                        active={isMenuActive('/integrations')}
                        iconClassName="k-i-inherited"
                        trailingIconClassName={
                            isMenuActive([
                                '/integrations',
                                '/integrations/integration-setup',
                                '/integrations/customer-integrations',
                            ])
                                ? ['k-i-arrow-chevron-right', styles.sectionOpen].join(' ')
                                : ['k-i-arrow-chevron-right', styles.sectionClose].join(' ')
                        }
                    >
                        Integrations
                    </DrawerMenu>
                    {isMenuActive([
                        '/integrations',
                        '/integrations/integration-setup',
                        '/integrations/customer-integrations',
                    ]) && (
                        <ul>
                            {typeof auth.userData?.profile?.role !== 'undefined' && (
                                <li>
                                    <DrawerMenu
                                        to="/integrations/customer-integrations"
                                        active={isMenuActive('/integrations/customer-integrations')}
                                    >
                                        Customer Integration
                                    </DrawerMenu>
                                </li>
                            )}
                            <li>
                                <DrawerMenu
                                    to="/integrations/integration-setup"
                                    active={isMenuActive('/integrations/integration-setup')}
                                >
                                    Integration Setup
                                </DrawerMenu>
                            </li>
                        </ul>
                    )}
                </li>
                {User.isSuperAdmin() && (
                    <li>
                        <DrawerMenu
                            to="/customfields"
                            active={isMenuActive('/customfields')}
                            iconClassName="k-i-ungroup"
                            trailingIconClassName={
                                isMenuActive(['/customfields', '/customfields/attributes', '/customfields/groups'])
                                    ? ['k-i-arrow-chevron-right', styles.sectionOpen].join(' ')
                                    : ['k-i-arrow-chevron-right', styles.sectionClose].join(' ')
                            }
                        >
                            Custom Field Dashboard
                        </DrawerMenu>

                        {isMenuActive(['/customfields', '/customfields/attributes', '/customfields/groups']) && (
                            <ul>
                                {typeof auth.userData?.profile?.role !== 'undefined' && (
                                    <li>
                                        <DrawerMenu
                                            to="/customfields/attributes"
                                            active={isMenuActive('/customfields/attributes')}
                                        >
                                            Attribute Management
                                        </DrawerMenu>
                                    </li>
                                )}
                                <li>
                                    <DrawerMenu
                                        to="/customfields/groups"
                                        active={isMenuActive('/customfields/groups')}
                                    >
                                        Groups Management
                                    </DrawerMenu>
                                </li>
                            </ul>
                        )}
                    </li>
                )}
                <li>
                    <DrawerMenu
                        to="/organization-settings"
                        active={isMenuActive('/organization-settings')}
                        iconClassName="k-i-myspace"
                    >
                        Organization Settings
                    </DrawerMenu>
                </li>
            </ul>
        </div>
    );
};

export default DrawerSideBar;
