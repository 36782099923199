/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import styles from './FixedDiv.module.scss';

/**
 * Utility component that renders a fixed div based on parent Rect. It can receive a
 * 'minWidth' property to determine if the div should stay with the 'fixed' behavior
 * or rollback to 'relative'
 * @param {{
 *  children: React.ReactNode;
 *  fixedClassName?: string;
 *  relativeClassName?: string;
 *  minWidth?: number;
 *  maxHeight?: number;
 * }} props Component Props
 * @returns {JSX.Element}
 */
const FixedDiv = ({ children, fixedClassName, relativeClassName, minWidth = 0, maxHeight }) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [divStyle, setDivStyle] = React.useState({});
    const [canHavePositionFixed, setCanHavePositionFixed] = React.useState(window.innerWidth >= minWidth);
    /**
     * @type {React.MutableRefObject<HTMLDivElement>}
     */
    const ref = React.useRef(null);

    /**
     * Property that verify if the component has the minimum width to be fixed.
     */
    const hasMinWidth = React.useMemo(() => {
        const condition = window.innerWidth >= minWidth;
        setCanHavePositionFixed(condition);
        return condition;
    }, [minWidth, window.innerWidth]);

    /**
     * Function that get the FixedDiv parent node in order to place it inside of it.
     */
    const getElementStyleBasedOnParent = React.useCallback(() => {
        if (!hasMinWidth) return setDivStyle({});

        const parentNode = ref.current?.parentNode;
        if (!parentNode) return setDivStyle({});
        const parentRect = parentNode?.getBoundingClientRect();
        if (!parentRect) return setDivStyle({});

        let elementTopPosition = parentRect.top;

        for (let doc = ref.current; doc.parentElement !== null; doc = doc.parentElement) {
            if (doc.scrollTop !== 0) {
                elementTopPosition += doc.scrollTop;
                break;
            }
        }

        setDivStyle({
            width: parentRect.width,
            top: elementTopPosition,
            left: parentRect.left,
            height: parentRect.height,
        });
    }, [hasMinWidth]);

    /**
     * Get the parent position to update component style and recalculate when
     * window resize event its triggered.
     */
    React.useEffect(() => {
        getElementStyleBasedOnParent();
        window.addEventListener('resize', getElementStyleBasedOnParent);
        setIsLoaded(true);
        return () => {
            window.removeEventListener('resize', getElementStyleBasedOnParent);
        };
    }, [getElementStyleBasedOnParent]);

    const getComponentClassName = React.useMemo(() => {
        const classes = [styles.container];
        if (!canHavePositionFixed) {
            classes.push(styles.disabled, relativeClassName);
        } else {
            classes.push(fixedClassName);
        }
        return classes.join(' ');
    }, [canHavePositionFixed, fixedClassName, relativeClassName]);

    return (
        <div
            ref={ref}
            className={getComponentClassName}
            style={{ ...divStyle, maxHeight }}
        >
            {isLoaded ? children : null}
        </div>
    );
};

export default FixedDiv;
