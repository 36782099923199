import { useEffect } from 'react';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';

import { ToolTip } from 'components';

import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import {
    currentElementHierarchyOwnerAtom,
    insertChildrenIntoSelectedAttributeAtom,
} from 'atons/AttributeManagement/dependencyAtoms';
import { AttributeRelationship } from 'domain/model/AttributeRelationship';
import AttributeTypes, { AttributeTypeId } from 'enums/AttributeTypes';
import useGetCustomFieldChildrenDependencies from 'react-query/queries/customFields/useGetCustomFieldChildrenDependencies';
import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';
import { formatPascalCaseIntoRegularText } from 'shared/helpers';

import styles from './TreeElement.module.scss';

export type TreeElementNodeDatum = CustomNodeElementProps['nodeDatum'] & {
    children: any[];
    id: number;
    customFieldTypeId: number;
    entityTypeId: number;
    entityType: string;
    name: string;
    parentId?: number;
    childrenCount: number | null;
};

interface TreeElementProps extends Omit<CustomNodeElementProps, 'nodeDatum'> {
    foreignObjectProps: { width: number; height: number; x: number };
    nodeDatum: TreeElementNodeDatum;
    isCentering: boolean;
    onCenterEnds: () => void;
}

const TreeElement: React.FC<TreeElementProps> = ({
    nodeDatum,
    foreignObjectProps,
    onNodeClick,
    isCentering,
    onCenterEnds,
    toggleNode,
}) => {
    const insertChildrenIntoSelectedAttribute = useUpdateAtom(insertChildrenIntoSelectedAttributeAtom);
    const selectedGridAttribute = useAtomValue(currentElementHierarchyOwnerAtom)!;
    const warningText = `This is the PARENT of the CURRENT ATTRIBUTE.
    You can't see his children. That would start an infinite loop.`;
    const { refetch } = useGetCustomFieldChildrenDependencies(nodeDatum.id, {
        enabled: false,
        onSuccess: data => onRequestElementChildren(data),
    });

    const isSelectedElement = nodeDatum.id === selectedGridAttribute.id;
    const isParentElement =
        !nodeDatum.parentId && !!selectedGridAttribute.parentId && selectedGridAttribute.parentId === nodeDatum.id;

    const onRequestElementChildren = (data: AttributeRelationship[]) => {
        const result = CustomFieldsAdapter.toRelationshipChildren(data);
        insertChildrenIntoSelectedAttribute({ result, id: nodeDatum.id });
    };

    const containerClassName = () => {
        const classes = [styles['tree-element']];
        if (isSelectedElement) classes.push(styles['tree-element--selected-grid-element']);
        if (isParentElement) classes.push(styles['tree-element--parent-element']);
        return classes.join(' ');
    };

    const entityTypeClassName = () => {
        const classes = [styles['tree-element__entity-type']];
        if (isSelectedElement) classes.push(styles['tree-element__entity-type--selected-grid-element']);
        if (isParentElement) classes.push(styles['tree-element__entity-type--parent-element']);
        return classes.join(' ');
    };

    const attributeTypeClassName = () => {
        const classes = [styles['tree-element__attribute-type']];
        if (isSelectedElement) classes.push(styles['tree-element__attribute-type--selected-grid-element']);
        if (isParentElement) classes.push(styles['tree-element__attribute-type--parent-element']);
        return classes.join(' ');
    };

    const childrenCountClassName = () => {
        const classes = [styles['tree-element__children-count']];
        if (isSelectedElement) classes.push(styles['tree-element__children-count--selected-grid-element']);
        if (isParentElement) classes.push(styles['tree-element__children-count--parent-element']);
        return classes.join(' ');
    };

    const onClickOnElement = evt => {
        onNodeClick(evt);
        toggleNode();
        if (
            isSelectedElement ||
            isParentElement ||
            (nodeDatum.children.length > 0 && nodeDatum.__rd3t.collapsed === false)
        )
            return;
        refetch();
    };

    useEffect(() => {
        if (isSelectedElement && isCentering) {
            document.getElementById(`selected-element-${nodeDatum.id}`)?.click();
            onCenterEnds();
        }
    }, [isSelectedElement, nodeDatum.id, isCentering, onCenterEnds]);

    return (
        <g>
            <foreignObject
                className={styles['foreignObject']}
                {...foreignObjectProps}
            >
                <div
                    role="option"
                    id={`selected-element-${nodeDatum.id}`}
                    aria-selected={false}
                    tabIndex={0}
                    onClick={onClickOnElement}
                    onKeyUp={a11yKeyBoardEventHandler(onClickOnElement as () => void)}
                    className={containerClassName()}
                >
                    {nodeDatum.childrenCount !== null && (
                        <div
                            title={`This element has ${nodeDatum.childrenCount?.toString()} dependant(s)`}
                            className={childrenCountClassName()}
                        >
                            {nodeDatum.childrenCount}
                        </div>
                    )}
                    {isParentElement && nodeDatum.__rd3t.depth !== 0 && (
                        <ToolTip
                            message={warningText}
                            className={styles['tree-element__warning']}
                        >
                            <span className="k-icon k-i-exclamation-circle" />
                        </ToolTip>
                    )}
                    {!!nodeDatum.entityType && (
                        <div
                            title={formatPascalCaseIntoRegularText(nodeDatum.entityType)}
                            className={entityTypeClassName()}
                        >
                            {formatPascalCaseIntoRegularText(nodeDatum.entityType)}
                        </div>
                    )}

                    <div
                        title={nodeDatum.name}
                        className={styles['tree-element__name']}
                    >
                        {nodeDatum.name}
                    </div>

                    {!!nodeDatum.customFieldTypeId && (
                        <div
                            title={AttributeTypes.getAttributeNameById(nodeDatum.customFieldTypeId as AttributeTypeId)}
                            className={attributeTypeClassName()}
                        >
                            {formatPascalCaseIntoRegularText(
                                AttributeTypes.getAttributeNameById(nodeDatum.customFieldTypeId as AttributeTypeId)
                            )}
                        </div>
                    )}
                </div>
            </foreignObject>
        </g>
    );
};

export default TreeElement;
