import * as React from 'react';
import { useAtomValue } from 'jotai';
import {
    CompositeFilterDescriptor,
    filterBy,
    FilterDescriptor,
    orderBy,
    SortDescriptor,
} from '@progress/kendo-data-query';
import { Page } from '@progress/kendo-react-grid/dist/npm/paging/Page';
import {
    Grid,
    GridColumn,
    GridFilterChangeEvent,
    GridPagerSettings,
    GridSortChangeEvent,
} from '@progress/kendo-react-grid';

import { customerAtom } from 'atons/Customer/atoms';

import { useGetCustomFieldGroups } from 'react-query/queries';

import IdColumn from './IdColumn/IdColumn';
import ActionColumn from './ActionColumn/ActionColumn';
import SortOrderColumn from './SortOrderColumn/SortOrderColumn';
import TextFilterCell from './TextFilterCell/TextFilterCell';

import styles from './GroupsGridList.module.scss';
import { useLocation } from 'react-router-dom';

/**
 * Proprierty for initial pagination
 */
const initialPageSetup: Page = {
    skip: 0,
    take: 25,
};
/**
 * Proprierty with some initial configurations
 */
const initialPageSettings: GridPagerSettings = {
    buttonCount: 25,
    info: true,
    pageSizes: [15, 25, 50],
    previousNext: true,
    type: 'numeric',
};
/**
 * Proprierty for initial sort
 */
const initialSort: SortDescriptor[] = [
    { field: 'sortOrder', dir: 'asc' },
    { field: 'name', dir: 'asc' },
];

const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
        {
            field: 'name',
            operator: 'startswith',
            value: '',
        },
    ],
};

const GroupsGridList: React.FC = () => {
    const customer = useAtomValue(customerAtom);
    const [page, setPage] = React.useState<Page>(initialPageSetup);
    const [pageSettings] = React.useState<GridPagerSettings>(initialPageSettings);
    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
    const [gridClassName, setGridClassName] = React.useState<string | undefined>();

    const location = useLocation();

    const { data: customFieldGroups = [], isRefetching } = useGetCustomFieldGroups();

    const onFilterChange = (event: GridFilterChangeEvent) => {
        const eventFilter = event.filter;
        const indexOfElement = eventFilter.filters.findIndex(element => (element as FilterDescriptor).field === 'name');
        // If the element doesn't exist, we should include it with the default data value.
        if (indexOfElement === -1)
            return setFilter({
                ...eventFilter,
                filters: [{ field: 'name', operator: 'startswith', value: '' }, ...eventFilter.filters],
            });
        const filterOption = eventFilter.filters[indexOfElement] as FilterDescriptor;
        const newFilter = eventFilter.filters.filter(element => (element as FilterDescriptor).field !== 'name');
        if (filterOption.value?.length > 2) {
            return setFilter({
                ...eventFilter,
                filters: [{ ...filterOption, operator: 'contains' }, ...newFilter],
            });
        }
        setFilter({ ...eventFilter, filters: [{ ...filterOption, operator: 'startswith' }, ...newFilter] });
    };

    const onSortChange = (event: GridSortChangeEvent) => {
        setSort(event.sort);
    };

    const getGridMaxHeight = (): string => {
        const APP_HEADER_BAR_HEIGHT = Math.ceil((document.getElementById('app-nav-bar')?.clientHeight ?? 0) / 10) * 10;
        const GROUPS_HEADER_CONTENT_HEIGHT =
            Math.ceil((document.getElementById('groups-page-title-content')?.clientHeight ?? 0) / 10) * 10;
        const GRID_PADDING = 30;
        const GRID_MAX_HEIGHT =
            window.innerHeight - GROUPS_HEADER_CONTENT_HEIGHT - APP_HEADER_BAR_HEIGHT - GRID_PADDING * 2;
        return location.pathname === 'manager/groups' ? `${GRID_MAX_HEIGHT}px` : `${GRID_MAX_HEIGHT - 35}px`; // If we are in manager/attributes, we would add the 35px of modal padding as well;
    };

    const getGridClassNameIfScrollIsVisible = React.useCallback((): string | undefined => {
        const element = document.getElementById(styles.groupsGrid);
        if (element === null) return '';
        return element.clientHeight >= Number(getGridMaxHeight().replace(/\D/g, ''))
            ? styles.scrollIsVisible
            : undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const element = document.getElementById(styles.groupsGrid);
        const log = () => setGridClassName(getGridClassNameIfScrollIsVisible());
        if (element !== null) {
            (element as HTMLDivElement).addEventListener('DOMNodeInserted', log);
        }
        return () => {
            if (element !== null) {
                (element as HTMLDivElement).removeEventListener('DOMNodeInserted', log);
            }
        };
    }, [customFieldGroups, getGridClassNameIfScrollIsVisible]);

    return (
        <div className={styles.gridContainer}>
            {customer ? (
                <div
                    id={styles.groupsGrid}
                    className={gridClassName}
                >
                    <Grid
                        className={[styles.grid, 'grid'].join(' ')}
                        style={{ maxHeight: getGridMaxHeight(), height: '100%' }}
                        data={
                            isRefetching
                                ? []
                                : filterBy(orderBy(customFieldGroups, sort), filter).slice(
                                      page.skip,
                                      page.take + page.skip
                                  )
                        }
                        sortable
                        sort={sort}
                        onSortChange={onSortChange}
                        filterable
                        filter={filter}
                        onFilterChange={onFilterChange}
                        onPageChange={({ page }) => setPage(page)}
                        skip={page.skip}
                        take={page.take}
                        pageable={pageSettings}
                        rowHeight={56}
                    >
                        <GridColumn
                            field="id"
                            filterable={false}
                            width="80px"
                            title="Select"
                            cell={IdColumn}
                        />

                        <GridColumn
                            field="name"
                            title="Name"
                            filterCell={TextFilterCell}
                        />

                        <GridColumn
                            field="sortOrder"
                            title="Sort Order"
                            cell={SortOrderColumn}
                            filterCell={TextFilterCell}
                        />

                        <GridColumn
                            field=""
                            title="Actions"
                            width="90px"
                            cell={ActionColumn}
                            filterable={false}
                        />
                    </Grid>
                </div>
            ) : (
                <div className="warningText">
                    <span className="k-icon k-i-warning icon-alert"></span>
                    <p className="mt-3">
                        <b>Please,</b> select a customer from the menu above to view{' '}
                        <span className="integrations-word">groups list</span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default GroupsGridList;
