import * as React from 'react';
import { useAtom } from 'jotai';

import { Button, Modal } from 'components';
import { selectedGroupsIdsAtom } from 'atons/Groups/atoms';
import { useDeleteCustomFieldGroup } from 'react-query/mutations';

import styles from './DeleteWarningModal.module.scss';
import { useGetCustomFieldGroups, useGetCustomFieldGroupsUsage } from 'react-query/queries';

interface DeleteWarningModalProps {
    show: boolean;
    closeBtnFunc: () => void;
}

const DeleteWarningModal: React.FC<DeleteWarningModalProps> = props => {
    const [selectedGroupsIds, setSelectedGroupsIds] = useAtom(selectedGroupsIdsAtom);

    const onDeleteGroupsSuccess = () => {
        setSelectedGroupsIds([]);
        props.closeBtnFunc();
    };

    const { data: customFieldGroups = [] } = useGetCustomFieldGroups();

    const { mutate: deleteCustomField } = useDeleteCustomFieldGroup({ onSuccess: onDeleteGroupsSuccess });

    const { data: customFieldGroupsUsage } = useGetCustomFieldGroupsUsage(selectedGroupsIds, {
        enabled: props.show && selectedGroupsIds.length > 0,
    });

    const deleteSelectedGroups = () => deleteCustomField(selectedGroupsIds);

    function getGroupNameById(id: number): string {
        const customFieldGroup = customFieldGroups.find(group => group.id === id);
        if (!customFieldGroup) return '';
        return customFieldGroup.name;
    }

    return (
        <Modal
            title={selectedGroupsIds.length > 1 ? 'Delete Groups' : 'Delete Group'}
            closeBtn
            {...props}
        >
            <p className={styles.paragraph}>
                Are you sure that you want to <b className={styles.danger}>PERMANENT</b> delete{' '}
                <b>{selectedGroupsIds.length}</b> {selectedGroupsIds.length > 1 ? 'groups?' : 'group?'}
            </p>

            {customFieldGroupsUsage && customFieldGroupsUsage.length > 0 && (
                <ul className={styles.usageList}>
                    {customFieldGroupsUsage.map(usageInfo => {
                        const groupName = getGroupNameById(usageInfo.id);

                        return groupName ? (
                            <li key={usageInfo.id}>
                                <b>{groupName}</b> {`is active in ${usageInfo.usageCount} attribute(s)`}
                            </li>
                        ) : (
                            <li key={usageInfo.id}>
                                Group with id <b>{usageInfo.id}</b> it's been used in {usageInfo.usageCount}{' '}
                                attribute(s)
                            </li>
                        );
                    })}
                </ul>
            )}

            <div className={styles.buttonWrapper}>
                <Button
                    text="Delete"
                    type="button"
                    btnType="cancel"
                    onClick={deleteSelectedGroups}
                />
            </div>
        </Modal>
    );
};

export default DeleteWarningModal;
