import { useQuery } from 'react-query';
import { useAtomValue } from 'jotai';

import { customerAtom } from 'atons/Customer/atoms';

import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';

import { type CustomFieldGroupsUsage, getCustomFieldGroupsUsage } from 'services/groupsApis';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';

const useGetCustomFieldGroupsUsage = (
    selectedGroupsIds: number[],
    options: ReactQueryHookOption<CustomFieldGroupsUsage[]> = { enabled: true }
) => {
    const customer = useAtomValue(customerAtom);

    const onError = useSetErrorMessage();

    return useQuery([queryKeys.getCustomFieldGroupsUsage, customer!, selectedGroupsIds], getCustomFieldGroupsUsage, {
        enabled: options.enabled && !!customer && !!customer.clientId,
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            if (typeof options?.onSuccess === 'function') options.onSuccess(data);
        },
        onError,
    });
};

export default useGetCustomFieldGroupsUsage;
