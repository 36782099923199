import * as React from 'react';
import { a11yKeyBoardEventHandler } from '../../shared/KeyboradKey';

import styles from './TabItem.module.scss';

/**
 * TabItem component
 * @param {{
 *  id: string;
 *  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
 *  className?: string;
 *  selectedClassName?: string;
 *  isSelected?: boolean;
 *  title?: string;
 *  disabled?: boolean;
 *  children: React.ReactNode;
 * }} props Component props
 * @returns {JSX.Element}
 */
function TabItem({ id, onClick, className, selectedClassName, isSelected, title, disabled, children }) {
    /**
     * Return a string containing all the component container classNames
     * @returns {string}
     */
    const getContainerClassName = () => {
        const containerClassNames = [styles.tabLinks];
        if (className) containerClassNames.push(className);
        if (disabled) containerClassNames.push(styles.disabled);
        if (isSelected) {
            containerClassNames.push(styles.tabSelected);
            if (selectedClassName) containerClassNames.push(selectedClassName);
        }
        return containerClassNames.join(' ');
    };

    return (
        <div
            id={id}
            onClick={disabled ? undefined : onClick}
            onKeyUp={disabled ? undefined : a11yKeyBoardEventHandler(onClick)}
            className={getContainerClassName()}
            title={title}
            role="tab"
            tabIndex={0}
        >
            <span>{children}</span>
        </div>
    );
}

export default TabItem;
