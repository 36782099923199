import { useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';

import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';
import { ReactQueryHookOption } from 'react-query/types';

import { deleteAttributeList } from 'services/attributeManagementApis';
import { useToast } from 'customHooks/useToast';

type ErrorResponse = {
    errors?: any[];
};

const useBulkDeleteCustomField = (options?: ReactQueryHookOption<ErrorResponse>) => {
    const customer = useAtomValue(customerAtom);

    const toast = useToast();

    return useMutation((selectedIdListToDelete: number[]) => deleteAttributeList(selectedIdListToDelete, customer!), {
        onSuccess: res => {
            queryClient.invalidateQueries([queryKeys.attributeFieldsList]);
            if (options?.onSuccess) options.onSuccess(res);
            if (res.errors && res.errors.length > 0) return;
            toast.success('Delete Successfully!');
        },
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.');
        },
        retry: 1,
    });
};

export default useBulkDeleteCustomField;
