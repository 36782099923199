import * as React from 'react';
import { useAtomValue, useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import { Badge, type VirtualizedListRender } from 'components';
import { parentDependenciesAtom, singleValueSelectedAtom, copyAtom } from 'atons/AttributeManagement/dependencyAtoms';

import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';

import styles from './OptionList.module.scss';

interface OptionListProps {
    id: number;
    name: string;
    key: string;
    selecteds: number;
    isSelected: boolean;
}

const OptionList: React.FC<VirtualizedListRender<OptionListProps>> = ({ index, item, style }) => {
    const parentDependencies = useAtomValue(parentDependenciesAtom);
    const [selected, setSelected] = useAtom(singleValueSelectedAtom);
    const copyUpdate = useUpdateAtom(copyAtom);

    const classNames = React.useMemo(() => {
        const classes = [styles.option];
        if (selected === item.id) classes.push(styles.selected);
        return classes.join(' ');
    }, [item, selected]);

    const copyClick = () => {
        copyUpdate(true);
    };

    return (
        <li
            className={classNames}
            key={item.id}
            style={style}
            tabIndex={0}
            onClick={() => setSelected(item.id)}
            onKeyUp={a11yKeyBoardEventHandler(() => setSelected(item.id))}
            role="option"
            aria-selected={false}
        >
            <span
                className={styles.option__text}
                title={item.name}
            >
                {item.name}
            </span>

            {!!parentDependencies &&
                parentDependencies?.parents[0]?.dependencies?.length > 0 &&
                parentDependencies?.parents[0]?.dependencies?.filter(dep => dep.parentOptionId === item.id).length >
                    0 && (
                    <>
                        <span
                            onClick={copyClick}
                            onKeyUp={a11yKeyBoardEventHandler(copyClick)}
                            className={styles.copy}
                            role="button"
                            tabIndex={0}
                        >
                            copy
                        </span>
                        <Badge>
                            {
                                parentDependencies?.parents[0]?.dependencies?.filter(
                                    dep => dep.parentOptionId === item.id
                                ).length
                            }
                        </Badge>
                    </>
                )}
        </li>
    );
};

export default OptionList;
