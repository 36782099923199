import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridRowProps } from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Page } from '@progress/kendo-react-grid/dist/npm/paging/Page';

import IdColumn from '../IdColumn/IdColumn';
import ValueColumn from '../ValueColumn/ValueColumn';
import ColorCodeColumn from '../ColorCodeColumn/ColorCodeColumn';
import SortOrderColumn from '../SortOrderColumn/SortOrderColumn';
import IsDeletedColumn from '../IsDeletedColumn/IsDeletedColumn';
import IsDefaultColumn from '../IsDefaultColumn/IsDefaultColumn';
import ActionsColumn from '../ActionsColumn/ActionsColumn';
import FilterCell from 'pages/AttributeManagement/components/AttributeManagementGrid/FilterCell';

import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';

import { usePicklistGridMaxHeight } from './usePicklistGridMaxHeight';
import { useGetCustomFieldPicklist } from 'react-query/queries';

import styles from './PicklistGrid.module.scss';

const initialPageSetup: Page = {
    skip: 0,
    take: 15,
};

const initialSort: SortDescriptor[] = [
    { field: 'sortOrder', dir: 'asc' },
    { field: 'value', dir: 'asc' },
];

const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
        {
            field: 'value',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'isDeleted',
            operator: 'contains',
            value: 'No',
        },
        {
            field: 'isDefault',
            operator: 'contains',
            value: '',
        },
    ],
};

type ExampleRow = {
    id: string;
    value: string;
    colorCode: string;
    isDefault: string;
    isDeleted: string;
    sortOrder: string;
};

interface PicklistGridProps {
    exampleRow?: ExampleRow;
}

const PicklistGrid: React.FC<PicklistGridProps> = ({ exampleRow }) => {
    const ref = React.useRef<Grid>(null);
    const [page, setPage] = React.useState<Page>(initialPageSetup);
    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);

    const { data: pickListData = [], isFetching } = useGetCustomFieldPicklist();

    const [gridMaxHeightPx, gridMaxHeight] = usePicklistGridMaxHeight();

    const rowRender = React.useCallback(
        (
            row: React.ReactElement<HTMLTableRowElement, string | React.JSXElementConstructor<any>>,
            { dataItem }: GridRowProps
        ) => {
            if (!!dataItem.id && dataItem.id === 'product-tour-item-example')
                return React.cloneElement(row, { id: dataItem.id + '_row' }, row.props.children);
            return row;
        },
        []
    );

    const loadingCell = React.useCallback(
        (
            defaultRendering: React.ReactElement<
                HTMLTableCellElement,
                string | React.JSXElementConstructor<any>
            > | null,
            props: GridCellProps
        ) => {
            const field = props.field || '';
            if (props.dataItem[field] === undefined) return <td>Loading....</td>;
            return defaultRendering;
        },
        []
    );

    const picklistDataFormatted = React.useMemo(() => {
        const elementsWithSortOrder = pickListData.filter(
            element => typeof element.sortOrder === 'number' && element.sortOrder > -1
        );
        const elementsWithoutSortOrder = pickListData.filter(element => element.sortOrder === null);
        return filterBy(
            [
                ...orderBy(CustomFieldsAdapter.toPicklistGrid(elementsWithSortOrder), sort),
                ...orderBy(CustomFieldsAdapter.toPicklistGrid(elementsWithoutSortOrder), sort),
            ],
            filter
        );
    }, [pickListData, sort, filter]);

    React.useEffect(() => {
        // @ts-ignore
        ref.current?.element.querySelectorAll('.k-textbox').forEach(el => {
            el.placeholder = 'Filter here...';
        });
        // @ts-ignore
        ref.current?.element.querySelectorAll('th').forEach(el => {
            el.title = el.innerText;
        });
    }, [picklistDataFormatted]);

    const pageSize = React.useMemo(() => Math.floor((gridMaxHeight / 46) * 1.7), [gridMaxHeight]);

    const getComponentClassName = React.useMemo((): string => {
        return gridMaxHeight < picklistDataFormatted.length * 46 ? ['grid', styles.scrollIsVisible].join(' ') : 'grid';
    }, [gridMaxHeight, picklistDataFormatted]);

    return (
        <Grid
            ref={ref}
            className={getComponentClassName}
            data={
                isFetching
                    ? []
                    : exampleRow
                    ? [exampleRow, ...picklistDataFormatted.slice(page.skip, page.skip + pageSize)]
                    : picklistDataFormatted.slice(page.skip, page.skip + pageSize)
            }
            scrollable="virtual"
            style={{ height: gridMaxHeightPx }}
            rowHeight={46}
            skip={page.skip}
            total={picklistDataFormatted.length}
            pageSize={pageSize}
            sortable={{
                mode: 'multiple',
                allowUnsort: true,
            }}
            sort={sort}
            onSortChange={({ sort }) => setSort(sort)}
            filterable={pickListData.length > 0}
            filter={filter}
            onFilterChange={({ filter }) => setFilter(filter)}
            onPageChange={({ page }) => setPage(page)}
            rowRender={rowRender}
            dataItemKey="id"
            cellRender={loadingCell}
        >
            <GridColumn
                field="id"
                title="Select"
                cell={IdColumn}
                width="80px"
                filterable={false}
            />

            <GridColumn
                field="value"
                title="Options"
                cell={ValueColumn}
                filterable
            />

            <GridColumn
                field="colorCode"
                title="Color"
                cell={ColorCodeColumn}
                filterable
            />

            <GridColumn
                field="sortOrder"
                title="Sort Order"
                cell={SortOrderColumn}
                filterable
            />

            <GridColumn
                field="isDeleted"
                title="Is Deleted"
                cell={IsDeletedColumn}
                width="110px"
                filter="boolean"
                filterable
                filterCell={FilterCell}
            />

            <GridColumn
                field="isDefault"
                title="Default"
                cell={IsDefaultColumn}
                width="110px"
                filter="boolean"
                filterable
                filterCell={FilterCell}
            />

            <GridColumn
                title="Actions"
                cell={ActionsColumn}
                width="90px"
                filterable={false}
            />
        </Grid>
    );
};

export default PicklistGrid;
