import * as React from 'react';

import LocalStorageService from '../../services/LocalStorageService';

/**
 * This hook provide utility states for a Product Tour component
 * @param {string} productTourId This is the Id of this product tour on localStorage
 * @param {{
 *  openOnFirstTimeThatSeeScreen: boolean;
 *  isReadyToOpenTooltip: boolean;
 * }} options Optional options of product tour
 */
const useProductTourUtilities = (
    productTourId,
    { openOnFirstTimeThatSeeScreen = false, isReadyToOpenTooltip = true }
) => {
    const [isProductTourActive, setIsProductTourActive] = React.useState(false);

    const visitedProductTours = React.useMemo(
        () =>
            LocalStorageService.hasItem('visitedProductTours')
                ? LocalStorageService.getItem('visitedProductTours')
                : [],
        []
    );

    const userHasAlreadyVisitedThisTour = React.useMemo(
        () => visitedProductTours.includes(productTourId),
        [productTourId, visitedProductTours]
    );

    const activeProductTour = React.useCallback(() => {
        setIsProductTourActive(true);

        if (openOnFirstTimeThatSeeScreen) {
            const currentVisitedProducts = LocalStorageService.getItem('visitedProductTours')?.filter(
                tourId => tourId !== productTourId
            );
            LocalStorageService.setItem('visitedProductTours', currentVisitedProducts);
        }
    }, [openOnFirstTimeThatSeeScreen, productTourId]);

    const closeProductTour = React.useCallback(() => {
        setIsProductTourActive(false);
        LocalStorageService.setItem('visitedProductTours', [...visitedProductTours, productTourId]);
    }, [productTourId, visitedProductTours]);

    React.useEffect(() => {
        if (!userHasAlreadyVisitedThisTour && isReadyToOpenTooltip && openOnFirstTimeThatSeeScreen) {
            activeProductTour();
        }
    }, [userHasAlreadyVisitedThisTour, activeProductTour, openOnFirstTimeThatSeeScreen, isReadyToOpenTooltip]);

    return { isProductTourActive, activeProductTour, closeProductTour };
};

export default useProductTourUtilities;
