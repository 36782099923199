import * as React from 'react';
import AttributeTypes from 'enums/AttributeTypes';

import styles from './Card.module.scss';

export interface CardProps {
    title?: string;
    block?: boolean;
    className?: string;
    warning?: string;
    customType?: AttributeTypes;
}

const Card: React.FC<CardProps> = ({ children, title, block, warning, customType, className }) => {
    const classNames = React.useMemo(() => {
        const classes = [styles.container];
        if (className) classes.push(className);
        return classes.join(' ');
    }, [className]);

    return (
        <div
            title={warning}
            className={classNames}
        >
            {block && (
                <div className={styles.block}>
                    <span className={['k-icon k-i-comment', styles.icon].join(' ')}></span>
                    <span>
                        Need to choose a <span className={styles.orange}>parent</span>
                    </span>
                </div>
            )}
            {!!title && (
                <header className={styles.cardHeader}>
                    <h3>{title}</h3>
                </header>
            )}

            <div className={[styles.children, customType !== AttributeTypes.Checkbox && styles.align].join(' ')}>
                {children}
            </div>
        </div>
    );
};

export default Card;
