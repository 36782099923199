import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import AttributeTypes, { AttributeType } from 'enums/AttributeTypes';

interface PicklistEntriesColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: { picklistEntries: number; attributeType: { description: string; id: number; name: string } };
}

const PicklistEntriesColumn: React.FC<PicklistEntriesColumnProps> = ({ dataItem }) => {
    return AttributeTypes.getByName(dataItem.attributeType.name as AttributeType) === AttributeTypes.DropDownList ? (
        <td>{dataItem.picklistEntries}</td>
    ) : (
        <td>---</td>
    );
};

export default PicklistEntriesColumn;
