import * as React from 'react';

import { Button, Modal } from 'components';
import { Groups } from 'pages';

import styles from './GroupsManagerModal.module.scss';

const GroupsManagerModal: React.FC = () => {
    const [isGroupsModalOpen, setIsGroupsModalOpen] = React.useState<boolean>(false);

    const onClickManageGroupsButton = (): void => setIsGroupsModalOpen(!isGroupsModalOpen);

    return (
        <>
            <Button
                type="button"
                text="Manage Groups"
                size="small"
                onClick={onClickManageGroupsButton}
            />

            <Modal
                show={isGroupsModalOpen}
                closeBtn
                closeBtnFunc={onClickManageGroupsButton}
                className={styles.groupsModal}
                modalContentClassName={styles.groupModalContent}
            >
                <Groups />
            </Modal>
        </>
    );
};

export default GroupsManagerModal;
