import * as React from 'react';

import styles from './VirtualizedList.module.scss';

export interface VirtualizedListRender<P = any> {
    index: number;
    style: Pick<React.CSSProperties, 'top' | 'position' | 'width' | 'height'>;
    item: P;
}

export interface VirtualizedListProps<P = any> {
    data: P[];
    render: (props: React.PropsWithChildren<VirtualizedListRender<P>>) => JSX.Element | null;
    itemHeight: number;
    loadItems?: number;
    topThreshold?: number;
    bottomThreshold?: number;
    className?: string;
}

const VirtualizedList: React.FC<VirtualizedListProps> = ({
    data = [],
    itemHeight,
    render: Render,
    bottomThreshold = 4,
    topThreshold = 4,
    loadItems = 10,
    className,
}) => {
    const [scrollTop, setScrollTop] = React.useState<number>(0);

    const onScroll = React.useCallback(
        (event: React.UIEvent<HTMLDivElement, UIEvent>) => setScrollTop(event.currentTarget.scrollTop),
        [setScrollTop]
    );

    const innerHeight = React.useMemo(() => {
        return data.length * itemHeight;
    }, [data.length, itemHeight]);

    const componentClassName = React.useMemo((): string => {
        const classNames = [styles.container];
        if (className) classNames.push(className);
        return classNames.join(' ');
    }, [className]);

    const startIndex = Math.max(Math.floor(scrollTop / itemHeight) - topThreshold, 0);
    const endIndex = Math.min(
        data.length, // don't render past the end of the list
        startIndex + loadItems + bottomThreshold
    );

    return (
        <div
            className={componentClassName}
            onScroll={onScroll}
        >
            {data.length > 0 ? (
                <ul
                    className={styles.innerHeight}
                    style={{ height: `${innerHeight}px` }}
                >
                    {data.slice(startIndex, endIndex).map((value, index) => (
                        <Render
                            key={value?.key}
                            index={index + startIndex}
                            item={value}
                            style={{
                                position: 'absolute',
                                top: `${(index + startIndex) * itemHeight}px`,
                                width: '100%',
                                height: `${itemHeight}px`,
                            }}
                        />
                    ))}
                </ul>
            ) : (
                <div className={styles.warning}>
                    <span className={['k-icon k-i-inbox icon-alert', styles.icon].join(' ')}></span>

                    <h2>Empty list</h2>

                    <span>
                        There are no items available
                    </span>
                </div>
            )}
        </div>
    );
};

export default VirtualizedList;
