import { EntityType } from 'services/attributeManagementApis';
import { addSpaceBetweenCammelCase, alphabeticalOrder } from 'shared/helpers';

export default class EntityTypeAdapter {
    public static toEntityTypeAutocompleteFieldOptions(entityTypes: EntityType[]) {
        return alphabeticalOrder(entityTypes, 'name').map(EntityTypeAdapter.toAutocompleteField);
    }

    private static toAutocompleteField(entityType: EntityType) {
        return {
            label: addSpaceBetweenCammelCase(entityType.name),
            value: entityType.id,
        };
    }
}
