import * as React from 'react';

import styles from './Tag.module.scss';

type TagType = 'primary' | 'secondary' | 'deselect' | 'info';

export interface TagProps {
    type?: TagType;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
}

const Tag: React.FC<TagProps> = ({ type = 'primary', children, onClick, disabled, className }) => {
    const classNames = React.useMemo((): string => {
        const classes = [styles.tag, styles[type]];
        if (className) classes.push(className);
        return classes.join(' ');
    }, [className, type]);

    if (onClick && typeof onClick === 'function') {
        return (
            <button
                className={classNames}
                onClick={onClick}
                disabled={disabled}
                type="button"
            >
                {children}
            </button>
        );
    }

    return <span className={[styles.tag, styles[type]].join(' ')}>{children}</span>;
};

export default Tag;
