import { useAtom } from 'jotai';
import { Modal, Button } from 'components';
import { nextAttributeIdOfUnsavedChangesModalAtom } from 'atons/AttributeManagement/atoms';

import styles from './UnsavedChangesModal.module.scss';

interface UnsavedChangesModalProps {
    switchCustomField: (value: number) => void;
    onSave: (callback: () => void) => Promise<void>;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({ switchCustomField, onSave }) => {
    const [nextAttributeIdOfUnsavedChangesModal, setNextAttributeIdOfUnsavedChangesModal] = useAtom(
        nextAttributeIdOfUnsavedChangesModalAtom
    );

    const closeModal = () => setNextAttributeIdOfUnsavedChangesModal(null);

    const switchCustomFieldCallback = () => switchCustomField(nextAttributeIdOfUnsavedChangesModal!);

    const onClickDiscard = () => {
        switchCustomFieldCallback();
        closeModal();
    };

    const onClickSave = async () => {
        await onSave(switchCustomFieldCallback);
        closeModal();
    };

    return (
        <Modal
            show={nextAttributeIdOfUnsavedChangesModal !== null}
            title="Unsaved changes"
            closeBtn
            closeBtnFunc={closeModal}
            className={styles['unsaved-changes-modal']}
        >
            <p className={styles['unsaved-changes-modal__paragraph']}>
                It looks like you made some changes to this form. Do you want to save changes before navigating to the
                next attribute?
            </p>

            <footer className={styles['unsaved-changes-modal__footer']}>
                <Button
                    text="Discard"
                    btnType="cancel"
                    onClick={onClickDiscard}
                    type="button"
                />

                <Button
                    text="Save"
                    type="button"
                    onClick={onClickSave}
                />
            </footer>
        </Modal>
    );
};

export default UnsavedChangesModal;
