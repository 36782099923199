import * as React from 'react';
import ToolTipPortal from './ToolTipPortal';
import styles from './ToolTip.module.scss';

interface ToolTipProps {
    className?: string;
    size?: string;
    iconType?: string;
    message: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ className, size, iconType, message, children }) => {
    const [show, setShow] = React.useState(false);
    const tooltipTargetReference = React.useRef<HTMLDivElement>(null);
    /**
     * Function that show the tooltip when the mouse is on the icon
     */
    const showToolTip = () => {
        setShow(true);
    };
    /**
     * Function that hide the tooltip when the mouse is on the icon
     */
    const hideToolTip = () => {
        setShow(false);
    };
    /**
     * Function that returns the tooltip target BoundingClientRect
     * @returns {DOMRect | null} Target DOMRect properties
     */
    const getTargetBoundingClientRect = () => {
        const { current } = tooltipTargetReference;
        if (!current || !current.getBoundingClientRect) return null;
        return current.getBoundingClientRect();
    };
    if (children) {
        return (
            <div
                className={[styles['tooltip-container'], className].join(' ')}
                onMouseOver={showToolTip}
                onFocus={showToolTip}
                onMouseLeave={hideToolTip}
                onBlur={showToolTip}
                ref={tooltipTargetReference}
            >
                {children}
                <ToolTipPortal
                    isOpen={show}
                    message={message}
                    getTargetBoundingClientRect={getTargetBoundingClientRect}
                />
            </div>
        );
    }
    return (
        <div className={[className, styles.cont].join(' ')}>
            <span
                onMouseOver={showToolTip}
                onFocus={showToolTip}
                onMouseLeave={hideToolTip}
                onBlur={hideToolTip}
                className={['k-icon', iconType, styles.infoCustGoals].join(' ')}
            />
            {show && (
                <div
                    className={size ? [styles.toolTip, size].join(' ') : styles.toolTip}
                    role="tooltip"
                >
                    <span>{message}</span>
                </div>
            )}
        </div>
    );
};

export default ToolTip;
