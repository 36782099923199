import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { getParentDependencies, ParentCustomField } from 'services/attributeManagementApis';

const useGetParentDependencies = (
    customFieldId: number,
    options: ReactQueryHookOption<ParentCustomField[]> = { enabled: false }
) => {
    const customer = useAtomValue(customerAtom);

    const toast = useToast();

    return useQuery(
        [queryKeys.getParentDependencies, String(customer?.clientId), Number(customFieldId)],
        getParentDependencies,
        {
            enabled: options.enabled,
            retry: 3,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            onError: error => {
                console.log(error);
                if (options.onError) options.onError(error);
                toast.error('Something went wrong, try again later.');
            },
            onSuccess: data => {
                if (options.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetParentDependencies;
