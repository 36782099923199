import ReactDom from 'react-dom';
import * as React from 'react';

import { Modal, Button } from '..';

import styles from './ErrorModal.module.scss';

type ItemList = {
    id: number;
    name: string;
    description: string;
    children: any;
}[];

type ValidationError = {
    header: string;
    description: string;
    itemList?: ItemList;
};

export interface ErrorModalProps {
    show: boolean;
    closeBtn: boolean;
    closeBtnFunc: () => void;
    title?: string;
    className?: string;
    errors?: ValidationError[];
}

/**
 * Component that shows an error modal with some util information for user
 */
const ErrorModal: React.FC<ErrorModalProps> = ({ show, closeBtn, closeBtnFunc, errors, title, className }) => {
    const [errorStep, setErrorStep] = React.useState(0);

    const currentStep = React.useMemo<number>(() => errorStep + 1, [errorStep]);

    const onClickBackButton = (): void => setErrorStep(value => value - 1);
    const onClickNextButton = (): void => setErrorStep(value => value + 1);

    const onClickConfirmButton = (): void => {
        closeBtnFunc();
        setErrorStep(0);
    };

    return errors && errors?.length
        ? ReactDom.createPortal(
              <Modal
                  show={show}
                  closeBtn={closeBtn}
                  closeBtnFunc={closeBtnFunc}
                  className={[styles.container, className].join(' ')}
                  title={title}
              >
                  <header className={styles.modalHeader}>
                      <h3>{errors[errorStep].header}</h3>

                      {errors.length > 1 && (
                          <span className={styles.errorStepWrapper}>{`${currentStep} / ${errors.length}`}</span>
                      )}
                  </header>

                  <p className={styles.paragraphContent}>{errors[errorStep].description}</p>

                  <footer className={styles.footerActionButtonsWrapper}>
                      {errorStep > 0 ? (
                          <Button
                              text="Back"
                              type="button"
                              onClick={onClickBackButton}
                          />
                      ) : (
                          <span className={styles.space} />
                      )}

                      <Button
                          text={currentStep === errors.length ? 'Confirm' : 'Next'}
                          type="button"
                          onClick={currentStep === errors.length ? onClickConfirmButton : onClickNextButton}
                          className={currentStep === errors.length ? styles.confirmButton : undefined}
                      />
                  </footer>
              </Modal>,
              document.body
          )
        : null;
};

export default ErrorModal;
