import { useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { Customer, customerAtom } from 'atons/Customer/atoms';
import { patchCustomFieldGroup, type PatchCustomFielGroupPayload } from 'services/groupsApis';
import { useToast } from 'customHooks/useToast';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { CustomFieldGroup } from 'services/attributeManagementApis';

const usePatchCustomFieldGroup = (options?: ReactQueryHookOption<CustomFieldGroup>) => {
    const customer = useAtomValue(customerAtom);
    const onError = useSetErrorMessage();

    const toast = useToast();

    return useMutation((payload: PatchCustomFielGroupPayload) => patchCustomFieldGroup(payload, customer as Customer), {
        onSuccess: data => {
            queryClient.invalidateQueries([queryKeys.customFieldGroups]);
            queryClient.invalidateQueries([queryKeys.getCustomFieldGroup]);
            toast.success('Updated Successfully!');
            if (options?.onSuccess) options.onSuccess(data);
        },
        onError: error => {
            onError(error);
            if (options?.onError) options.onError(error);
        },
    });
};

export default usePatchCustomFieldGroup;
