import * as React from 'react';
import { useAtom } from 'jotai';

import { globalVariables } from '../../atons/globalAtons';

import User from '../../domain/model/User';
import styles from './Main.module.scss';
import { IconMenuLink } from 'components';

const Main: React.FC = ({ children }) => {
    const [global] = useAtom(globalVariables);
    const features = [
        {
            id: 'integrations',
            title: 'Go to the Integrations',
            path: '/integrations',
            label: 'Customer Integration',
            icon: 'k-i-inherited',
            isVisible: true,
        },
        {
            id: 'attribute-management',
            title: 'Go to the Custom Field Dashboard',
            path: '/customfields',
            label: 'Custom Field Dashboard',
            icon: 'k-i-ungroup',
            isVisible: User.isSuperAdmin(),
        },
        {
            id: 'organization-settings',
            title: 'Go to the Organization Settings',
            path: '/organization-settings',
            label: 'Organization Settings',
            icon: 'k-i-myspace',
            isVisible: true,
        },
    ];
    const Content = (
        <>
            <div className={[styles.dashHome, 'pageTitleCont'].join(' ')}>
                <h2 className="title-main">
                    Admin Application
                    <span className="title-section-marker"> Dashboard</span>
                </h2>
                <h3 className={styles.titleMainSubH3}>
                    Welcome,
                    <span className={styles.blackFocus}> {global.userName} </span>
                </h3>
            </div>
            <div className={styles.featureListWrapper}>
                {features.map(feature =>
                    feature.isVisible ? (
                        <IconMenuLink
                            key={feature.id}
                            to={feature.path}
                            icon={feature.icon}
                            title={feature.title}
                        >
                            {feature.label}
                        </IconMenuLink>
                    ) : null
                )}
            </div>
        </>
    );
    return <div>{children ? children : Content}</div>;
};

export default Main;
