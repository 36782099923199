import { useQuery } from 'react-query';
import { useAtomValue } from 'jotai';

import User from 'domain/model/User';

import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';

import { getEntityTypeByUserId } from 'services/attributeManagementApis';
import { customerAtom } from 'atons/Customer/atoms';

import { useGetEntityTypeList } from '..';
import { useToast } from 'customHooks/useToast';

const useGetEntityTypeByUserId = (options?: ReactQueryHookOption<string>) => {
    const customer = useAtomValue(customerAtom);

    const toast = useToast();

    const { data: entityTypeListReturn = [] } = useGetEntityTypeList();

    return useQuery([queryKeys.entityTypeUserSelect, User.getInstance()?.getUserId()!], getEntityTypeByUserId, {
        enabled: customer !== null && entityTypeListReturn?.length > 0,
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: ({ entityTypeId }) => {
            if (options?.onSuccess) options.onSuccess(String(entityTypeId));
        },
        onError: (error: any) => {
            if (error?.response?.status !== 404) {
                toast.error('Something went wrong, try again later.');
            }
        },
    });
};

export default useGetEntityTypeByUserId;
