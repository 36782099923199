import { toNumber, convertNullAndUndefinedToString, formatPascalCaseIntoRegularText } from '../shared/helpers';
import type { AttributeSettingsData, PicklistItemFormatted } from 'atons/AttributeManagement/atoms';
import {
    CustomAttributeListItemResponse,
    CustomFieldDependency,
    CustomFieldDetailResponse,
    PicklistItemResponse,
    ParentCustomField,
    TacticOptionItem,
    TacticTypeDependency,
    Dependency,
    TacticTypeRelationItem,
} from 'services/attributeManagementApis';
import { CustomAttributeListItem } from 'pages/AttributeManagement/components/AttributeManagementGrid/AttributeManagementGrid';
import { AttributeRelationshipItem } from 'domain/model/AttributeRelationship';
import { AttributeTypeId } from 'enums/AttributeTypes';

export interface PicklistItem extends Omit<PicklistItemResponse, 'isDeleted' | 'isDefault' | 'sortOrder'> {
    isDeleted: string;
    isDefault: string;
    sortOrder: string | number;
}

export type RelationShipModalParameter = Pick<
    CustomAttributeListItem,
    'attributeType' | 'id' | 'name' | 'parentCustomFields' | 'childCustomFields' | 'entityTypeId' | 'entityType'
>;

export default class CustomFieldsAdapter {
    /**
     * Returns a fomated list of Attribute Management
     * @param {any[]} attributesList data
     */
    static toAttributeManagementGrid(attributesList: CustomAttributeListItemResponse[]): CustomAttributeListItem[] {
        return attributesList.map(CustomFieldsAdapter.toAttributeManagement);
    }
    /**
     * Format a record of Attribute Management List to match front-end expectation.
     * @param {any} value
     */
    static toAttributeManagement(value: CustomAttributeListItemResponse): CustomAttributeListItem {
        const createdDate = value.createdDate
            ? new Date(
                  Number(value.createdDate.split('T')[0].split('-')[0]),
                  Number(value.createdDate.split('T')[0].split('-')[1]),
                  Number(value.createdDate.split('T')[0].split('-')[2])
              )
            : null;
        const isHidden = value.isHidden ? 'Yes' : 'No';
        const isDeleted = value.isDeleted ? 'Yes' : 'No';
        const toolTip = Boolean(value.toolTip) ? 'Yes' : 'No';
        return {
            ...value,
            createdDate,
            isHidden,
            isDeleted,
            toolTip,
        };
    }

    static toPostNewCustomField(customField, userId, entityTypeId) {
        return {
            userId,
            name: customField.name,
            attributeTypeId: toNumber(customField.attributeTypeId),
            description: customField.description,
            isRequired: customField.isRequired,
            entityTypeId,
            isDisplayForFilter: customField.isDisplayForFilter,
            abbreviationForMulti: customField.abbreviationForMulti,
            isDefault: false, // In the first moment, this property should have a static value of `false`.
            isGet: false, // In the first moment, this property should have a static value of `false`.
            attributeValidationOption: toNumber(customField.attributeValidationOption),
            readOnlyStatus: !!customField.readOnlyStatus
                ? toNumber(customField.readOnlyStatus.replace(/\D/g, ''))
                : null,
            isMultiChoice: customField.isMultiChoice,
            autoRollup: customField.autoRollup,
            isDisplayForReview: customField.isDisplayForReview,
            isCopy: customField.isCopy,
            displayInCalendar: customField.displayInCalendar,
            sortOrder: toNumber(customField.attributeSortOrder),
            isEncodeMediaCodeString: customField.isEncodeMediaCodeString,
            isUserAttribute: customField.isUserAttribute,
            userMappingField: customField.userMappingField,
            tooltip: customField.tooltip,
            fieldHeight: toNumber(customField.fieldHeight),
            fieldWidth: toNumber(customField.fieldWidth),
            customFieldGroupId: !!customField.customFieldGroupId ? toNumber(customField.customFieldGroupId) : null,
            isHidden: customField.isHidden,
            isSearchableGrid: customField.isSearchableGrid,
            maxCharLength: customField.maxCharLength,
        };
    }

    static toPatchNewCustomField(customField, userId, entityTypeId) {
        return {
            id: customField.id,
            userId,
            name: customField.name,
            attributeTypeId: toNumber(customField.attributeTypeId),
            description: customField.description,
            isRequired: customField.isRequired,
            entityTypeId,
            isDisplayForFilter: customField.isDisplayForFilter,
            abbreviationForMulti: customField.abbreviationForMulti,
            isDefault: false, // In the first moment, this property should have a static value of `false`.
            isGet: false, // In the first moment, this property should have a static value of `false`.
            attributeValidationOption: toNumber(customField.attributeValidationOption),
            readOnlyStatus: !!customField.readOnlyStatus
                ? toNumber(customField.readOnlyStatus.replace(/\D/g, ''))
                : null,
            isMultiChoice: customField.isMultiChoice,
            autoRollup: customField.autoRollup,
            isDisplayForReview: customField.isDisplayForReview,
            isCopy: customField.isCopy,
            displayInCalendar: customField.displayInCalendar,
            sortOrder: toNumber(customField.attributeSortOrder),
            isEncodeMediaCodeString: customField.isEncodeMediaCodeString,
            isUserAttribute: customField.isUserAttribute,
            userMappingField: customField.userMappingField,
            tooltip: customField.tooltip,
            fieldHeight: toNumber(customField.fieldHeight),
            fieldWidth: toNumber(customField.fieldWidth),
            customFieldGroupId: !!customField.customFieldGroupId ? toNumber(customField.customFieldGroupId) : null,
            isHidden: customField.isHidden,
            isDeleted: customField.isDeleted,
            isSearchableGrid: customField.isSearchableGrid,
            maxCharLength: customField.maxCharLength,
        };
    }

    static toAttributeSetting(response: CustomFieldDetailResponse) {
        const adaptedResponse: AttributeSettingsData = {
            id: response.id,
            name: response.name,
            description: response.description,
            attributeTypeId: response.attributeType ? String(response.attributeType.id) : '',
            canHaveParent: response.canHaveParent,
            hasParentEnum: response.hasParentEnum,
            customFieldGroupId: response.customFieldGroup ? String(response.customFieldGroup.id) : '',
            attributeSortOrder: response.sortOrder ? String(response.sortOrder) : '',
            attributeValidationOption: String(response.attributeValidationOption),
            fieldHeight: String(response.fieldHeight),
            fieldWidth: String(response.fieldWidth),
            abbreviationForMulti: response.abbreviationForMulti,
            tooltip: response.tooltip,
            isDisplayForFilter: response.isDisplayForFilter,
            isMultiChoice: response.isMultiChoice,
            isEncodeMediaCodeString: response.isEncodeMediaCodeString,
            displayInCalendar: response.displayInCalendar,
            autoRollup: response.autoRollup,
            isCopy: response.isCopy,
            isRequired: response.isRequired,
            userMappingField: response.userMappingField,
            isUserAttribute: response.isUserAttribute,
            readOnlyStatus: response.readOnlyStatus ? String(response.readOnlyStatus) : null,
            isHidden: response.isHidden,
            isDisplayForReview: response.isDisplayForReview,
            isDeleted: response.isDeleted,
            picklistEntries: response.picklistEntries,
            childCustomFields: response.childCustomFields,
            parentCustomFields: response.parentCustomFields,
            entityType: response.entityType,
            entityTypeId: response.entityTypeId,
            isSearchableGrid: response.isSearchableGrid,
            maxCharLength: response.maxCharLength,
        };

        for (const key of Object.keys(adaptedResponse)) {
            adaptedResponse[key] =
                key !== 'readOnlyStatus' ? convertNullAndUndefinedToString(adaptedResponse[key]) : adaptedResponse[key];
        }

        return adaptedResponse;
    }

    static postNewPicklistItem(newPicklistRowValues: PicklistItemFormatted, userId: string) {
        return {
            userId,
            value: newPicklistRowValues.value,
            colorCode: String(newPicklistRowValues.color).replace(/^#/g, ''),
            isDefault: newPicklistRowValues.isDefault === 'Yes',
            isDeleted: false,
            sortOrder: !!String(newPicklistRowValues.sortOrder) ? Number(newPicklistRowValues.sortOrder) : null,
        };
    }

    static patchPicklistItem(picklistItem: {
        value: string;
        color: string;
        isDefault: 'Yes' | 'No';
        isDeleted: 'Yes' | 'No';
        sortOrder: string;
    }) {
        return {
            value: picklistItem.value,
            colorCode: !!picklistItem.color ? String(picklistItem.color).replace(/^#/g, '') : picklistItem.color,
            isDefault: picklistItem.isDefault === 'Yes',
            isDeleted: picklistItem.isDeleted === 'Yes',
            sortOrder: !!String(picklistItem.sortOrder) ? Number(picklistItem.sortOrder) : null,
        };
    }

    static deletePicklistItems(selectedPicklistItems: number[]) {
        return {
            ids: selectedPicklistItems,
        };
    }

    /**
     * Returns a fomated list of Picklist
     */
    static toPicklistGrid(picklistList: PicklistItemResponse[]): PicklistItem[] {
        return picklistList.map(CustomFieldsAdapter.toPicklistItem);
    }
    /**
     * Format a record of Picklist List to match front-end expectation.
     */
    static toPicklistItem(value: PicklistItemResponse): PicklistItem {
        const isDeleted = value.isDeleted ? 'Yes' : 'No';
        const isDefault = value.isDefault ? 'Yes' : 'No';
        const sortOrder = value.sortOrder !== null && typeof value.sortOrder === 'number' ? value.sortOrder : '';

        return {
            ...value,
            isDeleted,
            isDefault,
            sortOrder,
        };
    }

    static toAttributeTypeList(customFieldTypes) {
        return customFieldTypes?.map(CustomFieldsAdapter.toAttributeType);
    }

    static toAttributeType({ id: value, name: label }) {
        return {
            value,
            label: formatPascalCaseIntoRegularText(label),
        };
    }

    static toRelationshipModal({
        attributeType,
        id,
        name,
        parentCustomFields,
        childCustomFields,
        entityTypeId,
        entityType,
    }: RelationShipModalParameter): AttributeRelationshipItem {
        if (!!parentCustomFields.length && !!childCustomFields.length) {
            const children = CustomFieldsAdapter.toRelationshipChildren(childCustomFields, parentCustomFields[0].id);
            return {
                id: parentCustomFields[0].id,
                customFieldTypeId: parentCustomFields[0].customFieldTypeId,
                name: parentCustomFields[0].name,
                entityTypeId: parentCustomFields[0].entityTypeId,
                entityType: parentCustomFields[0].entityType,
                childrenCount: null,
                children: [
                    {
                        id,
                        customFieldTypeId: attributeType.id as AttributeTypeId,
                        childrenCount: children.length,
                        name,
                        entityTypeId,
                        entityType,
                        children,
                        parentId: parentCustomFields[0].id,
                    },
                ],
            };
        }

        if (!!parentCustomFields.length) {
            const children = CustomFieldsAdapter.toRelationshipChildren(childCustomFields, parentCustomFields[0].id);
            return {
                id: parentCustomFields[0].id,
                customFieldTypeId: parentCustomFields[0].customFieldTypeId,
                name: parentCustomFields[0].name,
                entityTypeId: parentCustomFields[0].entityTypeId,
                entityType: parentCustomFields[0].entityType,
                childrenCount: null,
                children: [
                    {
                        id,
                        customFieldTypeId: attributeType.id as AttributeTypeId,
                        childrenCount: children.length,
                        name,
                        entityTypeId,
                        entityType,
                        children,
                        parentId: parentCustomFields[0].id,
                    },
                ],
            };
        }

        if (!!childCustomFields.length) {
            const children = CustomFieldsAdapter.toRelationshipChildren(childCustomFields, id);
            return {
                id,
                customFieldTypeId: attributeType.id as AttributeTypeId,
                childrenCount: children.length,
                name,
                entityTypeId,
                entityType,
                children,
            };
        }

        throw new Error('There are no parentCustomFields and childCustomFields');
    }

    static toRelationshipChildren(
        childCustomFields: CustomAttributeListItem['childCustomFields'],
        parentId?: number
    ): AttributeRelationshipItem[] {
        return childCustomFields.map(child => ({
            id: child.id,
            name: child.name,
            childrenCount: child.childrenCount,
            customFieldTypeId: child.customFieldTypeId,
            entityTypeId: child.entityTypeId,
            entityType: child.entityType,
            children: [],
            parentId,
        }));
    }

    static toGroupNameColumn(customFieldGroup: CustomAttributeListItemResponse['customFieldGroup']): string {
        if (customFieldGroup && customFieldGroup.name) return customFieldGroup.name;
        return '';
    }

    static tacticTypeOptionListToPicklistOptions(tacticOptions: TacticOptionItem[]): PicklistItemResponse[] {
        return tacticOptions.map(CustomFieldsAdapter.tacticTypeOptionToPichlistOption);
    }

    private static tacticTypeOptionToPichlistOption(tacticOptions: TacticOptionItem): PicklistItemResponse {
        return {
            colorCode: '',
            id: +tacticOptions.id,
            isDefault: false,
            isDeleted: false,
            sortOrder: null,
            value: tacticOptions.value,
        };
    }

    static customFieldDependencyToTacticTypeDependency(
        customFieldDependency: CustomFieldDependency
    ): TacticTypeDependency {
        const parents = customFieldDependency.parents.map(CustomFieldsAdapter.customFieldParentToTacticTypeParent);

        return {
            parents,
            userId: customFieldDependency.userId,
        };
    }

    private static customFieldParentToTacticTypeParent(
        customFieldDependencyParent: ParentCustomField
    ): Pick<ParentCustomField, 'dependencies'> {
        return { dependencies: customFieldDependencyParent.dependencies };
    }

    static tacticTypeRelationItemsToParentCustomFields(tacticItems: TacticTypeRelationItem[]): ParentCustomField[] {
        return [
            {
                customFieldTypeId: -1,
                id: -1,
                dependencies: tacticItems.map(CustomFieldsAdapter.tacticOptionItemToParentCustomField),
            },
        ];
    }

    private static tacticOptionItemToParentCustomField(tacticItem: TacticTypeRelationItem): Dependency {
        return {
            childOptionId: tacticItem.childOptionId,
            parentOptionId: tacticItem.parentOptionId,
        };
    }
}
