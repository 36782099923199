import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { getTacticTypeCustomFieldDependenciesParents, TacticTypeRelationItem } from 'services/attributeManagementApis';
import { ReactQueryHookOption } from 'react-query/types';

const useGetTacticTypeCustomFieldDependenciesParents = (
    customFieldId: number,
    options: ReactQueryHookOption<TacticTypeRelationItem[]> = { enabled: false }
) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();
    return useQuery(
        [queryKeys.getTacticTypeCustomFieldDependenciesParents, String(customer?.clientId), Number(customFieldId)],
        getTacticTypeCustomFieldDependenciesParents,
        {
            enabled: options.enabled,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetTacticTypeCustomFieldDependenciesParents;
