import * as React from 'react';

import ToolTip from '../ToolTip/ToolTip';

import styles from './Label.module.scss';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    hint?: string;
    required?: boolean | string;
    disabled?: boolean;
    error?: boolean;
}

/**
 * Label Atom component.
 */
const Label: React.FC<LabelProps> = ({
    htmlFor,
    className,
    required,
    disabled,
    hint,
    children,
    error,
    ...labelProps
}) => {
    /**
     * Function that returns the component className property
     * @returns {string}
     */
    const getComponentClassNames = () => {
        const baseStyles = [styles.labelBaseStyle, className];
        if (disabled) baseStyles.push(styles.disabled);
        if (!!hint) baseStyles.push(styles.hasHint);
        if (!!error) baseStyles.push(styles.hasError);
        return baseStyles.join(' ');
    };

    return (
        <label
            {...labelProps}
            htmlFor={htmlFor}
            className={getComponentClassNames()}
            aria-disabled={disabled}
        >
            {required && <small className={styles.requiredIndicator}>*</small>}
            {children}
            {hint && (
                <ToolTip
                    message={hint}
                    className={styles.hintIcon}
                >
                    <i className="k-icon k-i-question" />
                </ToolTip>
            )}
        </label>
    );
};

export default Label;
