import styles from './ModalLegend.module.scss';

const ModalLegend: React.FC = () => {
    return (
        <div className={styles.legend}>
            <div className={styles['legend__line']}>
                <span className={[styles['legend__text'], styles['legend__text--parent-color']].join(' ')}>Parent</span>
            </div>

            <div className={styles['legend__line']}>
                <span className={[styles['legend__text'], styles['legend__text--current-color']].join(' ')}>
                    Current Attribute
                </span>
            </div>

            <div className={styles['legend__line']}>
                <span className={[styles['legend__text'], styles['legend__text--children-color']].join(' ')}>
                    Children
                </span>
            </div>
        </div>
    );
};

export default ModalLegend;
