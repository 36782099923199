import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import Tree from 'react-d3-tree';
import { relationshipItemAtom, scaleAtom, zoomAtom } from 'atons/AttributeManagement/dependencyAtoms';

import TreeElement, { TreeElementNodeDatum } from './TreeElement/TreeElement';

type CenteredValues = { x: number; y: number; width: number; height: number };

export const useCenteredTree = (): [CenteredValues, (element: HTMLElement | null) => void] => {
    const [translate, setTranslate] = useState<CenteredValues>({ x: 0, y: 0, width: 0, height: 0 });
    const containerRef = useCallback((containerElem: HTMLElement | null) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: (height - 400) / 2, width, height });
        }
    }, []);
    return [translate, containerRef];
};

const TreeD3: React.FC<{ isCentering: boolean; onCenterEnds: () => void }> = ({ isCentering, onCenterEnds }) => {
    const scale = useAtomValue(scaleAtom);
    const zoom = useAtomValue(zoomAtom);
    const [{ x, y, height, width }, containerRef] = useCenteredTree();
    const relationshipItem = useAtomValue(relationshipItemAtom);

    const nodeSize = { x: 200, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -(nodeSize.x / 2) };

    return (
        <div
            ref={containerRef as any}
            id="treeWrapper"
            style={{ width: '100%', height: '100%' }}
        >
            <Tree
                zoomable={true}
                zoom={zoom}
                scaleExtent={scale}
                data={relationshipItem}
                translate={{ x, y }}
                nodeSize={nodeSize}
                separation={{ siblings: 2, nonSiblings: 2 }}
                renderCustomNodeElement={({ nodeDatum, ...rd3tProps }) => (
                    <TreeElement
                        foreignObjectProps={foreignObjectProps}
                        nodeDatum={nodeDatum as TreeElementNodeDatum}
                        isCentering={isCentering}
                        onCenterEnds={onCenterEnds}
                        {...rd3tProps}
                    />
                )}
                dimensions={{ height, width }}
                orientation="vertical"
                pathFunc="diagonal"
                collapsible
            />
        </div>
    );
};

export default TreeD3;
