export default class Constants {
    /** The URL of the OIDC/OAuth2 provider */
    static Authority = process.env.REACT_APP_AUTHORITY || 'https://h9auth.develop.hive9.com';
    /** Your client application's identifier as registered with the OIDC/OAuth2 */
    static ClientId = process.env.REACT_APP_CLIENT_ID || 'AdminUi';
    /** The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider */
    static RedirectUri = process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/#/signin-oidc';
    /** The type of response desired from the OIDC/OAuth2 provider */
    static ResponseType = process.env.REACT_APP_RESPONSE_TYPE || 'code';
    /** The scope being requested from the OIDC/OAuth2 provider */
    static Scope = process.env.REACT_APP_SCOPE || 'openid email profile roles hive9_admin_module hive9_admin_api';
    /** The OIDC/OAuth2 post-logout redirect URI */
    static PostLogoutRedirectUri =
        process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI || 'http://localhost:3000/#/signout-oidc';
    /** The URL for the page containing the code handling the silent renew */
    static SilentRedirectUri = process.env.REACT_APP_SILENT_REDIRECT_URI || 'http://localhost:3000/silentRenew.html';
    /** Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration */
    static AutomaticSilentRenew = process.env.REACT_APP_AUTOMATIC_SILENT_RENEW || true;
    /** Will raise events for when user has performed a signout at the OP */
    static MonitorSession = process.env.REACT_APP_MONITOR_SESSION || false;
    /** Key used to store the user information in the session store */
    static UserStoreKey = `oidc.user:${this.Authority}:${this.ClientId}`;
    /** Enable authentication debug */
    static EnableAuthenticationDebug = process.env.REACT_APP_ENABLE_DEBUG || true;
    /** Key used to store the admin-module data in the local storage */
    static LocalStorageKey = `admin-module:${this.Authority}:${this.ClientId}`;
    /**This is the base legacy url*/
    static CacheClean = process.env.REACT_APP_LEGACY_BASE_URL || 'https://plan.develop.hive9.com';
}
