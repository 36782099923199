import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import { alphabeticalOrder, formatPascalCaseIntoRegularText } from 'shared/helpers';

import { VirtualizedList, TextInput } from 'components';
import TagList from '../TagList/TagList';
import OptionListWithCheckbox from '../OptionListWithCheckbox/OptionListWithCheckbox';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import {
    currentAttributeOptionsSearchTextAtom,
    singleValueSelectedAtom,
    checkBoxListTotalAtom,
} from 'atons/AttributeManagement/dependencyAtoms';

import useGetParentPicklistOptions from 'react-query/queries/useGetParentPicklistOptions';
import { useGetCustomFieldTypes } from 'react-query/queries';

import styles from './CurrentSelectedAttributeOptionList.module.scss';

const ParentOptionList: React.FC = () => {
    const [currentAttributeOptionsSearchText, setCurrentAttributeOptionsSearchText] = useAtom(
        currentAttributeOptionsSearchTextAtom
    );
    const singleValueSelected = useAtomValue(singleValueSelectedAtom);
    const { id: customFieldId, name: customName, attributeTypeId: TypeId } = useAtomValue(attributeSettingsAtom);
    const setCheckBoxListTotal = useUpdateAtom(checkBoxListTotalAtom);

    const { data: getCurrentCustomFiledPickListOptions, refetch: currentCustomFiledPickListOptions } =
        useGetParentPicklistOptions(Number(customFieldId));

    const { data: customFieldTypes = [] } = useGetCustomFieldTypes();

    const data = React.useMemo(
        () =>
            getCurrentCustomFiledPickListOptions &&
            (alphabeticalOrder(getCurrentCustomFiledPickListOptions, 'value')
                ?.map(item => {
                    return { id: item.id, name: item.value, key: item.id, isSelected: false };
                })
                ?.filter(value => value.name.includes(String(currentAttributeOptionsSearchText))) as any),
        [currentAttributeOptionsSearchText, getCurrentCustomFiledPickListOptions]
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setCurrentAttributeOptionsSearchText(event.target.value);

    React.useEffect(() => {
        getCurrentCustomFiledPickListOptions &&
            getCurrentCustomFiledPickListOptions?.length > 0 &&
            setCheckBoxListTotal(getCurrentCustomFiledPickListOptions);
    }, [getCurrentCustomFiledPickListOptions, setCheckBoxListTotal]);

    React.useEffect(() => {
        currentCustomFiledPickListOptions();
    }, [currentCustomFiledPickListOptions]);

    return (
        <div className={styles.container}>
            <div className={styles.subTitle}>
                <span>Name: {customName}</span>
                <span className={styles.type}>{formatPascalCaseIntoRegularText(customFieldTypes?.find(type => type.id === Number(TypeId))?.name)}</span>
            </div>

            <TextInput
                placeholder="Search"
                kendoIcon="k-i-zoom"
                disabled={singleValueSelected === null}
                value={String(currentAttributeOptionsSearchText)}
                onChange={onChange}
            />

            <TagList />

            <VirtualizedList
                data={data}
                itemHeight={30}
                render={props => <OptionListWithCheckbox {...props} />}
            />
        </div>
    );
};

export default ParentOptionList;
