import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';

import styles from './Labeled-row.module.scss';

interface LabeledRowProps extends React.HTMLAttributes<HTMLDivElement> {
    children:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
    label: string;
}

const LabeledRow = ({ children, label, className, ...props }: LabeledRowProps): JSX.Element => {
    const labeledRowClassNames = classNames(styles['labeled-row'], className);
    return (
        <div
            {...props}
            className={labeledRowClassNames}
        >
            <span className={styles['labeled-row__label']}>{label}</span>

            {children}
        </div>
    );
};

export default LabeledRow;
