import { useAtom, useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useGetCustomFieldDetail } from 'react-query/queries';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { CustomFieldGroup, patchCustomFieldPickList } from 'services/attributeManagementApis';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';

type FormPayload = {
    colorCode: string;
    id: number;
    isDefault: 'No' | 'Yes';
    isDeleted: 'No' | 'Yes';
    sortOrder: string;
    value: string;
};

const usePatchCustomFieldPickList = (options?: ReactQueryHookOption<CustomFieldGroup>) => {
    const [{ id: customFieldId }, setAttributeSettings] = useAtom(attributeSettingsAtom);
    const customer = useAtomValue(customerAtom);
    const onError = useSetErrorMessage();

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(customFieldId as number, {
        onSuccess: data => setAttributeSettings(CustomFieldsAdapter.toAttributeSetting(data)),
    });

    return useMutation(
        (payload: FormPayload) =>
            patchCustomFieldPickList(
                customer!.clientId,
                customFieldId,
                payload.id,
                CustomFieldsAdapter.patchPicklistItem({
                    value: payload.value,
                    color: payload.colorCode,
                    isDefault: payload.isDefault,
                    isDeleted: payload.isDeleted,
                    sortOrder: payload.sortOrder,
                })
            ),
        {
            onSuccess: (data: any) => {
                if (options && options.onSuccess) options.onSuccess(data);
                queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
                queryClient.invalidateQueries([queryKeys.customFieldDetail]);
                fetchCustomFieldDetail();
            },
            onError: error => {
                onError(error);
                if (options && options.onError) options.onError(error);
            },
        }
    );
};

export default usePatchCustomFieldPickList;
