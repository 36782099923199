import * as React from 'react';
import { useAtomValue } from 'jotai';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import { Button, ErrorModal, TextInput } from '../../../../../../components';

import { patchCustomFieldPickList } from '../../../../../../services/attributeManagementApis';

import styles from './SortOrderColumn.module.scss';
import { GridCellProps } from '@progress/kendo-react-grid';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { useToast } from 'customHooks/useToast';
import { useMutation } from 'react-query';

type YesOrNo = 'Yes' | 'No';

interface PicklistGridDataItem {
    colorCode: string;
    id: number;
    isDefault: YesOrNo;
    isDeleted: YesOrNo;
    sortOrder: string;
    value: string;
}

interface SortOrderColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: PicklistGridDataItem;
}

const SortOrderColumn: React.FC<SortOrderColumnProps> = ({ dataItem, id }) => {
    const [isEditingValue, setIsEditingValue] = React.useState<boolean>(false);
    const [error, setError] = React.useState<{
        title: string;
        errors: {
            header: string;
            description: string;
            itemList?: { id: number; name: string; description: string; children: any }[];
        }[];
    } | null>(null);
    const customer = useAtomValue(customerAtom);
    const { id: customFieldId } = useAtomValue(attributeSettingsAtom);
    const formRef = React.useRef<HTMLFormElement>(null);
    const toast = useToast();
    const isDeleted = dataItem.isDeleted === 'Yes';
    /**
     * Function that shows a generic toasty error
     */
    const showGenericToastError = () => toast.error('Something went wrong, try again later.');
    /**
     * Function that handles the BadRequest error
     */
    const handleBadRequestError = ({ errors, title }: { errors: any; title: string }) => {
        function errObjectHasHeaderAndDescriptionProperties(err) {
            return Object.hasOwn(err, 'header') && Object.hasOwn(err, 'description');
        }
        if (Object.hasOwn(errors, 'length') && errors.every(errObjectHasHeaderAndDescriptionProperties)) {
            return setError({ title, errors });
        }
        if (typeof errors === 'object' && Object.keys(errors).length > 0) {
            return setError({
                title,
                errors: Object.keys(errors).map(key => ({
                    header: '',
                    description: errors[key].join('\b'),
                })),
            });
        }
        showGenericToastError();
    };

    /**
     * Function that submit user change to picklist
     */
    const { mutate: patchCustomField } = useMutation(
        (sortOrder: string) =>
            patchCustomFieldPickList(
                customer?.clientId,
                customFieldId,
                dataItem.id,
                CustomFieldsAdapter.patchPicklistItem({
                    value: dataItem.value,
                    color: dataItem.colorCode,
                    isDefault: dataItem.isDefault,
                    isDeleted: dataItem.isDeleted,
                    sortOrder,
                })
            ),
        {
            onSuccess: () => queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]),
            onError: (error: any) => {
                if (error?.status === 400 && error.errors) {
                    return handleBadRequestError(error);
                }
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) });
            },
        }
    );

    const stopEditing = () => setIsEditingValue(false);

    const isSortOrderValueValid = (value: string): boolean => value.length > 0 && !isNaN(Number(value));

    const onSubmitChange = (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) event.preventDefault();
        const formData = new FormData(formRef.current as HTMLFormElement);
        const sortOrder = formData.get('sortOrder')?.toString();
        if (!sortOrder || sortOrder === dataItem.sortOrder?.toString()) return stopEditing();
        if (sortOrder && isSortOrderValueValid(sortOrder)) return patchCustomField(sortOrder);
    };

    return (
        <td
            id={id}
            style={{ display: 'flex' }}
        >
            {isEditingValue ? (
                <form
                    ref={formRef}
                    onSubmit={onSubmitChange}
                >
                    <TextInput
                        type="number"
                        id="sortOrder"
                        name="sortOrder"
                        defaultValue={
                            dataItem.sortOrder && !isNaN(Number(dataItem.sortOrder)) && Number(dataItem.sortOrder) > -1
                                ? dataItem.sortOrder.toString()
                                : ''
                        }
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        onBlur={() => onSubmitChange()}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                        required
                    />
                </form>
            ) : (
                <Button
                    btnType="link"
                    type="button"
                    title={isDeleted ? 'Only when it is active can it be edited' : undefined}
                    className={styles.sortOrderButton}
                    text={!!dataItem.sortOrder ? dataItem.sortOrder : '---'}
                    onClick={() => setIsEditingValue(true)}
                    disabled={isDeleted}
                />
            )}

            <ErrorModal
                show={!!error}
                title={error?.title}
                closeBtn
                closeBtnFunc={() => setError(null)}
                errors={error?.errors}
                className={styles.errorModal}
            />
        </td>
    );
};

export default SortOrderColumn;
