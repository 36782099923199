export class KeyboardKey {
    static TAB = 'Tab';
    static ENTER = 'Enter';
    static ESCAPE = 'Escape';
    static SPACE = ' ';
    static ARROW_UP = 'ArrowUp';
    static ARROW_LEFT = 'ArrowLeft';
    static ARROW_DOWN = 'ArrowDown';
    static ARROW_RIGHT = 'ArrowRight';
    static BACKSPACE = 'Backspace';
    static INSERT = 'Insert';
    static DELETE = 'Delete';
}

/**
 * This function is mean to be used with accessibitily keyboardEvents
 * @param {() => void} callback
 * @param {string | string[]} allowedKeys
 * @returns {(keyboardEvent: React.KeyboardEvent<HTMLElement>) => void}
 */
export const a11yKeyBoardEventHandler =
    (callback, allowedKeys = [KeyboardKey.ENTER, KeyboardKey.SPACE]) =>
    keyboardEvent => {
        if (!keyboardEvent.isTrusted) return;

        if (typeof allowedKeys === 'string') {
            if (allowedKeys === keyboardEvent.key) {
                keyboardEvent.stopPropagation();
                return callback();
            }
            return;
        }

        if (
            typeof allowedKeys === 'object' &&
            Object.hasOwn(allowedKeys, 'length') &&
            allowedKeys.includes(keyboardEvent.key)
        ) {
            return callback();
        }
    };
