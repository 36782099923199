import * as React from 'react';
import { a11yKeyBoardEventHandler } from '../../../shared/KeyboradKey';

import styles from './Upload.module.scss';

/**
 * Upload file component that support file drag and drop.
 * @param {{
 *  id: string;
 *  placeholder: string;
 *  placeHolderOnDragItem: string;
 *  fileList: File[];
 *  onChange: (files: FileList) => void;
 *  accept: string;
 *  className: string;
 * }} props Component Props
 * @returns {JSX.Element}
 */
const Upload = ({
    id,
    placeholder = 'Upload file',
    placeHolderOnDragItem = 'Drop file here',
    fileList = [],
    onChange = () => {},
    accept,
    className = '',
}) => {
    const inputRef = React.useRef();
    const [dragging, setDragging] = React.useState(false);

    const hasItemsOnDataTransfer = dataTransfer => dataTransfer?.items && dataTransfer.items.length > 0;

    const onClickContainer = event => {
        if (event.currentTarget.contains(inputRef.current)) {
            inputRef.current.click();
        }
    };

    const onDragEnter = React.useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        const { dataTransfer } = event;
        if (hasItemsOnDataTransfer(dataTransfer)) setDragging(true);
    }, []);

    const onDragLeave = React.useCallback(event => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    }, []);

    const onDragOver = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    /**
     * Function that handles the drop event
     * @param {React.DragEvent<HTMLDivElement>} event Drop event
     */
    const onDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            onChange(event.dataTransfer.files);
        }
        event.dataTransfer.clearData();
    };

    /**
     * Function that handles the input change event
     * @param {React.ChangeEvent<HTMLInputElement>} event Input change event
     */
    const onInputChangeEvent = event => {
        if (event.target.files?.length) {
            onChange(event.target.files);
        }
    };

    const getContainerClassNames = () => {
        const classes = [styles.container, className];
        if (dragging) classes.push(styles.hasDrag);
        return classes.join(' ');
    };

    const noFileContent = (
        <>
            <i className={dragging ? 'k-icon k-i-inbox k-icon-24' : 'k-icon k-i-upload k-icon-24'} />
            <span>{dragging ? placeHolderOnDragItem : placeholder}</span>
        </>
    );

    const withFileContent = (
        <ul className={styles.fileListContainer}>
            {fileList.map(file => (
                <li key={`${file.name}-${file.lastModified}`}>
                    <i className="k-icon k-i-attachment-45" />
                    <span>{file.name}</span>
                </li>
            ))}
        </ul>
    );

    return (
        <div
            id={id}
            className={getContainerClassNames()}
            onClick={onClickContainer}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onKeyUp={a11yKeyBoardEventHandler(onClickContainer)}
            role="button"
            tabIndex={0}
        >
            {fileList.length ? withFileContent : noFileContent}

            <input
                id="fileLoader"
                ref={inputRef}
                type="file"
                hidden
                onChange={onInputChangeEvent}
                accept={accept}
            />
        </div>
    );
};

export default Upload;
