import { useMutation } from 'react-query';
import { useAtomValue, useAtom } from 'jotai';

import { customerAtom } from 'atons/Customer/atoms';
import { selectedAttributeToDeleteOrReactivateAtom } from 'atons/AttributeManagement/atoms';

import { useToast } from 'customHooks/useToast';

import { toggleDeleteAttribute } from 'services/attributeManagementApis';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';

const usePatchToggleDeleteAttribute = (options?: ReactQueryHookOption<any>) => {
    const customer = useAtomValue(customerAtom);
    const [selectedAttributeToDeleteOrReactivate, setSelectedAttributeToDeleteOrReactivate] = useAtom(
        selectedAttributeToDeleteOrReactivateAtom
    );

    const toast = useToast();

    return useMutation(() => toggleDeleteAttribute(selectedAttributeToDeleteOrReactivate.id, customer!), {
        onSuccess: resp => {
            queryClient.invalidateQueries([queryKeys.attributeFieldsList]);
            toast.success(
                selectedAttributeToDeleteOrReactivate.isDeleted === 'Yes'
                    ? 'Reactivated Successfully!'
                    : 'Deleted Successfully!'
            );
            setSelectedAttributeToDeleteOrReactivate();
            if (options?.onSuccess) options?.onSuccess(resp);
        },
        onError: (error: any) => {
            setSelectedAttributeToDeleteOrReactivate();
            if (options?.onError) options?.onError(error);
            if (error?.response?.data?.errors && error.response.data.errors?.length > 0) return;
            toast.error('Something went wrong, try again later.');
        },
        retry: 1,
    });
};

export default usePatchToggleDeleteAttribute;
