import * as React from 'react';

const OrganizationSettings = props => {
    const Content = (
        <>
            <div className="pageTitleCont">
                <h2 className="title-main">
                    Organization
                    <span className="title-section-marker"> Settings Page </span>
                </h2>
            </div>
            <div className="mx-5 col-10 offset-2">
                <div className="text-center my-5">
                    <span className="k-icon k-i-warning icon-alert"></span>
                    <p className="mt-3">
                        <b>Coming soon!,</b> <span className="integrations-word">Organization Settings</span>
                    </p>
                </div>
            </div>
        </>
    );
    return <div>{props.children ? props.children : Content}</div>;
};

export default OrganizationSettings;
