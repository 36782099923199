import * as React from 'react';

import styles from './ValueColumn.module.scss';

const ValueColumn = ({ dataItem, id }) => {
    return (
        <td id={id}>
            <div className={styles.container}>
                <span title={dataItem.value}>{dataItem.value}</span>
            </div>
        </td>
    );
};

export default ValueColumn;
