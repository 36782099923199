import { useMutation } from 'react-query';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { changeEntityTypeUserSelection, EntityTypeBasedOnUserSelectionPayload } from 'services/attributeManagementApis';

const useChangeEntityTypeUserSelection = (options?: ReactQueryHookOption<any>) => {
    const onError = useSetErrorMessage();

    return useMutation((payload: EntityTypeBasedOnUserSelectionPayload) => changeEntityTypeUserSelection(payload), {
        onSuccess: data => {
            queryClient.invalidateQueries([queryKeys.entityTypeUserSelect]);
            if (options?.onSuccess) options.onSuccess(data);
        },
        onError: error => {
            onError(error);
            if (options?.onError) options.onError(error);
        },
        retry: 0,
    });
};

export default useChangeEntityTypeUserSelection;
