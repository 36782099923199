import * as React from 'react';
import { useAtom } from 'jotai';

import { groupDetailAtom } from 'atons/Groups/atoms';

import { Button, Modal } from 'components';
import { useDeleteCustomFieldGroup } from 'react-query/mutations';

import styles from './ConfirmDeleteGroupModal.module.scss';
import { useGetCustomFieldGroupsUsage } from 'react-query/queries';

export interface ConfirmDeleteGroupModalProps {
    show: boolean;
    closeBtnFunc: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const ConfirmDeleteGroupModal: React.FC<ConfirmDeleteGroupModalProps> = props => {
    const [groupDetail, setGroupDetail] = useAtom(groupDetailAtom);

    const onDeleteCustomFieldSuccessFully = () => {
        setGroupDetail(null);
    };

    const { data: customFieldGroupsUsage = [] } = useGetCustomFieldGroupsUsage([groupDetail?.id!], {
        enabled: props.show && typeof groupDetail?.id !== 'undefined',
    });

    const { mutate: deleteCustomFieldGroup } = useDeleteCustomFieldGroup({
        onSuccess: onDeleteCustomFieldSuccessFully,
    });

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        deleteCustomFieldGroup([groupDetail!.id!]);
        props.closeBtnFunc(event);
    };

    return (
        <Modal
            title="Delete Group"
            closeBtn
            {...props}
        >
            <p className="pb-4 text-center">
                Are you sure that you want to <b className={styles.danger}>PERMANENT</b> delete{' '}
                <b>{groupDetail!.name}</b> group?
            </p>

            {customFieldGroupsUsage.length > 0 && (
                <ul className={styles.usageList}>
                    {customFieldGroupsUsage.map(usageInfo => (
                        <li key={usageInfo.id}>
                            This Custom Field Group is active in {usageInfo.usageCount} attribute(s)
                        </li>
                    ))}
                </ul>
            )}

            <div className="d-flex justify-content-center">
                <Button
                    text="Delete"
                    type="button"
                    btnType="cancel"
                    onClick={onClick}
                />
            </div>
        </Modal>
    );
};

export default ConfirmDeleteGroupModal;
