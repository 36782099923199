import { User as OidcUser } from 'oidc-react';

import SessionStorageService from '../../services/SessionStorageService';

export default class User extends OidcUser {
    /**
     * @param {import('oidc-client').UserSettings} [userSettings=undefined]
     */
    constructor(userSettings) {
        super(userSettings || SessionStorageService.getUser());
    }
    /**
     * Return true if current user is a Super Admin
     * @param {import('oidc-client').UserSettings} [userSettings=SessionStorageService.getUser()]
     * @returns {boolean}
     */
    static isSuperAdmin(userSettings = SessionStorageService.getUser()) {
        if (!userSettings) return false;
        const currentUser = User.getInstance(userSettings);
        return currentUser.profile?.role === 'Hive9Admin';
    }
    /**
     * Return true if current user is a System Admin
     * @param {import('oidc-client').UserSettings} [userSettings=SessionStorageService.getUser()]
     * @returns {boolean}
     */
    static isSystemAdmin(userSettings = SessionStorageService.getUser()) {
        if (!userSettings) return false;
        const currentUser = User.getInstance(userSettings);
        return currentUser.profile?.isSystemAdmin;
    }
    /**
     * Return true if current user is a Client Admin
     * @param {import('oidc-client').UserSettings} [userSettings=SessionStorageService.getUser()]
     * @returns {boolean}
     */
    static isClientAdmin(userSettings = SessionStorageService.getUser()) {
        if (!userSettings) return false;
        const currentUser = User.getInstance(userSettings);
        return currentUser.profile?.isClientAdmin;
    }
    /**
     * Return a instance of the current user
     * @param {import('oidc-client').UserSettings} [userSettings=SessionStorageService.getUser()]
     * @returns {User | null}
     */
    static getInstance(userSettings = SessionStorageService.getUser()) {
        if (!userSettings) return null;
        return new User(userSettings);
    }
    /**
     * Verify if the user has the permission(s) passed as parameter
     * @param {string | string[]} permissions A string or array of string containing the required permissions
     * @returns {boolean}
     */
    static hasPermissions(permissions) {
        const currentUser = User.getInstance();
        if (typeof currentUser.profile.permissions === 'undefined') return false;
        if (typeof permissions === 'string') return currentUser.profile.permissions.includes(permissions);
        return permissions.every(permission => currentUser.profile.permissions.includes(permission));
    }
    /**
     * Retrieve user clientId property
     * @returns {string | null}
     */
    getClientId() {
        if (!this.profile?.clientId) return null;
        return this.profile.clientId;
    }
    /**
     * Retrieve userId property
     * @returns {string | null}
     */
    getUserId() {
        if (!this.profile?.userId) return null;
        return this.profile.userId;
    }
}
