import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './IconMenuLink.module.scss';

interface IconMenuLinkProps extends LinkProps {
    icon: string;
}

const IconMenuLink: React.FC<IconMenuLinkProps> = ({ icon, children, className, ...linkProps }) => {
    const getIconMenuLinkClassName = (): string => {
        const baseStyle = [styles.container];
        if (className) baseStyle.push(className);
        return baseStyle.join(' ');
    };

    return (
        <Link
            {...linkProps}
            className={getIconMenuLinkClassName()}
        >
            <div className={styles.contentIconImg}>
                <div className={[styles.iconBaseStyle, 'k-icon', icon].join(' ')}></div>
            </div>

            <div className={styles.contentLabel}>{children}</div>
        </Link>
    );
};

export default IconMenuLink;
