import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';
import { getEntityTypes } from 'services/attributeManagementApis';

import { queryKeys } from 'react-query/constants';
import { useToast } from 'customHooks/useToast';

const useGetEntityTypeList = () => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();

    return useQuery([queryKeys.entityTypeList, customer!], getEntityTypes, {
        enabled: customer !== null,
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.');
        },
    });
};

export default useGetEntityTypeList;
