import * as React from 'react';
import { useAtomValue, useAtom } from 'jotai';
import { useResetAtom, useUpdateAtom } from 'jotai/utils';

import {
    selectedParentAtom,
    parentDependenciesAtom,
    singleValueSelectedAtom,
    checkBoxListTotalAtom,
    nonCheckBoxListTotalAtom,
    possibleParentsListAtom,
    relationshipAtom,
    checkBoxCaseAtom,
    copySearchTextAtom,
    optionListToCopyAtom,
    copyModeAtom,
    copyAtom,
} from 'atons/AttributeManagement/dependencyAtoms';
import { attributeSettingsAtom, entityTypeListAtom, selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';

import { alphabeticalOrder } from 'shared/helpers';

import useGetParentDependencies from 'react-query/queries/useGetParentDependencies';
import useGetChildPicklistOptions from 'react-query/queries/useGetChildPicklistOptions';
import {
    useGetCustomFieldDetail,
    useGetTacticTypeCustomFieldDependenciesParents,
    useGetCustomFieldListPossibleParents,
} from 'react-query/queries';
import { usePutParentDependencies } from 'react-query/mutations/usePutParentDependencies';

import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';

import { GroupSelect, Card, Button, Modal, VirtualizedList, TextInput } from 'components';
import OptionListToCopy from '../DependenciesComponents/OptionListToCopy/OptionListToCopy';
import TagListCopy from '../DependenciesComponents/TagListCopy/TagListCopy';
import ParentAttributeOptionList from '../DependenciesComponents/ParentAttributeOptionList/ParentAttributeOptionList';
import CurrentSelectedAttributeOptionList from '../DependenciesComponents/CurrentSelectedAttributeOptionList/CurrentSelectedAttributeOptionList';
import SingleAttributeValuePlaceholder from '../DependenciesComponents/SingleAttributeValuePlaceholder/SingleAttributeValuePlaceholder';

import { CustomFieldDependency, Dependency } from 'services/attributeManagementApis';
import AttributeTypes from 'enums/AttributeTypes';
import User from 'domain/model/User';

import styles from './DependencySettings.module.scss';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { usePutTacticTypeDependencies } from 'react-query/mutations/usePutTacticTypeDependencies';
import EntityTypes from 'enums/EntityTypes';
import SaveWarningModal from './SaveWarningModal/SaveWarningModal';
import GraphButton from './GraphButton/GraphButton';
import RelationshipErrorModal from './RelationshipErrorModal/RelationshipErrorModal';

interface Props {
    closeModalFunc: () => void;
}

const DependencySettings: React.FC<Props> = ({ closeModalFunc }) => {
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
    const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = React.useState(false);
    const [lastParentId, setLastParentId] = React.useState<number | null>(null);
    const [copy, setCopy] = useAtom(copyAtom);
    const [copyMode, setCopyMode] = useAtom(copyModeAtom);
    const [relationship, setRelationship] = useAtom(relationshipAtom);
    const setAttributeSettings = useUpdateAtom(attributeSettingsAtom);
    const [selectedParent, setSelectedParent] = useAtom(selectedParentAtom);
    const [possibleParentsList, setPossibleParentsList] = useAtom(possibleParentsListAtom);
    const [parentDependencies, setParentDependencies] = useAtom(parentDependenciesAtom);
    const {
        id: customFieldId,
        attributeTypeId: typeId,
        hasParentEnum: hasParent,
    } = useAtomValue(attributeSettingsAtom);
    const singleValueSelected = useAtomValue(singleValueSelectedAtom);
    const nonCheckBoxListTotal = useAtomValue(nonCheckBoxListTotalAtom);
    const selectedEntityTypeId = useAtomValue(selectedEntityTypeIdAtom);
    const entityTypeList = useAtomValue(entityTypeListAtom);
    const [optionListToCopy, setOptionListToCopy] = useAtom(optionListToCopyAtom);
    const [copySearchText, setCopySearchText] = useAtom(copySearchTextAtom);
    const resetSingleValueSelected = useResetAtom(singleValueSelectedAtom);
    const resetParentDependencies = useResetAtom(parentDependenciesAtom);
    const resetCheckBoxListTotal = useResetAtom(checkBoxListTotalAtom);
    const resetNonCheckBoxListTotal = useResetAtom(nonCheckBoxListTotalAtom);
    const resetPossibleParentsList = useResetAtom(possibleParentsListAtom);
    const resetcheckBoxCase = useResetAtom(checkBoxCaseAtom);
    const checkBoxCaseUpdate = useUpdateAtom(checkBoxCaseAtom);

    const { mutate: includeUpdateParentDependencies, isSuccess: createUpdate } = usePutParentDependencies(
        customFieldId as number,
        parentDependencies as CustomFieldDependency,
        'put'
    );

    const { mutate: deleteParentDependencies, isSuccess: createUpdateDelete } = usePutParentDependencies(
        customFieldId as number,
        {
            userId: User.getInstance()?.getUserId() as string,
            parents: [],
        } as CustomFieldDependency,
        'delete'
    );

    const { mutate: includeUpdateParentTacticTypeDependencies, isSuccess: tacticTypeCreateUpdate } =
        usePutTacticTypeDependencies(customFieldId as number, { verb: 'put' });

    const { mutate: deleteParentTacticTypeDependencies, isSuccess: tacticTypeCreateUpdateDelete } =
        usePutTacticTypeDependencies(customFieldId as number, { verb: 'delete', showTasty: selectedParent !== null });

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(Number(customFieldId), {
        onSuccess: res => {
            setAttributeSettings(CustomFieldsAdapter.toAttributeSetting(res));
        },
    });

    const { refetch: refetchGetTacticTypeCustomFieldDependenciesParents } =
        useGetTacticTypeCustomFieldDependenciesParents(Number(customFieldId), {
            onSuccess: data => {
                if (!!data && data?.length > 0 && !selectedParent) {
                    setLastParentId(-1);
                    const parents = CustomFieldsAdapter.tacticTypeRelationItemsToParentCustomFields(data);
                    setParentDependencies({
                        userId: User.getInstance()?.getUserId() as string,
                        parents,
                    });
                    setSelectedParent(-1);
                    return setRelationship(true);
                }

                !selectedParent &&
                    setParentDependencies({
                        userId: User.getInstance()?.getUserId() as string,
                        parents: [],
                    });
                !data && setRelationship(false);
            },
        });

    const { data: customFieldListPossibleParents, refetch: refetchGetCustomFieldListPossibleParents } =
        useGetCustomFieldListPossibleParents(Number(customFieldId));

    const { refetch: refetchGetParentDependencies } = useGetParentDependencies(Number(customFieldId), {
        onSuccess(data) {
            if (!!data && data?.length > 0 && !selectedParent) {
                const [{ id }] = data;
                setLastParentId(id);
                setParentDependencies({
                    userId: User.getInstance()?.getUserId() as string,
                    parents: data,
                });
                setSelectedParent(data[0].id);
                return setRelationship(true);
            }

            !selectedParent &&
                setParentDependencies({
                    userId: User.getInstance()?.getUserId() as string,
                    parents: [],
                });
            !data && setRelationship(false);
        },
    });

    const { data: getChildPicklistOptions, refetch: refetchGetChildPicklistOptions } = useGetChildPicklistOptions(
        Number(customFieldId),
        Number(typeId)
    );

    const parent =
        customFieldListPossibleParents &&
        [{ id: -1, attributeTypeId: -1, name: 'TacticType', entityTypeId: -1 }, ...customFieldListPossibleParents].find(
            item => item.id === Number(selectedParent)
        );

    const parentType = customFieldListPossibleParents?.find(
        item => item.id === Number(selectedParent)
    )?.attributeTypeId;

    const copyChange = (event: React.ChangeEvent<HTMLInputElement>) => setCopySearchText(event.target.value);

    const openDeleteConfirmationModal = () => {
        setDeleteConfirmation(true);
    };

    const closeDeleteConfirmationModal = () => {
        setDeleteConfirmation(false);
    };

    const onClickSaveButton = () => {
        if (!parentDependencies?.parents) return;
        if (lastParentId !== null && parentDependencies.parents.length < 1) {
            return setIsSaveWarningModalOpen(true);
        }

        if (lastParentId !== null && lastParentId !== parentDependencies.parents[0].id) {
            return setIsSaveWarningModalOpen(true);
        }

        saveDependency();
    };

    const saveDependency = () => {
        setIsSaveWarningModalOpen(false);
        if (selectedParent === null) {
            setLastParentId(null);
            return removeDependency();
        }
        if (!parentDependencies?.parents) return;
        if (selectedParent === -1) {
            const tacticTypeDependencies = CustomFieldsAdapter.customFieldDependencyToTacticTypeDependency(
                parentDependencies as CustomFieldDependency
            );
            setLastParentId(-1);
            return includeUpdateParentTacticTypeDependencies(tacticTypeDependencies);
        }
        setLastParentId(parentDependencies?.parents[0].id);
        includeUpdateParentDependencies();
    };

    const removeDependency = () => {
        if (selectedParent === null) {
            deleteParentTacticTypeDependencies({
                userId: User.getInstance()?.getUserId() as string,
                parents: [],
            });
            deleteParentDependencies();
        } else if (selectedParent === -1) {
            deleteParentTacticTypeDependencies({
                userId: User.getInstance()?.getUserId() as string,
                parents: [],
            });
        } else {
            deleteParentDependencies();
        }
        setSelectedParent(null);
        setRelationship(false);
        closeDeleteConfirmationModal();
    };

    const closeCopy = () => {
        setCopy(false);
        setCopyMode(true);
        setOptionListToCopy([]);
        setCopySearchText('');
    };

    const cleanSelectedToCopy = () => {
        if (parentDependencies) {
            optionListToCopy.forEach(item => {
                parentDependencies.parents[0].dependencies = parentDependencies.parents[0].dependencies.filter(
                    dep => dep.parentOptionId !== item
                ) as Dependency[];
            });
            setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
        }
    };

    const saveCopyChanges = () => {
        if (copyMode) {
            //to overwriten
            cleanSelectedToCopy();
            optionListToCopy.forEach(item => {
                parentDependencies &&
                    parentDependencies.parents[0].dependencies
                        .filter(dep => dep.parentOptionId === singleValueSelected)
                        .forEach(parent => {
                            parentDependencies.parents[0].dependencies.push({
                                parentOptionId: Number(item),
                                childOptionId: parent.childOptionId,
                            });
                        });
            });
            setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
            closeCopy();
            setOptionListToCopy([]);
        } else {
            //to merge
            optionListToCopy.forEach(item => {
                parentDependencies &&
                    parentDependencies.parents[0].dependencies
                        .filter(dep => dep.parentOptionId === singleValueSelected)
                        .forEach(parent => {
                            if (
                                !parentDependencies.parents[0].dependencies.some(
                                    exist =>
                                        exist.parentOptionId === Number(item) &&
                                        exist.childOptionId === parent.childOptionId
                                )
                            ) {
                                parentDependencies.parents[0].dependencies.push({
                                    parentOptionId: Number(item),
                                    childOptionId: parent.childOptionId,
                                });
                            }
                        });
            });
            setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
            closeCopy();
            setOptionListToCopy([]);
        }
    };

    const getParentName = () => {
        if (selectedParent === -1) return 'Tactic Type';
        return possibleParentsList.find(parent => parent.id === selectedParent)?.name;
    };

    const availableParentOptions = React.useMemo((): { label: string; value: any }[] => {
        const options: { label: string; value: any }[] = [{ label: 'Choose a Parent', value: null }];
        const allowedTacticTypes = [
            EntityTypes.getByName('Tactic'),
            EntityTypes.getByName('LineItem'),
            EntityTypes.getByName('MediaCode'),
            EntityTypes.getByName('PurchaseRequestLineItem'),
            EntityTypes.getByName('AccrualRequest'),
            EntityTypes.getByName('AccrualRequestLineItem'),
        ];

        if (allowedTacticTypes.includes(selectedEntityTypeId as number)) {
            options.push({ label: 'Tactic Type', value: -1 });
        }

        if (customFieldListPossibleParents) {
            options.push(
                ...customFieldListPossibleParents?.map(({ id, name, entityTypeId }) => ({
                    label: entityTypeList.find(cf => cf.id === entityTypeId)?.name + ' - ' + name,
                    value: id,
                }))
            );
        }
        return options;
    }, [customFieldListPossibleParents, entityTypeList, selectedEntityTypeId]);

    const hasProblemWithCurrentDependency = React.useMemo(
        () =>
            parent === undefined &&
            selectedParent !== null &&
            !availableParentOptions.some(option => option.value === selectedParent),
        [availableParentOptions, selectedParent, parent]
    );

    React.useEffect(() => {
        if (createUpdate) {
            fetchCustomFieldDetail();
            setRelationship(true);
        }
        if (createUpdateDelete) {
            fetchCustomFieldDetail();
            setRelationship(false);
        }
        if (tacticTypeCreateUpdate) {
            fetchCustomFieldDetail();
            setRelationship(true);
        }
        if (tacticTypeCreateUpdateDelete) {
            fetchCustomFieldDetail();
            setRelationship(false);
        }
    }, [
        createUpdate,
        createUpdateDelete,
        tacticTypeCreateUpdate,
        tacticTypeCreateUpdateDelete,
        setRelationship,
        fetchCustomFieldDetail,
    ]);

    React.useEffect(() => {
        !!getChildPicklistOptions && checkBoxCaseUpdate(getChildPicklistOptions);
    }, [checkBoxCaseUpdate, getChildPicklistOptions]);

    React.useEffect(() => {
        customFieldListPossibleParents && setPossibleParentsList(customFieldListPossibleParents);
    }, [customFieldListPossibleParents, setPossibleParentsList]);

    React.useEffect(() => {
        const runEffect = () => {
            if (hasParent === 'AttributeParent') return refetchGetParentDependencies();
            if (hasParent === 'TacticTypeParent') {
                return refetchGetTacticTypeCustomFieldDependenciesParents();
            }
        };
        runEffect();
    }, [hasParent, refetchGetParentDependencies, refetchGetTacticTypeCustomFieldDependenciesParents]);

    React.useEffect(() => {
        refetchGetCustomFieldListPossibleParents();
        refetchGetChildPicklistOptions();
        return () => {
            queryClient.invalidateQueries([queryKeys.getChildPicklistOptions]);
            queryClient.invalidateQueries([queryKeys.getParentPicklistOptions]);
            queryClient.invalidateQueries([queryKeys.getEntityTypePossibleParentOptions]);
            queryClient.invalidateQueries([queryKeys.getParentDependencies]);
            queryClient.invalidateQueries([queryKeys.getTacticTypeCustomFieldDependenciesParents]);
            queryClient.invalidateQueries([queryKeys.getCustomFieldListPossibleParents]);
            queryClient.invalidateQueries([queryKeys.getPossibleTacticTypeParents]);
            setSelectedParent(null);
            resetSingleValueSelected();
            resetParentDependencies();
            resetCheckBoxListTotal();
            resetNonCheckBoxListTotal();
            resetPossibleParentsList();
            resetcheckBoxCase();
        };
    }, [
        refetchGetCustomFieldListPossibleParents,
        refetchGetChildPicklistOptions,
        setSelectedParent,
        resetSingleValueSelected,
        resetParentDependencies,
        resetCheckBoxListTotal,
        resetNonCheckBoxListTotal,
        resetPossibleParentsList,
        resetcheckBoxCase,
    ]);

    return (
        <div className={styles.container}>
            <div className={[styles.col, styles.zIndex].join(' ')}>
                <GroupSelect
                    label={{
                        text: 'Select parent attribute',
                        className: styles.titleDependency,
                    }}
                    select={{
                        options: availableParentOptions,
                        value: selectedParent as number,
                        onChange(value: string) {
                            if (Number(value) === selectedParent) return;
                            setRelationship(false);
                            setSelectedParent(isNaN(value as any) ? null : Number(value));
                            const parent =
                                value === '-1'
                                    ? { id: -1, attributeTypeId: -1, name: 'TacticType' }
                                    : customFieldListPossibleParents?.find(p => p.id === Number(value));
                            Number(value) !== null && !isNaN(Number(value))
                                ? setParentDependencies({
                                      userId: User.getInstance()?.getUserId() as string,
                                      parents: [
                                          {
                                              id: Number(parent?.id),
                                              customFieldTypeId: Number(parent?.attributeTypeId),
                                              dependencies: [],
                                          },
                                      ],
                                  })
                                : setParentDependencies({
                                      userId: User.getInstance()?.getUserId() as string,
                                      parents: [],
                                  });
                            resetSingleValueSelected();
                        },
                        autoComplete: 'on',
                    }}
                />
            </div>

            <div className={styles.col}>
                {selectedParent === null || selectedParent === 0 || nonCheckBoxListTotal.length === 0 ? (
                    <div className={styles.parentWarn}>
                        <span className="k-icon k-i-chevron-left"></span>
                        <span className={['k-icon k-i-chevron-left', styles.arrow].join(' ')}></span>
                        <span className={styles.warnText}>Choose a Parent to make a relationship</span>
                    </div>
                ) : (
                    <div className={styles.parentOk}>
                        <span className={['k-icon k-i-check-outline', styles.ok].join(' ')}></span>
                        <span className={styles.okText}>Great, you selected a parent, now you can proceed below!</span>
                    </div>
                )}
            </div>

            {parent ? (
                <div className={styles.col}>
                    <Card
                        title={selectedParent === -1 ? 'Tactic Type Options' : 'Parent Attribute'}
                        customType={parentType}
                        className={[styles.card].join(' ')}
                    >
                        <ParentAttributeOptionList />
                    </Card>
                </div>
            ) : (
                <div className={styles.col}>
                    <Card
                        title={selectedParent === -1 ? `Tactic Type Options` : `Parent Attribute`}
                        customType={parentType}
                        block={selectedParent === null || selectedParent === 0}
                        className={[styles.card].join(' ')}
                    ></Card>
                </div>
            )}

            {Number(typeId) === AttributeTypes.DropDownList ? (
                <div className={styles.col}>
                    <Card
                        warning={
                            singleValueSelected === null && selectedParent === null
                                ? "You need to choose a parent to enable this component and choose the options(if it's the case)"
                                : ''
                        }
                        title={`Current Attribute`}
                        customType={typeId}
                        block={selectedParent === null || selectedParent === 0 || nonCheckBoxListTotal.length === 0}
                        className={[
                            styles.card,
                            (singleValueSelected === null || selectedParent === null) && styles.disabled,
                        ].join(' ')}
                    >
                        {selectedParent !== null && selectedParent !== 0 && nonCheckBoxListTotal.length !== 0 && (
                            <div
                                title={
                                    relationship
                                        ? 'This parent and current attibute have a relationship.'
                                        : "This parent and current attibute don't have a relationship."
                                }
                                className={[styles.relationship, relationship && styles.success].join(' ')}
                            >
                                {relationship ? (
                                    <span className={['k-icon k-i-link', styles.icon].join(' ')}></span>
                                ) : (
                                    <span className={['k-icon k-i-unlink', styles.icon].join(' ')}></span>
                                )}
                            </div>
                        )}
                        <CurrentSelectedAttributeOptionList />
                    </Card>
                </div>
            ) : (
                <div className={styles.col}>
                    <Card
                        title={`Current Attribute`}
                        customType={typeId}
                        className={styles.card}
                        block={selectedParent === null || selectedParent === 0 || nonCheckBoxListTotal.length === 0}
                    >
                        {selectedParent !== null && selectedParent !== 0 && nonCheckBoxListTotal.length !== 0 && (
                            <div
                                title={
                                    relationship
                                        ? 'This parent and current attibute have a relationship.'
                                        : "This parent and current attibute don't have a relationship."
                                }
                                className={[styles.relationship, relationship && styles.success].join(' ')}
                            >
                                {relationship ? (
                                    <span className={['k-icon k-i-link', styles.icon].join(' ')}></span>
                                ) : (
                                    <span className={['k-icon k-i-unlink', styles.icon].join(' ')}></span>
                                )}
                            </div>
                        )}
                        <SingleAttributeValuePlaceholder />
                    </Card>
                </div>
            )}

            <footer className={[styles['action-buttons'], styles.fullCol].join(' ')}>
                <div className={styles['action-buttons__left-content']}>
                    <GraphButton />
                </div>

                <div className={styles['action-buttons__right-content']}>
                    <Button
                        title="Close the Attribute Configuration"
                        text="Cancel"
                        type="button"
                        btnType="cancel"
                        onClick={closeModalFunc}
                    />

                    {relationship && hasParent !== 'NoParent' && (
                        <Button
                            title="Remove the relationship"
                            text="Remove"
                            type="button"
                            btnType="partial"
                            onClick={openDeleteConfirmationModal}
                        />
                    )}

                    <Button
                        title="Save the relationship"
                        text="Save"
                        type="button"
                        btnType="primary"
                        disabled={selectedParent !== null && parentDependencies?.parents[0]?.dependencies?.length === 0}
                        onClick={onClickSaveButton}
                    />
                </div>
            </footer>

            <Modal
                title="Remove the Relationship?"
                show={deleteConfirmation}
                closeBtn
                closeBtnFunc={closeDeleteConfirmationModal}
            >
                <p>
                    You sure that you want to remove the relationship with the parent
                    {getParentName() && <b className={styles.orangeBold}>&nbsp;{getParentName()}</b>}?
                </p>

                <div className={styles.btnCont}>
                    <Button
                        text="No"
                        type="button"
                        onClick={closeDeleteConfirmationModal}
                    />

                    <Button
                        text="Yes"
                        type="button"
                        btnType="cancel"
                        onClick={removeDependency}
                    />
                </div>
            </Modal>

            <Modal
                className={styles.modalCopy}
                show={copy}
            >
                <div className={styles.qttNumber}>
                    {
                        parentDependencies?.parents[0]?.dependencies?.filter(
                            dep => dep.parentOptionId === singleValueSelected
                        ).length
                    }
                </div>

                <div
                    title={nonCheckBoxListTotal.find(parent => parent.id === singleValueSelected)?.value}
                    className={styles.copyItem}
                >
                    <span>Copy from</span>{' '}
                    <b className={styles.orangeBold}>
                        {nonCheckBoxListTotal.find(parent => parent.id === singleValueSelected)?.value}
                    </b>
                </div>

                <div className={styles.InsertOptions}>
                    <Button
                        className={[styles.defaultBtn, styles.over, copyMode && styles.selectedBtn].join(' ')}
                        text="Overwrite"
                        type="button"
                        onClick={() => setCopyMode(true)}
                    />

                    <Button
                        className={[styles.defaultBtn, !copyMode && styles.selectedBtn].join(' ')}
                        text="Merge"
                        type="button"
                        onClick={() => setCopyMode(false)}
                    />
                </div>

                <div className={styles.copyCont}>
                    <div className={styles.copyList}>
                        <TextInput
                            placeholder="Search"
                            kendoIcon={[styles.kIcon, 'k-i-zoom'].join(' ')}
                            value={String(copySearchText)}
                            onChange={copyChange}
                            className={[styles.search, styles.marginBt10].join(' ')}
                        />
                        <TagListCopy />

                        <VirtualizedList
                            className={styles.marginTp10}
                            data={alphabeticalOrder(nonCheckBoxListTotal, 'value')
                                ?.map(item => {
                                    return { id: item.id, name: item.value, key: item.id, isSelected: false };
                                })
                                .filter(value => value.name.includes(String(copySearchText)))
                                .filter(item => item.id !== singleValueSelected)}
                            itemHeight={30}
                            render={props => <OptionListToCopy {...props} />}
                        />
                    </div>
                </div>

                <div className={styles.btnCont}>
                    <Button
                        text="Copy"
                        type="button"
                        onClick={saveCopyChanges}
                        disabled={optionListToCopy.length === 0}
                    />

                    <Button
                        text="Cancel"
                        type="button"
                        btnType="cancel"
                        onClick={closeCopy}
                    />
                </div>
            </Modal>

            <SaveWarningModal
                isOpen={isSaveWarningModalOpen}
                title="Confirm changes"
                onCancel={() => setIsSaveWarningModalOpen(false)}
                onConfirm={saveDependency}
            />

            <RelationshipErrorModal
                isOpen={hasProblemWithCurrentDependency}
                onClickRemove={openDeleteConfirmationModal}
            />
        </div>
    );
};

export default DependencySettings;
