import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { getCustomFieldPicklistOptions } from 'services/attributeManagementApis';

const useGetParentPicklistOptions = (
    parentCustomFieldId: number,
    options: ReactQueryHookOption<unknown> = { enabled: true }
) => {
    const customer = useAtomValue(customerAtom);

    const toast = useToast();

    return useQuery(
        [queryKeys.getParentPicklistOptions, String(customer?.clientId), Number(parentCustomFieldId)],
        getCustomFieldPicklistOptions,
        {
            enabled: false,
            retry: 3,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.');
            },
        }
    );
};

export default useGetParentPicklistOptions;
