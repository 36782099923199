import * as React from 'react';
import styles from './Textarea.module.scss';

/**
 * Textarea component
 * @param {React.TextareaHTMLAttributes<HTMLTextAreaElement>} props Component props
 * @returns {JSX.Element}
 */
const Textarea = ({ id, disabled, title = '', value, className, ...props }) => {
    const inputTitleProperty = disabled ? 'This field it is disabled' : title;

    return (
        <textarea
            {...props}
            value={value}
            id={id}
            disabled={disabled}
            title={inputTitleProperty}
            className={[styles.textAreaBaseStyle, className].join(' ')}
        />
    );
};

export default Textarea;
