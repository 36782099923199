import * as React from 'react';
import { useAtom } from 'jotai';
import { useUpdateAtom, useResetAtom } from 'jotai/utils';
import {
    attributeConfigurationModalVisibleAtom,
    parentDependencyMessageAtom,
    parentDependencyModalAtom,
    selectedEntityTypeIdAtom,
    entityTypeListAtom,
    selectedListAtom,
} from 'atons/AttributeManagement/atoms';
import EntityTypeAdapter from 'adapters/EntityTypeAdapter';

import { AutoComplete, Button, Modal } from 'components';

import styles from './AttributeManagementHeader.module.scss';
import User from 'domain/model/User';
import { useBulkDeleteCustomField, useChangeEntityTypeUserSelection } from 'react-query/mutations';
import { useGetEntityTypeByUserId, useGetEntityTypeList } from 'react-query/queries';
import ExportDataModal from './ExportDataModal/ExportDataModal';

const AttributeManagementHeader: React.FC = () => {
    const [isExportDataModalOpen, setIsExportDataModalOpen] = React.useState<boolean>(false);
    const [parentDepMessage, setParentDepMessage] = useAtom(parentDependencyMessageAtom);
    const [parentDepModal, setParentDepModal] = useAtom(parentDependencyModalAtom);
    const [selectedEntityType, setSelectedEntityType] = useAtom(selectedEntityTypeIdAtom);
    const [selectedList, setSelectedList] = useAtom(selectedListAtom);
    const [show, setShow] = useAtom(attributeConfigurationModalVisibleAtom);
    const updateEntityTypeList = useUpdateAtom(entityTypeListAtom);
    const resetEntityTypeList = useResetAtom(entityTypeListAtom);

    /**
     * Call the attributesFieldsList API using the react-query with useQuery
     */
    const { data: entityTypeList } = useGetEntityTypeList();

    /**
     * Call the changeEntityTypeUserSelection API using the react-query with useMutation
     */
    const { mutate: changeEntityTypeUserSelectionAction } = useChangeEntityTypeUserSelection();

    /**
     * Call the deleteAttributeList API using the react-query with useQuery
     */
    const { mutate: deleteAttributeListAction } = useBulkDeleteCustomField({
        onSuccess(data) {
            setSelectedList([]);
            if (data.errors && data.errors.length > 0) {
                setParentDepMessage(data.errors);
                setParentDepModal({ ...data.errors[0], index: 0 });
                return;
            }
        },
    });

    useGetEntityTypeByUserId({
        onSuccess: (entityTypeId: string) => {
            setSelectedEntityType(Number(entityTypeId));
        },
    });

    const openExportDataModal = () => setIsExportDataModalOpen(true);
    const closeExportDataModal = () => setIsExportDataModalOpen(false);

    const saveNewEntityType = (entityTypeId: number) => {
        const userId = User.getInstance()?.getUserId();
        if (typeof userId !== 'string')
            throw new Error('There is a problem with your login info, please try again later');
        changeEntityTypeUserSelectionAction({ userId, entityTypeId });
    };

    const onSelectEntityType = (value: string | null) => {
        const selectedValueAsNumber = Number(value);
        if (!value || selectedEntityType === selectedValueAsNumber) return;
        setSelectedEntityType(selectedValueAsNumber);

        try {
            saveNewEntityType(selectedValueAsNumber);
        } catch (err: any) {
            console.error(err);
        }
    };

    /**
     * Function the is going to reedirect to the legacy page to clear the cache of the attributes
     */
    const clearAttributes = () => window.open(`${process.env.REACT_APP_LEGACY_BASE_URL}/api/Admin/ClearAttributeCache`);

    /**
     * Function that verify if it has more than one kind of error and renew the warnning to show them all
     */
    const verifyMoreErrors = () => {
        if (parentDepModal.index + 1 === parentDepMessage.length) return closeDeleteParentModal();
        setParentDepModal({ ...parentDepMessage[parentDepModal.index + 1], index: parentDepModal.index + 1 });
    };

    /**
     * Function that set to null/undefined the parentDepMessage and parentDepModal to close the modal
     */
    const closeDeleteParentModal = () => {
        setParentDepMessage(undefined);
        setParentDepModal(undefined);
    };

    const deleteAttribute = () => deleteAttributeListAction(selectedList);

    const toogleShowModalFunc = () => setShow(!show);

    /**
     * Flag that indicates if the current user has access to the System admin settings
     */
    const hasAccessToSysAdminSettings = User.isSuperAdmin();

    const isCreateNewAttributeButtonDisabled = React.useMemo(
        () => !selectedEntityType && !entityTypeList?.some(({ id }) => id === selectedEntityType),
        [entityTypeList, selectedEntityType]
    );

    React.useEffect(() => {
        entityTypeList && updateEntityTypeList(entityTypeList);
        return () => {
            resetEntityTypeList();
        };
    }, [entityTypeList, updateEntityTypeList, resetEntityTypeList]);

    return (
        <>
            {/* Modal for warnning erros about dependencies on the attributes */}
            <Modal
                closeBtn={true}
                className={styles.errorModal}
                title={parentDepModal?.header}
                closeBtnFunc={closeDeleteParentModal}
                show={parentDepModal}
            >
                <p>{parentDepModal?.description}</p>
                {parentDepModal?.itemList.map((item, index) => {
                    return item.children ? (
                        <p
                            key={index}
                            className={styles.childParent}
                        >
                            Attribute: {item.name} / {!item.children.length && item.description}{' '}
                            {item.children.length > 0 && 'Total children: ' + item.children.length}
                        </p>
                    ) : (
                        <p
                            key={index}
                            className={styles.childParent}
                        >
                            Attribute: {item.name} / {item.description}
                        </p>
                    );
                })}
                <div className={styles.buttonCont}>
                    <Button
                        text="Ok"
                        onClick={verifyMoreErrors}
                    />
                </div>
            </Modal>

            <ExportDataModal
                isOpen={isExportDataModalOpen}
                closeModal={closeExportDataModal}
            />

            <div
                id="attribute-page-title-content"
                className={[styles.header, 'pageTitleCont'].join(' ')}
            >
                <h2 className="title-main">
                    Attribute
                    <span className="title-section-marker"> Management </span>
                </h2>

                {entityTypeList && entityTypeList.length > 0 && (
                    <div className={styles.btnCont}>
                        <Button
                            id="export-modal-button"
                            width="130px"
                            type="button"
                            title="Open export options"
                            text="Export"
                            onClick={openExportDataModal}
                        />

                        <AutoComplete
                            options={EntityTypeAdapter.toEntityTypeAutocompleteFieldOptions(entityTypeList)}
                            value={String(selectedEntityType)}
                            placeholder="Select Entity Type"
                            className={'clients-list'}
                            onChange={onSelectEntityType}
                        />

                        <Button
                            id="delete-attributes-button"
                            width="130px"
                            type="button"
                            title="Delete an attribute(s)"
                            disabled={selectedList.length === 0}
                            text="Delete"
                            onClick={deleteAttribute}
                        />

                        <Button
                            id="create-attribute-button"
                            type="button"
                            text="Create"
                            width="130px"
                            title="Create a new attribute"
                            disabled={isCreateNewAttributeButtonDisabled}
                            onClick={toogleShowModalFunc}
                        />
                        {hasAccessToSysAdminSettings && (
                            <Button
                                id="clear-attribute-button"
                                type="button"
                                text="Clear Cache"
                                width="150px"
                                title="To clear cache for the attributes on legacy"
                                onClick={clearAttributes}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default AttributeManagementHeader;
