import { Button, Modal } from 'components';

import styles from './SaveWarningModal.module.scss';

interface SaveWarningModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    isOpen: boolean;
}

const SaveWarningModal: React.FC<SaveWarningModalProps> = ({ onCancel, onConfirm, title, isOpen }) => {
    return (
        <Modal
            show={isOpen}
            title={title}
            className={styles.modal}
        >
            <p>You will lose your previous relationship if you press the Confirm button. Would you like to proceed?</p>

            <div className={styles.modal__footer}>
                <Button
                    text="Cancel"
                    type="button"
                    btnType="cancel"
                    onClick={onCancel}
                />

                <Button
                    text="Confirm"
                    type="button"
                    onClick={onConfirm}
                />
            </div>
        </Modal>
    );
};

export default SaveWarningModal;
