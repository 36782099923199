import * as React from 'react';

import { InputFieldProps } from '../types';
import styles from './ColorInput.module.scss';

const ColorInput: React.FC<InputFieldProps> = ({
    id,
    disabled,
    title = '',
    type = 'color',
    className,
    value = '#FFFFFF',
    error,
    ...props
}) => {
    const inputTitleProperty = disabled ? 'This field it is disabled' : title;

    const convertHexToDecimal = (hexadecimalPair: string): number => {
        const [fistLetter, secondLetter] = hexadecimalPair;
        return parseInt(fistLetter, 16) * Math.pow(16, 1) + parseInt(secondLetter, 16) * Math.pow(16, 0);
    };

    const getContrastBasedOnHexColor = (hexColor: string | number | readonly string[]): string => {
        if (!hexColor || typeof hexColor !== 'string') return '#000000';
        const [red1, red2, green1, green2, blue1, blue2] = hexColor.replace('#', '');
        const red = convertHexToDecimal(red1 + red2);
        const green = convertHexToDecimal(green1 + green2);
        const blue = convertHexToDecimal(blue1 + blue2);

        return red * 0.299 + green * 0.587 + blue * 0.114 > 150 ? '#000000' : '#FFFFFF';
    };

    const colorInputClassName = React.useMemo((): string => {
        const baseStyle = [styles.inputBaseStyle];
        if (!!className) baseStyle.push(className);
        if (!!error) baseStyle.push(styles.error);
        return baseStyle.join(' ');
    }, [className, error]);

    return (
        <div className={styles.container}>
            <input
                {...props}
                id={id}
                type={type}
                className={colorInputClassName}
                disabled={disabled}
                value={!!value ? value : '#FFFFFF'}
                title={inputTitleProperty}
            />

            <label
                htmlFor={id}
                className={styles.colorInputLabel}
                style={{ color: getContrastBasedOnHexColor(value) }}
            >
                {!!value ? String(value).toUpperCase() : '#FFFFFF'}
            </label>
        </div>
    );
};

export default ColorInput;
