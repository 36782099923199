import * as React from 'react';

import { InputFieldProps } from '../types';
import styles from './Switch.module.scss';

const Switch: React.FC<InputFieldProps> = ({ disabled, title, error, ...props }) => {
    const inputTitleProperty = disabled ? 'This field it is disabled' : title;

    const switchClassName = React.useMemo((): string => {
        const baseStyle = [styles.switchBaseStyle];
        if (!!error) baseStyle.push(styles.error);
        return baseStyle.join(' ');
    }, [error]);

    return (
        <input
            {...props}
            className={switchClassName}
            disabled={disabled}
            title={inputTitleProperty}
            type="checkbox"
        />
    );
};

export default Switch;
