import { selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { CustomFieldGroup, getCustomFieldGroups } from 'services/attributeManagementApis';

const useGetCustomFieldGroups = (options?: ReactQueryHookOption<CustomFieldGroup[]>) => {
    const customer = useAtomValue(customerAtom);
    const entityTypeId = useAtomValue(selectedEntityTypeIdAtom);
    const toast = useToast();

    function apiErrorHandler(error) {
        if (error.status === 400 && error.errors && error.detail && typeof options?.onError === 'function')
            return options.onError(error);
        if (process.env.NODE_ENV === 'development') console.log(error);
        toast.error('Something went wrong, try again later.');
    }

    return useQuery([queryKeys.customFieldGroups, customer?.clientId!, entityTypeId], getCustomFieldGroups, {
        enabled: !!customer && !!customer.clientId && !!entityTypeId,
        retry: 0,
        refetchOnWindowFocus: false,
        onError: apiErrorHandler,
        onSuccess: options?.onSuccess,
    });
};

export default useGetCustomFieldGroups;
