import * as React from 'react';

import { Label, Textarea } from '..';

/**
 * Molecule component tha uses the Label and a input based on the input type.
 * @param {{
 *  label: {
 *      text: string;
 *      className?: string;
 *  };
 *  textarea: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
 *  className?: string;
 * }} props Component Props
 * @returns {JSX.Element}
 */
const GroupTextarea = ({ label, textarea, className }) => {
    return (
        <div className={className}>
            <Label
                htmlFor={textarea.id}
                className={label.className}
                required={textarea.required}
                disabled={textarea.disabled}
            >
                {label.text}
            </Label>

            <Textarea {...textarea} />
        </div>
    );
};

export default GroupTextarea;
