import * as React from 'react';
import { useMutation } from 'react-query';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import {
    attributeSettingsAtom,
    disabledFieldsBasedOnAttributeTypeAtom,
    attributeFormModeAtom,
    selectedEntityTypeIdAtom,
    attributeSettingsDefaultValue,
    isChangingAttributeTypeAtom,
    attributesListAtom,
    attributeSettingsInitialValueAtom,
} from '../../../../../atons/AttributeManagement/atoms';

import { GroupInput, GroupSelect, GroupTextarea, FixedDiv, Button, Modal, ErrorModal } from '../../../../../components';
import SysAdminSettings from '../SysAdminSettings/SysAdminSettings';

import { useToast } from '../../../../../customHooks/useToast';

import User from '../../../../../domain/model/User';
import { numberOnlyField } from '../../../../../shared/helpers';

import { postCustomField, patchCustomField } from '../../../../../services/attributeManagementApis';

import styles from './AttributeSettings.module.scss';
import CustomFieldsAdapter from '../../../../../adapters/CustomFieldsAdapter';
import { customerAtom } from 'atons/Customer/atoms';
import GroupsManagerModal from './GroupsManagerModal/GroupsManagerModal';
import {
    useGetCustomFieldTypes,
    useGetCustomFieldValidations,
    useGetCustomFieldGroups,
    useGetCustomFieldList,
} from 'react-query/queries';
import AttributeTypes from 'enums/AttributeTypes';
import UnsavedChangesModal from './UnsavedChangesModal/UnsavedChangesModal';

const AttributeSettings = ({ closeModalFunc, switchCustomField, isAllowtoMultiselect }) => {
    const sectionRef = React.useRef();
    const customer = useAtomValue(customerAtom);
    const updateAttributesList = useUpdateAtom(attributesListAtom);
    const [fixedContentMaxHeight, setFixedContentMaxHeight] = React.useState(undefined);
    const [showAttributeTypeConfirmationModal, setShowAttributeTypeConfirmationModal] = React.useState(false);
    const [selectedAttributeId, setSelectedAttributeId] = React.useState('');
    /**
     * @type {[Map<string, string>, React.Dispatch<React.SetStateAction<Map<string, string>>>]}
     */
    const [formErrors, setFormErrors] = React.useState(new Map());
    const [error, setError] = React.useState(null);
    const [attributeSettingsValues, updateAttributeSettingsValue] = useAtom(attributeSettingsAtom);
    const disabledFieldsBasedOnAttributeType = useAtomValue(disabledFieldsBasedOnAttributeTypeAtom);
    const setAttributeSettingsInitialValue = useUpdateAtom(attributeSettingsInitialValueAtom);
    const entityTypeId = useAtomValue(selectedEntityTypeIdAtom);
    const formMode = useAtomValue(attributeFormModeAtom);
    const setIsChangingAttributeTypeAtom = useUpdateAtom(isChangingAttributeTypeAtom);
    const toast = useToast();

    /**
     * Function that verify if the field is disabled
     */
    const isFieldDisabled = React.useCallback(
        fieldName => disabledFieldsBasedOnAttributeType.includes(fieldName),
        [disabledFieldsBasedOnAttributeType]
    );
    /**
     * Required fields names
     */
    const requiredFields = React.useMemo(
        () => [
            'name',
            'attributeTypeId',
            'fieldHeight',
            'fieldWidth',
            'abbreviationForMulti',
            'attributeValidationOption',
            //'readOnlyStatus',
        ],
        []
    );
    /**
     * Function that verify if the field is required
     */
    const isFieldRequired = React.useCallback(
        fieldName => !isFieldDisabled(fieldName) && requiredFields.includes(fieldName),
        [isFieldDisabled, requiredFields]
    );
    /**
     * Function that verify if form is valid
     */
    const verifyFormValidation = () => {
        const enabledAttributes = [];
        /**
         * @type {Map<string, string>} Invalid fields Set
         */
        const invalidFields = new Map();

        const fieldIsValid = field => {
            const value = attributeSettingsValues[field];
            if (typeof value === 'boolean') return;

            if (typeof value === 'number') {
                const isIntegerValue = Number.isInteger(value);
                if (isIntegerValue) invalidFields.set(field, 'This Field must have a integer value');
                return;
            }

            const stringHasContent = !!value;
            if (!stringHasContent) invalidFields.set(field, 'This is a required field');
        };

        for (const key of Object.keys(attributeSettingsValues)) {
            if (formMode === 'create' && key === 'id') continue;
            if (isFieldRequired(key)) {
                enabledAttributes.push(key);
                fieldIsValid(key);
            }
        }

        if (invalidFields.size > 0) throw invalidFields;
    };
    /**
     * Callback function triggered when some API call get failed
     */
    function apiErrorHandler(error) {
        if (error.status === 400 && error.errors && error.detail) return setError(error);
        if (process.env.NODE_ENV === 'development') console.log(error);
        toast.error('Something went wrong, try again later.');
    }
    const { refetch: refetchGetCustomFieldList } = useGetCustomFieldList({
        onSuccess: data => {
            updateAttributesList(data);
            return data;
        },
    });
    /**
     * Query that get the custom field types
     */
    const { data: customFieldTypes } = useGetCustomFieldTypes();
    /**
     * Query that get the custom field validations
     */
    const { data: customFieldValidations } = useGetCustomFieldValidations({
        enabled:
            Boolean(attributeSettingsValues.attributeTypeId) &&
            !disabledFieldsBasedOnAttributeType.includes('attributeValidationOption'),
    });
    /**
     * Query that get the custom field groups
     */
    const { data: customFieldGroups } = useGetCustomFieldGroups({
        enabled:
            Boolean(attributeSettingsValues.attributeTypeId) &&
            !disabledFieldsBasedOnAttributeType.includes('customFieldGroupId'),
    });
    /**
     * Success callback when user save or update a custom field
     */
    const onSaveAttributeSuccessfully = response => {
        toast.success('Save Successfully!');
        refetchGetCustomFieldList();
        if (response?.id) {
            const newAttributeSettingsValue = {
                ...attributeSettingsValues,
                id: response.id,
            };
            updateAttributeSettingsValue(newAttributeSettingsValue);
            setAttributeSettingsInitialValue(newAttributeSettingsValue);
        }
        setIsChangingAttributeTypeAtom(false);
    };
    /**
     * Function that call the POST HTTP Method of CustomField in order to create a CustomField
     */
    const { mutate: postCustomFieldAction } = useMutation(
        ({ attributeSettings, customer, entityTypeId }) =>
            postCustomField(attributeSettings, customer.clientId, entityTypeId, User.getInstance().getUserId()),
        {
            onSuccess: onSaveAttributeSuccessfully,
            onError: apiErrorHandler,
        }
    );
    /**
     * Function that call the PATCH HTTP Method of CustomField in order to update a CustomField
     */
    const { mutate: patchCustomFieldAction, mutateAsync: patchCustomFieldActionAsync } = useMutation(
        ({ attributeSettings, customer, entityTypeId }) =>
            patchCustomField(attributeSettings, customer.clientId, entityTypeId, User.getInstance().getUserId()),
        {
            onSuccess: onSaveAttributeSuccessfully,
            onError: apiErrorHandler,
        }
    );

    React.useEffect(() => {
        if (sectionRef.current) setFixedContentMaxHeight(sectionRef.current?.clientHeight);
    }, []);

    React.useEffect(() => {
        if (entityTypeId > 0) {
            attributeSettingsValues.entityTypeId = entityTypeId;
        }
    }, [entityTypeId, attributeSettingsValues]);

    React.useEffect(() => {
        const runEffect = () => {
            const formattedAttributeTypeId = AttributeTypes.getById(attributeSettingsValues.attributeTypeId);
            if (
                formattedAttributeTypeId === AttributeTypes.DropDownList &&
                attributeSettingsValues.abbreviationForMulti === ''
            )
                return onChangeInputField({ target: { name: 'abbreviationForMulti', value: 'MULTI' } });

            if (formattedAttributeTypeId !== AttributeTypes.DropDownList)
                onChangeInputField({ target: { name: 'abbreviationForMulti', value: '' } });

            if (isFieldDisabled('maxCharLength')) {
                onChangeInputField({ target: { name: 'maxCharLength', value: '' } });
            }
        };

        runEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeSettingsValues.attributeTypeId]);

    const resetErrorWarning = key => {
        if (formErrors && formErrors.has(key)) formErrors.delete(key);
    };
    /**
     * Function that handles the input change.
     * @param {React.ChangeEvent<HTMLInputElement>} event Event object
     */
    const onChangeInputField = ({ target }) => {
        resetErrorWarning(target.name);
        updateAttributeSettingsValue(prevState => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };
    /**
     * Function that handles the switches change.
     * @param {React.ChangeEvent<HTMLInputElement>} event Event object
     */
    const onChangeCheckboxField = ({ target }) => {
        updateAttributeSettingsValue(prevState => ({
            ...prevState,
            [target.name]: target.checked,
        }));
    };

    /**
     * Function that handles the submit of the form.
     * @param {React.FormEvent<HTMLFormElement> | undefined} event Submit event
     */
    const onFormSubmit = event => {
        if (event) event.preventDefault();

        try {
            verifyFormValidation();

            if (formMode === 'create') {
                return postCustomFieldAction({
                    attributeSettings: attributeSettingsValues,
                    customer,
                    entityTypeId,
                });
            }

            patchCustomFieldAction({
                attributeSettings: attributeSettingsValues,
                customer,
                entityTypeId,
            });
        } catch (/**
         * @type {any}
         */ err) {
            if (err instanceof Map) {
                setFormErrors(err);
            }
        }
    };
    /**
     * @param {() => void} callback Callback functions
     */
    const updateAttributeSettingsAsync = async callback => {
        try {
            verifyFormValidation();
            await patchCustomFieldActionAsync({
                attributeSettings: attributeSettingsValues,
                customer,
                entityTypeId,
            });
            if (callback && typeof callback === 'function') callback();
        } catch (error) {
            if (error instanceof Map) {
                setFormErrors(error);
            }
        }
    };
    /**
     * Function that handles the reset event
     * @param {React.FormEvent<HTMLFormElement>} event Reset event
     */
    const onFormReset = event => {
        event.preventDefault();
        closeModalFunc();
    };
    /**
     * Flag that indicates if the current user has access to the System admin settings
     */
    const hasAccessToSysAdminSettings = User.isSuperAdmin();
    /**
     * Function that open the Attribute type confirmation Modal
     */
    const openAttributeTypeConfirmationModal = () => setShowAttributeTypeConfirmationModal(true);
    /**
     * Function that close the Attribute type confirmation Modal
     */
    const closeAttributeTypeConfirmationModal = () => {
        setIsChangingAttributeTypeAtom(false);
        setShowAttributeTypeConfirmationModal(false);
    };
    /**
     * Function that Listen to changes on the attribute type id input
     */
    const onChangeAttributeTypeId = value => {
        if (formMode === 'create') {
            return updateAttributeSettingsValue(prevstate => ({
                ...prevstate,
                attributeTypeId: value,
            }));
        }
        setSelectedAttributeId(value);
        setIsChangingAttributeTypeAtom(true);
        openAttributeTypeConfirmationModal();
    };
    /**
     * Function that reset the form values when user confirm the change of the Attribute type id
     */
    const onReplaceAttributeTypeId = () => {
        updateAttributeSettingsValue(() => ({
            ...attributeSettingsDefaultValue,
            id: attributeSettingsValues.id,
            attributeTypeId: selectedAttributeId,
        }));
        setSelectedAttributeId('');
        closeAttributeTypeConfirmationModal();
    };

    const handleKeyDownForNumber = event => {
        // Allow backspace, delete, arrow keys, tab, etc.
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
        // Allow numbers (0-9)
        const isNumber = /^[0-9]$/.test(event.key);
        // Check if the pressed key is either a number or an allowed editing key
        if (!isNumber && !allowedKeys.includes(event.key)) {
            event.preventDefault(); // Prevent input if not a number or allowed key
        }
    };
    const isMaxCharLengthDisable = () => {
        return (
            isFieldDisabled('maxCharLength') ||
            !(
                (attributeSettingsValues['attributeTypeId'] === '1' &&
                    ['0', '1'].includes(attributeSettingsValues['attributeValidationOption'])) ||
                attributeSettingsValues['attributeTypeId'] === '4'
            )
        );
    };
    return (
        <form
            className={styles.attributeConfigurationForm}
            onSubmit={onFormSubmit}
            onReset={onFormReset}
            noValidate
        >
            <section
                className={styles.attributeSettingsSection}
                ref={sectionRef}
            >
                <div className="row">
                    <div className="col-xs-12 col-lg-8">
                        <div className="row">
                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupInput
                                    label={{ text: 'Attribute Name:' }}
                                    input={{
                                        type: 'text',
                                        id: 'name',
                                        name: 'name',
                                        value: attributeSettingsValues['name'],
                                        onChange: onChangeInputField,
                                        disabled: isFieldDisabled('name'),
                                        required: isFieldRequired('name'),
                                        error: formErrors.get('name'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupInput
                                    label={{
                                        text: 'Attribute Description:',
                                        hint: 'Please provide an internal description of the attribute which will not show up on any screen',
                                    }}
                                    input={{
                                        type: 'text',
                                        id: 'description',
                                        name: 'description',
                                        value: attributeSettingsValues['description'],
                                        onChange: onChangeInputField,
                                        disabled: isFieldDisabled('description'),
                                        required: isFieldRequired('description'),
                                        error: formErrors.get('description'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupSelect
                                    label={{ text: 'Attribute Type:' }}
                                    select={{
                                        id: 'attributeTypeId',
                                        name: 'attributeTypeId',
                                        value: attributeSettingsValues['attributeTypeId'],
                                        options: CustomFieldsAdapter.toAttributeTypeList(customFieldTypes),
                                        onChange: value => {
                                            if (value === attributeSettingsValues['attributeTypeId']) return;
                                            onChangeAttributeTypeId(value);
                                            resetErrorWarning('attributeTypeId');
                                        },
                                        disabled: isFieldDisabled('attributeTypeId'),
                                        required: isFieldRequired('attributeTypeId'),
                                        error: formErrors.get('attributeTypeId'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupSelect
                                    label={{ text: 'Attribute Group:' }}
                                    select={{
                                        id: 'customFieldGroupId',
                                        name: 'customFieldGroupId',
                                        defaultValue: { label: 'Select an option', value: '' },
                                        value: attributeSettingsValues['customFieldGroupId'],
                                        options: customFieldGroups?.map(({ id, name }) => ({
                                            label: name,
                                            value: id,
                                        })),
                                        onChange: value => {
                                            resetErrorWarning('customFieldGroupId');
                                            updateAttributeSettingsValue(prevState => ({
                                                ...prevState,
                                                customFieldGroupId: value,
                                            }));
                                        },
                                        disabled: isFieldDisabled('customFieldGroupId'),
                                        required: isFieldRequired('customFieldGroupId'),
                                        error: formErrors.get('customFieldGroupId'),
                                        trailling: <GroupsManagerModal />,
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupInput
                                    label={{
                                        text: 'Attribute Order:',
                                        hint: 'Please enter the order this attribute should appear in within its section on the attribute. Editing this attribute is easier from the grid where you can see the other attributes',
                                    }}
                                    input={{
                                        type: 'text',
                                        id: 'attributeSortOrder',
                                        name: 'attributeSortOrder',
                                        value: attributeSettingsValues['attributeSortOrder'],
                                        onChange: ({ target }) => {
                                            resetErrorWarning('attributeSortOrder');
                                            updateAttributeSettingsValue(prevState => ({
                                                ...prevState,
                                                [target.name]: numberOnlyField(target.value, true),
                                            }));
                                        },
                                        disabled: isFieldDisabled('attributeSortOrder'),
                                        required: isFieldRequired('attributeSortOrder'),
                                        error: formErrors.get('attributeSortOrder'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupSelect
                                    label={{ text: 'Attribute Validation' }}
                                    select={{
                                        id: 'attributeValidationOption',
                                        name: 'attributeValidationOption',
                                        value: attributeSettingsValues['attributeValidationOption'],
                                        options: customFieldValidations?.map(({ id, name }) => ({
                                            label: name,
                                            value: id,
                                        })),
                                        onChange: value => {
                                            resetErrorWarning('attributeValidationOption');
                                            updateAttributeSettingsValue(prevstate => ({
                                                ...prevstate,
                                                attributeValidationOption: value,
                                                maxCharLength: ['0', '1'].includes(value)
                                                    ? prevstate.maxCharLength
                                                    : '',
                                            }));
                                        },
                                        disabled: isFieldDisabled('attributeValidationOption'),
                                        required: isFieldRequired('attributeValidationOption'),
                                        error: formErrors.get('attributeValidationOption'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupSelect
                                    label={{ text: 'Attribute Height:' }}
                                    select={{
                                        id: 'fieldHeight',
                                        name: 'fieldHeight',
                                        value: attributeSettingsValues['fieldHeight'],
                                        options: [
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                            { value: '5', label: '5' },
                                            { value: '6', label: '6' },
                                        ],
                                        onChange: value => {
                                            resetErrorWarning('fieldHeight');
                                            updateAttributeSettingsValue(prevstate => ({
                                                ...prevstate,
                                                fieldHeight: value,
                                            }));
                                        },
                                        disabled: isFieldDisabled('fieldHeight'),
                                        required: isFieldRequired('fieldHeight'),
                                        error: formErrors.get('fieldHeight'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupSelect
                                    label={{ text: 'Attribute Width:' }}
                                    select={{
                                        id: 'fieldWidth',
                                        name: 'fieldWidth',
                                        value: attributeSettingsValues['fieldWidth'],
                                        options: [
                                            { value: '1', label: '1' },
                                            { value: '2', label: '2' },
                                            { value: '3', label: '3' },
                                            { value: '4', label: '4' },
                                        ],
                                        onChange: value => {
                                            resetErrorWarning('fieldWidth');
                                            updateAttributeSettingsValue(prevstate => ({
                                                ...prevstate,
                                                fieldWidth: value,
                                            }));
                                        },
                                        disabled: isFieldDisabled('fieldWidth'),
                                        required: isFieldRequired('fieldWidth'),
                                        error: formErrors.get('fieldWidth'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupInput
                                    label={{ text: 'Multi Select Abbreviation' }}
                                    input={{
                                        type: 'text',
                                        id: 'abbreviationForMulti',
                                        name: 'abbreviationForMulti',
                                        value: attributeSettingsValues['abbreviationForMulti'],
                                        onChange: onChangeInputField,
                                        disabled: isFieldDisabled('abbreviationForMulti'),
                                        required: isFieldRequired('abbreviationForMulti'),
                                        error: formErrors.get('abbreviationForMulti'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12 col-lg-6 py-2">
                                <GroupInput
                                    label={{ text: 'Max Charecter Length' }}
                                    input={{
                                        type: 'text',
                                        id: 'maxCharLength',
                                        name: 'maxCharLength',
                                        value: attributeSettingsValues['maxCharLength'],
                                        onChange: onChangeInputField,
                                        onKeyDown: handleKeyDownForNumber,
                                        maxLength: 12,
                                        disabled: isMaxCharLengthDisable(),
                                        required: isFieldRequired('maxCharLength'),
                                        error: formErrors.get('maxCharLength'),
                                    }}
                                />
                            </div>

                            <div className="col-xs-12">
                                <GroupTextarea
                                    label={{ text: 'Tooltip' }}
                                    textarea={{
                                        id: 'tooltip',
                                        name: 'tooltip',
                                        value: attributeSettingsValues['tooltip'],
                                        onChange: onChangeInputField,
                                        disabled: isFieldDisabled('tooltip'),
                                        required: isFieldRequired('tooltip'),
                                        error: formErrors.get('tooltip'),
                                    }}
                                />
                            </div>
                            {hasAccessToSysAdminSettings && (
                                <div className="col-xs-12 pb-0 pb-lg-3">
                                    <SysAdminSettings
                                        isFieldDisabled={isFieldDisabled}
                                        isFieldRequired={isFieldRequired}
                                        resetErrorWarning={resetErrorWarning}
                                        formErrors={formErrors}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={[styles.rightSidePanel, 'col-xs-12 col-lg-4'].join(' ')}>
                        <FixedDiv
                            fixedClassName="p-4 d-flex flex-column"
                            minWidth={992}
                            maxHeight={fixedContentMaxHeight}
                        >
                            <div className="row">
                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Visible in Filters',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Should this attribute be used in the left side filter panel?',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'isDisplayForFilt.3er',
                                            name: 'isDisplayForFilter',
                                            checked: attributeSettingsValues.isDisplayForFilter,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('isDisplayForFilter'),
                                            required: isFieldRequired('isDisplayForFilter'),
                                            error: formErrors.get('isDisplayForFilter'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Multi-Select',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Should multi-select be enabled for this attribute or should it only be enabled for single selection',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'isMultiChoice',
                                            name: 'isMultiChoice',
                                            checked: attributeSettingsValues.isMultiChoice,
                                            onChange: onChangeCheckboxField,
                                            disabled:
                                                formMode === 'edit' && isAllowtoMultiselect
                                                    ? isFieldDisabled('readOnlyStatus')
                                                    : isFieldDisabled('isMultiChoice'),
                                            required: isFieldRequired('isMultiChoice'),
                                            error: formErrors.get('isMultiChoice'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Media Encoding',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Can this attribute be used in media code management as a component of the URL',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'isEncodeMediaCodeString',
                                            name: 'isEncodeMediaCodeString',
                                            checked: attributeSettingsValues.isEncodeMediaCodeString,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('isEncodeMediaCodeString'),
                                            required: isFieldRequired('isEncodeMediaCodeString'),
                                            error: formErrors.get('isEncodeMediaCodeString'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Calendar Elegible',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Can this attribute be published to the calendar pop-up',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'displayInCalendar',
                                            name: 'displayInCalendar',
                                            checked: attributeSettingsValues.displayInCalendar,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('displayInCalendar'),
                                            required: isFieldRequired('displayInCalendar'),
                                            error: formErrors.get('displayInCalendar'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Auto Roll-Up',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Do the amounts in this attribute roll-up (sum) to higher levels?',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'autoRollup',
                                            name: 'autoRollup',
                                            checked: attributeSettingsValues.autoRollup,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('autoRollup'),
                                            required: isFieldRequired('autoRollup'),
                                            error: formErrors.get('autoRollup'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Copy',
                                            className: styles.labelCheckboxFields,
                                            hint: 'When the parent object is copied, will the contents of this attribute also be copied?',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'isCopy',
                                            name: 'isCopy',
                                            checked: attributeSettingsValues.isCopy,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('isCopy'),
                                            required: isFieldRequired('isCopy'),
                                            error: formErrors.get('isCopy'),
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12 col-lg-12 py-2">
                                    <GroupInput
                                        label={{
                                            text: 'Required',
                                            className: styles.labelCheckboxFields,
                                            hint: 'Should this attribute be marked as required?',
                                        }}
                                        input={{
                                            type: 'switch',
                                            id: 'isRequired',
                                            name: 'isRequired',
                                            checked: attributeSettingsValues.isRequired,
                                            onChange: onChangeCheckboxField,
                                            disabled: isFieldDisabled('isRequired'),
                                            required: isFieldRequired('isRequired'),
                                            error: formErrors.get('isRequired'),
                                        }}
                                    />
                                </div>
                                {formMode === 'edit' && (
                                    <div className="col-xs-12 col-lg-12 py-2">
                                        <GroupInput
                                            label={{
                                                text: 'Deleted',
                                                className: styles.labelCheckboxFields,
                                                hint: 'If checked this attribute will not be shown and will be marked as deleted',
                                            }}
                                            input={{
                                                type: 'switch',
                                                id: 'isDeleted',
                                                name: 'isDeleted',
                                                checked: attributeSettingsValues.isDeleted,
                                                onChange: onChangeCheckboxField,
                                                disabled: isFieldDisabled('isDeleted'),
                                                required: isFieldRequired('isDeleted'),
                                                error: formErrors.get('isDeleted'),
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className={styles.actionButtonsContainer}>
                                <div className={styles.actionButtonWrapper}>
                                    <Button
                                        type="reset"
                                        text="Close"
                                        btnType="close"
                                        className={styles.actionButton}
                                    />
                                </div>

                                <div className={styles.actionButtonWrapper}>
                                    <Button
                                        type="submit"
                                        text="Save"
                                        btnType="primary"
                                        className={styles.actionButton}
                                    />
                                </div>
                            </div>
                        </FixedDiv>
                    </div>
                </div>
            </section>

            <Modal
                title="Change Attribute Type"
                show={showAttributeTypeConfirmationModal}
                closeBtn
                closeBtnFunc={closeAttributeTypeConfirmationModal}
            >
                <p>
                    If you change the existing Attribute Type you'll lose all the previous data of this Attribute. Do
                    you want to proceed?
                </p>

                <div className={styles.changeAttributeTypeActionButtons}>
                    <Button
                        text="Yes"
                        type="button"
                        onClick={onReplaceAttributeTypeId}
                    />
                    <Button
                        text="No"
                        type="button"
                        btnType="cancel"
                        onClick={closeAttributeTypeConfirmationModal}
                    />
                </div>
            </Modal>

            <UnsavedChangesModal
                switchCustomField={switchCustomField}
                onSave={updateAttributeSettingsAsync}
            />

            <ErrorModal
                show={!!error}
                closeBtn
                closeBtnFunc={() => setError(null)}
                title={error?.detail}
                errors={error?.errors}
            />
        </form>
    );
};

export default AttributeSettings;
