import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { getCustomFieldValidationOptions } from 'services/attributeManagementApis';

const useGetCustomFieldValidations = (options: ReactQueryHookOption<unknown> = { enabled: true }) => {
    const onError = useSetErrorMessage();

    return useQuery(queryKeys.customFieldValidationOptions, getCustomFieldValidationOptions, {
        enabled: options.enabled,
        retry: 3,
        refetchOnWindowFocus: false,
        onError: onError,
    });
};

export default useGetCustomFieldValidations;
