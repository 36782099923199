import * as React from 'react';
import { a11yKeyBoardEventHandler } from '../../shared/KeyboradKey';
import styles from './NewIntegrationDropDown.module.scss';

const NewIntegrationDropDown = props => {
    const selector = React.useRef();
    /**
     * Function that open/close the dropdown
     */
    const menuToggle = () => {
        props.setMenu(!props.menu);
    };
    /**
     * Function that handle the click outside of the component
     */
    const handleClickOutside = React.useCallback(
        evt => {
            if (selector.current !== null && !selector?.current?.contains(evt.target)) {
                props.setMenu(false);
            }
        },
        [props]
    );
    /**
     * UseEffect that controls the actions of click on the screen where this component is used
     */
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);
    return (
        <div
            id={'NewIntegrationDropDown'}
            ref={selector}
            className={[styles.btnCont, props.className].join(' ')}
        >
            <div
                className={styles.newIntegrationBtn}
                onClick={menuToggle}
                onKeyUp={a11yKeyBoardEventHandler(menuToggle)}
                role="button"
                tabIndex={0}
            >
                <span
                    role="presentation"
                    className={[styles.plus, 'k-icon k-i-plus'].join(' ')}
                ></span>
                Add New Integration
            </div>
            {props.menu && (
                <div className={styles.integrationList}>
                    {props.list.map((item, index) => {
                        return props.profile?.role === 'Hive9Admin' && item.urlLegacy !== null ? (
                            <div
                                key={index}
                                title={'Add new integrations to legacy not active to admin user'}
                                className={[styles.itemList, styles.disabledItem].join(' ')}
                            >
                                {item.title}
                            </div>
                        ) : (
                            <div
                                key={index}
                                className={styles.itemList}
                                onClick={() => {
                                    props.onItemClick(item);
                                    props.setMenu(false);
                                }}
                                onKeyUp={a11yKeyBoardEventHandler(() => {
                                    props.onItemClick(item);
                                    props.setMenu(false);
                                })}
                                role="button"
                                tabIndex={0}
                            >
                                {item.title}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default NewIntegrationDropDown;
