import { useMemo } from 'react';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { Button } from 'components';
import { useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { currentElementHierarchyOwnerAtom, relationshipItemAtom } from 'atons/AttributeManagement/dependencyAtoms';
import CustomFieldsAdapter, { RelationShipModalParameter } from 'adapters/CustomFieldsAdapter';
import AttributeTypes, { AttributeTypeId } from 'enums/AttributeTypes';

const GraphButton: React.FC = () => {
    const { childCustomFields, parentCustomFields, ...attributeSettings } = useAtomValue(attributeSettingsAtom);
    const setIsRelationshipModalOpen = useUpdateAtom(relationshipItemAtom);
    const setCurrentElementHierarchyOwner = useUpdateAtom(currentElementHierarchyOwnerAtom);

    const shouldRenderGraphButton = useMemo(
        () => !!attributeSettings.id && Boolean(childCustomFields.length || parentCustomFields.length),
        [childCustomFields, parentCustomFields, attributeSettings.id]
    );

    const attributeType = useMemo(
        () => ({
            id: +attributeSettings.attributeTypeId,
            description: '',
            name: AttributeTypes.getAttributeNameById(attributeSettings.attributeTypeId as AttributeTypeId),
        }),
        [attributeSettings.attributeTypeId]
    );

    const onClickRelationshipAction = () => {
        const payload: RelationShipModalParameter = {
            id: attributeSettings.id!,
            name: attributeSettings.name,
            attributeType,
            entityTypeId: attributeSettings.entityTypeId,
            entityType: attributeSettings.entityType,
            childCustomFields,
            parentCustomFields,
        };
        setIsRelationshipModalOpen(CustomFieldsAdapter.toRelationshipModal(payload));
        setCurrentElementHierarchyOwner({
            ...payload,
            customFieldTypeId: payload.attributeType.id as AttributeTypeId,
            parentId: parentCustomFields && parentCustomFields[0] ? parentCustomFields[0].id : undefined,
            childrenCount: payload.childCustomFields.length,
        });
    };

    return shouldRenderGraphButton ? (
        <Button
            title="See the dependency tree visualization"
            text="Tree graph"
            type="button"
            onClick={onClickRelationshipAction}
        />
    ) : null;
};

export default GraphButton;
