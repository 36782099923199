import * as React from 'react';
import { a11yKeyBoardEventHandler } from '../../shared/KeyboradKey';
import styles from './Modal.module.scss';

/**
 * Modal component, useful for display a content that need to overlay the page content
 * @param {{
 *  show: boolean;
 *  closeBtn?: boolean;
 *  closeBtnFunc?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
 *  title?: string;
 *  className?: string;
 *  modalContentClassName?: string;
 *  tabModal?: boolean;
 *  children: React.ReactNode;
 * }} props Component Props
 * @returns {JSX.Element}
 */
const Modal = props => {
    React.useEffect(() => {
        props.show
            ? (document.querySelector('html').style.overflow = 'hidden')
            : document.querySelector('html').removeAttribute('style');
    }, [props.show]);
    return (
        <>
            {props.show && (
                <div
                    className={styles.backModal}
                    role="dialog"
                >
                    <div className={[styles.modal, props.className].join(' ')}>
                        {props.closeBtn && (
                            <span
                                id="modal-close-button"
                                title={'Close Modal'}
                                onClick={props.closeBtnFunc}
                                onKeyUp={a11yKeyBoardEventHandler(props.closeBtnFunc)}
                                className={styles.closeBtn}
                                role="button"
                                tabIndex={0}
                            >
                                X
                            </span>
                        )}
                        {props.title && (
                            <div
                                className={styles.title}
                                id="modal-title"
                            >
                                {props.title}
                            </div>
                        )}
                        {!props.tabModal && (
                            <div className={[styles.cont, props.modalContentClassName].join('')}>{props.children}</div>
                        )}
                        {props.tabModal && props.children}
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
