import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { getCustomFieldTypes } from 'services/attributeManagementApis';

const useGetCustomFieldTypes = () => {
    const onError = useSetErrorMessage();

    return useQuery(queryKeys.customFieldTypes, getCustomFieldTypes, {
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            onError(error);
        },
    });
};

export default useGetCustomFieldTypes;
