export type EntityType =
    | 'Campaign'
    | 'Program'
    | 'Tactic'
    | 'LineItem'
    | 'Budget'
    | 'MediaCode'
    | 'Plan'
    | 'PurchaseRequest'
    | 'PurchaseRequestLineItem'
    | 'PurchaseRequestTransaction'
    | 'RelatedTactic'
    | 'AccrualRequest'
    | 'AccrualRequestLineItem';

export type EntityTypeId =
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13;

export default class EntityTypes {
    /**
     * Function that returns the enum value based on Attribute Type name.
     */
    static getByName(name: EntityType): number {
        return EntityTypes[name];
    }

    static getById(id: EntityTypeId): EntityType {
        return {
            '1': 'Campaign',
            '2': 'Program',
            '3': 'Tactic',
            '4': 'LineItem',
            '5': 'Budget',
            '6': 'MediaCode',
            '7': 'Plan',
            '8': 'PurchaseRequest',
            '9': 'PurchaseRequestLineItem',
            '10': 'PurchaseRequestTransaction',
            '11': 'RelatedTactic',
            '12': 'AccrualRequest',
            '13': 'AccrualRequestLineItem',
        }[id] as EntityType;
    }

    static Campaign = 1;
    static Program = 2;
    static Tactic = 3;
    static LineItem = 4;
    static Budget = 5;
    static MediaCode = 6;
    static Plan = 7;
    static PurchaseRequest = 8;
    static PurchaseRequestLineItem = 9;
    static PurchaseRequestTransaction = 10;
    static RelatedTactic = 11;
    static AccrualRequest = 12;
    static AccrualRequestLineItem = 13;
}
