import { toast } from 'react-toastify';

export function useToast() {
    /**
     * @type {import('react-toastify').ToastOptions}
     */
    const defaultToastyOptions = {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    };
    /**
     * Shows a info toasty on user interface.
     * @param {string} message Toasty message
     * @param {import('react-toastify').ToastOptions} [options = defaultToastyOptions] Toasty options
     */
    function info(message, options) {
        return toast.info(message, { ...defaultToastyOptions, ...options });
    }
    /**
     * Shows a warning toasty on user interface.
     * @param {string} message Toasty message
     * @param {import('react-toastify').ToastOptions} [options = defaultToastyOptions] Toasty options
     */
    function warning(message, options) {
        return toast.warning(message, { ...defaultToastyOptions, ...options });
    }
    /**
     * Shows a success toasty on user interface.
     * @param {string} message Toasty message
     * @param {import('react-toastify').ToastOptions} [options = defaultToastyOptions] Toasty options
     */
    function success(message, options) {
        return toast.success(message, { ...defaultToastyOptions, ...options });
    }
    /**
     * Shows a error toasty on user interface.
     * @param {string} message Toasty message
     * @param {import('react-toastify').ToastOptions} [options = defaultToastyOptions] Toasty options
     */
    function error(message, options) {
        return toast.error(message, { ...defaultToastyOptions, ...options });
    }

    return { info, warning, success, error };
}
