import * as React from 'react';
import { a11yKeyBoardEventHandler } from '../../shared/KeyboradKey';
import styles from './SearchSelectionList.module.scss';

/**
 * This Component is the select option list to hive9 new app
 * @param {*} data Proprierty that passes the object list
 * @param {*} value Proprierty that passes the dynamic value that should be a changeable state
 * @param {*} onClick Proprierty that passes the function that executes on the onchange event
 * @param {*} className Proprierty that passes the extra css config if needed
 * @returns The select options list that uses traditional HTML
 */
const SearchSelectionList = props => {
    const { id, data, value, onClick, className } = props;
    const selector = React.useRef();
    const [combos, setCombos] = React.useState();
    const [str, setStr] = React.useState('');
    const [show, setShow] = React.useState(false);
    /**
     * Function the fires the focus on the field
     */
    const focus = () => {
        setShow(true);
    };
    /**
     * Function that handle the click outside of the component
     */
    const handleClickOutside = React.useCallback(
        evt => {
            if (selector.current !== null && !selector?.current?.contains(evt.target)) {
                setShow(false);
                if (value === '') setStr('');
            }
        },
        [value]
    );
    /**
     * UseEffect that observe the search
     */
    React.useEffect(() => {
        if (str) {
            setCombos(data.filter(item => item.description.toLowerCase().includes(str.toLowerCase())));
        } else {
            setCombos(data);
        }
    }, [str, data]);
    /**
     * UseEffect that load on mount and abserves the value
     */
    React.useEffect(() => {
        setCombos(data);
        value === ''
            ? setStr(data.find(item => item.id === 'en-US').description)
            : setStr(data.find(item => item.id === value).description);
    }, [data, value]);
    /**
     * UseEffect that controls the actions of click on the screen where this component is used
     */
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);
    return (
        <div
            ref={selector}
            className={[styles.componentCont, className].join(' ')}
        >
            <input
                id={id}
                type={'text'}
                className={styles.searchField}
                onChange={evt => {
                    setStr(evt.target.value);
                    onClick('');
                    setShow(true);
                }}
                onFocus={focus}
                value={str}
            />
            {show && (
                <div className={styles.listCont}>
                    {combos?.map((el, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    onClick(el.id);
                                    setStr(el.description);
                                    setShow(false);
                                }}
                                onKeyUp={a11yKeyBoardEventHandler(() => {
                                    onClick(el.id);
                                    setStr(el.description);
                                    setShow(false);
                                })}
                                role="button"
                                tabIndex={0}
                                className={value === el.id ? [styles.item, styles.selected].join(' ') : styles.item}
                            >
                                {el.description}
                            </div>
                        );
                    })}
                    {combos.length === 0 && (
                        <div className={styles.nomatch}>
                            <span>No match with this description</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchSelectionList;
