import { useMutation } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';

import { TacticTypeDependency, putTacticTypeDependencies } from 'services/attributeManagementApis';
import { useToast } from 'customHooks/useToast';

import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { customerAtom } from 'atons/Customer/atoms';
import { useAtomValue } from 'jotai';

type onSuccessOptions = {
    showTasty?: boolean;
    verb: 'delete' | 'put';
};

export const usePutTacticTypeDependencies = (customFieldId: number, { showTasty = true, verb }: onSuccessOptions) => {
    const customer = useAtomValue(customerAtom);

    const onError = useSetErrorMessage();
    const toast = useToast();

    return useMutation(
        (obj: TacticTypeDependency) => putTacticTypeDependencies(customer!.clientId, customFieldId, obj),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.customFieldDetail]);
                queryClient.invalidateQueries([queryKeys.getTacticTypeCustomFieldDependenciesParents]);
                if (showTasty) {
                    verb === 'delete'
                        ? toast.success('The relantionship is removed successfully')
                        : toast.success('The relantionship is done successfully');
                }
            },
            onError: err => {
                console.log({ err });
                onError(err);
            },
        }
    );
};
