import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import User from '../../domain/model/User';

/**
 * @param {import('react-router-dom').RouteProps & {
 *  permissions?: string[],
 *  allowClientAdmins?: boolean;
 *  allowSystemAdmins?: boolean;
 * }} [props={ allowClientAdmins: false, allowSystemAdmins: false, permissions: [] }] Protected route props
 * @returns {JSX.Element}
 */
const PrivateRoute = ({
    component,
    render,
    permissions = [],
    allowClientAdmins = false,
    allowSystemAdmins = false,
    ...props
}) => {
    const protectedContent = (
        <Route
            component={component}
            render={render}
            {...props}
        />
    );

    if (User.isSuperAdmin()) return protectedContent;
    if (allowClientAdmins && User.isClientAdmin()) return protectedContent;
    if (allowSystemAdmins && User.isSystemAdmin()) return protectedContent;
    if (permissions?.length && User.hasPermissions(permissions)) return protectedContent;

    return (
        <Route {...props}>
            <Redirect to="/not-authorized" />
        </Route>
    );
};

export default PrivateRoute;
