import ReactDom from 'react-dom';

import { Button, Modal } from 'components';
import styles from './DeleteSingleItemModal.module.scss';

interface DeleteSingleItemModalProps {
    closeBtnFunc: () => void;
    title: string;
    isOpen: boolean;
    buttonLabel: string;
    onConfirm: () => void;
}

const DeleteSingleItemModal: React.FC<DeleteSingleItemModalProps> = ({
    closeBtnFunc,
    title,
    isOpen,
    buttonLabel,
    onConfirm,
    children,
}) => {
    return isOpen
        ? ReactDom.createPortal(
              <Modal
                  closeBtn
                  closeBtnFunc={closeBtnFunc}
                  title={title}
                  show={isOpen}
                  className={styles.modalWrapper}
              >
                  <div className={styles.modalContent}>{children}</div>

                  <div className={styles.confirmButtonWrapper}>
                      <Button
                          title="Click here to confirm the change"
                          text={buttonLabel}
                          onClick={onConfirm}
                      />
                  </div>
              </Modal>,
              document.body
          )
        : null;
};

export default DeleteSingleItemModal;
