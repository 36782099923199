import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';

import {
    attributeSettingsAtom,
    disabledFieldsBasedOnEntityTypeAtom,
} from '../../../../../atons/AttributeManagement/atoms';

import { GroupInput, GroupSelect } from '../../../../../components';

import styles from './SysAdminSettings.module.scss';

const SysAdminSettings = ({ isFieldDisabled, isFieldRequired, formErrors, resetErrorWarning }) => {
    const [attributeSettingsValues, updateAttributeSettingsValue] = useAtom(attributeSettingsAtom);
    const disabledFieldsBasedOnEntityType = useAtomValue(disabledFieldsBasedOnEntityTypeAtom);

    /**
     * Function that handles the switches change.
     * @param {React.ChangeEvent<HTMLInputElement>} event Event object
     */
    const onChangeCheckboxField = ({ target }) =>
        updateAttributeSettingsValue(prevState => ({
            ...prevState,
            [target.name]: target.checked,
        }));
    /**
     * Function that verify if the field is disabled
     */
    const isFieldDisabledForEntity = React.useCallback(
        fieldName => disabledFieldsBasedOnEntityType.includes(fieldName),
        [disabledFieldsBasedOnEntityType]
    );

    const isFieldDisabledForchild = React.useCallback(id => ![0, 1, 2].includes(Number(id)), []);

    return (
        <div className="row">
            <div className="col-xs-12 pt-4 pb-2">
                <h2 className={styles.sectionTitle}>System Admin Settings</h2>
            </div>

            <div className="col-xs-12 col-md-6 py-2">
                <GroupSelect
                    label={{ text: 'Read Only Status:' }}
                    select={{
                        id: 'readOnlyStatus',
                        name: 'readOnlyStatus',
                        value: attributeSettingsValues['readOnlyStatus'],
                        options: [
                            { value: null, label: 'N/A' },
                            { value: '1', label: 'Created' },
                            { value: '2', label: 'Committed' },
                            { value: '3', label: 'Accepted' },
                            { value: '4', label: 'Submitted' },
                            { value: '5', label: 'Declined' },
                            { value: '6', label: 'Approved/In Progress/Complete' },
                        ],
                        onChange: value => {
                            resetErrorWarning('readOnlyStatus');
                            updateAttributeSettingsValue(prevstate => ({
                                ...prevstate,
                                readOnlyStatus: value === 'undefined' ? null : value,
                            }));
                        },
                        disabled: isFieldDisabled('readOnlyStatus'),
                        required: isFieldRequired('readOnlyStatus'),
                        error: formErrors?.get('readOnlyStatus'),
                    }}
                />
            </div>

            <div className="d-none d-md-block col-md-6" />

            <div className="col-xs-12 col-md-6 py-2">
                <GroupInput
                    label={{ text: 'Contains User' }}
                    input={{
                        type: 'switch',
                        id: 'isUserAttribute',
                        name: 'isUserAttribute',
                        checked: attributeSettingsValues['isUserAttribute'],
                        onChange: onChangeCheckboxField,
                        disabled: isFieldDisabled('isUserAttribute'),
                        required: isFieldRequired('isUserAttribute'),
                        error: formErrors?.get('isUserAttribute'),
                    }}
                />
            </div>

            <div className="col-xs-12 col-md-6 py-2">
                <GroupInput
                    label={{ text: 'Hidden' }}
                    input={{
                        type: 'switch',
                        id: 'isHidden',
                        name: 'isHidden',
                        checked: attributeSettingsValues['isHidden'],
                        onChange: onChangeCheckboxField,
                        disabled: isFieldDisabled('isHidden'),
                        required: isFieldRequired('isHidden'),
                        error: formErrors?.get('isHidden'),
                    }}
                />
            </div>

            <div className="col-xs-12 col-md-6 py-2">
                <GroupInput
                    label={{ text: 'Searchable' }}
                    input={{
                        type: 'switch',
                        id: 'isDisplayForReview',
                        name: 'isDisplayForReview',
                        checked: attributeSettingsValues['isDisplayForReview'],
                        onChange: onChangeCheckboxField,
                        disabled: isFieldDisabled('isDisplayForReview'),
                        required: isFieldRequired('isDisplayForReview'),
                        error: formErrors?.get('isDisplayForReview'),
                    }}
                />
            </div>

            <div className="col-xs-12 col-md-6 py-2">
                <GroupInput
                    label={{ text: 'Searchable Grid' }}
                    input={{
                        type: 'switch',
                        id: 'isSearchableGrid',
                        name: 'isSearchableGrid',
                        checked: attributeSettingsValues['isSearchableGrid'],
                        onChange: onChangeCheckboxField,
                        disabled:
                            isFieldDisabled('isSearchableGrid') ||
                            isFieldDisabledForEntity('isSearchableGrid') ||
                            isFieldDisabledForchild(attributeSettingsValues.attributeValidationOption),
                        required: isFieldRequired('isSearchableGrid'),
                        error: formErrors?.get('isSearchableGrid'),
                    }}
                />
            </div>

            <div className="col-xs-12 d-block d-lg-none py-3">
                <div className={styles.sysAdminBorderBottom} />
            </div>
        </div>
    );
};

export default SysAdminSettings;
