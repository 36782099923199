import { GridCellProps } from '@progress/kendo-react-grid';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import { CustomAttributeListItem } from '../AttributeManagementGrid';

import styles from './GroupNameColumn.module.scss';

interface ActionsColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomAttributeListItem;
}

const GroupNameColumn: React.FC<ActionsColumnProps> = ({ dataItem }) => {
    return <td className={styles['group-name']}>{CustomFieldsAdapter.toGroupNameColumn(dataItem.customFieldGroup)}</td>;
};

export default GroupNameColumn;
