import { atom } from 'jotai';

export interface Customer {
    clientId: string;
    name: string;
}

const initialValue = null;

export const customerAtom = atom<Customer | null>(initialValue);

export const customerListAtom = atom<Customer[]>([]);
