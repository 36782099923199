import * as React from 'react';

import { Label, LabelProps, AutoComplete } from '..';
import styles from './GroupSelect.module.scss';

interface GroupSelectLabelProps extends Omit<LabelProps, 'children' | 'disabled' | 'required' | 'htmlFor'> {
    text: string;
}

interface GroupSelectAutocompleteProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'autoComplete' | 'defaultValue' | 'error'> {
    options?: { label: string; value: any }[];
    onChange: (value: string) => void;
    autoComplete?: string;
    defaultValue?: { label: string; value: string };
    trailling?: React.ReactNode;
    error?: string;
}

export interface GroupSelectProps {
    label: GroupSelectLabelProps;
    select: GroupSelectAutocompleteProps;
    className?: string;
}

const GroupSelect: React.FC<GroupSelectProps> = ({ label, select, className }) => {
    const { text, ...labelProps } = label;
    const { className: selectClassName, autoComplete = '', trailling, ...selectProps } = select;

    return (
        <div className={[styles.container, className].join(' ')}>
            <Label
                {...labelProps}
                htmlFor={selectProps.id}
                required={selectProps.required}
                disabled={selectProps.disabled}
                error={!!selectProps.error}
            >
                {text}
            </Label>

            <div className={styles.inputWrapper}>
                <AutoComplete
                    {...selectProps}
                    className={[styles.selectInput, selectClassName].join(' ')}
                    autoComplete={autoComplete}
                />

                {trailling}
            </div>
        </div>
    );
};

export default GroupSelect;
