import { errorModalAtom } from 'atons/components/errorModalAtoms';
import * as React from 'react';
import { useAtom } from 'jotai';

import ErrorModal from './ErrorModal';

interface ErrorModalWrapperProps {}

const ErrorModalWrapper: React.FC<ErrorModalWrapperProps> = () => {
    const [errorProps, setErrorProps] = useAtom(errorModalAtom);

    const onPressCloseErrorModal = (): void => {
        if (errorProps?.closeBtnFunc) errorProps.closeBtnFunc();
        setErrorProps(null);
    };

    return errorProps !== null ? (
        <ErrorModal
            {...errorProps}
            closeBtnFunc={onPressCloseErrorModal}
        />
    ) : null;
};

export default ErrorModalWrapper;
