import { atom } from 'jotai';

export const selectedGroupsIdsAtom = atom<number[]>([]);

export interface GroupDetail {
    id?: number;
    name: string;
    sortOrder: string;
    isApprovalObject: boolean;
}

export const groupDetailAtom = atom<GroupDetail | null>(null);
