import * as React from 'react';
import { Switch, Route, HashRouter, useHistory, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './react-query/queryClient';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'jotai';
import { AuthProvider, useAuth } from 'oidc-react';
import { UserManager, Log } from 'oidc-client';
import Constants from './config/Constants';

// Pages and Components
import {
    Main,
    Integrations,
    IntegrationSetup,
    CustomerAssociation,
    AttributeManagement,
    OrganizationSettings,
    SigninOidc,
    SignoutOidc,
    PrivateRoute,
    Manager,
    Groups,
} from './pages/index';
import LoadingFull from './components/LoadingFull/LoadingFull';
import NotFound from './pages/NotFound/NotFound';
import NotAuthorized from './pages/NotAuthorized/NotAuthorized';
import { DrawerContainer, ErrorModalWrapper, NotAuthorizedModal } from './components/index';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.scss';
import './styles/admin-module-theme-ui.css';

function ProtectedApp() {
    const auth = useAuth();
    const location = useLocation();
    const [selected, setSelected] = React.useState(location.pathname);
    const IsLoggedIn = auth.userData && !auth.userData.expired;

    /**
     * Effect that handle custom autoSignIn logic
     */
    React.useEffect(() => {
        if (!auth.isLoading && !IsLoggedIn) {
            if (localStorage.getItem('clientInfo') !== null) {
                localStorage.removeItem('clientInfo');
            }
            auth.signIn({
                state: {
                    // Set the redirectUrl to be used in onSignIn function
                    redirectUrl: location.pathname,
                },
            });
        }
    }, [auth, IsLoggedIn, location.pathname]);

    /**
     * Effect that handle silentRenewErrors
     */
    React.useEffect(() => {
        const onSilentRenewError = error => {
            // When there's an error with silent renew redirect to the user sign in manually
            auth.signIn({
                state: {
                    redirectUrl: location.pathname,
                },
            });
        };

        auth.userManager.events.addSilentRenewError(onSilentRenewError);

        return function cleanup() {
            auth.userManager.events.removeSilentRenewError(onSilentRenewError);
        };
    });

    return (
        <>
            {!auth.isLoading && IsLoggedIn && (
                <QueryClientProvider client={queryClient}>
                    <Provider>
                        <HashRouter>
                            <NotAuthorizedModal />
                            <LoadingFull />
                            <ToastContainer />
                            <DrawerContainer>
                                <Switch>
                                    <Route
                                        exact={true}
                                        path="/"
                                        component={() => (
                                            <Main
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact={true}
                                        path="/signin-oidc"
                                        component={SigninOidc}
                                    />
                                    <Route
                                        exact={true}
                                        path="/signout-oidc"
                                        component={SignoutOidc}
                                    />
                                    <Route
                                        exact={true}
                                        path="/integrations"
                                        component={() => (
                                            <Integrations
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        )}
                                    />
                                    <PrivateRoute
                                        exact={true}
                                        path="/integrations/customer-integrations"
                                        component={CustomerAssociation}
                                    />
                                    <PrivateRoute
                                        exact={true}
                                        path="/integrations/integration-setup"
                                        permissions={['CanViewIntegrations']}
                                        component={IntegrationSetup}
                                    />
                                    <PrivateRoute
                                        exact={true}
                                        path="/customfields/groups"
                                        component={Groups}
                                    />
                                    <PrivateRoute
                                        exact={true}
                                        path="/customfields/attributes"
                                        component={AttributeManagement}
                                    />
                                    <PrivateRoute
                                        exact={true}
                                        path="/customfields"
                                        component={Manager}
                                    />
                                    <Route
                                        exact={true}
                                        path="/organization-settings"
                                        component={OrganizationSettings}
                                    />
                                    <Route
                                        exact={true}
                                        path="/not-authorized"
                                        component={NotAuthorized}
                                    />
                                    <Route
                                        exact={true}
                                        path="*"
                                        component={NotFound}
                                    />
                                </Switch>
                            </DrawerContainer>
                        </HashRouter>

                        <ErrorModalWrapper />
                    </Provider>
                    <ReactQueryDevtools />
                </QueryClientProvider>
            )}
        </>
    );
}

function App() {
    const history = useHistory();

    const config = {
        authority: Constants.Authority,
        client_id: Constants.ClientId,
        redirect_uri: Constants.RedirectUri,
        response_type: Constants.ResponseType,
        scope: Constants.Scope,
        post_logout_redirect_uri: Constants.PostLogoutRedirectUri,
        silent_redirect_uri: Constants.SilentRedirectUri,
        automaticSilentRenew: Constants.AutomaticSilentRenew,
        monitorSession: Constants.MonitorSession,
    };

    if (Constants.EnableAuthenticationDebug) {
        Log.logger = console;
        Log.level = Log.DEBUG;
    }

    const userManager = new UserManager(config);

    return (
        <AuthProvider
            userManager={userManager}
            // We set this to false to handle the autoSignIn manually to be able to send the current location.pathname
            // to the signIn method, by doing this we can redirect the user back to the location he wanted to go
            autoSignIn={false}
            onSignIn={user => {
                if (user?.state?.redirectUrl) {
                    // Redirect to requested redirectUrl
                    history.push(user.state.redirectUrl);
                } else {
                    // Redirect to Main page
                    history.push(Main);
                }
            }}
        >
            <ProtectedApp />
        </AuthProvider>
    );
}

export default App;
