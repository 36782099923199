import axiosClient from './axiosClient';
import SessionStorageService from '../services/SessionStorageService';

axiosClient.interceptors.request.use(
    config => {
        const user = SessionStorageService.getUser();
        if (!user) return config;
        config.headers['Authorization'] = `${user.token_type} ${user.access_token}`;
        return config;
    },
    error => Promise.reject(error)
);

export default axiosClient;
