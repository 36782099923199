import { selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { CustomAttributeListItemResponse, getCustomFieldList } from 'services/attributeManagementApis';

const useGetCustomFieldList = (options?: ReactQueryHookOption<CustomAttributeListItemResponse[]>) => {
    const customer = useAtomValue(customerAtom);
    const entityTypeByUserIdReturn = useAtomValue(selectedEntityTypeIdAtom);

    const toast = useToast();

    return useQuery(
        [queryKeys.attributeFieldsList, customer!, { entityTypeId: entityTypeByUserIdReturn }],
        getCustomFieldList,
        {
            enabled: customer !== null && typeof entityTypeByUserIdReturn === 'number',
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.');
                if (options?.onError) options.onError(error);
            },
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetCustomFieldList;
