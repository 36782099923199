import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { getEntityTypePossibleOptions } from 'services/attributeManagementApis';
import { ReactQueryHookOption } from 'react-query/types';

const useGetEntityTypePossibleOptions = (
    customFieldId: number,
    selectedParent: number,
    options: ReactQueryHookOption<unknown> = { enabled: true }
) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();
    return useQuery(
        [queryKeys.getEntityTypePossibleParentOptions, String(customer?.clientId), Number(customFieldId)],
        getEntityTypePossibleOptions,
        {
            enabled: !!customer?.clientId && !!customFieldId && !!options.enabled && selectedParent === -1,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetEntityTypePossibleOptions;
