import * as React from 'react';
import { useAtomValue, useAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';

import { VirtualizedListRender, Checkbox } from 'components';

import { optionListToCopyAtom } from 'atons/AttributeManagement/dependencyAtoms';

import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';
import styles from './OptionListToCopy.module.scss';

interface OptionListToCopyProps {
    id: number;
    name: string;
    key: string;
    isSelected?: boolean;
}

const OptionListToCopy: React.FC<VirtualizedListRender<OptionListToCopyProps>> = ({ index, item, style }) => {
    const checkboxRef = React.useRef<HTMLInputElement>(null);
    const [optionListToCopy, setOptionListToCopy] = useAtom(optionListToCopyAtom);

    const classNames = React.useMemo(() => {
        const classes = [styles.option];
        if (!!item.isSelected) classes.push(styles.selected);
        return classes.join(' ');
    }, [item.isSelected]);

    const isChecked = useAtomValue(
        selectAtom(
            optionListToCopyAtom,
            React.useCallback(
                list =>
                    list?.some(att => {
                        return att === item.id;
                    }),
                [item.id]
            )
        )
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setOptionListToCopy([...optionListToCopy, item.id]);
        } else {
            setOptionListToCopy(optionListToCopy.filter(opt => opt !== item.id));
        }
    };

    const onClickListOption = (event?: React.MouseEvent<HTMLLIElement>) => {
        if (event?.target['localName'] === 'input') return; //if the target is the checkbox
        checkboxRef?.current?.click();
    };

    return (
        <li
            className={[classNames].join(' ')}
            key={item.id}
            style={style}
            tabIndex={0}
            onClick={onClickListOption}
            onKeyUp={a11yKeyBoardEventHandler(onClickListOption)}
            role="option"
            aria-selected={false}
        >
            <Checkbox
                ref={checkboxRef}
                title={item.name}
                checked={isChecked}
                onChange={onChange}
                className={styles.checkbox}
            />

            <span
                className={styles.option__text}
                title={item.name}
            >
                {item.name}
            </span>
        </li>
    );
};

export default OptionListToCopy;
