import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { Tag } from 'components';
import { nonCheckBoxListTotalAtom, optionListToCopyAtom } from 'atons/AttributeManagement/dependencyAtoms';

import styles from './TagListCopy.module.scss';

const TagListCopy: React.FC = () => {
    const nonCheckBoxListTotal = useAtomValue(nonCheckBoxListTotalAtom);
    const [optionListToCopy, setOptionListToCopy] = useAtom(optionListToCopyAtom);

    const selecAll = () => {
        nonCheckBoxListTotal.forEach(check => {
            if (!optionListToCopy.some(opt => opt === check.id)) {
                optionListToCopy.push(check.id);
            }
        });
        setOptionListToCopy([...optionListToCopy]);
    };

    const deselecAll = () => {
        setOptionListToCopy([]);
    };

    return (
        <div className={styles.tagWrapper}>
            <Tag
                onClick={selecAll}
                className={styles.tag}
            >
                Select All
            </Tag>
            <Tag
                onClick={deselecAll}
                className={styles.tag}
                type={'deselect'}
            >
                Deselect All
            </Tag>
        </div>
    );
};

export default TagListCopy;
