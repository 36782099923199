import * as React from 'react';
import { useUpdateAtom, useResetAtom, useAtomValue } from 'jotai/utils';
import { useMutation, useQuery } from 'react-query';

import { ErrorModal } from '../../../../../../components';

import { getCustomFieldPickList, postCustomFieldPickList } from '../../../../../../services/attributeManagementApis';
import CustomFieldsAdapter from '../../../../../../adapters/CustomFieldsAdapter';

import {
    selectedPicklistToEdit,
    newPicklistRowValueAtom,
    attributeSettingsAtom,
} from '../../../../../../atons/AttributeManagement/atoms';

import { useToast } from '../../../../../../customHooks/useToast';
import { queryClient } from '../../../../../../react-query/queryClient';
import { queryKeys } from '../../../../../../react-query/constants';

import User from '../../../../../../domain/model/User';

import styles from './ActionsColumn.module.scss';
import { customerAtom } from 'atons/Customer/atoms';

const ActionsColumn = ({ dataItem, id }) => {
    const [error, setError] = React.useState(null);

    const newPicklistRowValues = useAtomValue(newPicklistRowValueAtom);
    const resetRow = useResetAtom(newPicklistRowValueAtom);
    const setSelectedPick = useUpdateAtom(selectedPicklistToEdit);
    const { id: customFieldId } = useAtomValue(attributeSettingsAtom);
    const customer = useAtomValue(customerAtom);

    const toast = useToast();

    const mode = !!dataItem.id ? 'edit' : 'create';
    const isItemDeleted = dataItem.isDeleted === 'Yes';

    /**
     * Call the getCustomFieldPickList API using the react-query with useQuery
     */
    const { data: cachedPicklistData } = useQuery(
        [queryKeys.getCustomFieldPicklist, customer.clientId, customFieldId],
        getCustomFieldPickList,
        {
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', {
                    onOpen: () => console.log(error),
                }),
        }
    );

    const showGenericToastError = () => toast.error('Something went wrong, try again later.');

    const handleBadRequestError = ({ errors, title }) => {
        function errObjectHasHeaderAndDescriptionProperties(err) {
            return Object.hasOwn(err, 'header') && Object.hasOwn(err, 'description');
        }
        if (Object.hasOwn(errors, 'length') && errors.every(errObjectHasHeaderAndDescriptionProperties)) {
            return setError({ title, errors });
        }
        if (typeof errors === 'object' && Object.keys(errors).length > 0) {
            return setError({
                title,
                errors: Object.keys(errors).map(key => ({
                    header: '',
                    description: errors[key].join('\b'),
                })),
            });
        }
        showGenericToastError();
    };

    const { mutate: postCustomField } = useMutation(
        () =>
            postCustomFieldPickList(
                customer.clientId,
                customFieldId,
                CustomFieldsAdapter.postNewPicklistItem(newPicklistRowValues, User.getInstance().getUserId())
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
                resetRow();
            },
            onError: error => {
                if (error?.status === 400 && error.errors) {
                    return handleBadRequestError(error);
                }
                showGenericToastError();
            },
        }
    );

    const isNewRowItemValid = () => !cachedPicklistData.some(({ value }) => value === newPicklistRowValues.value);

    const addNewRowToPicklist = () => {
        if (isNewRowItemValid()) return postCustomField();
        setError({
            title: 'Validation errors found',
            errors: [
                {
                    header: '',
                    description: `A item with value of "${newPicklistRowValues.value}" already exists on this Picklist`,
                },
            ],
        });
    };

    return mode === 'create' ? (
        <td
            id={id}
            style={{ top: 0, bottom: 0 }}
            className="k-grid-row-sticky"
        >
            <div className={styles.checkboxWrapper}>
                <button
                    id="save-new-item-button"
                    className={styles.actionButton}
                    onClick={addNewRowToPicklist}
                    disabled={!newPicklistRowValues.value.length}
                    title={
                        !newPicklistRowValues.value.length
                            ? 'You must type some value into the input field in order to enable this button'
                            : 'Click here to add this item to the Picklist'
                    }
                >
                    <i className="k-icon k-i-add" />
                    Add
                </button>
            </div>

            <ErrorModal
                show={!!error}
                title={error?.title}
                closeBtn
                closeBtnFunc={() => setError(null)}
                errors={error?.errors}
                className={styles.errorModal}
            />
        </td>
    ) : (
        <td id={id}>
            <div className={styles.container}>
                <button
                    className={[styles.actionButton, styles.colorBlack].join(' ')}
                    id={`edit-item-button-${dataItem.id}`}
                    onClick={() => setSelectedPick(dataItem)}
                    type="button"
                    title={isItemDeleted ? 'Only when it is active can it be edited' : 'Edit this item'}
                    disabled={isItemDeleted}
                >
                    <span className="k-icon k-i-track-changes-enable" />
                </button>
            </div>
        </td>
    );
};

export default ActionsColumn;
