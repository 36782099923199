import * as React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { useAtom } from 'jotai';
import { globalVariables } from '../../atons/globalAtons';
import Loader from 'react-loader-spinner';

const LoadingFull = () => {
    const [global] = useAtom(globalVariables);
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();
    return (
        <>
            {(isFetching !== 0 || isMutating !== 0 || global.loading) && (
                <div className="backLoading">
                    <Loader
                        className="loading"
                        type="ThreeDots"
                        color="#f8710e"
                        height={70}
                        width={70}
                    />
                </div>
            )}
        </>
    );
};

export default LoadingFull;
