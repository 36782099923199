import * as React from 'react';

import styles from './Button.module.scss';

export type ButtonType = 'error' | 'partial' | 'cancel' | 'close' | 'link' | 'primary';
type ButtonSize = 'small' | 'default' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    btnType?: ButtonType;
    width?: string;
    marginLeft?: string;
    marginRight?: string;
    text: React.ReactNode;
    size?: ButtonSize;
    className?: string;
    rightIcon?: string;
}

const Button: React.FC<ButtonProps> = ({
    text,
    disabled,
    btnType,
    onClick: onClickFunc,
    width,
    title,
    marginLeft,
    marginRight,
    className,
    rightIcon,
    size = 'default',
    ...props
}) => {
    const inputTitleProperty = disabled && !title ? 'This field is disabled' : title;
    /**
     * Function that sets the type of the button it can be error|partial|cancel|close and if you dont pass nothing it will be the default value
     * @returns The style that is going to be used on the button
     */
    const baseStyle = React.useMemo(() => {
        switch (btnType) {
            case 'link':
                return styles.linkButton;
            case 'primary':
                return styles.primaryButton;
            case 'error':
            case 'partial':
                return styles.errorPartial;
            case 'cancel':
            case 'close':
                return styles.cancelClose;
            default:
                return styles.default;
        }
    }, [btnType]);

    const buttonSizeClassName = React.useMemo(() => styles[`btnSize-${size}`], [size]);

    return (
        <button
            {...props}
            disabled={disabled}
            onClick={onClickFunc}
            style={{ width, marginLeft, marginRight, ...props.style }}
            title={inputTitleProperty}
            className={[className, baseStyle, buttonSizeClassName, disabled && styles.disabled].join(' ')}
        >
            {text}
            {!!rightIcon && <i className={`k-icon ${rightIcon} ml-1`} />}
        </button>
    );
};

export default Button;
