/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import AutoCompleteSelector from './AutoCompleteSelector';

const AutoCompleteContext = React.createContext({});

const defaultValue = { label: '', value: '' };

/**
 * function that returns the label of the provided value
 * @param {string} value Value to check if has a match
 * @param {{ label: string; value: any }[]} options Available options
 * @returns {{
 *   label: string;
 *   value: string;
 * }} Value of the Match
 */
function getElementByValue(value, options) {
    if (!options.length) return defaultValue;
    const element = options.find(option => String(option.value) === value);
    if (!!element) return element;
    return defaultValue;
}

/**
 * This component gives access to AutoCompleteContext's values for it children
 * @param {Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'defaultValue' | 'autoComplete'> & {
 *  options?: { label: string; value: any }[];
 *  onChange: (value: string) => void;
 *  defaultValue?: { label: string; value: string };
 *  autoComplete?: string;
 * }} props Component props
 * @returns {JSX.Element}
 */
function AutoCompleteProvider({
    options = [],
    value = '',
    defaultValue = undefined,
    onChange,
    autoComplete,
    onBlur,
    ...props
}) {
    const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(getElementByValue(value, options));
    const [availableOptions, setAvailableOptions] = React.useState(options);
    const [isClosingDropdown, setIsClosingDropdown] = React.useState(false);

    const autoCompleteRef = React.useRef();

    /**
     * Function that set focus on search input
     * @returns {void}
     */
    const focousSearchInput = () => autoCompleteRef.current?.focusInput();

    /**
     * Function that set focus on search input
     * @returns {void}
     */
    const blurSearchInput = () => autoCompleteRef.current?.blurInput();

    /**
     * Function that set focus on the first chield of the list
     * @returns {void}
     */
    const focousFirstElement = () => autoCompleteRef.current?.focusUlFirstChild();

    /**
     * Function that reset input to a default value
     * @returns {void}
     */
    const clearInputField = () => setSelectedValue(defaultValue);

    /**
     * Function that expand the options list
     */
    const openAutoCompleteOptions = React.useCallback(() => {
        if (props.disabled || isOptionsVisible) return;
        if (autoComplete) clearInputField();
        setIsOptionsVisible(true);
        focousSearchInput();
    }, [autoComplete, isOptionsVisible, props.disabled]);

    /**
     * Function that change the `isClosingDropdown` state in order to be able to close the
     * dropdown in the useEffect hook
     */
    const closeAutoCompleteOptions = React.useCallback(() => setIsClosingDropdown(true), []);

    /**
     * Function that update the 'selectedValue' when 'autoComplete' property it's set to true
     * @param {React.ChangeEvent<HTMLInputElement>} event HTMLInput change event
     */
    const onChangeText = event => {
        const { value: eventValue } = event.target;
        setSelectedValue({ value: '', label: eventValue });
    };

    /**
     * Function that validate the selected option
     * @param {string} selectedOptionValue User selected option
     * @returns {boolean} Validate result
     */
    const validateSelectedOption = selectedOptionValue => {
        if (defaultValue?.value === selectedOptionValue) return true;
        if (!selectedOptionValue) return false;
        const optionList = !!defaultValue ? [defaultValue, ...options] : options;
        return optionList.some(element => {
            if (typeof element.value === 'object' || typeof element.value === 'function') {
                return false;
            }
            return String(element.value) === String(selectedOptionValue);
        });
    };

    /**
     * This function executes the onChange callback and close the autocomplete options
     * @param {string} selectedOption User selected option
     */
    const submitSelected = selectedOption => {
        const value = String(selectedOption);
        if (onChange) onChange(value);
        closeAutoCompleteOptions();
    };

    /**
     * Function that get the selected value and validate the result
     * @param {React.MouseEvent<HTMLLIElement, MouseEvent>} event Mouse click event
     * @returns {void}
     */
    const onSelectOptionValue = event => {
        event.stopPropagation(); // this Prevent the openAutoCompleteOptions to be triggered
        const { dataset } = event.target;
        const datasetValue = dataset['value'];
        if (validateSelectedOption(datasetValue)) {
            return submitSelected(datasetValue);
        }
        submitSelected(value);
    };

    /**
     * Get the value of option based on the searchInput
     * @returns {string}
     */
    const getValueFromSearchInput = () => {
        const filteredOptions = options.filter(
            ({ label }) => label.toLowerCase() === selectedValue?.label.toLowerCase()
        );
        if (filteredOptions.length > 1 || !filteredOptions.length) return '';
        return filteredOptions[0].value;
    };

    /**
     * Function that retrieve the value of the search input and if has a match on possible options, it
     * submit the new value. Otherwise it keepth with the initial value
     * @returns {void}
     */
    const submitValueInput = () => {
        const selectedValue = getValueFromSearchInput();
        if (validateSelectedOption(selectedValue)) {
            return submitSelected(selectedValue);
        }
        submitSelected(value);
    };

    /**
     * Functiont that filter the options based on the selectedValue
     */
    const filterForOptionLabel = React.useCallback(
        element => {
            if (!selectedValue?.label) return true;
            if (!element.label) return false;
            return String(element.label).toLowerCase().includes(selectedValue?.label.toLowerCase());
        },
        [selectedValue]
    );

    /**
     * Side Effect that calls a filter function for options when the selectedValue changes
     */
    React.useEffect(() => {
        if (!autoComplete) return setAvailableOptions(options);
        const filteredOptions = options.filter(filterForOptionLabel);
        setAvailableOptions(filteredOptions);
    }, [selectedValue, filterForOptionLabel, options.length, autoComplete]);

    /**
     * Side Effect that update the selectedValue based on the input property value.
     */
    React.useEffect(() => {
        if (!isOptionsVisible) {
            const element = getElementByValue(String(value), options);
            setSelectedValue(element);
            autoCompleteRef.current?.blurInput();
        }
    }, [value, options, isOptionsVisible]);

    /**
     * Side Effect that verify if the dropdown should be closed
     */
    React.useEffect(() => {
        const onCloseModal = () => {
            setIsOptionsVisible(false);
            setIsClosingDropdown(false);
            if (onBlur && typeof onBlur === 'function') onBlur(null);
        };

        isClosingDropdown && onCloseModal();
    }, [isClosingDropdown]);

    return (
        <AutoCompleteContext.Provider
            value={{
                isOptionsVisible,
                selectedValue,
                availableOptions,
                isAutoComplete: autoComplete,
                defaultValue,
                openAutoCompleteOptions,
                closeAutoCompleteOptions,
                onChangeText,
                onSelectOptionValue,
                submitSelected,
                submitValueInput,
                focousSearchInput,
                focousFirstElement,
                blurSearchInput,
            }}
        >
            <AutoCompleteSelector
                {...props}
                ref={autoCompleteRef}
                autoComplete={autoComplete}
            />
        </AutoCompleteContext.Provider>
    );
}

/**
 * Hook that returns the context value of the AutoCompletContext.
 * @returns {{
 *  isOptionsVisible: boolean;
 *  openAutoCompleteOptions: () => void;
 *  closeAutoCompleteOptions: () => void;
 *  onChangeText: (event: React.ChangeEvent<HTMLInputElement>) => void;
 *  onSelectOptionValue: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
 *  submitSelected: (selectedOption: string) => void;
 *  submitValueInput: () => void;
 *  selectedValue: { label: string; value: any; };
 *  availableOptions: { label: string, value: any }[];
 *  isAutoComplete: boolean;
 *  focousSearchInput: () => void;
 *  focousFirstElement: () => void;
 *  blurSearchInput: () => void;
 * }}
 */
export const useAutoCompleteContext = () => React.useContext(AutoCompleteContext);

export default AutoCompleteProvider;
