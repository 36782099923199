import * as React from 'react';

import AttributeManagementGrid from './components/AttributeManagementGrid/AttributeManagementGrid';
import AttributeConfigurationModal from './components/AttributeConfigurationModal/AttributeConfigurationModal';

import styles from './AttributeManagement.module.scss';
import AttributeManagementHeader from './components/AttributeManagementHeader/AttributeManagementHeader';
import AttributeRelationShipModal from './components/AttributeRelationshipModal/AttributeRelationshipModal';

const AttributeManagement: React.FC = () => (
    <>
        <AttributeConfigurationModal />
        <AttributeManagementHeader />

        <div className={styles.gridCont}>
            <AttributeManagementGrid />
        </div>

        <AttributeRelationShipModal />
    </>
);

export default AttributeManagement;
