import * as React from 'react';

import { Label, LabelProps, TextInput, Switch, Checkbox, ColorInput, InputFieldProps } from '..';

import styles from './GroupInput.module.scss';

interface GroupInputInputProps extends InputFieldProps {
    dataSet?: Record<string, any>;
}

interface GroupInputLabelProps extends Omit<LabelProps, 'htmlFor' | 'required' | 'disabled' | 'error'> {
    text?: string;
}

export interface GroupInputProps {
    label: GroupInputLabelProps;
    input: GroupInputInputProps;
    className?: string;
}

const GroupInput: React.FC<GroupInputProps> = ({ label, input, className }) => {
    const { dataSet = {}, ...inputProps } = input;
    const { text, ...labelProps } = label;

    if (input.type === 'color')
        return (
            <div className={className}>
                <Label
                    htmlFor={inputProps.id}
                    required={inputProps.required}
                    disabled={inputProps.disabled}
                    error={!!inputProps.error}
                    {...labelProps}
                >
                    {text}
                </Label>

                <ColorInput
                    {...inputProps}
                    {...dataSet}
                />
            </div>
        );

    if (input.type === 'switch')
        return (
            <div className={[styles.switchInputWrapper, className].join(' ')}>
                <Switch
                    {...inputProps}
                    {...dataSet}
                />

                <Label
                    htmlFor={inputProps.id}
                    required={inputProps.required}
                    disabled={inputProps.disabled}
                    error={!!inputProps.error}
                    {...labelProps}
                    className={[styles.switchLabel, label.className].join(' ')}
                >
                    {text}
                </Label>
            </div>
        );

    if (input.type === 'checkbox')
        return (
            <div className={className}>
                <Checkbox
                    {...inputProps}
                    {...dataSet}
                />

                <Label
                    htmlFor={inputProps.id}
                    required={inputProps.required}
                    disabled={inputProps.disabled}
                    error={!!inputProps.error}
                    {...labelProps}
                    className={[styles.switchLabel, label.className].join(' ')}
                >
                    {text}
                </Label>
            </div>
        );

    return (
        <div className={className}>
            <Label
                htmlFor={inputProps.id}
                required={inputProps.required}
                disabled={inputProps.disabled}
                error={!!inputProps.error}
                {...labelProps}
            >
                {text}
            </Label>

            <TextInput
                {...inputProps}
                {...dataSet}
            />
        </div>
    );
};

export default GroupInput;
