import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { getPicklistExportData } from 'services/attributeManagementApis';
import { ReactQueryHookOption } from 'react-query/types';
import { AxiosResponse } from 'axios';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';

const useGetPicklistExportData = (options: ReactQueryHookOption<AxiosResponse<any>> = { enabled: false }) => {
    const customer = useAtomValue(customerAtom);
    const { id: attributeSettingsId } = useAtomValue(attributeSettingsAtom);

    const toast = useToast();
    return useQuery(
        [queryKeys.getDependencyExportData, customer?.clientId || '', attributeSettingsId!],
        getPicklistExportData,
        {
            enabled: options.enabled,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetPicklistExportData;
