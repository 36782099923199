import axios from '../config/axios';

export async function getCustomers() {
    const response = await axios.get('/acl-legacy/v1/clients');
    return response.data;
}

export async function getIntegrationsList() {
    const response = await axios.get('/int-config/v1/integrations');
    return response.data;
}

export async function getCustomerListAssociation({ queryKey: [, customer] }) {
    const response = await axios.get(`/client/v1/clients/${customer.clientId}/integrations`);
    return response.data;
}

export async function updateCustomerAssociation(customer, integrations) {
    const response = await axios.put(`/client/v1/clients/${customer.clientId}/integrations`, integrations);
    return response.data;
}

/**Integration setup */

export async function getIntegrationSetupList(customer) {
    const response = await axios.get(`/acl-legacy/v1/integrationinstances/${customer.queryKey[1].clientId}`);
    return response.data;
}

/**Tactic based performance */

export async function getIntegrationSetupById(instance) {
    const response = await axios.get(
        `/int-filebase/v1/tacticbasedperformanceinstances/${instance.queryKey[1].integrationInstanceIdMs}`
    );
    return response.data;
}

export async function createIntegrationSetup(instance) {
    const response = await axios.post('/int-filebase/v1/tacticbasedperformanceinstances', instance);
    return response.data;
}

export async function editIntegrationSetupSelected(instance) {
    const response = await axios.put(
        `/int-filebase/v1/tacticbasedperformanceinstances/${instance.tacticBasedPerformanceInstanceId}`,
        instance
    );
    return response.data;
}

export async function deleteIntegrationSetupSelected(instance) {
    const response = await axios.delete(
        `/int-filebase/v1/tacticbasedperformanceinstances/${instance.integrationInstanceIdMs}`
    );
    return response.data;
}

export async function getIntegrationSetupCombos() {
    const response = await axios.get('/int-filebase/v1/parameters');
    return response.data;
}

export async function getStageMappingList(customer) {
    const response = await axios.get(`/acl-legacy/v1/clients/${customer.queryKey[1].clientId}/stagefields`);
    return response.data;
}

export async function getCustomGoalsMappingList(customer) {
    const response = await axios.get(`/acl-legacy/v1/clients/${customer.queryKey[1].clientId}/customgoaltypefields`);
    return response.data;
}

export async function getNotificationsEmailList(customer) {
    const response = await axios.get(`/acl-legacy/v1/Clients/${customer.queryKey[1].clientId}/useremails`);
    return response.data;
}

export async function uploadIntegrationSetupFile(formData) {
    const response = await axios.post('/int-filestorage/v1/instancefiles', formData);
    return response.data;
}

export async function getHistoryIntegrationSetupById(instance) {
    const response = await axios.get(
        `/int-audit/v1/integrationinstanceslog/${instance.queryKey[1].integrationInstanceIdMs}`
    );
    return response.data;
}

export async function forceSyncNewByIntegrationSetupId(forceSyncSelected) {
    const response = await axios.post('/int-filebase-jobscheduler/v1/jobs', forceSyncSelected);
    return response.data;
}

/*Marketo*/

export async function getMarketoV2IntegrationParameters() {
    const response = await axios.get(`/int-marketov2/v1/marketov2instances/parameters`);
    return response.data;
}

export async function getMarketoV2IntegrationActivityTypes({ queryKey: [, params] }) {
    const response = await axios.get(`/int-marketov2/v1/marketov2instances/activitytypes`, { params });
    return response.data;
}

export async function getIntegrationSetupByIdMarketo({ queryKey: [, instance, params] }) {
    const response = await axios.get(`/int-marketov2/v1/marketov2instances/${instance.integrationInstanceIdMs}`, {
        params,
    });
    return response.data;
}

export async function createIntegrationSetupMarketo(instance) {
    const response = await axios.post(`/int-marketov2/v1/marketov2instances`, instance);
    return response.data;
}

export async function editIntegrationSetupSelectedMarketo(instance) {
    const response = await axios.put(`/int-marketov2/v1/marketov2instances/${instance.marketoV2InstanceId}`, instance);
    return response.data;
}

export async function deleteIntegrationSetupSelectedMarketo(instance) {
    const response = await axios.delete(`/int-marketov2/v1/marketov2instances/${instance.integrationInstanceIdMs}`);
    return response.data;
}
