import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { cloneDeep } from 'lodash';

import { AttributeRelationshipItem } from 'domain/model/AttributeRelationship';

import { CustomFieldDependency, PicklistItemResponse, CustomFieldParent } from 'services/attributeManagementApis';

/** ATOMS */
export const checkBoxCaseAtom = atomWithReset<PicklistItemResponse[]>([]);
export const checkBoxListTotalAtom = atomWithReset<PicklistItemResponse[]>([]);
export const nonCheckBoxListTotalAtom = atomWithReset<PicklistItemResponse[]>([]);
export const optionListToCopyAtom = atomWithReset<number[]>([]);
export const parentDependenciesAtom = atomWithReset<CustomFieldDependency | null>(null);
export const singleValueSelectedAtom = atomWithReset<number | null>(null);
export const possibleParentsListAtom = atomWithReset<CustomFieldParent[]>([]);
export const currentAttributeOptionsSearchTextAtom = atom<string>('');
export const copySearchTextAtom = atomWithReset<string>('');
export const parentOptionsSearchTextAtom = atom<string>('');
export const selectedParentAtom = atom<number | null>(null);
export const relationshipAtom = atom<boolean>(false);
export const copyAtom = atom<boolean>(false);
export const copyModeAtom = atom<boolean>(true);
/** REACT-KONVA RELATIONSHIP RELATED ATOMS  */
interface scaleAttributes {
    min: number;
    max: number;
}
export const zoomAtom = atomWithReset<number>(1);
export const scaleAtom = atomWithReset<scaleAttributes>({ min: 0.1, max: 2 });
export const relationshipItemAtom = atom<AttributeRelationshipItem | undefined>(undefined);
export const currentElementHierarchyOwnerAtom = atom<Omit<AttributeRelationshipItem, 'children'> | undefined>(
    undefined
);
export const insertChildrenIntoSelectedAttributeAtom = atom<
    undefined,
    { result: AttributeRelationshipItem[]; id: number }
>(undefined, (get, set, update) => {
    const clonedRelationshipItem = cloneDeep(get(relationshipItemAtom))!;

    const mergeChildrens = (tree: AttributeRelationshipItem, treeChildren: AttributeRelationshipItem[]) => {
        const currentTreeChildrens = cloneDeep(treeChildren);
        const mergedChildrens = update.result.reduce((prev, current) => {
            if (currentTreeChildrens.some((attr) => attr.id === current.id)) {
                return [...prev, {...currentTreeChildrens.find(attr => attr.id === current.id)!}]
            };
            return [...prev, current];
        }, [] as AttributeRelationshipItem[]);
        return {...tree, children: mergedChildrens }
    }

    const addElementInTree = (tree: AttributeRelationshipItem): AttributeRelationshipItem => {
        const { id, children: treeChildren } = tree;

        if (id === update.id && treeChildren.length > 0)
            return mergeChildrens(tree, treeChildren);

        if (id === update.id && !treeChildren.length)
            return { ...tree, children: update.result };

        return { ...tree, id, children: treeChildren.map(addElementInTree) };
    };
    set(relationshipItemAtom, addElementInTree(clonedRelationshipItem));
});
