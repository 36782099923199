import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';
import { getCustomFieldGroup } from 'services/groupsApis';

import { queryKeys } from 'react-query/constants';
import { useToast } from 'customHooks/useToast';
import { ReactQueryHookOption } from 'react-query/types';
import { CustomFieldGroup } from 'services/attributeManagementApis';

const useGetCustomFieldGroup = (customFieldGroupId?: number, options?: ReactQueryHookOption<CustomFieldGroup>) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();

    return useQuery([queryKeys.getCustomFieldGroup, customer!, customFieldGroupId!], getCustomFieldGroup, {
        enabled: customer !== null && typeof customFieldGroupId === 'number',
        retry: 3,
        refetchOnWindowFocus: false,
        onSuccess: data => {
            if (options?.onSuccess) options?.onSuccess(data);
        },
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.');
            if (options?.onError) options?.onError(error);
        },
    });
};

export default useGetCustomFieldGroup;
