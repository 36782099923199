import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { Button, TextInput } from 'components';

import styles from './SortOrderColumn.module.scss';
import { CustomFieldGroup } from 'services/attributeManagementApis';
import { usePatchCustomFieldGroup } from 'react-query/mutations';

interface SortOrderColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomFieldGroup;
}

const SortOrderColumn: React.FC<SortOrderColumnProps> = ({ dataItem }) => {
    const [isOnEditMode, setIsOnEditMode] = React.useState<boolean>(false);
    const formRef = React.useRef<HTMLFormElement>(null);

    const stopEditing = () => setIsOnEditMode(false);

    const { mutate: patchCustomFieldGroup } = usePatchCustomFieldGroup({ onSuccess: stopEditing });

    const isSortOrderValueValid = (value: string): boolean => value.length > 0 && !isNaN(Number(value));

    const patchSortOrder = ({ sortOrder }: { sortOrder: string }) => {
        patchCustomFieldGroup({
            id: dataItem.id,
            name: dataItem.name,
            sortOrder: Number(sortOrder),
        });
    };

    const onSubmitChange = (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        const formData = new FormData(formRef.current as HTMLFormElement);
        const sortOrder = formData.get('sortOrder')?.toString();
        if (!sortOrder || sortOrder === dataItem.sortOrder?.toString()) return stopEditing();
        if (sortOrder && isSortOrderValueValid(sortOrder)) return patchSortOrder({ sortOrder: sortOrder });
    };

    return (
        <td>
            <div className={styles.container}>
                {isOnEditMode ? (
                    <form
                        ref={formRef}
                        onSubmit={onSubmitChange}
                    >
                        <TextInput
                            type="number"
                            id="sortOrder"
                            name="sortOrder"
                            defaultValue={
                                dataItem.sortOrder && dataItem.sortOrder > -1 ? dataItem.sortOrder.toString() : ''
                            }
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            onBlur={() => onSubmitChange()}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={true}
                            required
                        />
                    </form>
                ) : (
                    <Button
                        btnType="link"
                        type="button"
                        className={styles.sortOrderButton}
                        text={dataItem.sortOrder?.toString() ?? '---'}
                        onClick={() => setIsOnEditMode(true)}
                    />
                )}
            </div>
        </td>
    );
};

export default SortOrderColumn;
