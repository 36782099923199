import * as React from 'react';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';
import GroupDetail from './GroupDetail/GroupDetail';
import GroupsGridList from './GroupsGridList/GroupsGridList';
import GroupsPageHeader from './GroupsPageHeader/GroupsPageHeader';

const Groups: React.FC = () => {
    React.useEffect(() => {
        queryClient.invalidateQueries(queryKeys.customFieldGroups);
    }, []);

    return (
        <>
            <GroupsPageHeader />
            <GroupsGridList />
            <GroupDetail />
        </>
    );
};

export default Groups;
