import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';

import styles from './NotAuthorizedModal.module.scss';

const NotAuthorizedModal = props => {
    const history = useHistory();
    const closeModal = () => {
        history.push('/');
    };
    return (
        <>
            {props.show && (
                <div className={styles.backModal}>
                    <div className={styles.modal}>
                        <div className={styles.centered}>
                            <h2 className="title-main">
                                Not
                                <span className="title-section-marker"> Autorized </span>
                            </h2>
                            <p className={styles.message}>
                                You can not acesses this content! We will send you back to home page.
                            </p>
                            <Button
                                text={'OK'}
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NotAuthorizedModal;
