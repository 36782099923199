/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useAtom } from 'jotai';
import { useAtomValue, useResetAtom } from 'jotai/utils';
import { useAuth } from 'oidc-react';

import * as XLSX from 'xlsx';
import { Upload, Button, ProductTour, useProductTourUtilities } from '../../../../../components';
import PicklistGrid from './PicklistGrid/PicklistGrid';
import EditPicklistItemModal from './EditPicklistItemModal/EditPicklistItemModal';

import { useToast } from '../../../../../customHooks/useToast';

import {
    attributeSettingsAtom,
    newPicklistRowValueAtom,
    selectedPicklistIdsAtom,
} from '../../../../../atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import { usePicklistGridMaxHeight } from './PicklistGrid/usePicklistGridMaxHeight';
import AddNewPicklistItem from './AddNewPicklistItem/AddNewPicklistItem';

import { useBulkDeleteCustomFieldPicklistItem, useBulkPostCustomFieldPickList } from 'react-query/mutations';
import { useGetCustomFieldPicklist } from 'react-query/queries';

import styles from './PicklistSettings.module.scss';
import useGetPicklistExportData from 'react-query/queries/customFields/picklist/useGetPicklistExportData';

const PicklistSettings = () => {
    const [exampleRow, setExampleRow] = React.useState(undefined);
    const [selectedPicklistIds, setSelectedPicklistIds] = useAtom(selectedPicklistIdsAtom);
    const [fileError, setFileError] = React.useState('');
    const [gridClassName, setGridClassName] = React.useState(undefined);
    const attributeSetting = useAtomValue(attributeSettingsAtom);
    const customer = useAtomValue(customerAtom);
    const toast = useToast();
    const auth = useAuth();
    const [newPicklistRowValue, setNewPicklistRowValue] = useAtom(newPicklistRowValueAtom);
    const resetNewPicklistRowValue = useResetAtom(newPicklistRowValueAtom);
    const [, gridMaxHeight] = usePicklistGridMaxHeight();
    /**
     * Call the deletePicklistItems API using the react-query with useQuery
     */
    const { mutate: deleteSelectedPicklists } = useBulkDeleteCustomFieldPicklistItem({
        onSuccess: () => {
            setSelectedPicklistIds([]);
            toast.success('Save Successfully!');
        },
    });
    /**
     * Call the postNewPicklistItem API using the react-query with useQuery
     */
    const { mutate: saveBulkPickList } = useBulkPostCustomFieldPickList({
        onError: error => {
            console.log(error);
            document.getElementById('fileLoader').value = '';
        },
        onSuccess: () => {
            document.getElementById('fileLoader').value = '';
        },
    });
    const { data: pickListData, isFetched: isPickListDataFetched } = useGetCustomFieldPicklist();

    const onExportDataSuccess = response => {
        try {
            const headers = new Headers(response.headers);
            const contentDisposition = headers.get('content-disposition');
            const filename = contentDisposition?.split('filename=')[1].split(';')[0].replace(/\\|"/g, '');
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename ? filename : 'data-export.csv');
            const container = document.getElementById('picklist-upload-container');
            if (container) {
                container.appendChild(link);
                link.click();
                container.removeChild(link);
                URL.revokeObjectURL(href);
                return;
            }
            throw new Error('Could not download the CSV file.');
        } catch (err) {
            toast.error(err.message);
        }
    };

    /**
     * Export picklists
     */
    const { refetch: getPicklistExportData } = useGetPicklistExportData({
        onSuccess: onExportDataSuccess,
        enabled: false,
    });

    const { isProductTourActive, activeProductTour, closeProductTour } = useProductTourUtilities(
        'customfield-picklist-settings',
        {
            openOnFirstTimeThatSeeScreen: true,
            isReadyToOpenTooltip: isPickListDataFetched,
        }
    );
    /**
     * Function that treats the upload files to load the products list
     * @param {*} file Propertt=y that passes the files
     */
    const onUploadFile = async file => {
        const fileRead = file[0];
        const data = await fileRead.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        if (!jsonData.some(prop => prop['List Items']) || jsonData.lenght === 0) {
            setFileError("This File doesn't have the right format or not contain any register.");
            document.getElementById('fileLoader').value = '';
            return;
        }
        setFileError('');
        let obj = {
            userId: auth.userData.profile.userId,
            picklists: [],
        };
        jsonData.forEach(item => {
            //TODO try to make a better code here when its possible
            //verify if the excel list has duplicated value
            //=======================================   =====================
            if (obj.picklists.some(i => i.value === item['List Items'])) {
                document.getElementById('fileLoader').value = '';
                return;
            }
            //verify if the list item is undefined
            //============================================================
            if (typeof item['List Items'] === 'undefined') return;
            //Verify if is an item the exist on the list and the sortOrder is igual to sortOrder undefined to not include
            //======================================================================================================================================
            if (
                pickListData.some(
                    i =>
                        i.value === item['List Items'] &&
                        i.sortOrder === null &&
                        typeof item['Sort Order'] === 'undefined'
                )
            )
                return;
            //verify if the existing list has the value and the sort order are different to update
            //==================================================================================================
            if (pickListData.some(i => i.value === item['List Items'] && i.sortOrder !== item['Sort Order'])) {
                //if sort order is not undefined verify if it is a number
                //=========================================================================================
                if (typeof item['Sort Order'] !== 'undefined' && typeof item['Sort Order'] === 'number') {
                    obj.picklists.push({
                        value: item['List Items'],
                        colorCode: 'FFFFFF',
                        isDefault: false,
                        isDeleted: false,
                        sortOrder: item['Sort Order'],
                    });
                    return;
                }
                //if the sort order is undefined(empty inside the csv) send null as sort order value
                //===================================================================================
                if (typeof item['Sort Order'] === 'undefined') {
                    obj.picklists.push({
                        value: item['List Items'],
                        colorCode: 'FFFFFF',
                        isDefault: false,
                        isDeleted: false,
                        sortOrder: null,
                    });
                    return;
                }
            } else {
                //if the sort order is undefined(empty inside the csv) and the item don't exists send null as sort order value
                //=============================================================================================================
                if (
                    !pickListData.some(i => i.value === item['List Items']) &&
                    typeof item['Sort Order'] === 'undefined'
                ) {
                    obj.picklists.push({
                        value: item['List Items'],
                        colorCode: 'FFFFFF',
                        isDefault: false,
                        isDeleted: false,
                        sortOrder: null,
                    });
                    return;
                }
                //Include a new item that has sortOrder value and it is new
                //==========================================================
                if (
                    !pickListData.some(i => i.value === item['List Items']) &&
                    typeof item['Sort Order'] !== 'undefined'
                ) {
                    obj.picklists.push({
                        value: item['List Items'],
                        colorCode: 'FFFFFF',
                        isDefault: false,
                        isDeleted: false,
                        sortOrder: item['Sort Order'],
                    });
                    return;
                }
                document.getElementById('fileLoader').value = '';
                return;
            }
        });
        if (obj.picklists.length === 0) {
            setFileError('All the items you uploaded already exist.');
            document.getElementById('fileLoader').value = '';
            return;
        }
        saveBulkPickList(obj);
        document.getElementById('fileLoader').value = '';
    };
    const getGridClassNameIfScrollIsVisible = React.useCallback(() => {
        const element = document.getElementById('picklist-grid');
        if (element === null) return undefined;
        return element.clientHeight >= gridMaxHeight ? styles.scrollIsVisible : undefined;
    }, []);
    React.useEffect(() => {
        const element = document.getElementById('picklist-grid');
        const log = () => setGridClassName(getGridClassNameIfScrollIsVisible());
        if (element !== null) {
            element.addEventListener('DOMNodeInserted', log);
        }
        return () => {
            if (element !== null) {
                element.removeEventListener('DOMNodeInserted', log);
            }
        };
    }, [getGridClassNameIfScrollIsVisible]);
    return (
        <section className={styles.picklistSettingsSection}>
            <ProductTour
                active={isProductTourActive}
                onFinishTour={() => {
                    setExampleRow(undefined);
                    resetNewPicklistRowValue();
                    closeProductTour();
                }}
                wellcomeMessage="Let's start a quick tutorial on Picklist settings and understand how to use this feature."
                tourList={[
                    {
                        elementSelector: '#picklist-grid',
                        // elementSelector: `.${['k-widget', 'k-grid'].join('.')}`,
                        title: 'Picklist',
                        description: 'You can consult all your registered options on this list.',
                    },
                    {
                        elementSelector: '#upload-picklist-button',
                        title: 'Upload picklist button',
                        description:
                            'You can add picklist items by uploading an excel or csv file. System will import the column with the header/first row "List Items" and "Sort Order". You can also download the template by clicking on the "Download CSV Template".',
                    },
                    {
                        elementSelector: '#picklist-example-file',
                        title: 'Download CSV template file',
                        description:
                            'You can download the upload template in csv format here. System will look for a column with the first row value as “List Items”(this one is required) and "Sort Order"(Optional). Please do not change this value. You can also save this file in excel format and upload it.',
                    },
                    {
                        elementSelector: '#new-item-insertion-row',
                        title: `Manual insertion`,
                        description: 'Easily insert new options on this picklist by filling these fields.',
                    },
                    {
                        elementSelector: '#new-value-picklist',
                        title: `${attributeSetting.name} option name`,
                        description: `Start your new option manual insertion by providing a ${attributeSetting.name} option name.`,
                        onElementFocus: () =>
                            setNewPicklistRowValue({ ...newPicklistRowValue, value: 'Example option' }),
                    },
                    {
                        elementSelector: '#color-pick-input',
                        title: `${attributeSetting.name} option color`,
                        description: 'Choose a color for your new option.',
                        onElementFocus: () => setNewPicklistRowValue({ ...newPicklistRowValue, color: '#2ca20b' }),
                    },
                    {
                        elementSelector: '#new-sort-order-value',
                        title: `${attributeSetting.name} option sort order`,
                        description: 'Optionally type a sort order for your new option.',
                        onElementFocus: () => setNewPicklistRowValue({ ...newPicklistRowValue, sortOrder: '1' }),
                    },
                    {
                        elementSelector: '#new-item-is-default',
                        title: 'Default option',
                        description: 'If your new option is a default one, you can press this button to switch to Yes.',
                    },
                    {
                        elementSelector: '#save-new-item-button',
                        title: 'Save new option',
                        description:
                            'After you complete filling all the manual insertion fields, you can click on this button to save your new option.',
                        onClickNext: () => {
                            setNewPicklistRowValue({ color: '#FFFFFF', isDefault: 'No', sortOrder: '', value: '' });
                            setExampleRow({
                                id: 'product-tour-item-example',
                                value: 'Example option',
                                colorCode: '2ca20b',
                                isDefault: 'No',
                                isDeleted: 'No',
                                sortOrder: '1',
                            });
                        },
                    },
                    {
                        elementSelector: '#product-tour-item-example_row',
                        title: 'View Option',
                        description: "After saving the new option, it'll be available on the list.",
                        onClickPrevious: () => {
                            setNewPicklistRowValue({
                                value: 'Example option',
                                color: '#2ca20b',
                                isDefault: 'No',
                                sortOrder: '1',
                            });
                            setExampleRow(undefined);
                        },
                    },
                    {
                        elementSelector: '#edit-item-button-product-tour-item-example',
                        title: 'Edit existing option',
                        description: 'You can start editing your option item by clicking on this button.',
                    },
                    {
                        elementSelector: '#picklist-help-menu-button',
                        title: 'Guide button',
                        description: 'You can review this guide at any time by pressing this help button.',
                    },
                ]}
            />

            <EditPicklistItemModal customer={customer} />

            <header
                className={styles.picklistHeader}
                id="picklist-header-content"
            >
                <div className={styles.uploadWrapper}>
                    <div
                        id="picklist-upload-container"
                        className={styles.uploadContent}
                    >
                        <Button
                            id="export-picklists-to-excel"
                            type="button"
                            title="Export Attribute Picklist"
                            text="Export Picklist"
                            className={styles.downloadButton}
                            onClick={() => getPicklistExportData()}
                        />
                        <Upload
                            id="upload-picklist-button"
                            placeholder="Upload Picklist"
                            onChange={onUploadFile}
                            className={styles.uploadButton}
                            accept={'.xlsx, .xls, .csv'}
                        />

                        <div className={styles.uploadFeedbackContent}>
                            <small id="picklist-example-file">
                                <a
                                    href="/examples/picklist-upload/picklist-upload-template.csv"
                                    download
                                >
                                    Download CSV Template
                                </a>
                            </small>

                            {fileError !== '' && (
                                <small className={styles.errorFormat}>
                                    <span>{fileError}</span>
                                </small>
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.picklistHeaderRightContent}>
                    <Button
                        id="delete-picklist-selecteds"
                        text="Delete"
                        type="button"
                        onClick={() => deleteSelectedPicklists(selectedPicklistIds)}
                        disabled={!selectedPicklistIds.length}
                    />

                    <Button
                        id="picklist-help-menu-button"
                        text={<i className="k-icon k-i-question" />}
                        type="button"
                        title="Click for receive a guide in this screen"
                        onClick={activeProductTour}
                        className={styles.productTourIconButton}
                    />
                </div>
            </header>

            <div id={styles.addNewPicklistItem}>
                <AddNewPicklistItem />
            </div>

            <div
                id="picklist-grid"
                className={[gridClassName, styles.picklistGridWrapper].join(' ')}
            >
                <PicklistGrid exampleRow={exampleRow} />
            </div>
        </section>
    );
};

export default PicklistSettings;
