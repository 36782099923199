import { GridCellProps } from '@progress/kendo-react-grid';
import { groupDetailAtom } from 'atons/Groups/atoms';
import { useUpdateAtom } from 'jotai/utils';
import * as React from 'react';

import { CustomFieldGroup } from 'services/attributeManagementApis';

import styles from './ActionColumn.module.scss';

interface ActionColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomFieldGroup;
}

const ActionColumn: React.FC<ActionColumnProps> = ({ dataItem }) => {
    const setSelectedGroupId = useUpdateAtom(groupDetailAtom);

    const onClickActionButton = () =>
        setSelectedGroupId({ id: dataItem.id, name: '', sortOrder: '', isApprovalObject: dataItem.isApprovalObject });

    return (
        <td>
            <div className={styles.center}>
                <button
                    onClick={onClickActionButton}
                    className={styles.actionButton}
                    type="button"
                >
                    <span
                        title={`Edit Group ${dataItem.name}`}
                        className="k-icon k-i-track-changes-enable"
                    />
                </button>
            </div>
        </td>
    );
};

export default ActionColumn;
