import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';

const FilterCell: React.FC<GridFilterCellProps> = ({ operator, onChange, value }) => {
    const filterValues = ['Yes', 'No'];
    const defaultItem = '(ALL)';

    function onDropDownListChange(event: DropDownListChangeEvent) {
        const value = event.value === defaultItem ? '' : event.value;

        onChange({
            value,
            operator: operator as string,
            syntheticEvent: event.syntheticEvent,
        });
    }

    return (
        <DropDownList
            data={filterValues}
            defaultItem={defaultItem}
            onChange={onDropDownListChange}
            style={{ width: '100%' }}
            value={value || defaultItem}
        />
    );
};

export default FilterCell;
