import * as React from 'react';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { Checkbox } from '../../../../../../components';

import {
    selectedPicklistIdsAtom,
    addIdToSelectedPickListAtom,
    removeIdToSelectedPickListAtom,
} from '../../../../../../atons/AttributeManagement/atoms';

import styles from './IdColumn.module.scss';

const IdColumn = ({ dataItem, id }) => {
    const selectedPicklistIds = useAtomValue(selectedPicklistIdsAtom);
    const addIdToSelectedPicklistIds = useUpdateAtom(addIdToSelectedPickListAtom);
    const removeIdToSelectedPicklistIds = useUpdateAtom(removeIdToSelectedPickListAtom);

    const mode = !!dataItem.id ? 'edit' : 'create';

    const isDeleted = dataItem.isDeleted === 'Yes';

    const onChangeCheckboxValue = ({ target }) => {
        if (target.checked) return addIdToSelectedPicklistIds(dataItem.id);
        return removeIdToSelectedPicklistIds(dataItem.id);
    };

    return mode === 'create' ? (
        <td
            id={id}
            style={{ top: 0, bottom: 0 }}
            className="k-grid-row-sticky"
        />
    ) : (
        <td id={id}>
            <div className={styles.checkboxWrapper}>
                {isDeleted ? null : (
                    <Checkbox
                        checked={selectedPicklistIds.includes(dataItem.id)}
                        onChange={onChangeCheckboxValue}
                    />
                )}
            </div>
        </td>
    );
};

export default IdColumn;
