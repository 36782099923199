import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useLocation } from 'react-router-dom';

import { AutoComplete, Button } from 'components';

import { selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';
import { selectedGroupsIdsAtom, groupDetailAtom } from 'atons/Groups/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import User from 'domain/model/User';

import { addSpaceBetweenCammelCase, alphabeticalOrder } from 'shared/helpers';

import { useGetEntityTypeByUserId, useGetEntityTypeList } from 'react-query/queries';
import { useChangeEntityTypeUserSelection } from 'react-query/mutations';

import DeleteWarningModal from './DeleteWarningModal/DeleteWarningModal';

import styles from './GroupsPageHeader.module.scss';
import useGetGroupsExportData from 'react-query/queries/customFields/groups/useGetGroupsExportData';
import { FileUtils } from 'shared/file.utils';

const GroupsPageHeader: React.FC = () => {
    const [showDeleteWarningModal, setShowDeleteWarningModal] = React.useState<boolean>(false);
    const [selectedEntityType, setSelectedEntityType] = useAtom(selectedEntityTypeIdAtom);
    const [selectedGroupsIds, setSelectedGroupsIds] = useAtom(selectedGroupsIdsAtom);
    const setGroupDetailAtom = useUpdateAtom(groupDetailAtom);
    const customer = useAtomValue(customerAtom);
    const location = useLocation();

    const { data: entityTypeListReturn = [] } = useGetEntityTypeList();

    const { mutate: changeEntityTypeUserSelectionAction } = useChangeEntityTypeUserSelection({
        onSuccess: () => setSelectedGroupsIds([]),
    });

    const { refetch: getGroupsExportData } = useGetGroupsExportData({
        onSuccess: response =>
            FileUtils.generateDownloadTagFromAxiosResponse(response, 'export-data-download-container'),
        enabled: false,
    });

    const getEntityTypeOptions = () =>
        alphabeticalOrder(entityTypeListReturn, 'name').map(item => ({
            label: addSpaceBetweenCammelCase(item.name),
            value: item.id,
        }));

    const onChangeAutoComplete = (value: string): void => {
        if (!value || value === 'null' || selectedEntityType === Number(value)) return;
        const userId = User.getInstance()?.getUserId();
        if (!userId) return;
        setSelectedEntityType(Number(value));
        changeEntityTypeUserSelectionAction({
            userId,
            entityTypeId: Number(value),
        });
    };

    useGetEntityTypeByUserId({ onSuccess: onChangeAutoComplete });

    const openDeleteWarningModal = () => setShowDeleteWarningModal(true);
    const closeDeleteWarningModal = () => setShowDeleteWarningModal(false);

    /**
     * This property is responsible to tell if the create new attribute button it's disabled or not
     */
    const isCreateNewAttributeButtonDisabled = React.useMemo(
        () => !selectedEntityType && !entityTypeListReturn?.some(({ id }) => id === selectedEntityType),
        [entityTypeListReturn, selectedEntityType]
    );

    const openGroupDetailModal = () => {
        setGroupDetailAtom({ name: '', sortOrder: '', isApprovalObject: false });
    };

    return (
        <div
            id="groups-page-title-content"
            className={styles.header + ' pageTitleCont'}
        >
            <h2 className="title-main">
                Groups
                <span className="title-section-marker"> Management </span>
            </h2>
            {customer !== null && (
                <div className={styles.btnCont}>
                    <Button
                        id="export-groups-button"
                        text="Export"
                        onClick={() => getGroupsExportData()}
                        type="button"
                    />

                    <AutoComplete
                        options={getEntityTypeOptions()}
                        value={String(selectedEntityType)}
                        placeholder="Select Entity Type"
                        className="clients-list"
                        onChange={onChangeAutoComplete}
                        readOnly={location.pathname === '/customfields/attributes'}
                        disabled={location.pathname === '/customfields/attributes'}
                    />

                    <Button
                        id="delete-groups-button"
                        disabled={selectedGroupsIds.length === 0}
                        text="Delete Group(s)"
                        onClick={openDeleteWarningModal}
                        type="button"
                    />

                    <Button
                        id="create-group-button"
                        text="Create New Group"
                        disabled={isCreateNewAttributeButtonDisabled}
                        onClick={openGroupDetailModal}
                        type="button"
                        className={styles.large}
                    />
                </div>
            )}

            <DeleteWarningModal
                show={showDeleteWarningModal}
                closeBtnFunc={closeDeleteWarningModal}
            />
            <div id="export-data-download-container" />
        </div>
    );
};

export default GroupsPageHeader;
