import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';

import { useToast } from 'customHooks/useToast';

import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';

import { getCustomFieldChildrenDependencies } from 'services/attributeManagementApis';
import { AttributeRelationship } from 'domain/model/AttributeRelationship';

const useGetCustomFieldChildrenDependencies = (
    customFieldId: number,
    options?: ReactQueryHookOption<AttributeRelationship[]>
) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();

    return useQuery(
        [queryKeys.getCustomFieldChildrenDependencies, customer!, customFieldId],
        getCustomFieldChildrenDependencies,
        {
            enabled: false,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
            },
        }
    );
};

export default useGetCustomFieldChildrenDependencies;
