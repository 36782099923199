import * as React from 'react';

import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';
import { InputFieldProps } from '../types';

import styles from './TextInput.module.scss';

interface TextInputProps extends InputFieldProps {
    kendoIcon?: string;
}

const TextInput: React.FC<TextInputProps> = ({
    disabled,
    title = '',
    type = 'text',
    className,
    error,
    kendoIcon,
    ...props
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputTitleProperty = disabled ? 'This field it is disabled' : title;

    const TextInputClassName = React.useMemo((): string => {
        const baseStyle = [styles.inputBaseStyle];
        if (!!className) baseStyle.push(className);
        if (!!error) baseStyle.push(styles.error);
        if (!!kendoIcon) baseStyle.push(styles.iconRight);
        return baseStyle.join(' ');
    }, [error, className, kendoIcon]);

    const focusInput = () => {
        inputRef.current?.focus();
    };

    return (
        <div className={styles.container}>
            <div className={styles.textInputWrapper}>
                <input
                    {...props}
                    ref={inputRef}
                    type={type}
                    className={TextInputClassName}
                    disabled={disabled}
                    title={inputTitleProperty}
                />

                {kendoIcon && (
                    <i
                        className={['k-icon', kendoIcon, styles.traillingIcon].join(' ')}
                        onClick={focusInput}
                        onKeyUp={a11yKeyBoardEventHandler(focusInput)}
                        tabIndex={0}
                        role="button"
                    />
                )}
            </div>

            {!!error && <small className={styles.errorMessage}>{error}</small>}
        </div>
    );
};

export default TextInput;
