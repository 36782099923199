import HTTPErrorResponseAdapter from 'adapters/HTTPErrorResponseAdapter';
import { Customer } from 'atons/Customer/atoms';
import axios from 'config/axios';
import { QueryFunctionContext } from 'react-query';
import { CustomFieldGroup } from './attributeManagementApis';

export type PostCustomFielGroupPayload = {
    entityTypeId: number;
    name: string;
    sortOrder: number | null;
};

export type PatchCustomFielGroupPayload = {
    id: number;
    name: string;
    sortOrder: number | null;
};

export type CustomFieldGroupsUsage = {
    id: number;
    usageCount: number;
};

export async function postCustomFieldGroup(
    payload: PostCustomFielGroupPayload,
    customer: Customer
): Promise<CustomFieldGroup> {
    try {
        const response = await axios.post(`/acl-legacy/v1/clients/${customer.clientId}/customfieldgroups`, payload);
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function patchCustomFieldGroup(
    payload: PatchCustomFielGroupPayload,
    customer: Customer
): Promise<CustomFieldGroup> {
    try {
        const { id, ...customFieldGroup } = payload;
        const response = await axios.patch(
            `/acl-legacy/v1/clients/${customer.clientId}/customfieldgroups/${id}`,
            customFieldGroup
        );
        return response.data;
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function deleteCustomFieldGroup(payload: number[], customer: Customer): Promise<void> {
    try {
        await axios.delete(`/acl-legacy/v1/clients/${customer.clientId}/customfieldgroups`, { data: payload });
    } catch (error: any) {
        throw HTTPErrorResponseAdapter.getErrorResponse(error);
    }
}

export async function getCustomFieldGroup({
    queryKey: [, customer, customFieldGroupId],
}: QueryFunctionContext<[string, Customer, number], any>): Promise<CustomFieldGroup> {
    const response = await axios.get(
        `/acl-legacy/v1/clients/${customer.clientId}/customfieldgroups/${customFieldGroupId}`
    );
    return response.data;
}

export async function getCustomFieldGroupsUsage({
    queryKey: [, customer, ids],
}: QueryFunctionContext<[string, Customer, number[]], any>): Promise<CustomFieldGroupsUsage[]> {
    const response = await axios.get(`/acl-legacy/v1/clients/${customer.clientId}/customfieldgroups/getusage`, {
        params: { ids },
        paramsSerializer({ ids }) {
            return `ids=${(ids as number[]).join('&ids=')}`;
        },
    });
    return response.data;
}
