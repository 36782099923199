import * as React from 'react';

import { convertNullAndUndefinedToString } from '../../../../../../shared/helpers';

import styles from './ColorCodeColumn.module.scss';

function getDefaultColorValue(colorCode) {
    const hexColor = convertNullAndUndefinedToString(colorCode);
    return hexColor === '' ? '#FFFFFF' : '#'.concat(colorCode);
}

const ColorCodeColumn = ({ dataItem, id }) => {
    const colorCode = getDefaultColorValue(dataItem?.colorCode);

    return (
        <td id={id}>
            <div className={styles.container}>
                <div className={styles.inputGroup}>
                    <input
                        id={`color-input-${dataItem.id}`}
                        type="color"
                        value={colorCode}
                        className={styles.colorInput}
                        disabled
                    />

                    <label
                        htmlFor={`color-input-${dataItem.id}`}
                        className={styles.colorInputLabel}
                    >
                        {colorCode}
                    </label>
                </div>
            </div>
        </td>
    );
};

export default ColorCodeColumn;
