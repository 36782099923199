import * as React from 'react';
import { useAuth } from 'oidc-react';
import { Calendar } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../../../react-query/queryClient';
import { queryKeys } from '../../../react-query/constants';
import { Modal, NewIntegrationDropDown, ToolTip, Button } from '../../../components/index';
import TacticBasedModal from './IntegrationSetupModals/TacticBasedModal';
import MarketoV2Modal from './IntegrationSetupModals/MarketoV2Modal';
import HistoryGrid from './IntegrationSetupHistoryGrid/IntegrationSetupHistoryGrid';
import { useAtomValue } from 'jotai/utils';
//import { globalVariables } from "../../atons/globalAtons";
import { toast } from 'react-toastify';
import {
    getCustomerListAssociation,
    getIntegrationSetupList,
    deleteIntegrationSetupSelected,
    deleteIntegrationSetupSelectedMarketo,
    uploadIntegrationSetupFile,
    forceSyncNewByIntegrationSetupId,
} from '../../../services/apiIntegration';

import styles from './IntegrationSetup.module.scss';
import { a11yKeyBoardEventHandler } from '../../../shared/KeyboradKey';
import { customerAtom, customerListAtom } from 'atons/Customer/atoms';
/**
 * Proprierty for initial pagination
 */
const initialPageSetup = {
    skip: 0,
    take: 25,
};
/**
 * Proprierty with some initial configurations
 */
const initialPageSettings = {
    buttonCount: 25,
    info: true,
    pageSizes: [15, 25, 50],
    previousNext: true,
    type: 'numeric',
};
/**
 * Proprierty for initial sort
 */
const initialSort = [];
/**
 * Proprierty for initial filter
 */
const initialFilter = {
    logic: 'and',
    filters: [
        {
            field: 'instance',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'provider',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'status',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'lastAutoSync',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'lastForceSync',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'forceSyncUser',
            operator: 'startswith',
            value: '',
        },
    ],
};
/**
 * Function that return the detail HTML when the plus sinal is clicked
 * @param {*} props Proprierty that passes each object per line on the grid
 * @returns Returns the html of the details per line on the grid
 */
// const DetailComponent = (props) => {
//   const dataItem = props.dataItem;
//   return (
//     <section>
//       <p>
//         <strong>In Stock:</strong> {dataItem.instanceName}
//       </p>
//     </section>
//   );
// };
/**
 * IntegrationSetup Functional Component
 * @param {*} props Proprierty that passes data/info inside the component
 * @returns Returns the IntegrationSetup Component
 */
const IntegrationSetup = ({ children }) => {
    const auth = useAuth();
    const customer = useAtomValue(customerAtom);
    const customerList = useAtomValue(customerListAtom);
    const fileInputField = React.useRef();
    const [data, setData] = React.useState();
    const [dataGrid, setDataGrid] = React.useState();
    const [page, setPage] = React.useState(initialPageSetup);
    const [sort, setSort] = React.useState(initialSort);
    const [pageSettings] = React.useState(initialPageSettings);
    const [filter, setFilter] = React.useState(initialFilter);
    const [addIntegrationsList, setAddIntegrationsList] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [selected, setSelected] = React.useState();
    //const [, setGlobal] = useAtom(globalVariables);
    const [visible, setVisible] = React.useState(false);
    const [mode, setMode] = React.useState();
    const [upload, setUpload] = React.useState(false);
    const [gridClassName, setGridClassName] = React.useState(undefined);
    const [syncSuccess, setSyncSuccess] = React.useState(false);
    const [marketoForm, setMarketoForm] = React.useState({
        instanceId: null,
        instanceType: null,
        userId: auth?.userData.profile.userId,
        scheduledDate: null,
    });
    const [history, setHistory] = React.useState(false);
    const [statusInt, setStatusInt] = React.useState(false);
    const [instance, setInstance] = React.useState();
    const [menu, setMenu] = React.useState(false);
    const [dragStages, setDragStages] = React.useState(1);
    const [dragClass, setDragClass] = React.useState(styles.wrapperQuestion);
    //Put the placeholder attibute into the filter text input on the grid
    //========================================================================
    document.querySelectorAll('.k-textbox').forEach(el => {
        el.setAttribute('placeholder', 'Filter here...');
    });
    //Put the title attibute into the th tags kendo don't do this
    //=================================================================
    document.querySelectorAll('.k-header').forEach(el => {
        el.setAttribute('title', el.innerText);
    });
    /**
     * Call the uploadFile API using the react-query with useQuery
     */
    const { mutate: mutateUpload } = useMutation(
        formData => {
            return uploadIntegrationSetupFile(formData);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                closeUploadFileModal();
                inputFileClear();
                setStatusInt(true);
            },
            onError: error => {
                console.log(error);
                closeUploadFileModal();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the deleteIntegrationSetupSelected API using the react-query with useQuery
     */
    const { mutate: mutateDeleteTactic } = useMutation(
        () => {
            return deleteIntegrationSetupSelected(selected);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                toast.success('Delete Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the deleteIntegrationSetupSelectedMarketo API using the react-query with useQuery
     */
    const { mutate: mutateDeleteMarketo } = useMutation(
        () => {
            return deleteIntegrationSetupSelectedMarketo(selected);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                toast.success('Delete Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the forceSyncByIntegrationSetupIdLegacy API using the react-query with useQuery
     */
    const { mutate: mutateForceSyncNewPost } = useMutation(
        instance => {
            return forceSyncNewByIntegrationSetupId(instance);
        },
        {
            onSuccess: () => {
                //queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                toast.success(
                    marketoForm.instanceId !== null ? 'Reschedule done Successfully!' : 'Force Sync done Successfully!',
                    {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setSyncSuccess(true);
                closeReschedule();
            },
            onError: error => {
                console.log(error);
                closeReschedule();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 0,
        }
    );
    /**
     * Call the getIntegrationsList API using the react-query with useQuery
     */
    const { data: integrationSetupList } = useQuery(
        [queryKeys.integrationSetupList, customer],
        getIntegrationSetupList,
        {
            enabled: customer !== null,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Call the getCustomerListAssociation API using the react-query with useQuery
     */
    const { data: customerAssociations } = useQuery(
        [queryKeys.customerAssociations, customer],
        getCustomerListAssociation,
        {
            enabled:
                customer !== null &&
                (auth.userData.profile.role === 'Hive9Admin' ||
                    auth.userData.profile.permissions?.includes('CanEditIntegrations')),
            retry: false,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Function that make a force sync in legacy
     * @param {*} instance Proprienty that passes the instance that will receive the force sync
     */
    const forceSyncLegacy = instance => {
        if (instance.urlLegacyForceSync !== null) {
            window.open(instance.urlLegacyForceSync);
            return;
        }
        mutateForceSyncNewPost({
            InstanceId: instance.integrationInstanceIdMs,
            InstanceType: instance.integrationType,
            UserId: auth.userData.profile.userId,
        });
        setInstance(instance);
    };
    /**
     * Function the open the create modal of integration setup
     * @param {*} mode This proprierty passes the mode if is 'edit' case or 'create' case.
     * @param {*} instance This passes the instance the should be edit on the edit case this is optional
     */
    const openIntegrationSetupModal = (mode, instance) => {
        setMode(mode);
        setVisible(true);
        setInstance(instance);
    };
    /**
     * Function that opens the modal of confirmation for delete
     * @param {*} instance Proprienty that passes intance that will be deleted with the id
     */
    const openDeleteModal = instance => {
        setSelected(instance);
        setShow(true);
    };
    /**
     * Function that closes the status integration modal
     */
    const closeStatusIntModal = () => {
        setStatusInt(false);
    };
    /**
     * Function that close the modal of confirmation for delete
     */
    const closeDeleteModal = () => {
        setSelected();
        setShow(false);
    };
    /**
     * Function that opens the modal for upload a file to the back-end
     */
    const openUploadFileModal = instance => {
        setSelected(instance);
        setUpload(true);
    };
    /**
     * Function that close the modal for upload
     */
    const closeUploadFileModal = () => {
        setSelected();
        setUpload(false);
    };
    /**
     * Function that opens the modal for history
     */
    const openHistoryModal = instance => {
        setInstance(instance);
        setHistory(true);
    };
    /**
     * Function that close the modal for history
     */
    const closeHistoryModal = () => {
        setInstance();
        setHistory(false);
    };
    /**
     * Function that send the upload file to back-end
     * @param {*} evt Proprierty that passes the element file list
     */
    const uploadFile = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        if (
            evt.target.files &&
            evt.target.files.length === 1 &&
            String('.csv, .json, .xls, .xlsx, .pipe, .txt')
                .replace(/[\s.]/g, '')
                .split(',')
                .some(val => val === evt.target.files[0].name.split('.')[1])
        ) {
            const formData = new FormData();
            formData.append('file', evt.target.files[0]);
            formData.append('instanceId', selected.integrationInstanceIdMs);
            formData.append('instanceType', selected.integrationType);
            formData.append('integrationMethod', 1);
            formData.append('userId', auth.userData.profile.userId);
            mutateUpload(formData);
        }
    };
    /**
     * Function that click in the input type file that is hidden
     */
    const inputFileClick = () => {
        if (fileInputField.current !== null) {
            fileInputField.current.click();
        }
    };
    /**
     * Function that clean selection of the file from input type file
     */
    const inputFileClear = () => {
        if (fileInputField.current !== null) {
            fileInputField.current.value = '';
        }
    };
    /**
     * Function that show the error message from upload the file
     * @param {*} integration Property that passes the integration with the errorMessage to show
     */
    const openErrorMessage = integration => {
        setSelected(integration);
        setShowError(true);
    };
    /**
     * Function that close the error message from upload file
     */
    const closeErrorModal = () => {
        setSelected();
        setShowError(false);
    };
    /**
     * Function that opens the reschedule modal and select the marketo integration to reschedule on a new date
     * @param {*} markInt Propertiy that passes the marketo integration to reschedule a new integration process
     */
    const openReschedule = markInt => {
        marketoForm.instanceId = markInt.integrationInstanceIdMs;
        marketoForm.instanceType = markInt.integrationType;
        setMarketoForm({ ...marketoForm });
    };
    /**
     * Function that closes the reschedule modal
     */
    const closeReschedule = () => {
        marketoForm.instanceId = null;
        marketoForm.instanceType = null;
        marketoForm.scheduledDate = null;
        setMarketoForm({ ...marketoForm });
    };
    /**
     * Function that call reschedule marketo
     */
    const doTheReschedule = () => {
        mutateForceSyncNewPost(marketoForm);
    };
    /**
     * Function that returns the HTML of the Instance Name colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Instance Name colunm per line on the grid
     */
    const instanceNameColunm = ({ dataItem }) => {
        return (
            <td title={dataItem.instance ? dataItem.instance : ''}>{dataItem.instance ? dataItem.instance : '--'}</td>
        );
    };
    /**
     * Function that returns the HTML of the Provider colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Provider colunm per line on the grid
     */
    const providerColunm = ({ dataItem }) => {
        return (
            <td title={dataItem.provider ? dataItem.provider : ''}>{dataItem.provider ? dataItem.provider : '--'}</td>
        );
    };
    /**
     * Function that returns the HTML of the Provider colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Provider colunm per line on the grid
     */
    const statusColunm = ({ dataItem }) => {
        return (
            <td className={styles.alignCenter}>
                {dataItem.status === 'Error' &&
                    (dataItem.urlAdminPortal !== null &&
                    dataItem.urlAdminPortal !== '' &&
                    (auth.userData.profile.role === 'Hive9Admin' ||
                        auth.userData.profile.permissions?.includes('CanEditIntegrations')) ? (
                        <ToolTip message={'Click and see details about the errors'}>
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={() => openErrorMessage(dataItem)}
                                onKeyUp={a11yKeyBoardEventHandler(() => openErrorMessage(dataItem))}
                                className={[styles.redCont, styles.cursorPointer].join(' ')}
                            >
                                {dataItem.status ? dataItem.status : '--'}
                            </div>
                        </ToolTip>
                    ) : (
                        <div className={styles.redCont}>{dataItem.status ? dataItem.status : '--'}</div>
                    ))}
                {dataItem.status === 'Partial' &&
                    (dataItem.urlAdminPortal !== null &&
                    dataItem.urlAdminPortal !== '' &&
                    (auth.userData.profile.role === 'Hive9Admin' ||
                        auth.userData.profile.permissions?.includes('CanEditIntegrations')) ? (
                        <ToolTip message={'Click and see de details about the partial'}>
                            <div
                                role="button"
                                tabIndex={0}
                                className={[styles.orangeCont, styles.cursorPointer].join(' ')}
                                onClick={() => openErrorMessage(dataItem)}
                                onKeyUp={a11yKeyBoardEventHandler(() => openErrorMessage(dataItem))}
                            >
                                {dataItem.status ? dataItem.status : '--'}
                            </div>
                        </ToolTip>
                    ) : (
                        <div className={styles.orangeCont}>{dataItem.status ? dataItem.status : '--'}</div>
                    ))}
                {dataItem.status === 'Success' && dataItem.status && (
                    <div className={styles.greenCont}>{dataItem.status ? dataItem.status : '--'}</div>
                )}
                {dataItem.status === 'In Progress' && dataItem.status && (
                    <div className={styles.blueCont}>{dataItem.status ? dataItem.status : '--'}</div>
                )}
                {!dataItem.status && <div>--</div>}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the Force Sync User colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Force Sync User colunm per line on the grid
     */
    const forceSyncUserColunm = ({ dataItem }) => {
        return (
            <td title={dataItem.forceSyncUser ? dataItem.forceSyncUser : ''}>
                {dataItem.forceSyncUser ? dataItem.forceSyncUser : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the Last Auto Sync colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Last Auto Sync colunm per line on the grid
     */
    const lastAutoSyncColunm = ({ dataItem }) => {
        const lastAutoSync = dataItem.lastAutoSync
            ? dataItem.lastAutoSync.split('T')[0] + ' ' + dataItem.lastAutoSync.split('T')[1].substring(0, 5)
            : '--';
        return <td title={dataItem.lastAutoSync ? lastAutoSync : ''}>{dataItem.lastAutoSync ? lastAutoSync : '--'}</td>;
    };
    /**
     * Function that returns the HTML of the Last Force Sync colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Last Auto Force colunm per line on the grid
     */
    const lastForceSyncColunm = ({ dataItem }) => {
        const lastForceSync = dataItem.lastForceSync
            ? dataItem.lastForceSync.split('T')[0] + ' ' + dataItem.lastForceSync.split('T')[1].substring(0, 5)
            : '--';
        return (
            <td title={dataItem.lastForceSync ? lastForceSync : ''}>{dataItem.lastForceSync ? lastForceSync : '--'}</td>
        );
    };
    /**
     * Function that returns the HTML of the Force Sync colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the Force Sync colunm per line on the grid
     */
    const forceSyncColunm = ({ dataItem }) => {
        return (
            <td
                title={dataItem.forceSync}
                className={styles.actionsBtn}
            >
                {dataItem.forceSync === 'Inactive' ? (
                    <span
                        title={dataItem.forceSync}
                        className={[styles.red, 'k-icon', 'k-i-close-circle', 'k-i-x-circle'].join(' ')}
                    ></span>
                ) : (typeof auth.userData.profile.role === 'undefined' &&
                      !auth.userData.profile.permissions?.includes('CanEditIntegrations')) ||
                  (typeof auth.userData.profile.role !== 'undefined' &&
                      auth.userData.profile.permissions?.includes('CanEditIntegrations')) ||
                  (typeof auth.userData.profile.role !== 'undefined' &&
                      dataItem.urlLegacy !== null &&
                      dataItem.urlLegacy !== '') ? (
                    dataItem.provider !== 'MarketoV2' ? (
                        <span
                            title={'Legacy Force Sync not active to this user'}
                            className={[styles.linkToForceSync, styles.linkInactive].join(' ')}
                        >
                            Force Sync
                        </span>
                    ) : (
                        <span className={[styles.linkToForceSync, styles.linkInactive].join(' ')}>--</span>
                    )
                ) : dataItem.provider !== 'MarketoV2' ? (
                    <span
                        role="button"
                        tabIndex={0}
                        title={'Force Sync'}
                        onClick={() => forceSyncLegacy(dataItem)}
                        onKeyUp={a11yKeyBoardEventHandler(() => forceSyncLegacy(dataItem))}
                        className={styles.linkToForceSync}
                    >
                        Force Sync
                    </span>
                ) : (
                    <span
                        role="button"
                        tabIndex={0}
                        className={styles.linkToForceSync}
                        onClick={() => openReschedule(dataItem)}
                        onKeyUp={a11yKeyBoardEventHandler(() => openReschedule(dataItem))}
                    >
                        Reschedule Sync
                    </span>
                )}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the actions colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the actions colunm per line on the grid
     */
    const actionsColumns = ({ dataItem }) => {
        return (
            <td className={styles.actionsBtn}>
                {/*File upload modal BTN*/}
                {dataItem.urlAdminPortal !== null &&
                    dataItem.urlAdminPortal !== '' &&
                    dataItem.provider === 'Tactic Based Performance' &&
                    (auth.userData.profile.role === 'Hive9Admin' ||
                        auth.userData.profile.permissions?.includes('CanEditIntegrations')) && (
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={() => openUploadFileModal(dataItem)}
                            onKeyUp={a11yKeyBoardEventHandler(() => openUploadFileModal(dataItem))}
                            title={'Upload File'}
                            className={[styles.pointer, 'k-icon', 'k-i-upload', styles.iconSizes].join(' ')}
                        ></span>
                    )}
                {/*History modal BTN*/}
                {dataItem.urlAdminPortal !== null &&
                    dataItem.urlAdminPortal !== '' &&
                    dataItem.provider === 'Tactic Based Performance' &&
                    (auth.userData.profile.role === 'Hive9Admin' ||
                        auth.userData.profile.permissions?.includes('CanEditIntegrations')) && (
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={() => openHistoryModal(dataItem)}
                            onKeyUp={a11yKeyBoardEventHandler(() => openHistoryModal(dataItem))}
                            title={'History'}
                            className={[styles.pointer, 'k-icon', 'k-i-subreport', 'k-i-txt', styles.iconSizes].join(
                                ' '
                            )}
                        ></span>
                    )}
                {/*Edit modal BTN*/}
                {(auth.userData.profile.role === 'Hive9Admin' ||
                    auth.userData.profile.permissions?.includes('CanEditIntegrations')) && (
                    <span
                        role="button"
                        tabIndex={0}
                        onClick={() => editInstance(dataItem)}
                        onKeyUp={a11yKeyBoardEventHandler(() => editInstance(dataItem))}
                        title={
                            dataItem.urlLegacy !== null &&
                            dataItem.urlLegacy !== '' &&
                            typeof auth.userData.profile.role !== 'undefined'
                                ? 'Legacy edit not active to admin user'
                                : 'Edit'
                        }
                        className={
                            dataItem.urlLegacy !== null &&
                            dataItem.urlLegacy !== '' &&
                            typeof auth.userData.profile.role !== 'undefined'
                                ? [
                                      styles.pointer,
                                      'k-icon',
                                      'k-i-track-changes-enable',
                                      styles.iconSizes,
                                      'k-state-disabled',
                                  ].join(' ')
                                : [styles.pointer, 'k-icon', 'k-i-track-changes-enable', styles.iconSizes].join(' ')
                        }
                    ></span>
                )}
                {/*Delete modal BTN*/}
                {dataItem.urlAdminPortal !== null &&
                    dataItem.urlAdminPortal !== '' &&
                    (auth.userData.profile.role === 'Hive9Admin' ||
                        auth.userData.profile.permissions?.includes('CanEditIntegrations')) && (
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={() => openDeleteModal(dataItem)}
                            onKeyUp={a11yKeyBoardEventHandler(() => openDeleteModal(dataItem))}
                            title={'Delete'}
                            className={[styles.pointer, 'k-icon', 'k-i-delete', 'k-i-trash', styles.iconSizes].join(
                                ' '
                            )}
                        ></span>
                    )}
            </td>
        );
    };
    /**
     * Function that controls the pagination
     * @param {*} event Propriertie that passes the element
     */
    const pageChange = event => {
        setPage(event.page);
    };
    /**
     * Function that open the modal to edit the instance
     * @param {*} instance Proprierty that passes the instance to edit
     */
    const editInstance = instance => {
        if (instance.urlLegacy !== null && instance.urlLegacy !== '') {
            window.open(instance.urlLegacy);
            return;
        }
        openIntegrationSetupModal('edit', instance);
    };
    /**
     * Function that open the modal to delete ask if the customer is sure of deleting the integration setup
     */
    const deleteInstance = () => {
        if (selected.provider === 'Tactic Based Performance') {
            mutateDeleteTactic();
        }
        if (selected.provider === 'MarketoV2') {
            mutateDeleteMarketo();
        }
        closeDeleteModal();
    };
    /**
     * Function that open the modal to delete the instance
     * @param {*} listItem Proprierty that passes the listItem
     * @returns
     */
    const createInstance = listItem => {
        const selectedItem = addIntegrationsList?.find(item => item.integrationId === listItem?.integrationId);
        if (selectedItem?.urlLegacy !== null) {
            //setGlobal((prevState) => ({ ...prevState, loading: true }));
            //return (window.location.href = selectedItem?.urlLegacy);
            window.open(selectedItem?.urlLegacy);
            return;
        }
        openIntegrationSetupModal('create', selectedItem);
    };
    const getGridMaxHeight = () => {
        const APP_HEADER_BAR_HEIGHT = Math.ceil((document.getElementById('app-nav-bar')?.clientHeight ?? 0) / 10) * 10;
        const ATTRIBUTE_HEADER_CONTENT_HEIGHT =
            Math.ceil((document.getElementById('integration-setup-header-content')?.clientHeight ?? 0) / 10) * 10;
        const GRID_PADDING = 30;
        const GRID_MAX_HEIGHT =
            window.innerHeight - ATTRIBUTE_HEADER_CONTENT_HEIGHT - APP_HEADER_BAR_HEIGHT - GRID_PADDING * 2;
        return `${GRID_MAX_HEIGHT}px`;
    };
    /**
     * Function that controls the drag start event
     * @param {*} evt Proprierty that passes html object
     */
    const onDragStartFunc = evt => {
        evt.dataTransfer.clearData();
    };
    /**
     * Function that controls the drag over event
     * @param {*} evt Proprierty that passes html object
     */
    const onDragOverFunc = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        setDragStages(2);
    };
    /**
     * Function that controls the leave drag zone event
     * @param {*} evt Proprierty that passes html object
     */
    const onDragLeaveFunc = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        setDragStages(1);
    };
    /**
     * Function that controls the dradrop event
     * @param {*} evt Proprierty that passes the file object
     */
    const onDropFunc = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        if (
            evt.dataTransfer.files &&
            evt.dataTransfer.files.length === 1 &&
            String('.csv, .json, .xls, .xlsx, .pipe, .txt')
                .replace(/[\s.]/g, '')
                .split(',')
                .some(val => val === evt.dataTransfer.files[0].name.split('.')[1])
        ) {
            const formData = new FormData();
            formData.append('file', evt.dataTransfer.files[0]);
            formData.append('instanceId', selected.integrationInstanceIdMs);
            formData.append('instanceType', selected.integrationType);
            formData.append('integrationMethod', 1);
            formData.append('userId', auth.userData.profile.userId);
            mutateUpload(formData);
            setDragStages(3);
        } else {
            setDragStages(1);
            evt.dataTransfer.value = '';
        }
        inputFileClear();
    };
    /**
     * Effect that observes the sucess return from force async
     */
    React.useEffect(() => {
        if (syncSuccess && instance) {
            data.find(item => item.integrationInstanceIdMs === instance.integrationInstanceIdMs).status = 'In Progress';
            setInstance();
        }
    }, [syncSuccess, instance, data]);
    /**
     * Effect that observes the class change on drag events
     */
    React.useEffect(() => {
        switch (dragStages) {
            case 1:
                setDragClass(styles.wrapperQuestion);
                break;
            case 2:
                setDragClass([styles.wrapperQuestion, styles.dragOn].join(' '));
                break;
            case 3:
                setDragClass([styles.wrapperQuestion, styles.afterDoc].join(' '));
                break;
            default:
                setDragClass(styles.wrapperQuestion);
                break;
        }
    }, [dragStages]);
    /**
     * UseEffect that controls the filter and sort changes and go back to page one
     */
    React.useEffect(() => {
        setPage(initialPageSetup);
        data && setDataGrid(filterBy(orderBy(data, sort), filter));
    }, [sort, filter, data]);
    /**
     * UseEffect that controls the load of the integration setup list
     */
    React.useEffect(() => {
        integrationSetupList && setData(integrationSetupList);
        integrationSetupList && setDataGrid(integrationSetupList);
    }, [integrationSetupList]);
    /**
     * UseEffect that will observe the lists loading to set the lists variables
     */
    React.useEffect(() => {
        customerAssociations && setAddIntegrationsList(customerAssociations);
    }, [customerAssociations]);
    /**
     * UseEffect that controls the modal to hide the scroll from hmtl and it is opened
     */
    React.useEffect(() => {
        visible
            ? (document.querySelector('html').style.overflow = 'hidden')
            : document.querySelector('html').removeAttribute('style');
    }, [visible]);
    const getGridClassNameIfScrollIsVisible = React.useCallback(() => {
        const element = document.getElementById(styles.intSetup);
        if (element === null) return undefined;
        return element.clientHeight >= Number(getGridMaxHeight().replace(/\D/g, ''))
            ? styles.scrollIsVisible
            : undefined;
    }, []);
    React.useEffect(() => {
        const element = document.getElementById(styles.intSetup);
        const log = () => setGridClassName(getGridClassNameIfScrollIsVisible());
        if (element !== null) {
            element.addEventListener('DOMNodeInserted', log);
        }
        return () => {
            if (element !== null) {
                element.removeEventListener('DOMNodeInserted', log);
            }
        };
    }, [dataGrid, getGridClassNameIfScrollIsVisible]);
    /**
     * The HTML content of the IntegrationSetup Component
     */
    const Content = (
        <>
            {/* Modal for marketo reschedule */}
            <Modal
                closeBtn={true}
                className={styles.rescheduleIntModal}
                title={'Reschedule the Integration'}
                closeBtnFunc={closeReschedule}
                show={marketoForm?.instanceId !== null && typeof marketoForm?.instanceId !== 'undefined'}
            >
                <div className={styles.btnCont}>
                    <Calendar
                        className={styles.calendar}
                        onChange={evt => {
                            marketoForm.scheduledDate = evt.target.value;
                            setMarketoForm({ ...marketoForm });
                        }}
                    />
                    <Button
                        disabled={marketoForm.scheduledDate === null || marketoForm.instanceId === null}
                        text={'Reschedule'}
                        onClick={doTheReschedule}
                        className={styles.confirmBtn}
                    />
                </div>
            </Modal>
            {/* Modal for status upload file */}
            <Modal
                closeBtn={true}
                className={styles.statusIntModalConfig}
                title={'Status of File Upload'}
                closeBtnFunc={closeStatusIntModal}
                show={statusInt}
            >
                <p>
                    Your file is <b>uploaded successfully</b> and it is processing. It may take some time to finish.
                    Keep watching the status of your request.
                </p>
                <div className={styles.btnCont}>
                    <Button
                        text={'OK'}
                        onClick={closeStatusIntModal}
                        className={styles.confirmBtn}
                    />
                </div>
            </Modal>
            {/* Modal for history */}
            <Modal
                closeBtn={true}
                className={styles.historyModalConfig}
                title={'History'}
                closeBtnFunc={closeHistoryModal}
                show={history}
            >
                <HistoryGrid
                    closeBtnFunc={closeHistoryModal}
                    instance={instance}
                />
            </Modal>
            {/* Modal for upload */}
            <Modal
                className={styles.uploadModalConfig}
                show={upload}
            >
                <div
                    onDrop={e => onDropFunc(e)}
                    onDragStart={e => onDragStartFunc(e)}
                    onDragOver={e => onDragOverFunc(e)}
                    onDragLeave={e => onDragLeaveFunc(e)}
                    className={[styles.wrapperQuestion, dragClass].join(' ')}
                >
                    <span
                        role="button"
                        tabIndex={0}
                        onClick={inputFileClick}
                        onKeyUp={a11yKeyBoardEventHandler(inputFileClick)}
                        className={[styles.upload, styles.pointer, 'k-icon', 'k-i-files'].join(' ')}
                    ></span>
                    <p
                        role="presentation"
                        onClick={inputFileClick}
                        onKeyUp={a11yKeyBoardEventHandler(inputFileClick)}
                        className={styles.pointer}
                    >
                        <strong>Click here,</strong> choose and <span className={styles.text - upload}>upload</span>{' '}
                        your file.
                        <br />
                        <span className={styles.formats}>Only ( .csv, .json, .xls, .xlsx, .pipe, .txt ) formats.</span>
                    </p>
                    <input
                        className={styles.displayNone}
                        onChange={evt => uploadFile(evt)}
                        ref={fileInputField}
                        accept={'.csv, .json, .xls, .xlsx, .pipe, .txt'}
                        type={'file'}
                    />
                </div>
                <div className={styles.btnCont}>
                    <Button
                        text={'Cancel'}
                        btnType={'cancel'}
                        onClick={closeUploadFileModal}
                        className={styles.cancelBtn}
                    />
                </div>
            </Modal>
            {/* Modal for delete */}
            <Modal
                className={styles.deleteModal}
                show={show}
            >
                <div className={styles.question}>
                    Are you sure that you want to delete the <span>{selected && selected.instance}</span> integration
                    setup?
                </div>
                <div className={styles.btnCont}>
                    <Button
                        text={'OK'}
                        onClick={deleteInstance}
                        className={styles.confirmBtn}
                    />
                    <Button
                        btnType={'cancel'}
                        text={'Cancel'}
                        onClick={closeDeleteModal}
                        className={styles.cancelBtn}
                    />
                </div>
            </Modal>
            {/* Modal for status error click */}
            <Modal
                show={showError}
                closeBtn={true}
                closeBtnFunc={closeErrorModal}
                title={'Error message'}
                className={styles.errorModal}
            >
                <p>{selected?.errorMessage ? selected?.errorMessage : 'No message provided'}</p>
                {selected?.messageLink ? (
                    <div className={styles.btnCont}>
                        <a
                            href={selected?.messageLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            <Button
                                text={'Download'}
                                onClick={closeErrorModal}
                                className={styles.confirmBtn}
                            />
                        </a>
                    </div>
                ) : (
                    <div className={styles.btnCont}>
                        <Button
                            text={'OK'}
                            onClick={closeErrorModal}
                            className={styles.confirmBtn}
                        />
                    </div>
                )}
            </Modal>
            {/* Modal for create/edit Tactic Based Performance*/}
            {instance && visible && (instance?.title || instance?.provider) === 'Tactic Based Performance' && (
                <TacticBasedModal
                    visible={visible}
                    setVisible={setVisible}
                    mode={mode}
                    instance={instance}
                    setInstance={setInstance}
                    customer={customer}
                />
            )}
            {/* Modal for create/edit Marqueto v2*/}
            {instance && visible && (instance?.title === 'Marketo V2' || instance?.provider === 'MarketoV2') && (
                <MarketoV2Modal
                    visible={visible}
                    setVisible={setVisible}
                    mode={mode}
                    instance={instance}
                    setInstance={setInstance}
                    customer={customer}
                />
            )}
            <div className={styles.integrationSetup}>
                <div
                    id="integration-setup-header-content"
                    className={'pageTitleCont'}
                >
                    <h2 className="title-main">
                        Manage your{' '}
                        {typeof auth.userData.profile.role !== 'undefined' ? (
                            <span className={'title-section-marker'}>
                                Integration Setup{customer && customerList && ': '}
                                {customer && customerList?.length > 0 && (
                                    <span className={'title-section-marker'}>
                                        {customerList?.length > 0 &&
                                            customerList.find(cus => cus.clientId === customer.clientId)?.name}
                                    </span>
                                )}
                            </span>
                        ) : (
                            <span className={'title-section-marker'}>
                                Integration Setup:{' '}
                                <span className={'title-section-marker'}>{auth.userData.profile.clientName}</span>
                            </span>
                        )}
                    </h2>
                    {customer &&
                        addIntegrationsList.length > 0 &&
                        (auth.userData.profile.role === 'Hive9Admin' ||
                            auth.userData.profile.permissions?.includes('CanEditIntegrations')) && (
                            <NewIntegrationDropDown
                                profile={auth.userData.profile}
                                menu={menu}
                                setMenu={setMenu}
                                list={addIntegrationsList}
                                className={styles.integrationButton}
                                onItemClick={createInstance}
                            />
                        )}
                </div>
                {!customer && addIntegrationsList.length === 0 && (
                    <div className={styles.warningText}>
                        <span className={['k-icon', 'k-i-warning', 'icon-alert'].join(' ')}></span>
                        <p className={''}>
                            <b>Please,</b> select a customer from the menu above to view{' '}
                            <span className={'integrations-word'}>integrations</span>
                        </p>
                    </div>
                )}
                {customer && integrationSetupList?.length > 0 && dataGrid && (
                    <div
                        id={styles.intSetup}
                        className={[styles.gridCont, gridClassName].join(' ')}
                    >
                        <Grid
                            style={{ maxHeight: getGridMaxHeight() }}
                            className={[styles.grid, 'grid'].join(' ')}
                            data={dataGrid.slice(page.skip, page.take + page.skip)}
                            sortable={true}
                            sort={sort}
                            onSortChange={e => {
                                setSort(e.sort);
                            }}
                            filterable={true}
                            filter={filter}
                            onFilterChange={e => setFilter(e.filter)}
                            skip={page.skip}
                            take={page.take}
                            total={dataGrid.length}
                            pageable={pageSettings}
                            onPageChange={pageChange}
                            scrollable="scrollable"
                        >
                            <GridColumn
                                field="instance"
                                title="Instance Name"
                                cell={instanceNameColunm}
                                filterable={true}
                            />
                            <GridColumn
                                field="provider"
                                title="Provider"
                                cell={providerColunm}
                                filterable={true}
                            />
                            <GridColumn
                                field="status"
                                title="Status"
                                className={styles.alignCenter}
                                filterable={true}
                                cell={statusColunm}
                            />
                            <GridColumn
                                field="lastAutoSync"
                                title="Last Auto Sync"
                                filterable={true}
                                cell={lastAutoSyncColunm}
                            />
                            <GridColumn
                                field="lastForceSync"
                                title="Last Force Sync"
                                cell={lastForceSyncColunm}
                                filterable={true}
                            />
                            <GridColumn
                                field="forceSyncUser"
                                title="Force Sync User"
                                filterable={true}
                                cell={forceSyncUserColunm}
                            />
                            <GridColumn
                                field="forceSync"
                                title="Force Sync"
                                filterable={false}
                                cell={forceSyncColunm}
                            />
                            {(auth.userData.profile.role === 'Hive9Admin' ||
                                (typeof auth.userData.profile.role === 'undefined' &&
                                    auth.userData.profile.permissions?.includes('CanEditIntegrations'))) && (
                                <GridColumn
                                    field=""
                                    title=""
                                    filterable={false}
                                    cell={actionsColumns}
                                    width="150px"
                                />
                            )}
                        </Grid>
                    </div>
                )}
                {integrationSetupList?.length === 0 && addIntegrationsList.length > 0 && (
                    <div className={styles.warningText}>
                        <span className={['k-icon', 'k-i-warning', 'icon-alert'].join(' ')}></span>
                        <p className={''}>
                            <b>You don't have customer integration setups,</b>{' '}
                            <span className={'integrations-word'}>please check it and try again</span>
                        </p>
                    </div>
                )}
                {integrationSetupList?.length === 0 && addIntegrationsList.length === 0 && (
                    <div className={styles.warningText}>
                        <span className={['k-icon', 'k-i-warning', 'icon-alert'].join(' ')}></span>
                        <p className={''}>
                            <b>You don't have customer integration setups and customer integrations,</b>{' '}
                            <span className={'integrations-word'}>please check it and try again</span>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
    return <div>{children ? children : Content}</div>;
};

export default IntegrationSetup;
