import * as React from 'react';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { Button, GroupInput, TextInput, ColorInput } from 'components';

import { newPicklistRowValueAtom } from 'atons/AttributeManagement/atoms';

import { numberOnlyField } from 'shared/helpers';
import { usePostCustomFieldPicklist } from 'react-query/mutations';

import styles from './AddNewPicklistItem.module.scss';

const AddNewPicklistItem = () => {
    const [newPicklistRowValue, setNewPicklistRowValue] = useAtom(newPicklistRowValueAtom);
    const resetRow = useResetAtom(newPicklistRowValueAtom);

    const handleChangeNewInput = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
        setNewPicklistRowValue({
            ...newPicklistRowValue,
            [target.name]: target.value,
        });

    const handleChangeSortOrder = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
        setNewPicklistRowValue({
            ...newPicklistRowValue,
            [target.name]: numberOnlyField(target.value, true),
        });

    const handleChangeIsDefaultInput = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
        setNewPicklistRowValue({
            ...newPicklistRowValue,
            [target.name]: target.checked ? 'Yes' : 'No',
        });

    const { mutate: postCustomFieldPicklist } = usePostCustomFieldPicklist({ onSuccess: resetRow });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        postCustomFieldPicklist(newPicklistRowValue);
    };

    return (
        <form
            id="add-new-picklist-item"
            className={styles.newItemInsertionRow}
            onSubmit={onSubmit}
        >
            <div className={styles.formAddInput}>
                <TextInput
                    id="new-value-picklist"
                    name="value"
                    placeholder="Option Name"
                    value={newPicklistRowValue.value}
                    onChange={handleChangeNewInput}
                />
            </div>

            <div className={styles.formAddInput}>
                <ColorInput
                    disabled={false}
                    className={''}
                    id="color-pick-input"
                    name="color"
                    type={'color'}
                    value={newPicklistRowValue.color}
                    onChange={handleChangeNewInput}
                />
            </div>

            <div className={styles.formAddInput}>
                <TextInput
                    id="new-sort-order-value"
                    name="sortOrder"
                    type={'number'}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    value={newPicklistRowValue.sortOrder}
                    placeholder="Sort Order"
                    onChange={handleChangeSortOrder}
                />
            </div>

            <div className={styles.formAddSwitch + ' d-flex flex-column justify-content-center h-100'}>
                <GroupInput
                    label={{
                        text: 'Default',
                    }}
                    input={{
                        type: 'switch',
                        id: 'new-item-is-default',
                        name: 'isDefault',
                        checked: newPicklistRowValue.isDefault === 'Yes',
                        onChange: handleChangeIsDefaultInput,
                        dataSet: { 'data-map-switches': JSON.stringify({ true: 'Yes', false: 'No' }) },
                    }}
                />
            </div>

            <Button
                className={styles.btnAdd}
                text="Add"
                type="submit"
                id="save-new-item-button"
                title={
                    !newPicklistRowValue.value
                        ? 'You must specify a Option Name in order to add a option'
                        : 'Insert new option'
                }
                disabled={!newPicklistRowValue.value}
            />
        </form>
    );
};

export default AddNewPicklistItem;
