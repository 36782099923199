import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from '../../../../react-query/queryClient';
import { queryKeys } from '../../../../react-query/constants';
import {
    getIntegrationSetupCombos,
    getStageMappingList,
    getCustomGoalsMappingList,
    getIntegrationSetupById,
    getNotificationsEmailList,
    editIntegrationSetupSelected,
    createIntegrationSetup,
} from '../../../../services/apiIntegration';
import { toast } from 'react-toastify';
import { Modal, TabList, AutoComplete, ToolTip, SearchSelectionList } from '../../../../components/index';
import { Checkbox } from '@progress/kendo-react-inputs';
import { numberOnlyField, removeEspecialCharacField } from '../../../../shared/helpers';

import styles from './IntegrationSetupModals.module.scss';

let formAlt;

const TabBars = {
    GeneralSettings: 'general-settings',
    TacticMapping: 'tactic-mapping',
    StageMapping: 'stage-mapping',
    CustomGoals: 'custom-goals',
    Notifications: 'notifications',
};

const TacticBasedModal = props => {
    const { visible, setVisible, mode, instance, setInstance, customer } = props;
    //Form Model
    //=====================
    const inicialForm = {
        integrationId: instance?.integrationIdMs ? instance?.integrationIdMs : instance?.integrationId,
        clientId: customer?.clientId,
        active: false,
        tacticId: '',
        cultureOptionId: 'en-US',
        submitDate: '',
        name: '',
        importLevelId: 0,
        multiplePeformanceOptionId: 0,
        sftpHost: '',
        sftpUser: '',
        sftpPassword: '',
        sftpSourceDirectory: '',
        sftpPort: '',
        dateFormatId: 0,
        fileFormatId: 0,
        filePrefix: '',
        startLine: '',
        syncPreference: {
            syncOptionId: 0,
            hourOptionId: 0,
            dayOptionId: 0,
            dayOfTheMonth: '',
        },
        stageMapping: [
            {
                stageId: 0,
                fieldToMap: '',
                field: false,
            },
        ],
        clientCustomGoalTypeMapping: [
            {
                clientCustomGoalTypeId: 0,
                fieldToMap: '',
                field: false,
            },
        ],
        notificationEmails: [],
    };
    const [selectedTab, setSelectedTab] = React.useState(TabBars.GeneralSettings);
    const [form, setForm] = React.useState(inicialForm);
    const [stageMappingState, setStageMappingState] = React.useState();
    const [customGoalsMappingState, setCustomGoalsMappingState] = React.useState();
    const [notificationsState, setNotificationsState] = React.useState();
    const [validation, setValidation] = React.useState(true);
    const [stageFull, setStageFull] = React.useState(false);
    const [customFull, setCustomFull] = React.useState(false);
    const [email, setEmail] = React.useState(0);
    /**
     * Call the editIntegrationSetupSelected API using the react-query with useQuery
     */
    const { mutate: mutatePut } = useMutation(
        form => {
            return editIntegrationSetupSelected(form);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                setVisible(false);
                toast.success('Save Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                setVisible(false);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the createIntegrationSetup API using the react-query with useQuery
     */
    const { mutate: mutatePost } = useMutation(
        form => {
            return createIntegrationSetup(form);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.integrationSetupList]);
                setVisible(false);
                toast.success('Save Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                setVisible(false);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );
    /**
     * Call the getIntegrationSetupCombos API using the react-query with useQuery
     */
    const { data: integrationSetupCombos } = useQuery(queryKeys.integrationSetupCombos, getIntegrationSetupCombos, {
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        },
    });
    /**
     * Call the getIntegrationsList API using the react-query with useQuery
     */
    const { data: integrationSetupById } = useQuery(
        [queryKeys.integrationSetupById, instance],
        getIntegrationSetupById,
        {
            enabled: instance !== null && mode === 'edit' && typeof integrationSetupCombos !== 'undefined',
            retry: 3,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            onError: error => {
                console.log(error);
                setVisible(false);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Call the getStageMappingList API using the react-query with useQuery
     */
    const { data: stageMappingList } = useQuery([queryKeys.stageMapping, customer], getStageMappingList, {
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        },
    });
    /**
     * Call the getStageMappingList API using the react-query with useQuery
     */
    const { data: customGoalsMappingList } = useQuery(
        [queryKeys.customGoalsMapping, customer],
        getCustomGoalsMappingList,
        {
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Call the getNotificationsEmailList API using the react-query with useQuery
     */
    const { data: notificationsEmailListReturn } = useQuery(
        [queryKeys.notificationsEmailsList, customer],
        getNotificationsEmailList,
        {
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Function that verify if the string start and end with foward slashes and if not it will add to it
     * @param {*} stg Proprierty that passes the string to be verified
     * @returns The string that start and end with foward slashes
     */
    const verifyFowardSlashStartEndAndAdd = stg => {
        let finalStg = stg;
        if (finalStg.charAt(0) !== '/') finalStg = '/' + finalStg;
        if (finalStg.substr(finalStg.length - 1) !== '/') finalStg = finalStg + '/';
        return finalStg;
    };
    /**
     * Function that executes the POST
     */
    const integrationSetupModalPostFunc = () => {
        form.startLine = Number(form.startLine);
        form.clientCustomGoalTypeMapping = form.clientCustomGoalTypeMapping.filter(
            cli => cli.clientCustomGoalTypeId !== 0
        );
        form.stageMapping = form.stageMapping.filter(cli => cli.stageId !== 0);
        form.sftpSourceDirectory = verifyFowardSlashStartEndAndAdd(form.sftpSourceDirectory);
        form.syncPreference.dayOfTheMonth =
            form.syncPreference.dayOfTheMonth === '' ? Number(0) : Number(form.syncPreference.dayOfTheMonth);
        mutatePost(form);
    };
    /**
     * Function that executes the PUT
     */
    const integrationSetupModalPutFunc = () => {
        form.startLine = Number(form.startLine);
        form.clientCustomGoalTypeMapping = form.clientCustomGoalTypeMapping.filter(
            cli => cli.clientCustomGoalTypeId !== 0
        );
        form.stageMapping = form.stageMapping.filter(cli => cli.stageId !== 0);
        form.sftpSourceDirectory = verifyFowardSlashStartEndAndAdd(form.sftpSourceDirectory);
        form.syncPreference.dayOfTheMonth =
            form.syncPreference.dayOfTheMonth === '' ? Number(0) : Number(form.syncPreference.dayOfTheMonth);
        mutatePut(form);
    };
    /**
     * Function that verify if the string start and end with foward slashes and if yes it will remove
     * @param {*} stg Proprierty that passes the string to be verified
     * @returns The string that not startand not end with foward slashes
     */
    const verifyFowardSlashStartEndAndRemoveIt = stg => {
        let finalStg = stg;
        if (finalStg.charAt(0) === '/') finalStg = finalStg.substring(1);
        if (finalStg.substr(finalStg.length - 1) === '/') finalStg = finalStg.slice(0, -1);
        return finalStg;
    };
    /**
     * Function that closes the modal
     */
    const handleClose = () => {
        setVisible(false);
        setInstance();
    };
    /**
     * Function that add one more item to the type list
     * @param {*} type Proprierty that passes the type of the list to be add
     */
    const addMapping = type => {
        if (type === 'stage') {
            if (form?.stageMapping.length >= stageMappingList?.length) return;
            form.stageMapping.push({
                stageId: 0,
                fieldToMap: '',
                field: false,
            });
        } else {
            if (form?.clientCustomGoalTypeMapping.length >= customGoalsMappingList?.length) return;
            form.clientCustomGoalTypeMapping.push({
                clientCustomGoalTypeId: 0,
                fieldToMap: '',
                field: false,
            });
        }
        setForm({ ...form });
    };
    /**
     * Function that choose the cultureId
     * @param {*} value Proprierty that passes the value
     */
    const chooseCulture = value => {
        form.cultureOptionId = value;
        setForm({ ...form });
    };
    /**
     * UseEffect that observe both stage and custom lists if they are all mapped
     */
    React.useEffect(() => {
        customGoalsMappingList?.length === form.clientCustomGoalTypeMapping?.length
            ? setCustomFull(true)
            : setCustomFull(false);
        stageMappingList?.length === form.stageMapping?.length ? setStageFull(true) : setStageFull(false);
        formAlt = form;
    }, [stageMappingList, customGoalsMappingList, form]);
    /**
     * UseEffect that observe the return of stageMappingList API
     */
    React.useEffect(() => {
        if (integrationSetupById) {
            if (stageMappingList) {
                setStageMappingState(() => {
                    let stateList = stageMappingList;
                    integrationSetupById.stageMapping.forEach(el => {
                        if (stageMappingList?.some(res => res.stageId === el.stageId)) {
                            stateList = stateList.filter(res => res.stageId !== el.stageId);
                        }
                    });
                    if (
                        integrationSetupById.stageMapping.length !== stageMappingList.length &&
                        !integrationSetupById.stageMapping.some(el => el.stageId === 0)
                    ) {
                        let formStageList = integrationSetupById.stageMapping;
                        formStageList.push({
                            stageId: 0,
                            fieldToMap: '',
                            field: false,
                        });
                        setForm(prevState => ({ ...prevState, stageMapping: formStageList }));
                    }
                    return stateList;
                });
            }
        } else {
            stageMappingList && setStageMappingState(stageMappingList);
        }
    }, [stageMappingList, integrationSetupById]);
    /**
     * UseEffect that observe the return of customGoalsMappingList API
     */
    React.useEffect(() => {
        if (integrationSetupById) {
            if (customGoalsMappingList) {
                setCustomGoalsMappingState(() => {
                    let goalList = customGoalsMappingList;
                    integrationSetupById.clientCustomGoalTypeMapping.forEach(el => {
                        if (
                            customGoalsMappingList?.some(
                                res => res.clientCustomGoalTypeId === el.clientCustomGoalTypeId
                            )
                        ) {
                            goalList = goalList.filter(res => res.clientCustomGoalTypeId !== el.clientCustomGoalTypeId);
                        }
                    });
                    if (
                        integrationSetupById.clientCustomGoalTypeMapping.length !== customGoalsMappingList.length &&
                        !integrationSetupById.clientCustomGoalTypeMapping.some(el => el.stageId === 0)
                    ) {
                        let formClientCustomGoalTypeList = integrationSetupById.clientCustomGoalTypeMapping;
                        formClientCustomGoalTypeList.push({
                            clientCustomGoalTypeId: 0,
                            fieldToMap: '',
                            field: false,
                        });
                        setForm(prevState => ({
                            ...prevState,
                            clientCustomGoalTypeMapping: formClientCustomGoalTypeList,
                        }));
                    }
                    return goalList;
                });
            }
        } else {
            customGoalsMappingList && setCustomGoalsMappingState(customGoalsMappingList);
        }
    }, [customGoalsMappingList, integrationSetupById]);
    /**
     * UseEffect that observe the return of notificationsEmailListReturn API
     */
    React.useEffect(() => {
        if (integrationSetupById) {
            if (notificationsEmailListReturn) {
                setNotificationsState(() => {
                    let stateList = notificationsEmailListReturn;
                    integrationSetupById.notificationEmails &&
                        integrationSetupById.notificationEmails.forEach(el => {
                            if (notificationsEmailListReturn?.some(res => res.email === el)) {
                                stateList = stateList.filter(res => res.email !== el);
                            }
                        });
                    return stateList;
                });
            }
        } else {
            notificationsEmailListReturn && setNotificationsState(notificationsEmailListReturn);
        }
    }, [notificationsEmailListReturn, integrationSetupById]);
    /**
     * UseEffect that observe the return of integrationSetupById API
     */
    React.useEffect(() => {
        if (integrationSetupById) {
            integrationSetupById.clientCustomGoalTypeMapping.forEach(el => {
                if (el.fieldToMap) {
                    el.field = true;
                }
            });
            integrationSetupById.stageMapping.forEach(el => {
                if (el.fieldToMap) {
                    el.field = true;
                }
            });
            !integrationSetupById.notificationEmails && (integrationSetupById.notificationEmails = []);
            //In the PUT case I have to set the sftpPassword to "" because returns null and is not going to be required in PUT
            //=================================================================================================================
            integrationSetupById.sftpPassword = '';
            if (integrationSetupById.cultureOptionId === '' || !integrationSetupById.cultureOptionId)
                integrationSetupById.cultureOptionId = 'en-US';
            integrationSetupById.sftpSourceDirectory = verifyFowardSlashStartEndAndRemoveIt(
                integrationSetupById.sftpSourceDirectory
            );
            setForm(integrationSetupById);
        }
    }, [integrationSetupById]);
    /**
     * UseEffect that observe the tab changing
     */
    React.useEffect(() => {
        //Clean custom type with change the tab and the person dosen't include the new mapping
        //=====================================================================================
        const custom = formAlt?.clientCustomGoalTypeMapping.filter(
            item =>
                ((item.fieldToMap === '' && item.clientCustomGoalTypeId !== 0) ||
                    (item.fieldToMap !== '' && item.clientCustomGoalTypeId !== 0)) &&
                item.field === false
        );
        custom?.length > 0 &&
            custom.forEach(f =>
                formAlt?.clientCustomGoalTypeMapping.splice(
                    formAlt?.clientCustomGoalTypeMapping.findIndex(
                        e => e.clientCustomGoalTypeId === f.clientCustomGoalTypeId
                    ),
                    1
                )
            );
        custom?.length > 0 &&
            formAlt?.clientCustomGoalTypeMapping.push({
                clientCustomGoalTypeId: 0,
                fieldToMap: '',
                field: false,
            });
        //Clean stage with change the tab and the person dosen't include the new mapping
        //===============================================================================
        const stage = formAlt?.stageMapping.filter(
            item =>
                ((item.fieldToMap === '' && item.stageId !== 0) || (item.fieldToMap !== '' && item.stageId !== 0)) &&
                item.field === false
        );
        stage?.length > 0 &&
            stage.forEach(f =>
                formAlt?.stageMapping.splice(
                    formAlt?.stageMapping.findIndex(e => e.stageId === f.stageId),
                    1
                )
            );
        stage?.length > 0 &&
            formAlt?.stageMapping.push({
                stageId: 0,
                fieldToMap: '',
                field: false,
            });
        //setEmail(0);
        //apply the change
        //====================
        setForm({ ...formAlt });
    }, [selectedTab]);
    /**
     * UseEffect that observe validation form
     */
    React.useEffect(() => {
        if (
            form.tacticId === '' ||
            form.submitDate === '' ||
            form.name === '' ||
            form.cultureOptionId === '' ||
            form.importLevelId === 0 ||
            form.multiplePeformanceOptionId === 0 ||
            form.sftpHost === '' ||
            form.sftpUser === '' ||
            (mode === 'create' && form.sftpPassword === '') ||
            form.sftpSourceDirectory === '' ||
            form.sftpPort === '' ||
            form.dateFormatId === 0 ||
            form.fileFormatId === 0 ||
            form.filePrefix === '' ||
            form.stageMapping.filter(item => item.fieldToMap === '' && item.stageId !== 0)?.length > 0 ||
            form.stageMapping.filter(item => item.fieldToMap !== '' && item.stageId !== 0 && item.field === false)
                ?.length > 0 ||
            (form.stageMapping.filter(item => item.stageId === 0 && item.fieldToMap === '')?.length === 0 &&
                !stageFull) ||
            form.clientCustomGoalTypeMapping.filter(
                item => item.fieldToMap === '' && item.clientCustomGoalTypeId !== 0 && item.field === false
            )?.length > 0 ||
            form.clientCustomGoalTypeMapping.filter(
                item => item.fieldToMap !== '' && item.clientCustomGoalTypeId !== 0 && item.field === false
            )?.length > 0 ||
            (form.clientCustomGoalTypeMapping.filter(
                item => item.fieldToMap === '' && item.clientCustomGoalTypeId === 0
            )?.length === 0 &&
                !customFull) ||
            (form.stageMapping.filter(item => item.stageId !== 0 && item.fieldToMap !== '' && item.field === true)
                ?.length === 0 &&
                form.clientCustomGoalTypeMapping.filter(
                    item => item.clientCustomGoalTypeId !== 0 && item.fieldToMap !== '' && item.field === true
                )?.length === 0) ||
            form.syncPreference.syncOptionId === 0 ||
            (form.syncPreference.syncOptionId === 2 && form.syncPreference.hourOptionId === 0) ||
            (form.syncPreference.syncOptionId === 3 &&
                (form.syncPreference.hourOptionId === 0 || form.syncPreference.dayOptionId === 0)) ||
            (form.syncPreference.syncOptionId === 4 &&
                (form.syncPreference.hourOptionId === 0 || form.syncPreference.dayOfTheMonth === ''))
        ) {
            setValidation(true);
        } else {
            setValidation(false);
        }
    }, [form, mode, stageFull, customFull]);
    /**
     * UseEffect that cleans the component
     */
    React.useEffect(() => {
        return () => {
            formAlt = undefined;
        };
    }, []);
    /**
     * The HTML content of the IntegrationModal Component
     */
    const tabs = [
        {
            id: TabBars.GeneralSettings,
            label: 'General Settings',
        },
        {
            id: TabBars.TacticMapping,
            label: 'Tactic Mapping',
        },
        {
            id: TabBars.StageMapping,
            label: 'Stage Mapping',
        },
        {
            id: TabBars.CustomGoals,
            label: 'Custom Goals',
        },
        {
            id: TabBars.Notifications,
            label: 'Notifications',
        },
    ];
    return (
        <div>
            {integrationSetupCombos && (
                <Modal
                    show={visible}
                    closeBtnFunc={handleClose}
                    closeBtn={handleClose}
                    tabModal={true}
                    className={[styles.modal, styles.wrapperDialog, styles.displayMaxContent].join(' ')}
                    title={
                        mode === 'create' ? 'Create External Service Integration' : 'Edit External Service Integration'
                    }
                >
                    <TabList
                        selectedTabId={selectedTab}
                        tabs={tabs}
                        onChange={({ id }) => setSelectedTab(id)}
                    />
                    <div className={styles.tab}>
                        {/* TAB 1 - General Settings*/}
                        {selectedTab === TabBars.GeneralSettings && (
                            <div
                                className={styles.tabCont}
                                title="General Settings"
                            >
                                <form>
                                    {/* Instance Name */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="instance-name"
                                        >
                                            Instance Name: *
                                        </label>
                                        <input
                                            id="instance-name"
                                            className={styles.fieldIntegration}
                                            maxLength={'1000'}
                                            value={form?.name}
                                            onChange={evt => {
                                                form.name = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                    {/* Import Level */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="importLevelId"
                                        >
                                            Import Level: *
                                        </label>
                                        <AutoComplete
                                            id="importLevelId"
                                            placeholder="Select Import Level"
                                            value={form?.importLevelId}
                                            onChange={value => {
                                                form.importLevelId = Number(value);
                                                setForm({ ...form });
                                            }}
                                            options={integrationSetupCombos?.importLevels.map(el => ({
                                                value: el.id,
                                                label: el.description,
                                            }))}
                                            autoComplete={false}
                                            className={[styles.autocompleteField, styles.selectExtraChildPadding].join(
                                                ' '
                                            )}
                                        />
                                    </div>
                                    {/* Multiple Peformance Records */}
                                    <div className={styles.wrapperFields}>
                                        <div className={styles.positionRelative}>
                                            <label
                                                className={styles.labelField}
                                                htmlFor="multiplePerformanceOptionId"
                                            >
                                                Multiple Peformance Records: *
                                            </label>
                                            <ToolTip
                                                className={[styles.warn, styles.zIndex1].join(' ')}
                                                size={styles.toolTipMPR}
                                                iconType={'k-i-question k-i-help'}
                                                message={
                                                    'Please select the option to aggregate imported values with existing records or replace them.'
                                                }
                                            />
                                        </div>
                                        <AutoComplete
                                            id="multiplePerformanceOptionId"
                                            placeholder="Select Multiple Peformance Records"
                                            value={form?.multiplePeformanceOptionId}
                                            onChange={value => {
                                                form.multiplePeformanceOptionId = Number(value);
                                                setForm({ ...form });
                                            }}
                                            options={integrationSetupCombos?.multiplePeformanceOptions.map(el => ({
                                                value: el.id,
                                                label: el.description,
                                            }))}
                                            autoComplete={false}
                                            className={[styles.autocompleteField, styles.selectExtraChildPadding].join(
                                                ' '
                                            )}
                                        />
                                    </div>
                                    {/* SFTP Host */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="sftp-host"
                                        >
                                            SFTP Host: *
                                        </label>
                                        <input
                                            id="sftp-host"
                                            value={form?.sftpHost}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.sftpHost = evt.target.value;
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* SFTP User */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="sftp-user"
                                        >
                                            SFTP User: *
                                        </label>
                                        <input
                                            id="sftp-user"
                                            value={form?.sftpUser}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.sftpUser = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* SFTP Password */}
                                    <div className={styles.wrapperFields}>
                                        <label className={styles.labelField}>
                                            SFTP Password: {mode === 'edit' ? '' : '*'}{' '}
                                        </label>
                                        <input
                                            value={form?.sftpPassword}
                                            maxLength={'50'}
                                            onChange={evt => {
                                                form.sftpPassword = evt.target.value;
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="password"
                                            placeholder={
                                                mode === 'edit'
                                                    ? 'If you want to change your password, write the new one here!'
                                                    : ''
                                            }
                                            autoComplete="off"
                                        />
                                    </div>
                                    {/* SFTP Source Directory */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="sftp-source-directory"
                                        >
                                            SFTP Source Directory: *
                                        </label>
                                        <input
                                            id="sftp-source-directory"
                                            value={form?.sftpSourceDirectory}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.sftpSourceDirectory = evt.target.value;
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* SFTP Port */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="sftp-port"
                                        >
                                            SFTP Port: *
                                        </label>
                                        <input
                                            id="sftp-port"
                                            value={form?.sftpPort}
                                            maxLength={'255'}
                                            onChange={evt => {
                                                form.sftpPort = numberOnlyField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* Culture */}
                                    <div className={[styles.wrapperFields, styles.paused].join(' ')}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="culture"
                                        >
                                            Culture: *
                                        </label>
                                        <SearchSelectionList
                                            id="culture"
                                            value={form?.cultureOptionId}
                                            data={integrationSetupCombos?.cultureOptions}
                                            onClick={chooseCulture}
                                        />
                                    </div>
                                    {/* Start line */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="start-line"
                                        >
                                            Start line:
                                        </label>
                                        <input
                                            id="start-line"
                                            value={form?.startLine}
                                            maxLength="10"
                                            onChange={evt => {
                                                form.startLine = numberOnlyField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="phone"
                                        />
                                    </div>
                                    {/* Date Format */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="dateFormatId"
                                        >
                                            Date Format: *
                                        </label>
                                        <AutoComplete
                                            id="dateFormatId"
                                            placeholder="Select Date Format"
                                            value={form?.dateFormatId}
                                            onChange={value => {
                                                form.dateFormatId = Number(value);
                                                setForm({ ...form });
                                            }}
                                            options={integrationSetupCombos?.dateFormats.map(el => ({
                                                value: el.id,
                                                label: el.description,
                                            }))}
                                            autoComplete={false}
                                            className={[styles.autocompleteField, styles.selectExtraChildPadding].join(
                                                ' '
                                            )}
                                        />
                                    </div>
                                    {/* File Format */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="fileFormatId"
                                        >
                                            File Format: *
                                        </label>
                                        <AutoComplete
                                            id="fileFormatId"
                                            placeholder="Select File Format"
                                            value={form?.fileFormatId}
                                            onChange={value => {
                                                form.fileFormatId = Number(value);
                                                setForm({ ...form });
                                            }}
                                            options={integrationSetupCombos?.fileFormats.map(el => ({
                                                value: el.id,
                                                label: el.description,
                                            }))}
                                            autoComplete={false}
                                            className={[styles.autocompleteField, styles.selectExtraChildPadding].join(
                                                ' '
                                            )}
                                        />
                                    </div>
                                    {/* File Prefix   */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="file-prefix"
                                        >
                                            File Prefix: *
                                        </label>
                                        <input
                                            id="file-prefix"
                                            value={form?.filePrefix}
                                            maxLength={'1000'}
                                            onChange={evt => {
                                                form.filePrefix = evt.target.value;
                                                setForm({ ...form });
                                            }}
                                            className={styles.fieldIntegration}
                                            type="text"
                                        />
                                    </div>
                                    {/* Integration Status */}
                                    {mode === 'edit' && (
                                        <div className={styles.wrapperFields}>
                                            <label
                                                className={styles.labelField}
                                                htmlFor="integration-status"
                                            >
                                                Integration Status:
                                            </label>
                                            <div
                                                className={
                                                    form?.active
                                                        ? [styles.checkFix, styles.wrapperCheckbox, styles.green].join(
                                                              ' '
                                                          )
                                                        : [styles.checkFix, styles.wrapperCheckbox, styles.red].join(
                                                              ' '
                                                          )
                                                }
                                            >
                                                <Checkbox
                                                    id="integration-status"
                                                    className={styles.checkIntegrationSetup}
                                                    value={form?.active}
                                                    onChange={evt => {
                                                        form.active = evt.target.value;
                                                        setForm({ ...form });
                                                    }}
                                                    label={form?.active ? 'Active' : 'Inactive'}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* Sync Preference */}
                                    <div className={styles.wrapperFields}>
                                        <label
                                            className={styles.labelField}
                                            htmlFor="fileFormatId"
                                        >
                                            Sync Preference: *
                                        </label>
                                        <div className={styles.wrapperPrefixFields}>
                                            <AutoComplete
                                                id="fileFormatId"
                                                placeholder="Select Sync Preference"
                                                value={form?.syncPreference.syncOptionId}
                                                onChange={value => {
                                                    console.log({
                                                        value,
                                                        opts: integrationSetupCombos?.syncOptions.map(el => ({
                                                            value: el.id,
                                                            label: el.description,
                                                        })),
                                                    });
                                                    form.syncPreference.syncOptionId = Number(value);
                                                    form.syncPreference.dayOfTheMonth = '';
                                                    form.syncPreference.hourOptionId = 0;
                                                    form.syncPreference.dayOptionId = 0;
                                                    setForm({ ...form });
                                                }}
                                                options={integrationSetupCombos?.syncOptions.map(el => ({
                                                    value: el.id,
                                                    label: el.description,
                                                }))}
                                                autoComplete={false}
                                                className={
                                                    form?.syncPreference.syncOptionId === 2
                                                        ? [
                                                              styles.syncSize,
                                                              styles.selectExtraChildPadding,
                                                              styles.flexFill,
                                                          ].join(' ')
                                                        : [styles.selectExtraChildPadding, styles.flexFill].join(' ')
                                                }
                                            />
                                            {(form?.syncPreference.syncOptionId ===
                                                integrationSetupCombos?.syncOptions[1].id ||
                                                form?.syncPreference.syncOptionId ===
                                                    integrationSetupCombos?.syncOptions[2].id ||
                                                form?.syncPreference.syncOptionId ===
                                                    integrationSetupCombos?.syncOptions[3].id) && (
                                                <AutoComplete
                                                    id="fileFormatId"
                                                    placeholder="Select Hour"
                                                    value={form?.syncPreference.hourOptionId}
                                                    onChange={value => {
                                                        form.syncPreference.hourOptionId = Number(value);
                                                        setForm({ ...form });
                                                    }}
                                                    options={integrationSetupCombos?.hourOptions.map(el => ({
                                                        value: el.id,
                                                        label: el.description,
                                                    }))}
                                                    autoComplete={false}
                                                    className={[styles.selectExtraChildPadding, styles.flexFill].join(
                                                        ' '
                                                    )}
                                                />
                                            )}
                                            {form?.syncPreference.syncOptionId ===
                                                integrationSetupCombos?.syncOptions[3].id && (
                                                <input
                                                    type="phone"
                                                    maxLength="2"
                                                    placeholder={'Day of the Month'}
                                                    title={'1-31 range'}
                                                    value={form?.syncPreference.dayOfTheMonth}
                                                    onChange={evt => {
                                                        form.syncPreference.dayOfTheMonth =
                                                            evt.target.value > 31
                                                                ? 31
                                                                : numberOnlyField(evt.target.value);
                                                        setForm({ ...form });
                                                    }}
                                                    className={[styles.fieldIntegrationSync, styles.extra].join(' ')}
                                                />
                                            )}
                                            {form?.syncPreference.syncOptionId ===
                                                integrationSetupCombos?.syncOptions[2].id && (
                                                <AutoComplete
                                                    id="fileFormatId"
                                                    placeholder="Select Day"
                                                    value={form?.syncPreference.dayOptionId}
                                                    onChange={value => {
                                                        form.syncPreference.dayOptionId = Number(value);
                                                        setForm({ ...form });
                                                    }}
                                                    options={integrationSetupCombos?.dayOptions.map(el => ({
                                                        value: el.id,
                                                        label: el.description,
                                                    }))}
                                                    autoComplete={false}
                                                    className={[styles.selectExtraChildPadding, styles.flexFill].join(
                                                        ' '
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                        {/* TAB 2 - Tactic Mapping*/}
                        {selectedTab === TabBars.TacticMapping && (
                            <div
                                className={styles.tacticMapping}
                                title="Tactic Mapping"
                            >
                                <>
                                    <div className={styles.headerTab}>
                                        <div className={styles.headerTitle}>
                                            <span>Hive9 Fields</span>
                                        </div>
                                        <div className={styles.headerTitle}>
                                            <span>Source Fields</span>
                                        </div>
                                    </div>
                                    {/* Tatic Id */}
                                    <div className={[styles.wrapperFields, styles.tactic].join(' ')}>
                                        <input
                                            disabled={true}
                                            className={[styles.dropdownField, styles.tactic].join(' ')}
                                            value={'Tactic Id*'}
                                        />
                                        <input
                                            className={[styles.fieldIntegration, styles.tactic].join(' ')}
                                            maxLength={'255'}
                                            value={form?.tacticId}
                                            onChange={evt => {
                                                form.tacticId = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                    <div className={[styles.wrapperFields, styles.tactic].join(' ')}>
                                        {/* Submit Date */}
                                        <input
                                            disabled={true}
                                            className={[styles.dropdownField, styles.tactic].join(' ')}
                                            value={'Submit Date*'}
                                        />
                                        <input
                                            className={[styles.fieldIntegration, styles.tactic].join(' ')}
                                            maxLength={'255'}
                                            value={form?.submitDate}
                                            onChange={evt => {
                                                form.submitDate = removeEspecialCharacField(evt.target.value);
                                                setForm({ ...form });
                                            }}
                                            type="text"
                                        />
                                    </div>
                                </>
                            </div>
                        )}
                        {/* TAB 3 - Stage Mapping*/}
                        {selectedTab === TabBars.StageMapping && (
                            <div
                                title="Stage Mapping"
                                className={styles.tacticMapping}
                            >
                                <>
                                    <div className={styles.headerTab}>
                                        <div className={styles.headerTitle}>
                                            <span>Hive9 Fields</span>
                                        </div>
                                        <div className={styles.headerTitle}>
                                            <span>Source Fields</span>
                                        </div>
                                        <div style={{ width: '35px', height: '35px' }} />
                                    </div>

                                    {stageMappingList.length > 0 && form?.stageMapping?.length > 0
                                        ? form?.stageMapping?.map((stage, index) => {
                                              return (
                                                  <div
                                                      key={index}
                                                      className={[styles.wrapperFields, styles.tactic].join(' ')}
                                                  >
                                                      {!form.stageMapping[index].field && (
                                                          <div className={styles.flexFill}>
                                                              <AutoComplete
                                                                  id="fileFormatId"
                                                                  placeholder="Select Stage"
                                                                  value={form.stageMapping[index].stageId}
                                                                  onChange={value => {
                                                                      form.stageMapping[index].fieldToMap = '';
                                                                      form.stageMapping[index].stageId = Number(value);
                                                                      setForm({ ...form });
                                                                  }}
                                                                  options={stageMappingState?.map(el => ({
                                                                      value: el.stageId,
                                                                      label: el.label,
                                                                  }))}
                                                                  autoComplete={false}
                                                                  className={styles.selectExtraChildPadding}
                                                              />
                                                          </div>
                                                      )}
                                                      {form.stageMapping[index].field && (
                                                          <div className={styles.flexFill}>
                                                              <input
                                                                  disabled={true}
                                                                  type="text"
                                                                  value={
                                                                      stageMappingList?.find(
                                                                          el =>
                                                                              el.stageId ===
                                                                              form.stageMapping[index].stageId
                                                                      ).label
                                                                  }
                                                                  className={[
                                                                      styles.fieldIntegration,
                                                                      styles.tactic,
                                                                      styles.fieldHolderToolTip,
                                                                  ].join(' ')}
                                                              />
                                                          </div>
                                                      )}
                                                      <div className={styles.flexFill}>
                                                          <input
                                                              disabled={form.stageMapping[index].stageId === 0}
                                                              type="text"
                                                              value={form.stageMapping[index].fieldToMap}
                                                              maxLength={'250'}
                                                              onChange={evt => {
                                                                  form.stageMapping[index].fieldToMap =
                                                                      removeEspecialCharacField(evt.target.value);
                                                                  setForm({ ...form });
                                                              }}
                                                              className={[styles.fieldIntegration, styles.tactic].join(
                                                                  ' '
                                                              )}
                                                          />
                                                      </div>
                                                      <div className={styles.wrapperBtnPlus}>
                                                          {form?.stageMapping?.length === index + 1 &&
                                                              form?.stageMapping?.length < stageMappingList?.length &&
                                                              !form?.stageMapping[index].field &&
                                                              stageMappingState?.length > 1 && (
                                                                  <button
                                                                      className={
                                                                          form?.stageMapping[index].fieldToMap === ''
                                                                              ? styles.disabled
                                                                              : ''
                                                                      }
                                                                      title={'To add one more database association'}
                                                                      disabled={
                                                                          form?.stageMapping[index].fieldToMap === ''
                                                                      }
                                                                      onClick={() => {
                                                                          addMapping('stage');
                                                                          form.stageMapping[index].field = true;
                                                                          setStageMappingState(
                                                                              stageMappingState.filter(
                                                                                  el =>
                                                                                      el.stageId !==
                                                                                      form?.stageMapping[index].stageId
                                                                              )
                                                                          );
                                                                          setForm({ ...form });
                                                                      }}
                                                                  >
                                                                      <i className="k-icon k-i-plus" />
                                                                  </button>
                                                              )}
                                                          {form?.stageMapping[index].field && (
                                                              <button
                                                                  title={'To remove this database association'}
                                                                  disabled={form?.stageMapping[index].fieldToMap === ''}
                                                                  onClick={() => {
                                                                      stageMappingState.push(
                                                                          stageMappingList.find(
                                                                              el =>
                                                                                  el.stageId ===
                                                                                  form.stageMapping[index].stageId
                                                                          )
                                                                      );
                                                                      form.stageMapping = form.stageMapping.filter(
                                                                          el =>
                                                                              el.stageId !==
                                                                              form.stageMapping[index].stageId
                                                                      );
                                                                      if (
                                                                          !form.stageMapping.some(
                                                                              el => el.stageId === 0
                                                                          )
                                                                      )
                                                                          form.stageMapping.push({
                                                                              stageId: 0,
                                                                              fieldToMap: '',
                                                                              field: false,
                                                                          });
                                                                      setForm({ ...form });
                                                                  }}
                                                              >
                                                                  <i className="k-icon k-i-delete" />
                                                              </button>
                                                          )}
                                                          {form.stageMapping.length === stageMappingList.length &&
                                                              index === stageMappingList.length - 1 &&
                                                              !form.stageMapping[index].field && (
                                                                  <button
                                                                      title={
                                                                          'To add the last possible database association'
                                                                      }
                                                                      disabled={
                                                                          form?.stageMapping[index].fieldToMap === ''
                                                                      }
                                                                      onClick={() => {
                                                                          form.stageMapping[index].field = true;
                                                                          setStageMappingState(
                                                                              stageMappingState.filter(
                                                                                  el =>
                                                                                      el.stageId !==
                                                                                      form?.stageMapping[index].stageId
                                                                              )
                                                                          );
                                                                          setForm({ ...form });
                                                                      }}
                                                                  >
                                                                      <i className="k-icon k-i-check" />
                                                                  </button>
                                                              )}
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : stageMappingList?.length === 0 && (
                                              <div className={styles.warningMsg}>
                                                  <span className={'k-icon k-i-warning .k-i-exception'}></span>
                                                  <p>
                                                      There is no <b>Stage Mapping</b> to this customer.
                                                  </p>
                                              </div>
                                          )}
                                </>
                            </div>
                        )}
                        {/* TAB 4 - Custom Goals*/}
                        {selectedTab === TabBars.CustomGoals && (
                            <div
                                className={styles.tacticMapping}
                                title="Custom Goals"
                            >
                                <>
                                    <div className={styles.headerTab}>
                                        <div className={styles.headerTitle}>
                                            <span>Hive9 Fields</span>
                                        </div>
                                        <div className={styles.headerTitle}>
                                            <span>Source Fields</span>
                                        </div>

                                        <div style={{ width: '35px', height: '35px' }} />
                                    </div>
                                    {customGoalsMappingList?.length > 0 && form?.clientCustomGoalTypeMapping?.length > 0
                                        ? form?.clientCustomGoalTypeMapping?.map((stage, index) => {
                                              return (
                                                  <div
                                                      key={index}
                                                      className={[styles.wrapperFields, styles.tactic].join(' ')}
                                                  >
                                                      {!form.clientCustomGoalTypeMapping[index].field && (
                                                          <div className={styles.flexFill}>
                                                              <AutoComplete
                                                                  id="select-custom-goals"
                                                                  placeholder="Select Custom Goals"
                                                                  value={
                                                                      form.clientCustomGoalTypeMapping[index]
                                                                          .clientCustomGoalTypeId
                                                                  }
                                                                  onChange={value => {
                                                                      form.clientCustomGoalTypeMapping[
                                                                          index
                                                                      ].fieldToMap = '';
                                                                      form.clientCustomGoalTypeMapping[
                                                                          index
                                                                      ].clientCustomGoalTypeId = Number(value);
                                                                      setForm({ ...form });
                                                                  }}
                                                                  options={customGoalsMappingState?.map(el => ({
                                                                      value: el.clientCustomGoalTypeId,
                                                                      label: el.label,
                                                                  }))}
                                                                  autoComplete={false}
                                                                  className={styles.autoCompleteFieldTactic}
                                                              />
                                                          </div>
                                                      )}
                                                      {form.clientCustomGoalTypeMapping[index].field && (
                                                          <div className={styles.fieldHolderToolTip}>
                                                              <input
                                                                  disabled={true}
                                                                  type="text"
                                                                  value={
                                                                      customGoalsMappingList?.find(
                                                                          el =>
                                                                              el.clientCustomGoalTypeId ===
                                                                              form.clientCustomGoalTypeMapping[index]
                                                                                  .clientCustomGoalTypeId
                                                                      ).label
                                                                  }
                                                                  className={[
                                                                      styles.fieldIntegration,
                                                                      styles.toolTipInput,
                                                                      styles.tactic,
                                                                  ].join(' ')}
                                                              />
                                                              {customGoalsMappingList?.find(
                                                                  el =>
                                                                      el.clientCustomGoalTypeId ===
                                                                      form.clientCustomGoalTypeMapping[index]
                                                                          .clientCustomGoalTypeId
                                                              ).dataType === 3 && (
                                                                  <ToolTip
                                                                      className={styles.warnCustomGoal}
                                                                      iconType={'k-i-information k-i-info'}
                                                                      message={'This field has a percentage value'}
                                                                  />
                                                              )}
                                                          </div>
                                                      )}
                                                      <div className={styles.flexFill}>
                                                          <input
                                                              disabled={
                                                                  form.clientCustomGoalTypeMapping[index]
                                                                      .clientCustomGoalTypeId === 0
                                                              }
                                                              type="text"
                                                              value={form.clientCustomGoalTypeMapping[index].fieldToMap}
                                                              maxLength={'250'}
                                                              onChange={evt => {
                                                                  form.clientCustomGoalTypeMapping[index].fieldToMap =
                                                                      removeEspecialCharacField(evt.target.value);
                                                                  setForm({ ...form });
                                                              }}
                                                              className={[styles.fieldIntegration, styles.tactic].join(
                                                                  ' '
                                                              )}
                                                          />
                                                      </div>
                                                      <div className={styles.wrapperBtnPlus}>
                                                          {form?.clientCustomGoalTypeMapping.length === index + 1 &&
                                                              form?.clientCustomGoalTypeMapping.length <
                                                                  customGoalsMappingList?.length &&
                                                              !form?.clientCustomGoalTypeMapping[index].field &&
                                                              customGoalsMappingState?.length > 1 && (
                                                                  <button
                                                                      title={'To add one more database association'}
                                                                      disabled={
                                                                          form?.clientCustomGoalTypeMapping[index]
                                                                              .fieldToMap === ''
                                                                      }
                                                                      onClick={() => {
                                                                          addMapping('customGoals');
                                                                          form.clientCustomGoalTypeMapping[
                                                                              index
                                                                          ].field = true;
                                                                          setCustomGoalsMappingState(
                                                                              customGoalsMappingState.filter(
                                                                                  el =>
                                                                                      el.clientCustomGoalTypeId !==
                                                                                      form?.clientCustomGoalTypeMapping[
                                                                                          index
                                                                                      ].clientCustomGoalTypeId
                                                                              )
                                                                          );
                                                                          setForm({ ...form });
                                                                      }}
                                                                  >
                                                                      <i className="k-icon k-i-plus" />
                                                                  </button>
                                                              )}
                                                          {form?.clientCustomGoalTypeMapping[index].field && (
                                                              <button
                                                                  title={'To remove this database association'}
                                                                  disabled={
                                                                      form?.clientCustomGoalTypeMapping[index]
                                                                          .fieldToMap === ''
                                                                  }
                                                                  onClick={() => {
                                                                      customGoalsMappingState.push(
                                                                          customGoalsMappingList.find(
                                                                              el =>
                                                                                  el.clientCustomGoalTypeId ===
                                                                                  form.clientCustomGoalTypeMapping[
                                                                                      index
                                                                                  ].clientCustomGoalTypeId
                                                                          )
                                                                      );
                                                                      form.clientCustomGoalTypeMapping =
                                                                          form.clientCustomGoalTypeMapping.filter(
                                                                              el =>
                                                                                  el.clientCustomGoalTypeId !==
                                                                                  form.clientCustomGoalTypeMapping[
                                                                                      index
                                                                                  ].clientCustomGoalTypeId
                                                                          );
                                                                      if (
                                                                          !form.clientCustomGoalTypeMapping.some(
                                                                              el => el.clientCustomGoalTypeId === 0
                                                                          )
                                                                      )
                                                                          form.clientCustomGoalTypeMapping.push({
                                                                              clientCustomGoalTypeId: 0,
                                                                              fieldToMap: '',
                                                                              field: false,
                                                                          });
                                                                      setForm({ ...form });
                                                                  }}
                                                              >
                                                                  <i className="k-icon k-i-delete" />
                                                              </button>
                                                          )}
                                                          {form.clientCustomGoalTypeMapping.length ===
                                                              customGoalsMappingList.length &&
                                                              index === customGoalsMappingList.length - 1 &&
                                                              !form.clientCustomGoalTypeMapping[index].field && (
                                                                  <button
                                                                      title={
                                                                          'To add the last possible database association'
                                                                      }
                                                                      disabled={
                                                                          form?.clientCustomGoalTypeMapping[index]
                                                                              .fieldToMap === ''
                                                                      }
                                                                      onClick={() => {
                                                                          form.clientCustomGoalTypeMapping[
                                                                              index
                                                                          ].field = true;
                                                                          setCustomGoalsMappingState(
                                                                              customGoalsMappingState.filter(
                                                                                  el =>
                                                                                      el.clientCustomGoalTypeId !==
                                                                                      form?.clientCustomGoalTypeMapping[
                                                                                          index
                                                                                      ].clientCustomGoalTypeId
                                                                              )
                                                                          );
                                                                          setForm({ ...form });
                                                                      }}
                                                                  >
                                                                      <i className="k-icon k-i-check" />
                                                                  </button>
                                                              )}
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : customGoalsMappingList?.length === 0 && (
                                              <div className={styles.warningMsg}>
                                                  <span className={'k-icon k-i-warning .k-i-exception'}></span>
                                                  <p>
                                                      There is no <b>Custom Goals Type Mapping</b> to this customer.
                                                  </p>
                                              </div>
                                          )}
                                </>
                            </div>
                        )}
                        {/* TAB 5 - Notifications*/}
                        {selectedTab === TabBars.Notifications && (
                            <div
                                title="Notifications"
                                className={styles.tacticMapping}
                            >
                                <>
                                    <div className={styles.headerTab}>
                                        <div className={[styles.headerTitle, styles.fullWidth].join(' ')}>
                                            <span>E-mail</span>
                                        </div>
                                        <div style={{ width: '35px', height: '35px' }} />
                                    </div>
                                    {form?.notificationEmails?.length > 0 &&
                                        form?.notificationEmails?.map((stage, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={[styles.wrapperFields, styles.tactic].join(' ')}
                                                >
                                                    <input
                                                        type="text"
                                                        disabled={true}
                                                        className={[
                                                            styles.fieldIntegration,
                                                            styles.toolTipInput,
                                                            styles.tactic,
                                                        ].join(' ')}
                                                        value={form.notificationEmails[index]}
                                                    />
                                                    <div className={styles.wrapperBtnPlus}>
                                                        {form?.notificationEmails[index] && (
                                                            <button
                                                                title={'To remove this e-mail'}
                                                                onClick={() => {
                                                                    notificationsState.push(
                                                                        notificationsEmailListReturn.find(
                                                                            el =>
                                                                                el.email ===
                                                                                form.notificationEmails[index]
                                                                        )
                                                                    );
                                                                    form.notificationEmails =
                                                                        form.notificationEmails?.filter(
                                                                            el => el !== form.notificationEmails[index]
                                                                        );
                                                                    setForm({ ...form });
                                                                }}
                                                            >
                                                                <i className="k-icon k-i-delete" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {notificationsState.length > 0 ? (
                                        <div className={[styles.wrapperFields, styles.tactic].join(' ')}>
                                            <AutoComplete
                                                id="select-notification-email"
                                                className={[styles.dropdownFieldFull, styles.fieldHolderToolTip].join(
                                                    ' '
                                                )}
                                                defaultValue={{ value: 0, label: 'Select E-mail' }}
                                                value={email}
                                                onChange={value => setEmail(value)}
                                                options={notificationsState?.map(el => ({
                                                    value: el.email,
                                                    label: el.email,
                                                }))}
                                                autoComplete={false}
                                            />
                                            <div className={styles.wrapperBtnPlus}>
                                                <button
                                                    className={email === 0 ? styles.disabled : ''}
                                                    title={'Add e-mail'}
                                                    disabled={email === 0}
                                                    onClick={() => {
                                                        form.notificationEmails?.push(email);
                                                        setNotificationsState(
                                                            notificationsState.filter(i => i.email !== email)
                                                        );
                                                        setForm({ ...form });
                                                        setEmail(0);
                                                    }}
                                                >
                                                    <i className="k-icon k-i-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        form?.notificationEmails?.length === 0 && (
                                            <div className={styles.warningMsg}>
                                                <span className={'k-icon k-i-warning .k-i-exception'}></span>
                                                <p>
                                                    There is no <b>E-mail list</b> to show to the customer.
                                                </p>
                                            </div>
                                        )
                                    )}
                                    <div className={styles.warnningMessage}>
                                        You can receive error or partial error messages in your e-mail to understand
                                        what happened and run the process again.
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                    {/* Action Buttons */}
                    <div className={styles.wrapperActions}>
                        <span className={styles.warningText}>
                            <b>Attention:</b> Fill in all required <b>(*)</b> fields to proceed and at least one stage
                            mapping is required
                        </span>
                        <button
                            className={validation ? [styles.confirmBtn, styles.disabled].join(' ') : styles.confirmBtn}
                            title={validation ? 'Still have some fields to fill to be able to save!' : ''}
                            onClick={
                                mode === 'create'
                                    ? () => integrationSetupModalPostFunc(form)
                                    : () => integrationSetupModalPutFunc(form)
                            }
                            disabled={validation}
                        >
                            Save Integration
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default TacticBasedModal;
