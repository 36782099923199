import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { useQuery } from 'react-query';
import { queryKeys } from '../../../../react-query/constants';
import { getHistoryIntegrationSetupById } from '../../../../services/apiIntegration';
import { toast } from 'react-toastify';
import { ToolTip } from '../../../../components/index';
import styles from './IntegrationSetupHistoryGrid.module.scss';

/**
 * Proprierty for initial pagination
 */
const initialPageHistorySetup = {
    skip: 0,
    take: 8,
};
/**
 * Proprierty for initial sort
 */
const initialHistorySort = [];
/**
 * Proprierty for initial filter
 */
const initialHistoryFilter = {
    logic: 'and',
    filters: [
        //   {
        //     field: "integrationType",
        //     operator: "startswith",
        //     value: "",
        //   },
        //   {
        //     field: "integrationMethod",
        //     operator: "startswith",
        //     value: "",
        //   },
        {
            field: 'executionDate',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'userName',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'fileName',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'recordsReceived',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'recordsProcessed',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'recordsAccepted',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'recordsRejected',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'status',
            operator: 'startswith',
            value: '',
        },
    ],
};

const IntegrationSetupHistoryGrid = props => {
    const { instance } = props;
    const [dataHistory, setDataHistory] = React.useState();
    const [dataHistoryGrid, setDataHistoryGrid] = React.useState();
    const [pageHistory, setHistoryPage] = React.useState(initialPageHistorySetup);
    const [sortHistory, setHistorySort] = React.useState(initialHistorySort);
    const [filterHistory, setHistoryFilter] = React.useState(initialHistoryFilter);
    const ref = React.useRef();
    /**
     * Call the getHistoryIntegrationSetupById API using the react-query with useQuery
     */
    const { data: historyIntegrationSetupList } = useQuery(
        [queryKeys.historyIntegrationSetupList, instance],
        getHistoryIntegrationSetupById,
        {
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                props.closeBtnFunc();
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );
    /**
     * Function that controls the pagination
     * @param {*} event Propriertie that passes the element
     */
    const pageHistoryChange = event => {
        setHistoryPage(event.page);
    };
    /**
     * Function that returns the HTML of the integration type colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the integration type colunm per line on the grid
     */
    // const integtrationTypeColunm = (props) => {
    //     return (
    //     <td
    //         title={
    //         props.dataItem.integrationType ? props.dataItem.integrationType : "--"
    //         }
    //     >
    //         {props.dataItem.integrationType ? props.dataItem.integrationType : "--"}
    //     </td>
    //     );
    // };
    /**
     * Function that returns the HTML of the integration method colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the integration method colunm per line on the grid
     */
    // const integrationMethodColunm = (props) => {
    //     return (
    //     <td
    //         className={styles.alignCenter}
    //         title={
    //         props.dataItem.integrationMethod ? props.dataItem.integrationMethod : "--"
    //         }
    //     >
    //         {props.dataItem.integrationMethod ? props.dataItem.integrationMethod : "--"}
    //     </td>
    //     );
    // };
    /**
     * Function that returns the HTML of the status colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the status colunm per line on the grid
     */
    // const statusColunm = (props) => {
    //     return (
    //     <td
    //         className={styles.alignCenter}
    //         title={props.dataItem.status ? props.dataItem.status : "--"}
    //     >
    //         {props.dataItem.status ? props.dataItem.status : "--"}
    //     </td>
    //     );
    // };
    /**
     * Function that returns the HTML of the is forced sync colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is forced sync colunm per line on the grid
     */
    // const isForcedSyncColunm = (props) => {
    //     return (
    //     <td
    //         className={styles.alignCenter}
    //         title={props.dataItem.isForceSync ? props.dataItem.isForceSync ? "True" : "False" : "--"}
    //     >
    //         {props.dataItem.isForceSync ? props.dataItem.isForceSync ? "True" : "False" : "--"}
    //     </td>
    //     );
    // };
    /**
     * Function that returns the HTML of the user name colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the user name colunm per line on the grid
     */
    const userNameColunm = props => {
        return (
            <td title={props.dataItem.userName ? props.dataItem.userName : ''}>
                {props.dataItem.userName ? props.dataItem.userName : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the file name colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the file name colunm per line on the grid
     */
    const fileNameColunm = props => {
        return (
            <td title={props.dataItem.fileName ? props.dataItem.fileName : ''}>
                {props.dataItem.fileName ? props.dataItem.fileName : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the execution date colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is forced sync colunm per line on the grid
     */
    const executionDateColunm = props => {
        const executionDate = props.dataItem.executionDate
            ? props.dataItem.executionDate.split('T')[0] +
              ' ' +
              props.dataItem.executionDate.split('T')[1].substring(0, 5)
            : '--';
        return (
            <td title={props.dataItem.executionDate ? executionDate : ''}>
                {props.dataItem.executionDate ? executionDate : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the records receive colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is records receive colunm per line on the grid
     */
    const recordsReceiveColunm = props => {
        return (
            <td title={props.dataItem.recordsReceived ? props.dataItem.recordsReceived : ''}>
                {props.dataItem.recordsReceived ? props.dataItem.recordsReceived : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the records processed colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is records processed colunm per line on the grid
     */
    const recordsProcessedColunm = props => {
        return (
            <td title={props.dataItem.recordsProcessed ? props.dataItem.recordsProcessed : ''}>
                {props.dataItem.recordsProcessed ? props.dataItem.recordsProcessed : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the records accepted colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is records accepted colunm per line on the grid
     */
    const recordsAcceptedColunm = props => {
        return (
            <td title={props.dataItem.recordsAccepted ? props.dataItem.recordsAccepted : ''}>
                {props.dataItem.recordsAccepted ? props.dataItem.recordsAccepted : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the records rejected colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the is records rejected colunm per line on the grid
     */
    const recordsRejectedColunm = props => {
        return (
            <td title={props.dataItem.recordsRejected ? props.dataItem.recordsRejected : ''}>
                {props.dataItem.recordsRejected ? props.dataItem.recordsRejected : '--'}
            </td>
        );
    };
    /**
     * Function that returns the HTML of the message colunm for each line on the grid
     * @param {*} props Proprierty that passes each object per line on the grid
     * @returns Returns the html of the message colunm per line on the grid
     */
    const statusColunm = props => {
        /**
         * @todo include the nested property {props.dataItem.message}
         */
        if ((props.dataItem.status === 'Error' || props.dataItem.status === 'Partial') && props.dataItem.status) {
            return props.dataItem?.messageLink ? (
                <td>
                    <ToolTip
                        message={props.dataItem.errorMessage ? props.dataItem.errorMessage : 'No message provided'}
                    >
                        <a
                            href={props.dataItem?.messageLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            <div className={props.dataItem.status === 'Error' ? styles.redCont : styles.orangeCont}>
                                {props.dataItem.status ? props.dataItem.status : '--'}
                            </div>
                        </a>
                    </ToolTip>
                </td>
            ) : (
                <td>
                    <ToolTip
                        message={props.dataItem.errorMessage ? props.dataItem.errorMessage : 'No message provided'}
                    >
                        <div className={props.dataItem.status === 'Error' ? styles.redCont : styles.orangeCont}>
                            {props.dataItem.status ? props.dataItem.status : '--'}
                        </div>
                    </ToolTip>
                </td>
            );
        } else if (props.dataItem.status === 'Success' && props.dataItem.status) {
            return (
                <td>
                    <div className={styles.greenCont}>{props.dataItem.status ? props.dataItem.status : '--'}</div>
                </td>
            );
        } else if (props.dataItem.status === 'In Progress' && props.dataItem.status) {
            return (
                <td>
                    <div className={styles.blueCont}>{props.dataItem.status ? props.dataItem.status : '--'}</div>
                </td>
            );
        } else {
            return (
                <td>
                    <div>--</div>
                </td>
            );
        }
    };
    /**
     * UseEffect that controls the text on the filters and the header title
     */
    React.useEffect(() => {
        ref.current?.element.querySelectorAll('.k-textbox').forEach(el => {
            el.placeholder = 'Filter here...';
        });
        ref.current?.element.querySelectorAll('th').forEach(el => {
            el.title = el.innerText;
        });
    }, [dataHistoryGrid]);
    /**
     * UseEffect that controls the load of the integration setup list
     */
    React.useEffect(() => {
        historyIntegrationSetupList && setDataHistory(historyIntegrationSetupList);
        historyIntegrationSetupList && setDataHistoryGrid(historyIntegrationSetupList);
    }, [historyIntegrationSetupList]);
    /**
     * UseEffect that controls the filter and sort changes and go back to page one
     */
    React.useEffect(() => {
        setHistoryPage(initialPageHistorySetup);
        dataHistory && setDataHistoryGrid(filterBy(orderBy(dataHistory, sortHistory), filterHistory));
    }, [sortHistory, filterHistory, dataHistory]);
    /**
     * The HTML content of the IntegrationModal Component
     */
    return (
        <div id="historyIntegrationSetup">
            {dataHistory?.length > 0 ? (
                <Grid
                    ref={ref}
                    style={{}}
                    className={[styles.grid, 'grid'].join(' ')}
                    data={dataHistoryGrid?.slice(pageHistory.skip, pageHistory.take + pageHistory.skip)}
                    sortable={true}
                    sort={sortHistory}
                    onSortChange={e => setHistorySort(e.sort)}
                    filterable={true}
                    filter={filterHistory}
                    onFilterChange={e => setHistoryFilter(e.filter)}
                    skip={pageHistory.skip}
                    take={pageHistory.take}
                    total={dataHistoryGrid?.length}
                    pageable={dataHistory?.length > pageHistory.take ? true : false}
                    onPageChange={pageHistoryChange}
                >
                    {/* <GridColumn
                            field="integrationType"
                            title="Integration Type"
                            cell={integtrationTypeColunm}
                            filterable={true} /> */}
                    {/* <GridColumn
                            field="integrationMethod"
                            title="Integration Method"
                            cell={integrationMethodColunm}
                            filterable={true} /> */}
                    <GridColumn
                        field="executionDate"
                        title="Run Date/Time"
                        cell={executionDateColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="userName"
                        title="User"
                        cell={userNameColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="fileName"
                        title="File Name"
                        cell={fileNameColunm}
                        filterable={true}
                    />
                    {/* <GridColumn
                            field="status"
                            title="Status"
                            cell={statusColunm}
                            filterable={false} /> */}
                    {/* <GridColumn
                            field="isForceSync"
                            title="Is Force Sync"
                            cell={isForcedSyncColunm}
                            filterable={false} /> */}
                    <GridColumn
                        field="recordsReceived"
                        title="# Received"
                        cell={recordsReceiveColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="recordsProcessed"
                        title="# Processed"
                        cell={recordsProcessedColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="recordsAccepted"
                        title="# Accepted"
                        cell={recordsAcceptedColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="recordsRejected"
                        title="# Rejected"
                        cell={recordsRejectedColunm}
                        filterable={true}
                    />
                    <GridColumn
                        field="status"
                        title="Last Status"
                        cell={statusColunm}
                        filterable={true}
                    />
                </Grid>
            ) : (
                dataHistoryGrid && (
                    <div className={[styles.noList].join(' ')}>
                        <div className={styles.warningMsg}>
                            <span className="k-icon k-i-warning .k-i-exception"></span>
                            <p>
                                There is no <b>History</b> to this integration setup.
                            </p>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default IntegrationSetupHistoryGrid;
