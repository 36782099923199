import * as React from 'react';
import { useAtom } from 'jotai';
import { GridCellProps } from '@progress/kendo-react-grid';

import { Checkbox } from 'components';
import { selectedGroupsIdsAtom } from 'atons/Groups/atoms';

import { CustomFieldGroup } from 'services/attributeManagementApis';

import styles from './IdColumn.module.scss';

interface IdColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomFieldGroup;
}

const IdColumn: React.FC<IdColumnProps> = ({ id, dataItem }) => {
    const [selectedGroupsIds, setSelectedGroupsIds] = useAtom(selectedGroupsIdsAtom);

    const isChecked = React.useMemo(
        () => selectedGroupsIds.some(groupId => groupId === dataItem.id),
        [dataItem.id, selectedGroupsIds]
    );

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;
            if (checked) return setSelectedGroupsIds([...selectedGroupsIds, dataItem.id]);
            setSelectedGroupsIds(selectedGroupsIds.filter(groupId => groupId !== dataItem.id));
        },
        [selectedGroupsIds, setSelectedGroupsIds, dataItem.id]
    );

    return (
        <td id={id}>
            <div className={styles.container}>
                <Checkbox
                    id={String(dataItem.id)}
                    aria-checked={isChecked}
                    checked={isChecked}
                    onChange={onChange}
                />
            </div>
        </td>
    );
};

export default IdColumn;
