import * as React from 'react';
import { useAtomValue } from 'jotai/utils';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';

import { Button } from 'components';

import styles from './IsDeletedColumn.module.scss';
import DeleteSingleItemModal from './DeleteSingleItemModal/DeleteSingleItemModal';
import { usePatchCustomFieldPickList } from 'react-query/mutations';

const IsDeletedColumn = ({ dataItem, id }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const { name: AttributeName } = useAtomValue(attributeSettingsAtom);
    const mode = !!dataItem.id ? 'edit' : 'create';

    const openConfirmationModal = () => setIsDeleteModalOpen(true);
    const closeConfirmationModal = () => setIsDeleteModalOpen(false);

    const { mutate: patchCustomFieldPicklist } = usePatchCustomFieldPickList();

    const retrieveItem = () =>
        patchCustomFieldPicklist({
            id: dataItem.id,
            value: dataItem.value,
            colorCode: dataItem.colorCode,
            isDefault: dataItem.isDefault,
            isDeleted: 'No',
            sortOrder: dataItem.sortOrder,
        });

    const deleteItem = () =>
        patchCustomFieldPicklist({
            id: dataItem.id,
            value: dataItem.value,
            colorCode: dataItem.colorCode,
            isDefault: dataItem.isDefault,
            isDeleted: 'Yes',
            sortOrder: dataItem.sortOrder,
        });

    const onClickConfirmChanges = dataItem.isDeleted === 'Yes' ? retrieveItem : deleteItem;

    if (mode === 'create')
        return (
            <td
                id={id}
                style={{ top: 0, bottom: 0 }}
                className="k-grid-row-sticky"
            >
                <Button
                    text="No"
                    className={styles.buttonBaseStyle}
                    disabled
                />
            </td>
        );

    return (
        <td id={id}>
            {dataItem.isDeleted === 'Yes' ? (
                <Button
                    text="Yes"
                    title="Click here if you want to reactivate this item"
                    className={styles.buttonBaseStyle}
                    onClick={openConfirmationModal}
                />
            ) : (
                <Button
                    text="No"
                    title="Click here if you want to delete this item"
                    className={styles.buttonBaseStyle}
                    onClick={openConfirmationModal}
                />
            )}

            <DeleteSingleItemModal
                buttonLabel={dataItem.isDeleted === 'Yes' ? 'Reactivate' : 'Delete'}
                closeBtnFunc={closeConfirmationModal}
                isOpen={isDeleteModalOpen}
                onConfirm={onClickConfirmChanges}
                title={dataItem.isDeleted === 'Yes' ? 'Reactivate Item' : 'Delete Item'}
            >
                Are you sure that you want to {dataItem.isDeleted === 'Yes' ? 'reactivate' : 'delete'}
                &nbsp;the&nbsp;<b>{dataItem.value}</b> item from {AttributeName} attribute?
            </DeleteSingleItemModal>
        </td>
    );
};

export default IsDeletedColumn;
