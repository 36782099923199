import * as React from 'react';

import { useAutoCompleteContext } from './AutoCompleteContext';

import { a11yKeyBoardEventHandler } from '../../../shared/KeyboradKey';

import styles from './AutoCompleteOptions.module.scss';

function AutoCompleteOptions({ autoCompleteRef }, ref) {
    const {
        isOptionsVisible,
        selectedValue,
        onSelectOptionValue,
        availableOptions,
        submitValueInput,
        isAutoComplete,
        focousSearchInput,
        closeAutoCompleteOptions,
        defaultValue,
    } = useAutoCompleteContext();
    const autoCompleteOptionsRef = React.useRef();
    const ulRef = React.useRef();
    const [containerStyle, setContainerStyle] = React.useState({});

    /**
     * Hook that provide a function called 'focusUlFirstChild' to the fowardRef provided by parent.
     */
    React.useImperativeHandle(ref, () => ({
        focusUlFirstChild: () => ulRef.current?.firstChild?.focus(),
    }));

    /**
     * Event listener that will be triggered when clicking outside the menu options container
     * @returns {void}
     */
    const handleClickOutsideOptionsMenu = React.useCallback(
        event => {
            event.preventDefault();
            const hasClickedOnSelectInput =
                autoCompleteRef.current !== null && autoCompleteRef.current.contains(event.target);
            // If the autoComplete property is set to true, when user click on the input it should focus the input
            if (isAutoComplete && hasClickedOnSelectInput) {
                return focousSearchInput();
            }
            // If the user clicks on the input but it's not a autocomplete, the options list should be closed
            if (hasClickedOnSelectInput) {
                event.stopPropagation();
                return closeAutoCompleteOptions();
            }
            // If the user clicks outside the option list it should try to submit the input value
            if (autoCompleteOptionsRef.current !== null && !autoCompleteOptionsRef.current?.contains(event.target)) {
                submitValueInput();
            }
        },
        [submitValueInput, isAutoComplete, focousSearchInput, autoCompleteRef, closeAutoCompleteOptions]
    );

    /**
     * Side Effect that add the listener for outside clicks of menu options container
     * @returns {void}
     */
    React.useEffect(() => {
        if (!isOptionsVisible) return;
        document.addEventListener('click', handleClickOutsideOptionsMenu, true);
        return () => {
            document.removeEventListener('click', handleClickOutsideOptionsMenu, true);
        };
    }, [handleClickOutsideOptionsMenu, isOptionsVisible]);

    /**
     * Side Effect that calculate the dropdown position and height.
     * @returns {void}
     */
    React.useEffect(() => {
        if (autoCompleteOptionsRef.current?.parentNode?.getBoundingClientRect()) {
            const { height } = autoCompleteOptionsRef.current.parentNode.getBoundingClientRect();
            const itemHeight = 36;
            const optionsHeight = !!defaultValue
                ? itemHeight + itemHeight * availableOptions.length
                : itemHeight * availableOptions.length;

            const itemPaddings = 6;
            const listHeight = optionsHeight ? optionsHeight + itemPaddings : itemHeight + itemPaddings;
            const maxListHeight = 200;
            const shouldRenderListOnTop =
                maxListHeight + autoCompleteOptionsRef.current.getBoundingClientRect().top > window.innerHeight;
            const getRenderTopPosition = () => (listHeight > maxListHeight ? -maxListHeight : -listHeight);
            return setContainerStyle({
                top: shouldRenderListOnTop ? getRenderTopPosition() : height,
                height: listHeight,
                shouldRenderListOnTop,
            });
        }
        return setContainerStyle({});
    }, [isOptionsVisible, selectedValue?.label, availableOptions, defaultValue]);

    /**
     * Function that scroll to the provided position
     * @param {number} top Position that tells the scroll to be.
     * @param {'auto' | 'smooth'} behavior Behavior of the scroll
     * @returns {void}
     */
    const ulElementScrollTo = (top = 0, behavior = 'auto') => ulRef.current.scrollTo({ top, behavior });

    /**
     * Function that returns the element position on the list
     * @param {React.LiHTMLAttributes<HTMLLIElement>} element
     * @returns {number} Position of the element
     */
    const getSelectedElementYPosition = element => element.offsetHeight - element.offsetHeight * 2;

    /**
     * Function that focus a option on the list
     */
    const focusSelectedElement = React.useCallback(() => {
        const currentSelectedOption = [...ulRef.current.childNodes.values()].find(
            ({ dataset }) => dataset['value'] === selectedValue?.value
        );

        if (!currentSelectedOption) return;

        const elementYPosition = getSelectedElementYPosition(currentSelectedOption);

        ulElementScrollTo(elementYPosition);
        currentSelectedOption.focus();
    }, [selectedValue?.value]);

    /**
     * Callback function that it's called when the list get expanded
     */
    const onOpenListOptions = React.useCallback(() => {
        if (selectedValue?.value) {
            return focusSelectedElement();
        }
        focousSearchInput();
    }, [selectedValue?.value, focousSearchInput, focusSelectedElement]);

    /**
     * Side effect that call the 'onOpenListOptions' when the list get expanded
     */
    React.useEffect(() => {
        if (isOptionsVisible && ulRef.current) {
            onOpenListOptions();
        }
    }, [isOptionsVisible, onOpenListOptions]);

    /**
     * Function that returns the component container css classNames
     * @returns {string}
     */
    const getContainerClassNames = () => {
        const classNames = [styles.optionsContainer];
        if (containerStyle.shouldRenderListOnTop) {
            classNames.push(styles.renderTop);
        }
        if (isOptionsVisible) {
            classNames.push(styles.optionsVisible);
        }
        return classNames.join(' ');
    };

    /**
     * Function that returns the option className value
     * @param {any} optionValue Option value
     * @returns {string}
     */
    const getOptionClassName = optionValue => {
        const classes = [styles.optionLI];
        if (optionValue === selectedValue?.value) classes.push(styles.selected);
        return classes.join(' ');
    };

    /**
     * Function that select the previous sibling element if exists
     * @returns {void}
     */
    const selectPreviousElement = () => {
        const currentSelectedValue = document.activeElement;
        const previousSibling = currentSelectedValue.previousSibling;
        if (!previousSibling) return;
        return previousSibling.focus();
    };

    /**
     * Function that select the next sibling element if exists
     * @returns {void}
     */
    const selectNextElement = () => {
        const currentSelectedValue = document.activeElement;
        const nextSibling = currentSelectedValue.nextSibling;
        if (!nextSibling) return;
        return nextSibling.focus();
    };

    /**
     * Function that listen for the keyDown event in order to select a next or previous element.
     * @returns {void}
     */
    const onKeyDown = event => {
        event.preventDefault();
        const ArrowUp = 38;
        const ArrowDown = 40;
        const Enter = 'Enter';
        if (event.keyCode === ArrowUp || event.which === ArrowUp) return selectPreviousElement();
        if (event.keyCode === ArrowDown || event.which === ArrowDown) return selectNextElement();
        if (event.key === Enter || event.which === 13) return onSelectOptionValue(event);
        focousSearchInput();
    };

    return (
        <div
            ref={autoCompleteOptionsRef}
            style={{ top: containerStyle.top, height: containerStyle.height }}
            className={getContainerClassNames()}
            aria-expanded={isOptionsVisible}
        >
            <ul
                className={styles.optionsUL}
                role="listbox"
                ref={ulRef}
            >
                {!!defaultValue && (
                    <li
                        title={defaultValue.label}
                        tabIndex={0}
                        role="option"
                        aria-selected={selectedValue?.value === defaultValue.value}
                        className={getOptionClassName(defaultValue.value)}
                        data-value={defaultValue.value}
                        onClick={onSelectOptionValue}
                        onKeyDown={onKeyDown}
                    >
                        {defaultValue.label}
                    </li>
                )}
                {availableOptions.map(({ label, value }) => (
                    <li
                        key={value}
                        title={label}
                        tabIndex={0}
                        role="option"
                        aria-selected={selectedValue?.value === value}
                        className={getOptionClassName(value)}
                        data-value={value}
                        onClick={onSelectOptionValue}
                        onKeyDown={onKeyDown}
                    >
                        {label}
                    </li>
                ))}
                {!availableOptions.length && (
                    <li
                        className={styles.optionLI}
                        title="There is no option for that filter"
                        data-value=""
                        onClick={onSelectOptionValue}
                        onKeyUp={a11yKeyBoardEventHandler(onSelectOptionValue)}
                        aria-selected={selectedValue?.value === ''}
                        role="option"
                        tabIndex={0}
                    >
                        No option found
                    </li>
                )}
            </ul>
        </div>
    );
}

export default React.forwardRef(AutoCompleteOptions);
