import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import { alphabeticalOrder, formatPascalCaseIntoRegularText } from 'shared/helpers';

import TagList from '../TagList/TagList';
import OptionList from '../OptionList/OptionList';
import OptionListWithCheckbox from '../OptionListWithCheckbox/OptionListWithCheckbox';
import { TextInput, VirtualizedList } from 'components';

import {
    selectedParentAtom,
    checkBoxListTotalAtom,
    nonCheckBoxListTotalAtom,
    parentOptionsSearchTextAtom,
    possibleParentsListAtom,
    singleValueSelectedAtom,
} from 'atons/AttributeManagement/dependencyAtoms';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';

import AttributeTypes, { AttributeType } from 'enums/AttributeTypes';
import { useGetCustomFieldTypes } from 'react-query/queries';
import { useGetEntityTypePossibleOptions } from 'react-query/queries';

import useGetParentPicklistOptions from 'react-query/queries/useGetParentPicklistOptions';

import styles from './ParentAttributeOptionList.module.scss';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';

type OptionRenderType = null | 'withCheckbox' | 'withoutCheckbox';

const ParentAttributeOptionList: React.FC = () => {
    const selectedParent = useAtomValue(selectedParentAtom);
    const possibleParentsList = useAtomValue(possibleParentsListAtom);
    const { attributeTypeId: currentAttributeTypeId, id } = useAtomValue(attributeSettingsAtom);
    const [parentOptionsSearchText, setParentOptionsSearchText] = useAtom(parentOptionsSearchTextAtom);
    const setSingleValueSelected = useUpdateAtom(singleValueSelectedAtom);

    const setNonCheckBoxListTotal = useUpdateAtom(nonCheckBoxListTotalAtom);
    const setCheckBoxListTotal = useUpdateAtom(checkBoxListTotalAtom);

    const { data: getParentPicklistOptions, refetch: parentPicklistOptions } = useGetParentPicklistOptions(
        Number(selectedParent)
    );

    const { data: getEntityTypePossibleParentOptions, refetch: entityTypePossibleParentOptions } =
        useGetEntityTypePossibleOptions(Number(id), Number(selectedParent));

    const { data: customFieldTypes = [] } = useGetCustomFieldTypes();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setParentOptionsSearchText(event.target.value);

    const shouldRenderWithCheckbox = React.useCallback((): OptionRenderType => {
        if (!(customFieldTypes.length > 0)) return null;
        const currentAttributeType = customFieldTypes.find(item => item.id === Number(currentAttributeTypeId))?.name;
        if (!currentAttributeType)
            throw new Error(`Could not find any customFieldType with id ${currentAttributeTypeId}`);
        const typesThatShouldRenderWithoutCheckbox = [AttributeTypes.DropDownList];
        return typesThatShouldRenderWithoutCheckbox.includes(
            AttributeTypes.getByName(currentAttributeType as AttributeType)
        )
            ? 'withoutCheckbox'
            : 'withCheckbox';
    }, [currentAttributeTypeId, customFieldTypes]);

    const possibleParentsWithTacticType = [
        ...possibleParentsList,
        { id: -1, attributeTypeId: -1, name: 'Tactic Type', entityTypeId: -1 },
    ];

    const data = React.useMemo(() => {
        if (selectedParent === -1) {
            return (
                getEntityTypePossibleParentOptions &&
                (alphabeticalOrder(getEntityTypePossibleParentOptions, 'value')
                    ?.map(item => {
                        return { id: item.id, name: item.value, key: item.id, isSelected: false };
                    })
                    ?.filter(value =>
                        value.name.toLowerCase().includes(String(parentOptionsSearchText).toLowerCase())
                    ) as any)
            );
        }

        return (
            getParentPicklistOptions &&
            (alphabeticalOrder(getParentPicklistOptions, 'value')
                ?.map(item => {
                    return { id: item.id, name: item.value, key: item.id, isSelected: false };
                })
                ?.filter(value =>
                    value.name.toLowerCase().includes(String(parentOptionsSearchText).toLowerCase())
                ) as any)
        );
    }, [selectedParent, parentOptionsSearchText, getParentPicklistOptions, getEntityTypePossibleParentOptions]);

    React.useEffect(() => {
        const runEffect = () => {
            if (selectedParent === -1) {
                if (
                    getEntityTypePossibleParentOptions &&
                    getEntityTypePossibleParentOptions?.length > 0 &&
                    shouldRenderWithCheckbox() === 'withCheckbox'
                ) {
                    const formattedData = CustomFieldsAdapter.tacticTypeOptionListToPicklistOptions(
                        getEntityTypePossibleParentOptions
                    );

                    setCheckBoxListTotal(formattedData);
                    return setNonCheckBoxListTotal(formattedData);
                }

                if (getEntityTypePossibleParentOptions) {
                    const formattedData = CustomFieldsAdapter.tacticTypeOptionListToPicklistOptions(
                        getEntityTypePossibleParentOptions
                    );
                    setNonCheckBoxListTotal(formattedData);
                    return setSingleValueSelected(formattedData[0]?.id);
                }
            }

            if (
                getParentPicklistOptions &&
                getParentPicklistOptions?.length > 0 &&
                shouldRenderWithCheckbox() === 'withCheckbox'
            ) {
                setCheckBoxListTotal(getParentPicklistOptions);
                return setNonCheckBoxListTotal(getParentPicklistOptions);
            }

            if (getParentPicklistOptions) {
                setNonCheckBoxListTotal(getParentPicklistOptions);
                return setSingleValueSelected(getParentPicklistOptions[0]?.id);
            }
        };
        runEffect();
    }, [
        getEntityTypePossibleParentOptions,
        getParentPicklistOptions,
        selectedParent,
        setCheckBoxListTotal,
        setNonCheckBoxListTotal,
        setSingleValueSelected,
        shouldRenderWithCheckbox,
    ]);

    React.useEffect(() => {
        selectedParent !== -1 && parentPicklistOptions();
        selectedParent === -1 && entityTypePossibleParentOptions();
    }, [selectedParent, parentPicklistOptions, entityTypePossibleParentOptions]);

    return (
        <>
            {((data && data.length > 0) || parentOptionsSearchText !== '') && (
                <div className={styles.container}>
                    <div className={styles.subTitle}>
                        <span>
                            Name: {possibleParentsWithTacticType?.find(parent => parent.id === selectedParent)?.name}
                        </span>

                        <span className={styles.type}>
                            {formatPascalCaseIntoRegularText(
                                customFieldTypes?.find(
                                    type =>
                                        type.id ===
                                        possibleParentsWithTacticType?.find(item => item.id === Number(selectedParent))
                                            ?.attributeTypeId
                                )?.name
                            )}
                        </span>
                    </div>

                    <TextInput
                        placeholder="Search"
                        kendoIcon="k-i-zoom"
                        value={String(parentOptionsSearchText)}
                        onChange={onChange}
                    />

                    {shouldRenderWithCheckbox() === 'withCheckbox' ? (
                        <TagList />
                    ) : (
                        <div className={styles.tagWrapper}></div>
                    )}

                    <VirtualizedList
                        data={data}
                        itemHeight={30}
                        render={props =>
                            shouldRenderWithCheckbox() === null ? null : shouldRenderWithCheckbox() ===
                              'withCheckbox' ? (
                                <OptionListWithCheckbox {...props} />
                            ) : (
                                <OptionList {...props} />
                            )
                        }
                    />
                </div>
            )}

            {data && data.length === 0 && parentOptionsSearchText === '' && (
                <div className={styles.warning}>
                    <span className={['k-icon k-i-warning icon-alert', styles.icon].join(' ')}></span>
                    <h2>Warning</h2>
                    <span>
                        This parent doesn't have options
                        <br /> to make relationships.
                    </span>
                </div>
            )}
        </>
    );
};

export default ParentAttributeOptionList;
