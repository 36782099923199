import * as React from 'react';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import {
    selectedAttributeToDeleteOrReactivateAtom,
    parentDependencyModalAtom,
    parentDependencyMessageAtom,
} from 'atons/AttributeManagement/atoms';

import { Button, Modal } from 'components';

import styles from './ToggleDeleteAttributeModal.module.scss';
import { usePatchToggleDeleteAttribute } from 'react-query/mutations';

const ToggleDeleteAttributeModal: React.FC = () => {
    const setParentDepMessage = useUpdateAtom(parentDependencyMessageAtom);
    const setParentDepModal = useUpdateAtom(parentDependencyModalAtom);

    const [selectedAttributeToDeleteOrReactivate, setSelectedAttributeToDeleteOrReactivate] = useAtom(
        selectedAttributeToDeleteOrReactivateAtom
    );

    /**
     * Call the toggleDeleteAttribute API using the react-query with useQuery
     */
    const { mutate: toggleDeleteAttibute } = usePatchToggleDeleteAttribute({
        onError: (error: any) => {
            if (error?.response?.data?.errors && error.response.data.errors?.length > 0) {
                setParentDepMessage(error.response.data.errors);
                setParentDepModal({ ...error.response.data.errors[0], index: 0 });
                return;
            }
        },
    });

    /**
     * Function that closes the modal
     */
    const closeDeleteToogleModal = () => setSelectedAttributeToDeleteOrReactivate();

    return (
        <Modal
            closeBtn
            className={styles.deleteToggleModal}
            title={
                selectedAttributeToDeleteOrReactivate?.isDeleted === 'No' ? 'Delete Attribute' : 'Reactivate Attribute'
            }
            closeBtnFunc={closeDeleteToogleModal}
            show={selectedAttributeToDeleteOrReactivate}
        >
            <p>
                Are you sure that you want to{' '}
                {selectedAttributeToDeleteOrReactivate?.isDeleted === 'No' ? 'delete' : 'reactivate'} the{' '}
                <b>{selectedAttributeToDeleteOrReactivate?.name}</b> attribute?
            </p>

            <div className={styles.buttonCont}>
                <Button
                    text={selectedAttributeToDeleteOrReactivate?.isDeleted === 'No' ? 'Delete' : 'Reactivate'}
                    onClick={() => toggleDeleteAttibute(selectedAttributeToDeleteOrReactivate?.id)}
                />
            </div>
        </Modal>
    );
};

export default ToggleDeleteAttributeModal;
