import { isEmpty } from 'lodash';

export function numberOnlyField(value, enableBeginWithZero = false) {
    if (enableBeginWithZero && typeof enableBeginWithZero === 'boolean') {
        const str = value
            .replace(/\D+/g, '') //only numbers
            .replace(/[`~!@#$%^&*()_|+\-=?;:¨´'",.<>{}[\]\\/]/gi, ''); // remove all especial characters
        return str;
    }
    const str = value
        .replace(/\D+/g, '') //only numbers
        .replace(/^0+/, '') //do not begin with zero
        .replace(/[`~!@#$%^&*()_|+\-=?;:¨´'",.<>{}[\]\\/]/gi, ''); // remove all especial characters
    return str;
}

export function removeEspecialCharacField(value) {
    const str = value.replace(/[`~!@#$%^&*()|+=?;:¨´'",.<>{}[\]\\/]/gi, ''); // remove all especial characters
    return str;
}

/**
 * Function that convert file to base64
 * @param {*} fl Proprierty that passes the file
 * @returns The base64 version of the file
 */
export function convertFileToBase64(fl) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(fl);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
}

export function toNumber(value) {
    const result = Number(value);
    return Number.isNaN(result) ? null : result;
}

export function convertNullAndUndefinedToString(value) {
    if (typeof value === 'undefined' || value === null) return '';
    return value;
}

export function formatPascalCaseIntoRegularText(text) {
    if (!text) return;
    const [firstLetter, ...otherLetters] = text;
    const otherLettersString = otherLetters.join('');
    return `${firstLetter.toUpperCase()}${otherLettersString.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
}

export function addSpaceBetweenCammelCase(text) {
    if (typeof text !== 'string') return text;
    return text.replace(/([A-Z])/g, ' $1');
}

/**
 *
 * @template T
 * @param {T[]} list list of elements
 * @param {string} textName property name
 * @returns {T[]}
 */
export function alphabeticalOrder(list, textName) {
    return list.sort(function (a, b) {
        if (a[textName].toLowerCase() < b[textName].toLowerCase()) {
            return -1;
        } else if (a[textName].toLowerCase() > b[textName].toLowerCase()) {
            return 1;
        }
        return 0;
    });
}

/**
 * This function converts a object into a URLSearchParams string.
 * @param {Record<string, any>} parameters Parameters object to be converted into url search params.
 * @returns {string | null}
 */
export function buildURLSearchParams(parameters) {
    if (parameters === undefined) return null;
    if (isEmpty(parameters)) return null;
    const urlSearchParams = new URLSearchParams(parameters);
    return '?'.concat(urlSearchParams.toString());
}
