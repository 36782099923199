import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';

import { useToast } from 'customHooks/useToast';

import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';

import { CustomFieldDetailResponse, getCustomFieldDetail } from 'services/attributeManagementApis';

const useGetCustomFieldDetail = (
    selectedCustomFieldId: number,
    options?: ReactQueryHookOption<CustomFieldDetailResponse>
) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();
    return useQuery([queryKeys.customFieldDetail, customer!, selectedCustomFieldId], getCustomFieldDetail, {
        enabled: false,
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
        onSuccess: data => {
            if (options?.onSuccess) options.onSuccess(data);
        },
    });
};

export default useGetCustomFieldDetail;
