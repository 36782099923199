import { useAtom, useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { customerAtom } from 'atons/Customer/atoms';
import { deleteCustomFieldPickListItems } from 'services/attributeManagementApis';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import { useGetCustomFieldDetail } from 'react-query/queries';

const useBulkDeleteCustomFieldPicklistItem = (options?: ReactQueryHookOption<unknown>) => {
    const [{ id: customFieldId }, setAttributeSettings] = useAtom(attributeSettingsAtom);
    const customer = useAtomValue(customerAtom);
    const onError = useSetErrorMessage();

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(customFieldId as number, {
        onSuccess: data => setAttributeSettings(CustomFieldsAdapter.toAttributeSetting(data)),
    });

    return useMutation(
        (selectedPicklistIds: number[]) =>
            deleteCustomFieldPickListItems(
                customer!.clientId,
                customFieldId,
                CustomFieldsAdapter.deletePicklistItems(selectedPicklistIds)
            ),
        {
            onSuccess: data => {
                if (options && options.onSuccess) options.onSuccess(data);
                queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
                queryClient.invalidateQueries([queryKeys.customFieldDetail]);
                fetchCustomFieldDetail();
            },
            onError: error => {
                onError(error);
            },
        }
    );
};

export default useBulkDeleteCustomFieldPicklistItem;
