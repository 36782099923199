import * as React from 'react';
import { useAuth } from 'oidc-react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAtomValue } from 'jotai/utils';
import { queryClient } from '../../../react-query/queryClient';
import { queryKeys } from '../../../react-query/constants';
import { updateCustomerAssociation } from '../../../services/apiIntegration';

import { Modal, Button } from '../../../components';
import AssociationGrid from './CustomerAssociationGrid/CustomerAssociationGrid';

import styles from './CustomerAssociation.module.scss';
import { customerAtom, customerListAtom } from 'atons/Customer/atoms';

const CustomerAssociation = ({ children }) => {
    const [integrationList, setIntegrationList] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const customer = useAtomValue(customerAtom);
    const customerList = useAtomValue(customerListAtom);
    const auth = useAuth();

    /**
     * Call the updateCustomerAssociation API using the react-query with useQuery
     */
    const { mutate } = useMutation(
        integrations => {
            return updateCustomerAssociation(customer, integrations);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.customerAssociations]);
                toast.success('Save Successfully!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
            retry: 3,
        }
    );

    const update = () => {
        setShow(false);
        const selectedIntegrations = {
            integrations: [],
        };
        integrationList.forEach(intItem => {
            selectedIntegrations.integrations.push({
                integrationId: intItem.integrationId,
            });
        });
        mutate(selectedIntegrations);
    };

    const openModal = () => {
        setShow(true);
    };

    const closeModal = () => {
        setShow(false);
    };

    const Content = (
        <>
            {/* Modal for corfimation */}
            <Modal show={show}>
                <div className={styles.question}>
                    Are you sure that you want to change the associations of
                    <span>
                        {' '}
                        {customer &&
                            customerList &&
                            customerList.length > 0 &&
                            customerList.find(cus => cus.clientId === customer.clientId).name}
                    </span>
                    ?
                </div>
                <div className={styles.btnCont}>
                    <Button
                        text={'Ok'}
                        onClick={update}
                    />
                    <Button
                        btnType={'cancel'}
                        text={'Cancel'}
                        onClick={closeModal}
                    />
                </div>
            </Modal>
            <div className={'pageTitleCont'}>
                <h2 className={'title-main'}>
                    Manage your{' '}
                    {typeof auth.userData.profile.role !== 'undefined' ? (
                        <span className={'title-section-marker'}>
                            Customer Integrations
                            {customer && customerList && ': '}
                            {customer && customerList && (
                                <span className={'title-section-marker'}>
                                    {customerList.length > 0 &&
                                        customerList.find(cus => cus.clientId === customer.clientId).name}
                                </span>
                            )}
                        </span>
                    ) : (
                        <span className={'title-section-marker'}>
                            Customer Integration:{' '}
                            <span className={'title-section-marker'}>{auth.userData.profile.clientName}</span>
                        </span>
                    )}
                </h2>
                {integrationList.length > 0 && (
                    <Button
                        text={'Save'}
                        width={'100px'}
                        className={styles.saveBtn}
                        onClick={openModal}
                    />
                )}
            </div>
            {customer === null ? (
                <div className={styles.warningText}>
                    <span className={['k-icon', 'k-i-warning', 'icon-alert'].join(' ')}></span>
                    <p className={''}>
                        <b>Please,</b> select a customer from the menu above to view{' '}
                        <span className={'integrations-word'}>integrations</span>
                    </p>
                </div>
            ) : (
                <>
                    {integrationList && (
                        <AssociationGrid
                            integrationList={integrationList}
                            setIntegrationList={setIntegrationList}
                        />
                    )}
                    {integrationList.length > 0 && (
                        <div className={styles.buttonHolder}>
                            <Button
                                text={'Save'}
                                width={'100px'}
                                className={styles.saveBtn}
                                onClick={openModal}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );

    return <div>{children ? children : Content}</div>;
};

export default CustomerAssociation;
