import { useAtom, useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useGetCustomFieldDetail } from 'react-query/queries';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { postCustomFieldBulkPickList } from 'services/attributeManagementApis';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import { useToast } from 'customHooks/useToast';

const useBulkPostCustomFieldPickList = (options?: ReactQueryHookOption<unknown>) => {
    const [{ id: customFieldId }, setAttributeSettings] = useAtom(attributeSettingsAtom);
    const customer = useAtomValue(customerAtom);
    const onError = useSetErrorMessage();

    const toast = useToast();

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(customFieldId as number, {
        onSuccess: data => setAttributeSettings(CustomFieldsAdapter.toAttributeSetting(data)),
    });

    return useMutation(PicklistObj => postCustomFieldBulkPickList(customer!.clientId, customFieldId, PicklistObj), {
        onSuccess: data => {
            if (options && options.onSuccess) options.onSuccess(data);
            queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
            queryClient.invalidateQueries([queryKeys.customFieldDetail]);
            fetchCustomFieldDetail();
            toast.success('Options(s) saved Successfully!');
        },
        onError: error => {
            onError(error);
            if (options && options.onError) options.onError(error);
        },
        retry: 0,
    });
};

export default useBulkPostCustomFieldPickList;
