import { useAtom } from 'jotai';
import * as React from 'react';

import { Button, GroupInput } from 'components';
import { Switch } from 'components/Inputs';

import { groupDetailAtom, GroupDetail } from 'atons/Groups/atoms';
import { usePostCustomFieldGroup, usePatchCustomFieldGroup } from 'react-query/mutations';
import { useGetCustomFieldGroup } from 'react-query/queries';
import ConfirmDeleteGroupModal from './ConfirmDeleteGroupModal/ConfirmDeleteGroupModal';

type GroupDetailKey = keyof Omit<GroupDetail, 'id'>;

const GroupForm: React.FC = () => {
    const [isDeleteConfirmationDialogOpened, setIsDeleteConfirmationDialogOpened] = React.useState<boolean>(false);
    const [groupDetail, setGroupDetail] = useAtom(groupDetailAtom);

    const closeGroupDetailModal = () => {
        setGroupDetail(null);
    };

    const { mutate: postCustomFieldGroup } = usePostCustomFieldGroup({ onSuccess: closeGroupDetailModal });

    const { mutate: patchCustomFieldGroup } = usePatchCustomFieldGroup({ onSuccess: closeGroupDetailModal });

    const { data: customFieldGroupData } = useGetCustomFieldGroup(groupDetail?.id, {
        onError: closeGroupDetailModal,
    });

    const validateFormValues = (): boolean => {
        if (!groupDetail) throw new Error('Invalid form');
        if (!groupDetail.name) throw new Error("Name field it's required!");
        if (isNaN(Number(groupDetail.sortOrder))) throw new Error('Must provide a valid number for Sort Order field');
        if (Number(groupDetail.sortOrder) < 0) throw new Error("Sort Order can't be negative");
        return true;
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            if (validateFormValues()) {
                const payload = {
                    name: groupDetail!.name,
                    sortOrder: Number(groupDetail!.sortOrder),
                    isApprovalObject: Boolean(groupDetail!.isApprovalObject),
                };
                if (!!groupDetail?.id) return patchCustomFieldGroup({ id: groupDetail!.id!, ...payload });
                postCustomFieldGroup(payload);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onReset = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDeleteConfirmationDialogOpened(true);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value, type, checked },
        } = event;

        if (type === 'checkbox') {
            setGroupDetail({ ...groupDetail!, [name as GroupDetailKey]: checked });
        } else {
            setGroupDetail({ ...groupDetail!, [name as GroupDetailKey]: value });
        }
    };

    React.useEffect(() => {
        if (customFieldGroupData) {
            setGroupDetail({
                id: customFieldGroupData.id,
                name: customFieldGroupData.name,
                sortOrder: !!customFieldGroupData.sortOrder ? String(customFieldGroupData.sortOrder) : '',
                isApprovalObject: customFieldGroupData.isApprovalObject,
            });
        }
    }, [customFieldGroupData, setGroupDetail]);

    return (
        <form
            onSubmit={onSubmit}
            onReset={onReset}
            className="row"
        >
            <div className="col-xs-12 py-2">
                <GroupInput
                    label={{ text: 'Group Name:' }}
                    input={{
                        type: 'text',
                        id: 'name',
                        name: 'name',
                        value: groupDetail!.name,
                        required: true,
                        onChange,
                    }}
                />
            </div>

            <div className="col-xs-12 py-2">
                <GroupInput
                    label={{ text: 'Sort Order:' }}
                    input={{
                        type: 'number',
                        id: 'sortOrder',
                        name: 'sortOrder',
                        value: groupDetail!.sortOrder,
                        min: '0',
                        onChange,
                    }}
                />
            </div>
            <div className="col-xs-12 py-2">
                <span className="group-approval">Is Approval Object :</span>
                <Switch
                    id="isApprovalObject"
                    name="isApprovalObject"
                    title={'IsApprovalObject:'}
                    className="approval-switch"
                    checked={groupDetail?.isApprovalObject}
                    onChange={onChange}
                />
            </div>

            {groupDetail?.id && (
                <div className="col-xs-12 col-sm-6 py-3">
                    <Button
                        text="Delete Group"
                        btnType="cancel"
                        type="reset"
                        width="100%"
                    />
                </div>
            )}

            <div className={groupDetail?.id ? 'col-xs-12 col-sm-6 py-3' : 'col-xs-12 py-3'}>
                <Button
                    text="Confirm"
                    type="submit"
                    width="100%"
                />
            </div>

            <ConfirmDeleteGroupModal
                show={isDeleteConfirmationDialogOpened}
                closeBtnFunc={() => setIsDeleteConfirmationDialogOpened(false)}
            />
        </form>
    );
};

export default GroupForm;
