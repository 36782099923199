import * as React from 'react';
import { useQuery } from 'react-query';
import { getter } from '@progress/kendo-react-common';
import { toast } from 'react-toastify';
import { useAuth } from 'oidc-react';
import { useAtomValue } from 'jotai/utils';
import { getCustomerListAssociation, getIntegrationsList } from '../../../../services/apiIntegration';
import { queryKeys } from '../../../../react-query/constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import styles from './CustomerAssociationGrid.module.scss';
import { customerAtom } from 'atons/Customer/atoms';

const DATA_ITEM_KEY = 'integrationId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

/**
 * AssociationGrid
 * @param {*} props Proprierties passed for the component
 * @returns AssociationGrid component
 */
const CustomerAssociationGrid = ({ setIntegrationList, integrationList }) => {
    const [dataState, setDataState] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const customer = useAtomValue(customerAtom);
    const auth = useAuth();
    const ref = React.useRef();

    const findDateStateItem = React.useCallback(
        integrationId => dataState.find(item => item.integrationId === integrationId),
        [dataState]
    );

    const updateDataState = React.useCallback(
        (integrationId, updatedIntegration) => {
            const indexOfIntegration = dataState.findIndex(integration => integration.integrationId === integrationId);
            const newDataState = [
                ...dataState.slice(0, indexOfIntegration),
                updatedIntegration,
                ...dataState.slice(indexOfIntegration + 1),
            ];
            setDataState(newDataState);
        },
        [dataState]
    );

    /**
     * Function that fires when checkbox from list is checked/unchecked
     */
    const onSelectionChange = React.useCallback(
        event => {
            if (event.dataItem === null) return;
            if (typeof event.dataItem.selected === 'undefined' || event.dataItem.selected === false) {
                if (integrationList.some(item => item.integrationId === event.dataItem.integrationId)) return;
                const dateStateItem = { ...findDateStateItem(event.dataItem.integrationId), selected: true };
                updateDataState(dateStateItem.integrationId, dateStateItem);
                setSelectedState(dateStateItem);
                const newIntegrationList = [
                    ...integrationList,
                    {
                        integrationId: event.dataItem.integrationId,
                        title: event.dataItem.title,
                    },
                ];
                setIntegrationList(newIntegrationList);
            } else {
                const dateStateItem = { ...findDateStateItem(event.dataItem.integrationId), selected: false };
                updateDataState(dateStateItem.integrationId, dateStateItem);
                setSelectedState(dateStateItem);
                setIntegrationList(integrationList.filter(list => list.integrationId !== dateStateItem.integrationId));
            }
        },
        [integrationList, setIntegrationList, updateDataState, findDateStateItem]
    );

    /**
     * Function that fires when checkbox on the header is checked/unchecked
     */
    const onHeaderSelectionChange = React.useCallback(
        event => {
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {};
            event.dataItems.forEach(gridItem => {
                const integration = findDateStateItem(gridItem.integrationId);
                newSelectedState[integration.integrationId] = checked;
            });
            setSelectedState(newSelectedState);
            if (checked) {
                let newIntegrationList = [];
                const newDataState = event.dataItems.map(gridItem => {
                    if (!newIntegrationList.some(item => item.integrationId === gridItem.integrationId)) {
                        newIntegrationList = [
                            ...newIntegrationList,
                            { integrationId: gridItem.integrationId, title: gridItem.title },
                        ];
                    }
                    return { ...findDateStateItem(gridItem.integrationId), selected: true };
                });
                setIntegrationList(newIntegrationList);
                setDataState(newDataState);
            } else {
                setIntegrationList([]);
                const newDateState = [...dataState].map(gridItem => ({
                    ...findDateStateItem(gridItem.integrationId),
                    selected: false,
                }));
                setDataState(newDateState);
            }
        },
        [setIntegrationList, dataState, findDateStateItem]
    );

    /**
     * Call the getIntegrationsList API using the react-query with useQuery
     */
    const { data: integrationListData } = useQuery(queryKeys.integrationList, getIntegrationsList, {
        enabled: customer !== null && typeof auth.userData.profile.role !== 'undefined',
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        },
    });

    /**
     * Call the getCustomerListAssociation API using the react-query with useQuery
     */
    const { data: customerAssociations } = useQuery(
        [queryKeys.customerAssociations, customer],
        getCustomerListAssociation,
        {
            enabled:
                integrationListData?.length > 0 &&
                customer !== null &&
                typeof auth.userData.profile.role !== 'undefined',
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            },
        }
    );

    /**
     * useEffect that will observe the lists loading to set the lists variables
     */
    React.useEffect(() => {
        customerAssociations && setIntegrationList(customerAssociations);
        customerAssociations &&
            integrationListData &&
            setDataState(
                integrationListData.map(dataItem =>
                    Object.assign({
                        selected: customerAssociations.some(checks => checks.integrationId === dataItem.integrationId),
                        integrationId: dataItem.integrationId,
                        title: dataItem.title,
                    })
                )
            );
    }, [customerAssociations, integrationListData, setIntegrationList]);

    /**
     * Effect that clear the list on component unmount
     */
    React.useEffect(() => {
        return () => {
            setDataState([]);
        };
    }, []);

    return (
        <>
            {typeof auth.userData.profile.role !== 'undefined' && (
                <div className={styles.gridCont}>
                    {dataState && (
                        <Grid
                            ref={ref}
                            data={dataState.map(item => ({
                                ...item,
                                [SELECTED_FIELD]: item.selected,
                            }))}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: 'multiple',
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                        >
                            <Column
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    dataState.findIndex(item => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <Column
                                field="title"
                                title="Product Name"
                            />
                        </Grid>
                    )}
                </div>
            )}
        </>
    );
};

export default CustomerAssociationGrid;
