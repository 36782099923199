import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './DrawerMenu.module.scss';

interface DrawerMenuProps extends LinkProps {
    active?: boolean;
    iconClassName?: string;
    trailingIconClassName?: string;
    selectedClassName?: string;
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({
    to,
    active = false,
    className,
    selectedClassName = styles.selectedLink,
    iconClassName,
    trailingIconClassName,
    children,
    ...linkProps
}) => {
    const getContainerClassName = (): string => {
        const baseClassName = [styles.linkList];
        if (active) baseClassName.push(selectedClassName);
        if (className) baseClassName.push(className);
        return baseClassName.join(' ');
    };

    return (
        <Link
            to={to}
            className={getContainerClassName()}
            {...linkProps}
        >
            <i className={['k-icon', iconClassName].join(' ')} />

            <span>{children}</span>

            {trailingIconClassName && (
                <i
                    className={['k-icon', trailingIconClassName].join(' ')}
                    role="img"
                />
            )}
        </Link>
    );
};
export default DrawerMenu;
