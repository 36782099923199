import Constants from '../config/Constants';

export default class SessionStorageService {
    /**
     * Return the current user.
     * @returns {import('oidc-react').User | null}
     */
    static getUser() {
        return SessionStorageService.getItem(Constants.UserStoreKey);
    }

    /**
     * @param {string} itemKey
     */
    static getItem(itemKey) {
        const item = sessionStorage.getItem(itemKey);
        if (!item) return null;
        return JSON.parse(item);
    }
}
