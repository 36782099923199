import { Customer } from 'atons/Customer/atoms';

type AutocompleteOption = {
    label: string;
    value: string;
};

export default class CustomerAdapter {
    static customersToAutocompleteOptions(customerList: Customer[]): AutocompleteOption[] {
        return customerList.map(CustomerAdapter.toAutocompleteOption);
    }

    private static toAutocompleteOption(customer: Customer): AutocompleteOption {
        return { label: customer.name, value: customer.clientId };
    }
}
