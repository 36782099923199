import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useMutation } from 'react-query';
import { useAtomValue } from 'jotai';

import { Button, TextInput } from 'components';
import User from 'domain/model/User';

import { Customer, customerAtom } from 'atons/Customer/atoms';
import { CustomFieldDetailResponse, patchCustomFieldSortOrder } from 'services/attributeManagementApis';

import styles from './SortOrderColumn.module.scss';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';

type PatchSortPayload = { userId: string | null; sortOrder: number };

interface SortOrderColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomFieldDetailResponse;
}

const SortOrderColumn: React.FC<SortOrderColumnProps> = ({ dataItem }) => {
    const [isOnEditMode, setIsOnEditMode] = React.useState<boolean>(false);
    const customer = useAtomValue(customerAtom);
    const formRef = React.useRef<HTMLFormElement>(null);

    const stopEditing = () => setIsOnEditMode(false);

    const onPatchSuccess = () => {
        stopEditing();
        queryClient.invalidateQueries(queryKeys.attributeFieldsList);
    };

    const { mutate: patchSortOrder } = useMutation(
        ({ sortOrder, customer }: { sortOrder: string; customer: Customer }) =>
            patchCustomFieldSortOrder<PatchSortPayload>(dataItem.id, customer, {
                userId: User.getInstance()!.getUserId(),
                sortOrder: Number(sortOrder),
            }),
        {
            onSuccess: onPatchSuccess,
            onError: () => {},
        }
    );

    const isSortOrderValueValid = (value: string): boolean => value.length > 0 && !isNaN(Number(value));

    const onSubmitChange = (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) event.preventDefault();
        const formData = new FormData(formRef.current as HTMLFormElement);
        const sortOrder = formData.get('sortOrder')?.toString();
        if (!sortOrder || sortOrder === dataItem.sortOrder?.toString()) return stopEditing();
        if (sortOrder && isSortOrderValueValid(sortOrder))
            return patchSortOrder({ sortOrder: sortOrder, customer: customer! });
    };

    return (
        <td className={styles.container}>
            {isOnEditMode ? (
                <form
                    ref={formRef}
                    onSubmit={onSubmitChange}
                >
                    <TextInput
                        type="number"
                        id="sortOrder"
                        name="sortOrder"
                        defaultValue={
                            dataItem.sortOrder && dataItem.sortOrder > -1 ? dataItem.sortOrder.toString() : ''
                        }
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        onBlur={() => onSubmitChange()}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                        required
                    />
                </form>
            ) : (
                <Button
                    btnType="link"
                    type="button"
                    className={styles.sortOrderButton}
                    text={dataItem.sortOrder ?? '---'}
                    onClick={() => setIsOnEditMode(true)}
                />
            )}
        </td>
    );
};

export default SortOrderColumn;
