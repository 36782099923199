import * as React from 'react';
import { useAtom } from 'jotai';
import { useResetAtom, useAtomValue } from 'jotai/utils';
import { useMutation } from 'react-query';

import { Modal, GroupInput, Button, ErrorModal } from '../../../../../../components';
import { useToast } from '../../../../../../customHooks/useToast';

import {
    attributeSettingsAtom,
    selectedPicklistToEdit,
    PicklistItem,
} from '../../../../../../atons/AttributeManagement/atoms';

import { queryClient } from '../../../../../../react-query/queryClient';
import { queryKeys } from '../../../../../../react-query/constants';

import CustomFieldsAdapter from '../../../../../../adapters/CustomFieldsAdapter';
import { patchCustomFieldPickList } from '../../../../../../services/attributeManagementApis';

import styles from './EditPicklistItemModal.module.scss';

interface Customer {
    clientId: string;
    name: string;
}

interface EditPicklistItemModalProps {
    customer: Customer;
}

const EditPicklistItemModal: React.FC<EditPicklistItemModalProps> = ({ customer }) => {
    const [error, setError] = React.useState<{ title: string; errors: any } | null>(null);
    const { id: customFieldId, name: AttributeName } = useAtomValue(attributeSettingsAtom);
    const [selectedItem, setSelectedItem] = useAtom(selectedPicklistToEdit);
    const resetSelectedItem = useResetAtom(selectedPicklistToEdit);

    const toast = useToast();

    const onChangeInputTextField = ({ target }) =>
        setSelectedItem({ ...(selectedItem as PicklistItem), [target.name]: target.value });

    const onChangeColorField = ({ target }) =>
        setSelectedItem({ ...(selectedItem as PicklistItem), [target.name]: target.value.replace('#', '') });

    const onChangeInputSwitchField = ({ target }) => {
        const dataMap = JSON.parse(target.dataset['mapSwitches']);
        setSelectedItem({ ...(selectedItem as PicklistItem), [target.name]: dataMap[target.checked] });
    };

    /**
     * Function that shows a generic toasty error
     */
    const showGenericToastError = () => toast.error('Something went wrong, try again later.');
    /**
     * Function that handles the BadRequest error
     */
    const handleBadRequestError = ({ errors, title }) => {
        function errObjectHasHeaderAndDescriptionProperties(err) {
            return Object.hasOwn(err, 'header') && Object.hasOwn(err, 'description');
        }
        if (Object.hasOwn(errors, 'length') && errors.every(errObjectHasHeaderAndDescriptionProperties)) {
            return setError({ title, errors });
        }
        if (typeof errors === 'object' && Object.keys(errors).length > 0) {
            return setError({
                title,
                errors: Object.keys(errors).map(key => ({
                    header: '',
                    description: errors[key].join('\b'),
                })),
            });
        }
        showGenericToastError();
    };

    /**
     * Function that sent a PATCH request for picklist item.
     */
    const { mutate: patchPicklistItem } = useMutation(
        ({
            customer,
            customFieldId,
            newPicklistValues,
        }: {
            customer: Customer;
            customFieldId: any;
            newPicklistValues: any;
        }) =>
            patchCustomFieldPickList(
                customer.clientId,
                customFieldId,
                newPicklistValues.id,
                CustomFieldsAdapter.patchPicklistItem({
                    value: newPicklistValues.value,
                    color: newPicklistValues.colorCode,
                    isDefault: newPicklistValues.isDefault,
                    isDeleted: newPicklistValues.isDeleted,
                    sortOrder: newPicklistValues.sortOrder,
                })
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
                resetSelectedItem();
            },
            onError: (error: { status?: number; errors: any[]; title: string }) => {
                if (error?.status === 400 && error.errors) {
                    return handleBadRequestError(error);
                }
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) });
            },
        }
    );

    const onSubmitForm = event => {
        event.preventDefault();
        patchPicklistItem({ customer, customFieldId, newPicklistValues: selectedItem });
    };

    const onResetForm = event => {
        event.preventDefault();
        resetSelectedItem();
    };

    if (!selectedItem) return null;

    return (
        <Modal
            show
            closeBtn
            closeBtnFunc={resetSelectedItem}
            title="Edit picklist item"
            className={styles.modal}
        >
            <form
                onSubmit={onSubmitForm}
                onReset={onResetForm}
                className="row"
            >
                <div className="col-xs-12 pb-2">
                    <GroupInput
                        label={{
                            text: `${AttributeName}:`,
                        }}
                        input={{
                            type: 'text',
                            id: 'value',
                            name: 'value',
                            value: selectedItem.value,
                            onChange: onChangeInputTextField,
                        }}
                    />
                </div>

                <div className="col-xs-12 col-lg-6 py-2">
                    <GroupInput
                        label={{
                            text: 'Sort order:',
                        }}
                        input={{
                            type: 'text',
                            id: 'sortOrder',
                            name: 'sortOrder',
                            value: selectedItem.sortOrder,
                            onChange: onChangeInputTextField,
                        }}
                    />
                </div>

                <div className="col-xs-12 col-lg-6 py-2">
                    <GroupInput
                        label={{
                            text: 'Color:',
                        }}
                        input={{
                            type: 'color',
                            id: 'colorCode',
                            name: 'colorCode',
                            value: !!selectedItem.colorCode ? '#'.concat(selectedItem.colorCode) : '',
                            onChange: onChangeColorField,
                        }}
                    />
                </div>

                <div className="col-xs-12 py-2">
                    <GroupInput
                        label={{
                            text: 'Is Deleted',
                        }}
                        input={{
                            type: 'switch',
                            id: 'isDeleted',
                            name: 'isDeleted',
                            checked: selectedItem.isDeleted === 'Yes',
                            onChange: onChangeInputSwitchField,
                            dataSet: { 'data-map-switches': JSON.stringify({ true: 'Yes', false: 'No' }) },
                        }}
                    />
                </div>

                <div className="col-xs-12 py-2">
                    <GroupInput
                        label={{
                            text: 'Default',
                        }}
                        input={{
                            type: 'switch',
                            id: 'isDefault',
                            name: 'isDefault',
                            checked: selectedItem.isDefault === 'Yes',
                            onChange: onChangeInputSwitchField,
                            dataSet: { 'data-map-switches': JSON.stringify({ true: 'Yes', false: 'No' }) },
                        }}
                    />
                </div>

                <footer className={['col-xs-12 pt-4', styles.actionButtonsWrapper].join(' ')}>
                    <Button
                        type="reset"
                        btnType="cancel"
                        text="Cancel"
                    />

                    <Button
                        type="submit"
                        text="Save"
                    />
                </footer>
            </form>

            <ErrorModal
                show={!!error}
                title={error?.title}
                closeBtn
                closeBtnFunc={() => setError(null)}
                errors={error?.errors}
                className={styles.errorModal}
            />
        </Modal>
    );
};

export default EditPicklistItemModal;
