import * as React from 'react';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';

import styles from './TextFilterCell.module.scss';

type Operator =
    | 'contains'
    | 'doesnotcontain'
    | 'eq'
    | 'neq'
    | 'startswith'
    | 'endswith'
    | 'isnull'
    | 'isnotnull'
    | 'isempty'
    | 'isnotempty';

type DropDownItem = {
    text: string;
    operator: Operator;
};

interface DropDownButtonEvent extends Omit<DropDownButtonItemClickEvent, 'item'> {
    item: DropDownItem;
}

/**
 * FilterCell for Groups Management Grid columns
 */
const TextFilterCell: React.FC<GridFilterCellProps> = ({ onChange, value, operator, operators }) => {
    function onDropDownListChange({ syntheticEvent, value: newValue }: InputChangeEvent): void {
        if (operator) return onChange({ value: newValue, syntheticEvent, operator: operator });
        onChange({ value: newValue, syntheticEvent, operator: 'contains' });
    }

    function onSubmit(event: React.FormEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    function onItemClick(event: DropDownButtonEvent): void {
        const { item } = event;
        if (item.operator === operator) return;
        onChange({ syntheticEvent: event.syntheticEvent, operator: item.operator, value });
    }

    return (
        <form
            onSubmit={onSubmit}
            onReset={onSubmit}
            className={styles.container}
        >
            <Input
                onChange={onDropDownListChange}
                value={value}
                className={styles.input}
            />

            <DropDownButton
                icon="filter"
                items={operators}
                onItemClick={onItemClick}
                popupSettings={{ popupClass: styles.popup, animate: false }}
                itemRender={(li, props) => {
                    return (
                        <span
                            {...props}
                            className={operator === li.item.operator ? styles.selected : undefined}
                        >
                            {li?.item?.text}
                        </span>
                    );
                }}
            />
        </form>
    );
};

export default TextFilterCell;
