import * as React from 'react';
import ReactDOM from 'react-dom';
import styles from './ToolTipPortal.module.scss';

interface ToolTipPortalProps {
    isOpen: boolean;
    message: string;
    getTargetBoundingClientRect: () => DOMRect | null;
}

const ToolTipPortal: React.FC<ToolTipPortalProps> = ({ isOpen, message, getTargetBoundingClientRect }) => {
    const toolTipRef = React.useRef({});

    const computeTheTooltipPosition = React.useCallback(
        (tooltipNodeRect: DOMRect) => {
            const targetRect = getTargetBoundingClientRect();
            if (!targetRect) return tooltipNodeRect;
            const toolTipRightX = tooltipNodeRect.x + tooltipNodeRect.width;
            // Verifying if tooltip overflows the screen by the right side.
            if (toolTipRightX > window.innerWidth) {
                const newLeftTooltip = window.innerWidth - toolTipRightX - targetRect.width * 2;
                return {
                    top: tooltipNodeRect.top,
                    left: newLeftTooltip + 'px',
                    borderRadius: '10px 10px 0 10px',
                };
            }
            // Verifying if tooltip overflows the screen by the top side.
            if (tooltipNodeRect.y < 0) {
                const newTopTooltip = tooltipNodeRect.height + targetRect.height;
                return {
                    left: tooltipNodeRect.left,
                    top: newTopTooltip + 'px',
                };
            }
            return {
                top: tooltipNodeRect.top,
                left: tooltipNodeRect.left,
            };
        },
        [getTargetBoundingClientRect]
    );
    /**
     * Function that gives a callback when receive the tooltip reference.
     */
    const setRef = React.useCallback(
        (node: HTMLSpanElement) => {
            if (node) {
                const toolTipStyle = computeTheTooltipPosition(node.getBoundingClientRect());
                for (const style in toolTipStyle) {
                    if (Object.hasOwnProperty.call(toolTipStyle, style)) {
                        const styleValue = toolTipStyle[style];
                        node.style[style] = styleValue;
                    }
                }
                toolTipRef.current = node;
                return;
            }
        },
        [computeTheTooltipPosition]
    );
    /**
     * Function that returns the tooltip wrapper style based on the target DOMRect properties
     */
    const getToolTipStyle = () => {
        const targetRect = getTargetBoundingClientRect();
        if (!targetRect) return { top: 0, left: 0 };
        return {
            top: targetRect.top,
            left: targetRect.left + targetRect.width,
        };
    };
    return isOpen
        ? ReactDOM.createPortal(
              <div
                  className={styles.portalCont}
                  style={getToolTipStyle()}
              >
                  <span
                      ref={setRef}
                      className={[styles.toolTip, styles.largeTooltip].join(' ')}
                  >
                      {message}
                  </span>
              </div>,
              document.body
          )
        : null;
};

export default ToolTipPortal;
