import { useUpdateAtom } from 'jotai/utils';
import { errorModalAtom } from 'atons/components/errorModalAtoms';
import { useToast } from './useToast';

export const useSetErrorMessage = () => {
    const setErrorModalProps = useUpdateAtom(errorModalAtom);

    const toast = useToast();

    const hasErrorPayload = (errors: any[]) => {
        return errors.every(
            error =>
                Object.hasOwn(error, 'header') &&
                typeof error['header'] === 'string' &&
                Object.hasOwn(error, 'description') &&
                typeof error['description'] === 'string'
        );
    };

    const showErrorToasty = () => toast.error('Something went wrong, try again later.');

    return (error: any) => {
        if (!error) return showErrorToasty();
        if (!error.errors || (typeof error.errors !== 'object' && !Object.hasOwn(error.errors, 'length')))
            return showErrorToasty();

        const { status, errors, title } = error;
        if (!title || status < 400 || !hasErrorPayload(errors)) return showErrorToasty();

        setErrorModalProps({
            closeBtn: true,
            closeBtnFunc: () => {},
            show: true,
            errors,
            title,
        });
    };
};
