export type AttributeType = 'TextBox' | 'DropDownList' | 'Checkbox' | 'Paragraph' | 'FileUpload' | 'RichTextEditor';
export type HasParent = 'NoParent' | 'AttributeParent' | 'TacticTypeParent';
export type AttributeTypeId = 1 | 2 | 3 | 4 | 5 | 6 | '1' | '2' | '3' | '4' | '5' | '6';

export default class AttributeTypes {
    /**
     * Function that returns the enum value based on Attribute Type name.
     */
    static getByName(name: AttributeType): number {
        return AttributeTypes[name];
    }

    /**
     * Function that formats the id and return the enum representation of the value.
     */
    static getById(id: AttributeTypeId): number {
        return this.AttributeTypeIdMap[id];
    }

    static TextBox = 1;
    static DropDownList = 2;
    static Checkbox = 3;
    static Paragraph = 4;
    static FileUpload = 5;
    static RichTextEditor = 6;

    private static get AttributeTypeIdMap() {
        return {
            1: AttributeTypes.TextBox,
            2: AttributeTypes.DropDownList,
            3: AttributeTypes.Checkbox,
            4: AttributeTypes.Paragraph,
            5: AttributeTypes.FileUpload,
            6: AttributeTypes.RichTextEditor,
        };
    }

    static getAttributeNameById(id: AttributeTypeId): AttributeType {
        return {
            '1': 'TextBox',
            '2': 'DropDownList',
            '3': 'Checkbox',
            '4': 'Paragraph',
            '5': 'FileUpload',
            '6': 'RichTextEditor',
        }[id] as AttributeType;
    }
}
