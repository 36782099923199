//Here is were we going to name the react-query states
export const queryKeys = {
    customers: 'customers',
    customerAssociations: 'customerAssociations',
    integrationList: 'integrationList',
    integrationSetupList: 'integrationSetupList',
    integrationSetupCombos: 'integrationSetupCombos',
    stageMapping: 'stageMapping',
    customGoalsMapping: 'customGoalsMapping',
    integrationSetupById: 'integrationSetupById',
    historyIntegrationSetupList: 'historyIntegrationSetupList',
    marketoV2IntegrationParameters: 'marketoV2IntegrationParameters',
    marketoV2IntegrationActivityTypes: 'marketoV2IntegrationActivityTypes',
    integrationSetupByIdMarketo: 'integrationSetupByIdMarketo',
    notificationsEmailsList: 'notificationsEmailsList',
    entityTypeList: 'entityTypeList',
    customFieldDetail: 'customFieldDetail',
    entityTypeUserSelect: 'entityTypeUserSelect',
    customFieldTypes: 'customFieldTypes',
    customFieldValidationOptions: 'customFieldValidationOptions',
    customFieldGroups: 'customFieldGroups',
    attributeFieldsList: 'attributeFieldsList',
    getCustomFieldPicklist: 'getCustomFieldPicklist',
    getCustomFieldGroup: 'getCustomFieldGroup',
    getCustomFieldGroupsUsage: 'getCustomFieldGroupsUsage',
    getCustomFieldListPossibleParents: 'getCustomFieldListPossibleParents',
    getParentPicklistOptions: 'getParentPicklistOptions',
    getChildPicklistOptions: 'getChildPicklistOptions',
    getParentDependencies: 'getParentDependencies',
    getEntityTypePossibleParentOptions: 'getEntityTypePossibleParentOptions',
    getTacticTypeCustomFieldDependenciesParents: 'getTacticTypeCustomFieldDependenciesParents',
    getDependencyExportData: 'getDependencyExportData',
    getCustomFieldExportData: 'getCustomFieldExportData',
    getGroupsExportData: 'getGroupsExportData',
};
