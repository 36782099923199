import * as React from 'react';

import { TabItem } from '..';
import styles from './TabList.module.scss';

/**
 * TabList component
 * @param {{
 *  className?: string;
 *  tabs: {
 *      id: string;
 *      label: string;
 *      className?: string;
 *      selectedClassName?: string;
 *      disabled?: boolean;
 *      title?: string;
 *  }[];
 *  selectedTabId: string;
 *  onChange: (selectedTab: { id: string; label: string; className: string; selectedClassName: string; }) => void;
 * }} props TabList props
 * @returns {JSX.Element}
 */
function TabList({ className, tabs, selectedTabId, onChange }) {
    /**
     * Return a string containing all the component container classNames
     * @returns {string} container classNames
     */
    const getContainerClassName = () => {
        const tabListClassNames = [styles.tabList];
        if (className) {
            tabListClassNames.push(className);
        }
        return tabListClassNames.join(' ');
    };
    const getTabByTabId = tabId => tabs.find(tab => tab.id === tabId);
    /**
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event Click event
     */
    const _onChangeTab = ({ currentTarget }) => {
        const selectedTab = getTabByTabId(currentTarget.id);
        if (!selectedTab) return;
        onChange(selectedTab);
    };
    return (
        <div
            className={getContainerClassName()}
            role="tablist"
        >
            {tabs.map(
                ({
                    id,
                    label,
                    className,
                    selectedClassName,
                    disabled,
                    title = `click to open ${label.toLowerCase()} tab`,
                }) => (
                    <TabItem
                        key={id}
                        id={id}
                        onClick={_onChangeTab}
                        isSelected={selectedTabId === id}
                        className={className}
                        selectedClassName={selectedClassName}
                        title={title}
                        disabled={disabled}
                    >
                        {label}
                    </TabItem>
                )
            )}
        </div>
    );
}

export default TabList;
