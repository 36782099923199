import * as React from 'react';
import { useUpdateAtom } from 'jotai/utils';
import { GridCellProps } from '@progress/kendo-react-grid';

import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';
import {
    attributeConfigurationModalVisibleAtom,
    attributeSettingsAtom,
    attributeSettingsInitialValueAtom,
} from 'atons/AttributeManagement/atoms';
import { useGetCustomFieldDetail } from 'react-query/queries';
import { CustomFieldDetailResponse } from 'services/attributeManagementApis';
import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';

import styles from './ActionsColumn.module.scss';
import { CustomAttributeListItem } from '../AttributeManagementGrid';
import { currentElementHierarchyOwnerAtom, relationshipItemAtom } from 'atons/AttributeManagement/dependencyAtoms';
import { AttributeTypeId } from 'enums/AttributeTypes';

interface ActionsColumnProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: CustomAttributeListItem;
}

const ActionsColumn: React.FC<ActionsColumnProps> = ({ dataItem }) => {
    const setAttributeSettingsAtom = useUpdateAtom(attributeSettingsAtom);
    const setAttributeConfigurationModalVisibility = useUpdateAtom(attributeConfigurationModalVisibleAtom);
    const setIsRelationshipModalOpen = useUpdateAtom(relationshipItemAtom);
    const setCurrentElementHierarchyOwner = useUpdateAtom(currentElementHierarchyOwnerAtom);
    const setAttributeSettingsInitialValue = useUpdateAtom(attributeSettingsInitialValueAtom);

    const onGetCustomFieldDetailSuccess = (data: CustomFieldDetailResponse) => {
        const attributeSettingsData = CustomFieldsAdapter.toAttributeSetting(data);
        setAttributeSettingsAtom(attributeSettingsData);
        setAttributeSettingsInitialValue(attributeSettingsData);
        setAttributeConfigurationModalVisibility(true);
    };

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(Number(dataItem.id), {
        onSuccess: onGetCustomFieldDetailSuccess,
    });

    const onClickEditAction = () => fetchCustomFieldDetail();

    const onClickRelationshipAction = () => {
        setIsRelationshipModalOpen(CustomFieldsAdapter.toRelationshipModal(dataItem));
        setCurrentElementHierarchyOwner({
            id: dataItem.id,
            name: dataItem.name,
            customFieldTypeId: dataItem.attributeType.id as AttributeTypeId,
            entityTypeId: dataItem.entityTypeId,
            entityType: dataItem.entityType,
            parentId:
                dataItem.parentCustomFields && dataItem.parentCustomFields[0]
                    ? dataItem.parentCustomFields[0].id
                    : undefined,
            childrenCount: dataItem.childCustomFields.length,
        });
    };

    return (
        <td className={styles['action-column']}>
            <div className={styles['action-column__content']}>
                <span
                    onClick={onClickEditAction}
                    onKeyUp={a11yKeyBoardEventHandler(onClickEditAction)}
                    role="button"
                    tabIndex={0}
                    title={'Edit this attribute'}
                    className="k-icon k-i-track-changes-enable"
                />

                {(!!dataItem.childCustomFields.length || !!dataItem.parentCustomFields.length) && (
                    <span
                        onClick={onClickRelationshipAction}
                        onKeyUp={a11yKeyBoardEventHandler(onClickRelationshipAction)}
                        role="button"
                        tabIndex={0}
                        title="Show attribute relationship"
                        className={`k-icon k-i-inherited ${styles['action-column__item']}`}
                    />
                )}
            </div>
        </td>
    );
};

export default ActionsColumn;
