import { FC } from 'react';
import { Button } from 'components';

import styles from './RelationshipErrorModal.module.scss';
import GraphButton from '../GraphButton/GraphButton';

interface RelationshipErrorModalProps {
    isOpen: boolean;
    onClickRemove: () => void;
}

const RelationshipErrorModal: FC<RelationshipErrorModalProps> = ({ isOpen, onClickRemove }) => {
    if (!isOpen) return null;
    return (
        <div className={styles['relationship-error-modal']}>
            <main className={styles['relationship-error-modal__wrapper']}>
                <h2 className={styles['relationship-error-modal__title']}>Dependency Error</h2>

                <p className={styles['relationship-error-modal__paragraph']}>
                    There is an error with this flow. This dependency can be causing an infinite loop. You may want to
                    remove this dependency now or check the Tree graph in order to find a better solution for that.
                </p>

                <footer className={styles['relationship-error-modal__action-buttons']}>
                    <GraphButton />

                    <Button
                        text="Remove now"
                        type="button"
                        btnType="cancel"
                        onClick={onClickRemove}
                    />
                </footer>
            </main>
        </div>
    );
};

export default RelationshipErrorModal;
