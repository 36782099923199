import { useMutation } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';

import { putParentDependencies, CustomFieldDependency } from 'services/attributeManagementApis';
import { useToast } from 'customHooks/useToast';

import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { customerAtom } from 'atons/Customer/atoms';
import { useAtomValue } from 'jotai';

export const usePutParentDependencies = (customFieldId: number, obj: CustomFieldDependency, verb: string) => {
    const customer = useAtomValue(customerAtom);

    const onError = useSetErrorMessage();
    const toast = useToast();

    return useMutation(() => putParentDependencies(customer!.clientId, customFieldId, obj), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.customFieldDetail]);
            verb === 'delete'
                ? toast.success('The relantionship is removed successfully')
                : toast.success('The relantionship is done successfully');
        },
        onError: error => {
            onError(error);
        },
    });
};
