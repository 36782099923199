import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function queryErrorHandler(error) {
    // error is type unknown because in js, anything can be an error (e.g. throw(5))
    const id = 'react-query-error';
    const title =
        error instanceof Error
            ? // remove the initial 'Error: ' that accompanies many errors
              error.toString().replace(/^Error:\s*/, '')
            : 'error connecting to server';

    // prevent duplicate toasts
    toast({ id, title, status: 'error', variant: 'subtle', isClosable: true });
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: queryErrorHandler,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError: queryErrorHandler,
        },
    },
});
