import * as React from 'react';
import { Grid, GridColumn, GridPageChangeEvent, GridPagerSettings } from '@progress/kendo-react-grid';
import { orderBy, filterBy, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';

import { CustomAttributeListItemResponse } from '../../../../services/attributeManagementApis';

import { customerAtom } from 'atons/Customer/atoms';
import { attributesListAtom } from 'atons/AttributeManagement/atoms';
import { selectedAttributeToDeleteOrReactivateAtom } from 'atons/AttributeManagement/atoms';

import CustomFieldsAdapter from '../../../../adapters/CustomFieldsAdapter';
import FilterCell from './FilterCell';
import SortOrderColumn from './SortOrderColumn/SortOrderColumn';
import CheckboxGridColumn from './CheckboxGridColumn/CheckboxGridColumn';
import ToggleDeleteAttributeModal from './ToggleDeleteAttributeModal/ToggleDeleteAttributeModal';
import ActionsColumn from './ActionsColumn/ActionsColumn';

import { formatPascalCaseIntoRegularText } from '../../../../shared/helpers';
import { a11yKeyBoardEventHandler } from '../../../../shared/KeyboradKey';
import styles from './AttributeManagementGrid.module.scss';
import { useGetCustomFieldList } from 'react-query/queries';
import PicklistEntriesColumn from './PicklistEntriesColumn/PicklistEntriesColumn';
import GroupNameColumn from './GroupNameColumn/GroupNameColumn';

type FilterYesOrNo = 'Yes' | 'No';

export interface CustomAttributeListItem
    extends Omit<CustomAttributeListItemResponse, 'createdDate' | 'isHidden' | 'isDeleted' | 'toolTip'> {
    createdDate: Date | null;
    isHidden: FilterYesOrNo;
    isDeleted: FilterYesOrNo;
    toolTip: FilterYesOrNo;
}

/**
 * Proprierty for initial pagination
 */
const initialPageSetup = {
    skip: 0,
    take: 25,
};
/**
 * Proprierty with some initial configurations
 */
const initialPageSettings: GridPagerSettings = {
    buttonCount: 25,
    info: true,
    pageSizes: [15, 25, 50],
    previousNext: true,
    type: 'numeric',
};
/**
 * Proprierty for initial sort
 */
const initialSort: SortDescriptor[] = [
    { field: 'sortOrder', dir: 'asc' },
    { field: 'name', dir: 'asc' },
];

const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
        {
            field: 'name',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'isHidden',
            operator: 'contains',
            value: '',
        },
        {
            field: 'toolTip',
            operator: 'contains',
            value: '',
        },
        {
            field: 'createdOn',
            operator: 'startswith',
            value: '',
        },
        {
            field: 'isDeleted',
            operator: 'contains',
            value: 'No',
        },
        {
            field: 'picklistEntries',
            operator: 'startswith',
            value: '',
        },
    ],
};

interface KendoGridRefactor extends Omit<Grid, 'element'> {
    element?: HTMLElement;
}

const AttributeManagementGrid: React.FC = () => {
    const [page, setPage] = React.useState(initialPageSetup);
    const [forceReRender, forceRender] = React.useState(false);
    const [pageSettings] = React.useState<GridPagerSettings>(initialPageSettings);
    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
    const [gridClassName, setGridClassName] = React.useState<string | undefined>(undefined);
    const customer = useAtomValue(customerAtom);
    const updateAttributesList = useUpdateAtom(attributesListAtom);
    const selectedAttributeToDeleteOrReactivate = useUpdateAtom(selectedAttributeToDeleteOrReactivateAtom);
    const ref = React.useRef<KendoGridRefactor>();

    /**
     * Call the attributesFieldsList API using the react-query with useQuery
     */
    const { data: attributesFieldsList } = useGetCustomFieldList();

    /**
     * UseMemo to load the data list
     */
    const data = React.useMemo<CustomAttributeListItem[]>(() => {
        if (attributesFieldsList) return CustomFieldsAdapter.toAttributeManagementGrid(attributesFieldsList);
        return [];
    }, [attributesFieldsList]);
    /**
     * Function that controls the pagination
     */
    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    /**
     * Function that contruct the html inside the hiddenColumn column
     * @param {*} props Property that passes the data per line
     * @returns The html per line of the grid
     */
    const hiddenColumn = ({ dataItem }) => {
        return (
            <td>
                <span>{dataItem.isHidden}</span>
            </td>
        );
    };
    /**
     * Function that contruct the html inside the attributeTypeColumn column
     * @param {*} props Property that passes the data per line
     * @returns The html per line of the grid
     */
    const attributeTypeColumn = ({ dataItem }) => {
        return (
            <td>
                <span>{formatPascalCaseIntoRegularText(dataItem.attributeType.name)}</span>
            </td>
        );
    };
    /**
     * Function that contruct the html inside the toolTipColumn column
     * @param {*} props Property that passes the data per line
     * @returns The html per line of the grid
     */
    const toolTipColumn = props => {
        return (
            <td>
                <span>{props.dataItem.toolTip}</span>
            </td>
        );
    };
    /**
     * Function that contruct the html inside the isDeletedColumn column
     * @param {*} props Property that passes the data per line
     * @returns The html per line of the grid
     */
    const isDeletedColumn = props => {
        return (
            <td>
                {props.dataItem.isDeleted === 'Yes' ? (
                    <div
                        title="Click here if want to reactivate this attribute"
                        onClick={() => selectedAttributeToDeleteOrReactivate(props.dataItem)}
                        onKeyUp={a11yKeyBoardEventHandler(() => selectedAttributeToDeleteOrReactivate(props.dataItem))}
                        role="button"
                        tabIndex={0}
                        className={styles.yes}
                    >
                        Yes
                    </div>
                ) : (
                    <div
                        title="Click here if want to delete this attribute"
                        onClick={() => selectedAttributeToDeleteOrReactivate(props.dataItem)}
                        onKeyUp={a11yKeyBoardEventHandler(() => selectedAttributeToDeleteOrReactivate(props.dataItem))}
                        role="button"
                        tabIndex={0}
                        className={styles.no}
                    >
                        No
                    </div>
                )}
            </td>
        );
    };
    /**
     * Function that handles the filter event change.
     * @param {import('@progress/kendo-react-grid').GridFilterChangeEvent} event filter change event
     * @returns {void}
     */
    const onFilterChange = event => {
        const filter = event.filter;
        const indexOfElement = filter.filters.findIndex(element => element.field === 'name');
        // If the element doesn't exist, we should include it with the default data value.
        if (indexOfElement === -1)
            return setFilter({
                ...filter,
                filters: [{ field: 'name', operator: 'startswith', value: '' }, ...filter.filters],
            });
        const filterOption = filter.filters[indexOfElement];
        const newFilter = filter.filters.filter(element => element.field !== 'name');
        if (filterOption?.value?.length > 2) {
            return setFilter({
                ...filter,
                filters: [{ ...filterOption, operator: 'contains' }, ...newFilter],
            });
        }
        setFilter({ ...filter, filters: [{ ...filterOption, operator: 'startswith' }, ...newFilter] });
    };
    /**
     * UseEffect that controls the text on the filters and the header title
     */
    React.useEffect(() => {
        const forceInterval = setInterval(() => forceRender(!forceReRender), 100);
        ref.current?.element?.querySelectorAll('.k-textbox').forEach(el => {
            (el as HTMLInputElement).placeholder = 'Filter here...';
        });
        ref.current?.element?.querySelectorAll('th').forEach(el => {
            el.title = el.innerText;
        });
        return () => {
            clearInterval(forceInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const gridDataWithSortOrder = React.useMemo(
        () => data.filter(element => typeof element.sortOrder === 'number' && element.sortOrder > -1),
        [data]
    );

    const gridDataWithoutSortOrder = React.useMemo(() => data.filter(element => element.sortOrder === null), [data]);

    const getGridMaxHeight = (): string => {
        const APP_HEADER_BAR_HEIGHT = Math.ceil((document.getElementById('app-nav-bar')?.clientHeight ?? 0) / 10) * 10;
        const ATTRIBUTE_HEADER_CONTENT_HEIGHT =
            Math.ceil((document.getElementById('attribute-page-title-content')?.clientHeight ?? 0) / 10) * 10;
        const GRID_PADDING = 30;
        const GRID_MAX_HEIGHT =
            window.innerHeight - ATTRIBUTE_HEADER_CONTENT_HEIGHT - APP_HEADER_BAR_HEIGHT - GRID_PADDING * 2;
        return `${GRID_MAX_HEIGHT}px`;
    };

    const getGridClassNameIfScrollIsVisible = React.useCallback(() => {
        const element = document.getElementById(styles.attributeGrid);
        if (element === null) return undefined;
        return element.clientHeight >= Number(getGridMaxHeight().replace(/\D/g, ''))
            ? styles.scrollIsVisible
            : undefined;
    }, []);

    React.useEffect(() => {
        const element = document.getElementById(styles.attributeGrid);
        const log = () => setGridClassName(getGridClassNameIfScrollIsVisible());
        if (element !== null) {
            (element as HTMLDivElement).addEventListener('DOMNodeInserted', log);
        }
        return () => {
            if (element !== null) {
                (element as HTMLDivElement).removeEventListener('DOMNodeInserted', log);
            }
        };
    }, [data, getGridClassNameIfScrollIsVisible]);

    React.useEffect(() => {
        attributesFieldsList && updateAttributesList(attributesFieldsList);
    }, [attributesFieldsList, updateAttributesList]);

    const gridData = filterBy(
        [...orderBy(gridDataWithSortOrder, sort), ...orderBy(gridDataWithoutSortOrder, sort)],
        filter
    );

    return !!customer ? (
        <div
            id={styles.attributeGrid}
            className={gridClassName}
        >
            <ToggleDeleteAttributeModal />

            <Grid
                ref={ref as unknown as React.LegacyRef<Grid> | undefined}
                className={[styles.grid, 'grid'].join(' ')}
                data={gridData?.slice(page?.skip, page?.take + page?.skip)}
                sortable
                sort={sort}
                onSortChange={e => setSort(e.sort)}
                filterable
                filter={filter}
                onFilterChange={onFilterChange}
                skip={page?.skip}
                style={{ maxHeight: getGridMaxHeight() }}
                take={page?.take}
                total={gridData?.length}
                dataItemKey="id"
                pageable={pageSettings}
                onPageChange={pageChange}
                scrollable="scrollable"
            >
                <GridColumn
                    field="id"
                    cell={CheckboxGridColumn}
                    width="80px"
                    filterable={false}
                    title="Select"
                />
                <GridColumn
                    field="name"
                    title="Name"
                    filterable
                />
                <GridColumn
                    field="attributeType.name"
                    width="150px"
                    filterable={false}
                    cell={attributeTypeColumn}
                    title="Attribute Type"
                />
                <GridColumn
                    field="customFieldGroup.name"
                    width="150px"
                    filterable
                    cell={GroupNameColumn}
                    title="Group Name"
                />
                <GridColumn
                    field="sortOrder"
                    title="Sort Order"
                    width="130px"
                    cell={SortOrderColumn}
                    filterable={false}
                />
                <GridColumn
                    field="isHidden"
                    title="Hidden"
                    width="100px"
                    filter="boolean"
                    cell={hiddenColumn}
                    filterCell={FilterCell}
                    filterable
                />
                <GridColumn
                    field="toolTip"
                    title="Tool Tip"
                    width="100px"
                    filter="boolean"
                    cell={toolTipColumn}
                    filterCell={FilterCell}
                    filterable
                />
                <GridColumn
                    field="createdDate"
                    title="Created On"
                    filter="date"
                    format="{0:MM/dd/yyyy}"
                    filterable
                />
                <GridColumn
                    field="picklistEntries"
                    title="Picklist Entries"
                    width="150px"
                    cell={PicklistEntriesColumn}
                    filterable
                />
                <GridColumn
                    field="isDeleted"
                    title="Deleted"
                    width="110px"
                    filter="boolean"
                    cell={isDeletedColumn}
                    filterCell={FilterCell}
                    filterable
                />
                <GridColumn
                    field="Actions"
                    title=""
                    width="90px"
                    filterable={false}
                    cell={ActionsColumn}
                />
            </Grid>
        </div>
    ) : (
        <div className="warningText">
            <span className="k-icon k-i-warning icon-alert"></span>
            <p className="mt-3">
                <b>Please,</b> select a customer from the menu above to view{' '}
                <span className="integrations-word">attributes list</span>
            </p>
        </div>
    );
};

export default AttributeManagementGrid;
