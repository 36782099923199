import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Modal, Button, LabeledRow, Checkbox } from 'components';
import useGetGroupsExportData from 'react-query/queries/customFields/groups/useGetGroupsExportData';

import useGetCustomFieldsExportData from 'react-query/queries/customFields/useGetCustomFieldsExportData';
import { includeDeletedValuesOnExportedDataAtom } from 'atons/AttributeManagement/atoms';
import useGetDependenciesExportData from 'react-query/queries/customFields/useGetDepedenciesExportData';
import { FileUtils } from 'shared/file.utils';

import styles from './ExportDataModal.module.scss';
import useGetCustomFieldsExportDetailedData from 'react-query/queries/customFields/useGetCustomFieldsExportDetailedData';
import useGetGroupsExportDetailedData from 'react-query/queries/customFields/groups/useGetGroupsExportDetailedData';
import useGetDepedenciesExportDetailedData from 'react-query/queries/customFields/useGetDepedenciesExportDetailedData';

interface ExportDataModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const ExportDataModal: React.FC<ExportDataModalProps> = ({ isOpen, closeModal }) => {
    const [{ includeDeletedAttributes, includeDeletedDependencies }, setIncludeDeleted] = useAtom(
        includeDeletedValuesOnExportedDataAtom
    );
    const onSuccess = response =>
        FileUtils.generateDownloadTagFromAxiosResponse(response, 'export-data-download-container');

    const { refetch: getDependenciesExportData } = useGetDependenciesExportData({
        onSuccess,
        enabled: false,
    });

    const { refetch: getDependenciesExportDetailedData } = useGetDepedenciesExportDetailedData({
        onSuccess,
        enabled: false,
    });

    const { refetch: getGroupsExportData } = useGetGroupsExportData({
        onSuccess,
        enabled: false,
    });

    const { refetch: getGroupsExportDetailedData } = useGetGroupsExportDetailedData({
        onSuccess,
        enabled: false,
    });

    const { refetch: getCustomFieldsExportData } = useGetCustomFieldsExportData({
        onSuccess,
        enabled: false,
    });

    const { refetch: getCustomFieldsExportDetailedData } = useGetCustomFieldsExportDetailedData({
        onSuccess,
        enabled: false,
    });

    useEffect(() => {
        return () => {
            setIncludeDeleted({
                includeDeletedAttributes: false,
                includeDeletedDependencies: false,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Modal
            closeBtn
            show={isOpen}
            closeBtnFunc={closeModal}
            title="Export data"
        >
            <div className={styles['export-data']}>
                <div className={styles['export-data__checkbox-wrapper']}>
                    <Checkbox
                        id="include-deleted-attribute-checkbox"
                        name="include-deleted-attribute-checkbox"
                        checked={includeDeletedAttributes}
                        onChange={event =>
                            setIncludeDeleted(previousValue => ({
                                ...previousValue,
                                includeDeletedAttributes: event.target.checked,
                            }))
                        }
                    />

                    <label htmlFor="include-deleted-attribute-checkbox">
                        Include deleted values on export Attribute data
                    </label>
                </div>

                <LabeledRow
                    label="Export attributes"
                    className="Test"
                >
                    <Button
                        id="export-attributes-to-excel"
                        type="button"
                        title="Export normal data of attributes"
                        text="Normal"
                        onClick={() => getCustomFieldsExportData()}
                        className={styles['export-data__button']}
                        btnType="primary"
                    />

                    <Button
                        id="export-attributes-to-excel-detailed"
                        type="button"
                        title="Export detailed data of attributes"
                        text="Detailed"
                        onClick={() => getCustomFieldsExportDetailedData()}
                        className={styles['export-data__button']}
                    />
                </LabeledRow>

                <div className={styles['export-data__checkbox-wrapper']}>
                    <Checkbox
                        id="include-deleted-attribute-dependency-checkbox"
                        name="include-deleted-attribute-dependency-checkbox"
                        checked={includeDeletedDependencies}
                        onChange={event =>
                            setIncludeDeleted(previousValue => ({
                                ...previousValue,
                                includeDeletedDependencies: event.target.checked,
                            }))
                        }
                    />

                    <label htmlFor="include-deleted-attribute-dependency-checkbox">
                        Include deleted values on export dependencies
                    </label>
                </div>

                <LabeledRow label="Export attributes dependencies">
                    <Button
                        id="export-dependencies-to-excel"
                        type="button"
                        title="Export normal attribute dependencies"
                        text="Normal"
                        onClick={() => getDependenciesExportData()}
                        className={styles['export-data__button']}
                        btnType="primary"
                    />

                    <Button
                        id="export-dependencies-to-excel-detailed"
                        type="button"
                        title="Export detailed attribute dependencies"
                        text="Detailed"
                        onClick={() => getDependenciesExportDetailedData()}
                        className={styles['export-data__button']}
                    />
                </LabeledRow>

                <LabeledRow label="Export custom fields groups">
                    <Button
                        id="export-groups-to-excel"
                        type="button"
                        title="Export normal custom fields groups"
                        text="Normal"
                        onClick={() => getGroupsExportData()}
                        className={styles['export-data__button']}
                        btnType="primary"
                    />

                    <Button
                        id="export-groups-to-excel-detailed"
                        type="button"
                        title="Export detailes custom fields groups"
                        text="Detailed"
                        onClick={() => getGroupsExportDetailedData()}
                        className={styles['export-data__button']}
                    />
                </LabeledRow>

                <div
                    id="export-data-download-container"
                    className={styles['export-data__download-wrapper--hidden']}
                />
            </div>
        </Modal>
    );
};

export default ExportDataModal;
