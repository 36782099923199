import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { Button, Modal } from 'components';
import TreeD3 from './TreeD3/TreeD3';
import { relationshipItemAtom, scaleAtom, zoomAtom } from 'atons/AttributeManagement/dependencyAtoms';
import ModalLegend from './ModalLegend/ModalLegend';

import styles from './AttributeRelationshipModal.module.scss';

const AttributeRelationShipModal: React.FC = () => {
    const [isCentering, setCentering] = useState(false);
    const [relationshipItem, setRelationshipItem] = useAtom(relationshipItemAtom);
    const [zoom, setZoom] = useAtom(zoomAtom);
    const scale = useAtomValue(scaleAtom);
    const resetZoomLevel = useResetAtom(zoomAtom);

    const onCloseModal = () => setRelationshipItem(undefined);
    const onClickCenter = () => setCentering(true);
    const onCenterEnds = () => setCentering(false);

    React.useEffect(() => {
        resetZoomLevel();
    }, [resetZoomLevel, relationshipItem]);

    return (
        <Modal
            title="Attribute Relationship"
            closeBtn
            closeBtnFunc={onCloseModal}
            className={styles.modal}
            show={!!relationshipItem}
        >
            <ModalLegend />

            <TreeD3
                isCentering={isCentering}
                onCenterEnds={onCenterEnds}
            />

            <Button
                text="Zoom +"
                className={styles.zIn}
                onClick={() => zoom < scale.max && setZoom(prev => prev + 0.2)}
            />

            <Button
                text="Zoom -"
                className={styles.ZOut}
                onClick={() => zoom > scale.min && setZoom(prev => prev - 0.2)}
            />

            <Button
                text="Center"
                className={styles.center}
                onClick={onClickCenter}
            />

            <Button
                text="Close"
                className={styles.btn}
                onClick={onCloseModal}
            />
        </Modal>
    );
};

export default AttributeRelationShipModal;
