import * as React from 'react';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useQuery } from 'react-query';
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Avatar } from '@progress/kendo-react-layout';

import { isDrawerSideBarExpandedAtom } from 'atons/components/drawerAtoms';
import { customerAtom, customerListAtom } from 'atons/Customer/atoms';

import Brand from 'assets/hive9-brand.svg';

import { getCustomers } from 'services/apiGeneral';

import { AutoComplete, Modal } from 'components';

import CustomerAdapter from 'adapters/CustomerAdapter';

import { queryKeys } from 'react-query/constants';
import { useToast } from 'customHooks/useToast';
import { globalVariables } from 'atons/globalAtons';
import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';

const NavBar: React.FC = () => {
    const [isSideBarExpanded, setIsSideBarExpanded] = useAtom(isDrawerSideBarExpandedAtom);
    const [customer, setCustomer] = useAtom(customerAtom);
    const [customerList, setCustomerList] = useAtom(customerListAtom);
    const [global, setGlobal] = useAtom(globalVariables);
    const [show, setShow] = React.useState(false);

    const auth = useAuth();

    const toast = useToast();

    const handleClick = () => setIsSideBarExpanded(!isSideBarExpanded);

    /**
     * Call the API using the react-query with useQuery
     */
    const { data: customers } = useQuery(queryKeys.customers, getCustomers, {
        enabled: typeof auth.userData?.profile?.role !== 'undefined',
        retry: 3,
        refetchOnWindowFocus: false,
        onError: error => {
            console.log(error);
            toast.error('Something went wrong, try again later.');
        },
    });

    /**
     * Effect that observes the clients return from API
     */
    React.useEffect(() => {
        if (customers && customers.length > 0) setCustomerList(customers);
    }, [customers, setCustomerList]);

    const onChangeCustomer = (value: any) => {
        if (!value) return;
        localStorage.setItem('clientInfo', JSON.stringify(customerList.find(clt => clt.clientId === value)));
        const clientInfo = localStorage.getItem('clientInfo');
        if (clientInfo) setCustomer(JSON.parse(clientInfo));
    };

    /**
     * Function that opens the modal of confirmation for sign-out
     */
    const openLogOutModal = () => setShow(true);

    /**
     * Function that opens the modal of confirmation for delete
     */
    const closeLogOutModal = () => setShow(false);

    /**
     * Function that sign-out the user
     */
    const logout = async () => {
        setGlobal(prevState => ({ ...prevState, loading: true }));
        setShow(false);
        await auth.userManager.signoutRedirect();
        await auth.userManager.removeUser();
        await auth.userManager.clearStaleState();
    };

    return (
        <>
            <Modal show={show}>
                <div className="question">
                    <span>{global.userName}</span>, are you sure that you want to quit the application?
                </div>

                <div className="btnCont">
                    <button
                        onClick={closeLogOutModal}
                        className="cancelBtn"
                    >
                        Cancel
                    </button>

                    <button
                        onClick={logout}
                        className="confirmBtn"
                    >
                        Ok
                    </button>
                </div>
            </Modal>

            <div
                id="app-nav-bar"
                className="custom-toolbar d-flex justify-content-betwee"
            >
                <div className="companyLogo">
                    <Button
                        icon="menu"
                        look="flat"
                        onClick={handleClick}
                        id="hamburger-menu-button"
                    />

                    <Link to="/">
                        <img
                            src={Brand}
                            alt="Hive9 - Brand"
                            className="toolbar-brand"
                        />
                    </Link>

                    <div className={'admLogo'}>
                        <Link
                            to="/"
                            className="title"
                        >
                            ADMIN<span className="title-main"> Portal</span>
                        </Link>
                    </div>
                </div>

                {typeof auth.userData?.profile?.role !== 'undefined' ? (
                    <div className="dropCustomer">
                        {customers && customers.length > 0 && (
                            <>
                                <span className="mr-3 client-selector">
                                    <b>Customer selector:</b>{' '}
                                </span>

                                <AutoComplete
                                    autoComplete="on"
                                    disabled={typeof auth?.userData?.profile?.role === 'undefined' ? true : false}
                                    options={CustomerAdapter.customersToAutocompleteOptions(customers)}
                                    value={customer?.clientId}
                                    placeholder="Select Customer"
                                    className="clients-list mr-3"
                                    onChange={onChangeCustomer}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    <div className="dropCustomer">
                        <span className="clientSingle clients-list mr-3">{auth.userData?.profile?.clientName}</span>
                    </div>
                )}

                <div className={'avatar'}>
                    <span className={'userName'}>{global.userName}</span>

                    <Tooltip
                        anchorElement="target"
                        position="top"
                    >
                        <div title="Log out">
                            <Avatar
                                shape="circle"
                                type={global.userPhoto ? 'image' : 'icon'}
                            >
                                {global.userPhoto ? (
                                    <img
                                        src="https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg"
                                        alt={''}
                                    />
                                ) : (
                                    <h2
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            margin: 0,
                                        }}
                                    >
                                        {global.userName?.substring(0, 1)}
                                        {global.userName?.split(' ').length > 1 &&
                                            global.userName?.split(' ').at(-1)?.substring(0, 1)}
                                    </h2>
                                )}
                            </Avatar>
                        </div>
                    </Tooltip>

                    <div
                        onClick={openLogOutModal}
                        onKeyUp={a11yKeyBoardEventHandler(openLogOutModal)}
                        role="button"
                        tabIndex={0}
                        title="Sign-out"
                        className="logout k-icon k-i-logout"
                    ></div>
                </div>
            </div>
        </>
    );
};

export default NavBar;
