import * as React from 'react';

import { Button } from '../..';

import styles from './TourItem.module.scss';

const maxWidth = 500;

/**
 * Tour item component
 * @param {{
 *  elementSelector: string;
 *  title: string;
 *  description: string;
 *  onElementFocus: () => void;
 *  onElementBlur: () => void;
 *  isActive: boolean;
 *  onClickNext: () => void;
 *  onClickPrevious: () => void;
 *  onPressSkipButton: () => void;
 *  currentElementWrapperInlineStyle: { left: number; top: number; width: number; height: number; };
 *  previousButtonLabel?: string;
 *  nextButtonLabel?: string;
 * }} props Component props
 * @returns {JSX.Element}
 */
const TourItem = ({
    elementSelector,
    title,
    description,
    isActive,
    currentElementWrapperInlineStyle,
    previousButtonLabel = 'Previous',
    nextButtonLabel = 'Next',
    onPressSkipButton,
    onElementFocus,
    onElementBlur,
    onClickNext,
    onClickPrevious,
}) => {
    const [ref, setRef] = React.useState(null);
    const getElementClassName = React.useCallback(
        baseStyle => (isActive ? [baseStyle, styles.active] : [baseStyle]).join(' '),
        [isActive]
    );

    const leftPosition = () => {
        if (!ref) return undefined;
        if (!elementSelector) return '50%';
        const targetElement = document.querySelector(elementSelector);
        if (!targetElement) return undefined;

        const wrapperPaddings = 20;

        const { width: thisComponentWidth } = ref.getBoundingClientRect();
        const { left: targetLeft } = targetElement.getBoundingClientRect();
        const baseComponentPosition = targetLeft - wrapperPaddings;

        const thisComponentShouldBePlacedOnLeftSide = baseComponentPosition + thisComponentWidth > window.innerWidth;

        return thisComponentShouldBePlacedOnLeftSide
            ? baseComponentPosition - thisComponentWidth + 'px'
            : baseComponentPosition + 'px';
    };

    const topPosition = () => {
        if (!ref) return undefined;
        if (!elementSelector) return '50%';
        const targetElement = document.querySelector(elementSelector);
        if (!targetElement) return undefined;

        const wrapperPaddings = 20;

        const { height: thisComponentHeight } = ref.getBoundingClientRect();
        const { top: targetTop, height: targetHeight } = targetElement.getBoundingClientRect();
        const { height: fullTargetHeight } = currentElementWrapperInlineStyle;
        const baseComponentPosition = targetTop - wrapperPaddings;

        const thisComponentShouldBePlacedOnTopSide =
            baseComponentPosition + targetHeight + thisComponentHeight > window.innerHeight;

        return thisComponentShouldBePlacedOnTopSide
            ? baseComponentPosition - thisComponentHeight + 'px'
            : baseComponentPosition + fullTargetHeight + 'px';
    };

    const transformStyle = React.useMemo(() => {
        if (!elementSelector) return 'translate(-50%, -50%)';
        return undefined;
    }, [elementSelector]);

    React.useEffect(() => {
        if (!isActive) return;
        if (onElementFocus && typeof onElementFocus === 'function') onElementFocus();
        return () => {
            if (onElementBlur && typeof onElementBlur === 'function') onElementBlur();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    const onRefChange = React.useCallback(node => setRef(node), []);

    return (
        <div
            ref={onRefChange}
            className={[getElementClassName(styles.productContainer), !ref && styles.hidden].join(' ')}
            style={{
                left: leftPosition(),
                top: topPosition(),
                transform: transformStyle,
                maxWidth: `${maxWidth}px`,
            }}
        >
            <div className={styles.headerWrapper}>
                {!!title && (
                    <h3 className={[styles.titleContent, !elementSelector && styles.center].join(' ')}>{title}</h3>
                )}

                {!!elementSelector && (
                    <Button
                        type="button"
                        btnType="link"
                        text="Skip"
                        onClick={onPressSkipButton}
                        className={getElementClassName(styles.skipButton)}
                    />
                )}
            </div>

            <p className={[styles.descriptionContent, !elementSelector && styles.center].join(' ')}>{description}</p>

            <footer
                className={[getElementClassName(styles.actionButtonsWrapper), !elementSelector && styles.center].join(
                    ' '
                )}
            >
                <Button
                    type="button"
                    btnType="link"
                    text={previousButtonLabel}
                    onClick={onClickPrevious}
                    className={styles.previousButton}
                />

                <Button
                    type="button"
                    btnType="link"
                    text={nextButtonLabel}
                    onClick={onClickNext}
                    className={styles.nextButton}
                />
            </footer>
        </div>
    );
};

export default TourItem;
