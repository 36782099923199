import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { customerAtom } from 'atons/Customer/atoms';

import { queryKeys } from 'react-query/constants';
import { useToast } from 'customHooks/useToast';

import { getCustomFieldPickList } from 'services/attributeManagementApis';

const useGetCustomFieldPicklist = () => {
    const customer = useAtomValue(customerAtom);
    const { id: attributeSettingsId, isDeleted } = useAtomValue(attributeSettingsAtom);

    const toast = useToast();

    return useQuery(
        [queryKeys.getCustomFieldPicklist, customer?.clientId as string, attributeSettingsId!, isDeleted],
        getCustomFieldPickList,
        {
            enabled: customer !== null,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            onError: error =>
                toast.error('Something went wrong, try again later.', {
                    onOpen: () => console.log(error),
                }),
            retry: 0,
        }
    );
};

export default useGetCustomFieldPicklist;
