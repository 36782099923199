import * as React from 'react';
import ReactDom from 'react-dom';

import TourItem from './TourItem/TourItem';

import styles from './ProductTour.module.scss';

/**
 * Product tour component.
 * @param {{
 *  active: boolean;
 *  tourList: {
 *      title: string;
 *      elementSelector: string;
 *      description: string;
 *      onElementFocus: () => void;
 *      onElementBlur: () => void;
 *      onClickPrevious: () => void;
 *      onClickNext: () => void;
 *  }[];
 *  wellcomeMessage: string;
 *  onFinishTour: () => void;
 *  onStartTour: () => void;
 * }} props Component props
 * @returns {JSX.Element}
 */
const ProductTour = ({ active, tourList, wellcomeMessage, onFinishTour, onStartTour }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [inlineStyle, setInlineStyle] = React.useState({});

    const getClassName = baseStyle => (active ? [baseStyle, styles.active] : [baseStyle]).join(' ');

    const getTourGuideInlineStyle = React.useCallback(id => {
        const tourElement = document.querySelector(id);
        if (!tourElement) return;
        const { top: offsetTop, left: offsetLeft } = tourElement.getBoundingClientRect();
        const tourElementWidth = tourElement.clientWidth;
        const tourElementHeight = tourElement.clientHeight;

        const left = offsetLeft + tourElementWidth / 2;
        const top = offsetTop + tourElementHeight / 2;
        setInlineStyle({
            left,
            top,
            width: tourElementWidth + 40,
            height: tourElementHeight + 40,
        });
    }, []);

    const tourItems = React.useMemo(() => {
        if (wellcomeMessage) {
            return [
                {
                    title: 'Start a new guide',
                    elementSelector: undefined,
                    description: wellcomeMessage,
                    onClickNext: onStartTour,
                },
                ...tourList,
            ];
        }
        return tourList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourList, wellcomeMessage]);

    React.useEffect(() => {
        getTourGuideInlineStyle(tourItems[selectedIndex].elementSelector);
    }, [selectedIndex, tourItems, getTourGuideInlineStyle]);

    const finishTour = () => {
        setSelectedIndex(0);
        onFinishTour();
    };

    const onClickNextStep = () => {
        if (selectedIndex === tourItems.length - 1) return finishTour();
        setSelectedIndex(prevValue => prevValue + 1);
    };

    const onClickPreviousStep = () => {
        if (selectedIndex === 0) return finishTour();
        setSelectedIndex(prevValue => prevValue - 1);
    };

    return ReactDom.createPortal(
        <div className={getClassName(styles.container)}>
            {active &&
                tourItems.map((tour, index) => (
                    <TourItem
                        key={tour.elementSelector || 'wellcome-content'}
                        {...tour}
                        isActive={selectedIndex === index}
                        currentElementWrapperInlineStyle={inlineStyle}
                        onClickNext={() => {
                            if (tour.onClickNext && typeof tour.onClickNext === 'function') {
                                tour.onClickNext();
                            }
                            onClickNextStep();
                        }}
                        onClickPrevious={() => {
                            if (tour.onClickPrevious && typeof tour.onClickPrevious === 'function') {
                                tour.onClickPrevious();
                            }
                            onClickPreviousStep();
                        }}
                        onPressSkipButton={finishTour}
                        previousButtonLabel={selectedIndex === 0 ? 'Skip' : 'Previous'}
                        nextButtonLabel={
                            selectedIndex === tourItems.length - 1
                                ? 'Finish'
                                : selectedIndex === 0 && !!wellcomeMessage
                                ? 'Start'
                                : 'Next'
                        }
                    />
                ))}

            {!!tourItems[selectedIndex].elementSelector ? (
                <div
                    className={getClassName(styles.elementWrapper)}
                    style={inlineStyle}
                />
            ) : (
                <div className={styles.wellcomeElementBackground} />
            )}
        </div>,
        document.body
    );
};

export default ProductTour;
