import * as React from 'react';
import { useAtomValue, useAtom } from 'jotai';
import _ from 'lodash';
import { selectAtom } from 'jotai/utils';

import { VirtualizedListRender, Checkbox } from 'components';
import AttributeTypes from 'enums/AttributeTypes';

import {
    parentDependenciesAtom,
    singleValueSelectedAtom,
    checkBoxCaseAtom,
} from 'atons/AttributeManagement/dependencyAtoms';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { CustomFieldDependency } from 'services/attributeManagementApis';

import { a11yKeyBoardEventHandler } from 'shared/KeyboradKey';
import styles from './OptionListWithCheckbox.module.scss';

interface OptionWithCheckboxProps {
    id: number;
    name: string;
    key: string;
    isSelected?: boolean;
}

const OptionListWithCheckbox: React.FC<VirtualizedListRender<OptionWithCheckboxProps>> = ({ index, item, style }) => {
    const checkboxRef = React.useRef<HTMLInputElement>(null);
    const singleValueSelected = useAtomValue(singleValueSelectedAtom);
    const checkBoxCase = useAtomValue(checkBoxCaseAtom);
    const { attributeTypeId: typeId } = useAtomValue(attributeSettingsAtom);
    const [parentDependencies, setParentDependencies] = useAtom(parentDependenciesAtom);

    const classNames = React.useMemo(() => {
        const classes = [styles.option];
        if (!!item.isSelected) classes.push(styles.selected);
        return classes.join(' ');
    }, [item.isSelected]);

    const isChecked = useAtomValue(
        selectAtom(
            parentDependenciesAtom,
            React.useCallback(
                list =>
                    list?.parents[0]?.dependencies?.some(att => {
                        if (
                            Number(typeId) === AttributeTypes.DropDownList &&
                            singleValueSelected !== null &&
                            singleValueSelected !== att.parentOptionId
                        )
                            return false;
                        if (Number(typeId) === AttributeTypes.DropDownList) {
                            return att.childOptionId === item.id;
                        }
                        return att.parentOptionId === item.id;
                    }),
                [item.id, typeId, singleValueSelected]
            )
        )
    );

    const includeItemInDropDownCustomField = ({
        parentOptionId,
        childOptionId,
    }: {
        parentOptionId: number;
        childOptionId: number;
    }) => {
        const parentDependencyCopy = _.cloneDeep(parentDependencies);
        parentDependencyCopy?.parents[0]?.dependencies?.push({ parentOptionId, childOptionId });
        setParentDependencies(parentDependencyCopy as CustomFieldDependency);
    };

    const includeItemInCheckboxCustomField = ({ parentOptionId }: { parentOptionId: number }) => {
        const parentDependencyCopy = _.cloneDeep(parentDependencies);
        parentDependencyCopy?.parents[0]?.dependencies?.push({
            parentOptionId,
            childOptionId: Number(checkBoxCase[0].id),
        });
        parentDependencyCopy?.parents[0]?.dependencies?.push({
            parentOptionId,
            childOptionId: Number(checkBoxCase[1].id),
        });
        setParentDependencies(parentDependencyCopy as CustomFieldDependency);
    };

    const includeItemInRegularCustomField = ({ parentOptionId }: { parentOptionId: number }) => {
        const parentDependencyCopy = _.cloneDeep(parentDependencies);
        if (!parentDependencyCopy) return;

        parentDependencyCopy?.parents[0]?.dependencies?.push({
            parentOptionId,
            childOptionId: null,
        });
        setParentDependencies(parentDependencyCopy as CustomFieldDependency);
    };

    const removeItemInDropDownCustomField = ({
        parentOptionId,
        childOptionId,
    }: {
        parentOptionId: number;
        childOptionId: number;
    }) => {
        const parentDependenciesCopy = _.cloneDeep(parentDependencies);
        if (!parentDependenciesCopy?.parents[0].dependencies) return;
        parentDependenciesCopy.parents[0].dependencies = _.filter(
            parentDependenciesCopy.parents[0].dependencies,
            dependency => !(dependency.parentOptionId === parentOptionId && dependency.childOptionId === childOptionId)
        );
        setParentDependencies(parentDependenciesCopy as CustomFieldDependency);
    };

    const removeItemInCheckboxCustomField = ({ parentOptionId }: { parentOptionId: number }) => {
        const parentDependenciesCopy = _.cloneDeep(parentDependencies);
        if (parentDependenciesCopy) {
            parentDependenciesCopy.parents[0].dependencies = _.filter(
                parentDependenciesCopy.parents[0].dependencies,
                dependency =>
                    (dependency.parentOptionId !== parentOptionId && dependency.childOptionId !== checkBoxCase[0].id) ||
                    (dependency.parentOptionId !== parentOptionId && dependency.childOptionId !== checkBoxCase[1].id)
            );
            setParentDependencies(parentDependenciesCopy as CustomFieldDependency);
        }
    };

    const removeItemInRegularCustomField = ({ parentOptionId }: { parentOptionId: number }) => {
        const parentDependenciesCopy = _.cloneDeep(parentDependencies);
        if (parentDependenciesCopy) {
            parentDependenciesCopy.parents[0].dependencies = _.filter(
                parentDependenciesCopy.parents[0].dependencies,
                dependency => dependency.parentOptionId !== parentOptionId && dependency.childOptionId === null
            );
            setParentDependencies(parentDependenciesCopy as CustomFieldDependency);
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const typeIdNumber = Number(typeId);
        const itemId = Number(item.id);
        const singleValueSelectedNumber = Number(singleValueSelected);

        if (event.target.checked) {
            switch (typeIdNumber) {
                case AttributeTypes.DropDownList:
                    return includeItemInDropDownCustomField({
                        parentOptionId: singleValueSelectedNumber,
                        childOptionId: itemId,
                    });

                case AttributeTypes.Checkbox:
                    return includeItemInCheckboxCustomField({ parentOptionId: itemId });

                default:
                    return includeItemInRegularCustomField({ parentOptionId: itemId });
            }
        } else {
            switch (typeIdNumber) {
                case AttributeTypes.DropDownList:
                    return removeItemInDropDownCustomField({
                        parentOptionId: singleValueSelectedNumber,
                        childOptionId: itemId,
                    });

                case AttributeTypes.Checkbox:
                    return removeItemInCheckboxCustomField({ parentOptionId: itemId });

                default:
                    return removeItemInRegularCustomField({ parentOptionId: itemId });
            }
        }
    };

    const onClickListOption = (event?: React.MouseEvent<HTMLLIElement>) => {
        if (event?.target['localName'] === 'input') return; //if the target is the checkbox
        checkboxRef?.current?.click();
    };

    return (
        <li
            className={[
                classNames,
                Number(typeId) === AttributeTypes.DropDownList && singleValueSelected === null && styles.disabled,
            ].join(' ')}
            key={item.id}
            style={style}
            tabIndex={0}
            onClick={onClickListOption}
            onKeyUp={a11yKeyBoardEventHandler(onClickListOption)}
            role="option"
            aria-selected={false}
        >
            <Checkbox
                ref={checkboxRef}
                title={item.name}
                checked={isChecked}
                onChange={onChange}
                disabled={Number(typeId) === AttributeTypes.DropDownList && singleValueSelected === null}
                className={styles.checkbox}
            />

            <span
                className={styles.option__text}
                title={item.name}
            >
                {item.name}
            </span>
        </li>
    );
};

export default OptionListWithCheckbox;
