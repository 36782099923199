import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { ExportDataQueryParams, getCustomFieldsExportData } from 'services/attributeManagementApis';
import { ReactQueryHookOption } from 'react-query/types';
import { AxiosResponse } from 'axios';
import { includeDeletedValuesOnExportedDataAtom, selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';
import { useGetEntityTypeList } from '../entityType';

const useGetCustomFieldsExportDetailedData = (
    options: ReactQueryHookOption<AxiosResponse<any>> = { enabled: false }
) => {
    const customer = useAtomValue(customerAtom);
    const { includeDeletedAttributes } = useAtomValue(includeDeletedValuesOnExportedDataAtom);
    const toast = useToast();
    const { data: entityTypeList } = useGetEntityTypeList();
    const selectedEntityType = useAtomValue(selectedEntityTypeIdAtom);

    const entityType = entityTypeList?.find(({ id }) => selectedEntityType === id);

    return useQuery(
        [
            queryKeys.getCustomFieldExportData,
            customer?.clientId || '',
            {
                detailed: true,
                entityType: entityType?.name as ExportDataQueryParams['entityType'],
                includeDeleted: includeDeletedAttributes,
            },
        ],
        getCustomFieldsExportData,
        {
            enabled: options.enabled,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error =>
                toast.error('Something went wrong, try again later.', { onOpen: () => console.log(error) }),
            onSuccess: data => {
                if (options?.onSuccess) options.onSuccess(data);
                return data;
            },
        }
    );
};

export default useGetCustomFieldsExportDetailedData;
