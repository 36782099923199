import * as React from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { Tag } from 'components';
import {
    singleValueSelectedAtom,
    parentDependenciesAtom,
    checkBoxListTotalAtom,
    checkBoxCaseAtom,
} from 'atons/AttributeManagement/dependencyAtoms';
import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { CustomFieldDependency } from 'services/attributeManagementApis';
import AttributeTypes from 'enums/AttributeTypes';

import styles from './TagList.module.scss';

const TagList: React.FC = () => {
    const { id: customFieldId, attributeTypeId: typeId } = useAtomValue(attributeSettingsAtom);
    const singleValueSelected = useAtomValue(singleValueSelectedAtom);
    const checkBoxCase = useAtomValue(checkBoxCaseAtom);
    const [checkBoxListTotal] = useAtom(checkBoxListTotalAtom);
    const [parentDependencies, setParentDependencies] = useAtom(parentDependenciesAtom);

    const selecAll = () => {
        //Add logic
        //====================================================
        if (Number(typeId) === AttributeTypes.DropDownList) {
            checkBoxListTotal?.forEach(check => {
                if (
                    !parentDependencies?.parents[0].dependencies.some(
                        dep => dep.parentOptionId === Number(singleValueSelected) && dep.childOptionId === check.id
                    )
                ) {
                    parentDependencies?.parents[0].dependencies.push({
                        parentOptionId: Number(singleValueSelected),
                        childOptionId: check.id,
                    });
                }
            });
        } else if (Number(typeId) === AttributeTypes.Checkbox) {
            checkBoxListTotal?.forEach(check => {
                if (
                    !parentDependencies?.parents[0].dependencies.some(
                        dep => dep.parentOptionId === Number(check.id) && dep.childOptionId === checkBoxCase[0].id
                    ) ||
                    !parentDependencies?.parents[0].dependencies.some(
                        dep => dep.parentOptionId === Number(check.id) && dep.childOptionId === checkBoxCase[1].id
                    )
                ) {
                    parentDependencies?.parents[0].dependencies.push({
                        parentOptionId: Number(check.id),
                        childOptionId: Number(checkBoxCase[0].id),
                    });
                    parentDependencies?.parents[0].dependencies.push({
                        parentOptionId: Number(check.id),
                        childOptionId: Number(checkBoxCase[1].id),
                    });
                }
            });
        } else {
            checkBoxListTotal?.forEach(check => {
                if (
                    !parentDependencies?.parents[0].dependencies.some(
                        dep => dep.parentOptionId === check.id && dep.childOptionId === Number(customFieldId)
                    )
                ) {
                    parentDependencies?.parents[0].dependencies.push({
                        parentOptionId: check.id,
                        childOptionId: Number(customFieldId),
                    });
                }
            });
        }
        setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
    };

    const deselecAll = () => {
        //Remove logic
        //====================================================
        if (Number(typeId) === AttributeTypes.DropDownList) {
            if (parentDependencies) {
                parentDependencies.parents[0].dependencies = parentDependencies?.parents[0]?.dependencies?.filter(
                    dep => dep.parentOptionId !== Number(singleValueSelected)
                );
                setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
                return;
            }
        } else if (Number(typeId) === AttributeTypes.Checkbox) {
            if (parentDependencies) {
                parentDependencies.parents[0].dependencies = parentDependencies.parents[0].dependencies.filter(
                    dep => dep.childOptionId !== checkBoxCase[0].id && dep.childOptionId !== checkBoxCase[1].id
                );
                setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
                return;
            }
        } else {
            if (parentDependencies) {
                parentDependencies.parents[0].dependencies = parentDependencies?.parents[0]?.dependencies?.filter(
                    dep => dep.childOptionId !== Number(customFieldId)
                );
                setParentDependencies({ ...(parentDependencies as CustomFieldDependency) });
                return;
            }
        }
    };

    return (
        <div className={styles.tagWrapper}>
            <Tag
                onClick={selecAll}
                className={styles.tag}
                disabled={singleValueSelected === null && Number(typeId) === AttributeTypes.DropDownList}
            >
                Select All
            </Tag>
            <Tag
                onClick={deselecAll}
                className={styles.tag}
                type={'deselect'}
                disabled={singleValueSelected === null && Number(typeId) === AttributeTypes.DropDownList}
            >
                Deselect All
            </Tag>
        </div>
    );
};

export default TagList;
