import * as React from 'react';
import { useUpdateAtom } from 'jotai/utils';
import { useAuth } from 'oidc-react';

import { globalVariables } from '../../atons/globalAtons';
import { NavBar } from '..';
import DrawerSideBar from './DrawerSideBar/DrawerSideBar';

import { customerAtom } from 'atons/Customer/atoms';

interface DrawerContainerProps {}

const DrawerContainer: React.FC<DrawerContainerProps> = ({ children }) => {
    const setGlobal = useUpdateAtom(globalVariables);
    const setCustomer = useUpdateAtom(customerAtom);
    const auth = useAuth();

    /**
     * Effect that mounts the page
     */
    React.useEffect(() => {
        const userName = auth?.userData?.profile.fullName ?? '';
        if (typeof auth?.userData?.profile.role === 'undefined') {
            localStorage.setItem(
                'clientInfo',
                JSON.stringify({
                    name: auth?.userData?.profile?.clientName,
                    clientId: auth?.userData?.profile?.clientId,
                })
            );
            const clientInfo = localStorage.getItem('clientInfo');
            if (clientInfo) setCustomer(JSON.parse(clientInfo));
        } else {
            const clientInfo = localStorage.getItem('clientInfo');
            if (clientInfo !== null) {
                setCustomer(JSON.parse(clientInfo));
            }
        }
        setGlobal(prevState => ({ ...prevState, userName: userName }));
    }, [setGlobal, auth?.userData?.profile, setCustomer]);

    return (
        <>
            <NavBar />

            <div className="mainCont">
                <DrawerSideBar />

                <div className={'routeContent'}>{children}</div>
            </div>
        </>
    );
};

export default DrawerContainer;
