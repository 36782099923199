import { customerAtom } from 'atons/Customer/atoms';
import { useToast } from 'customHooks/useToast';
import { useAtomValue } from 'jotai';
import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { getCustomFieldPossibleParents } from 'services/attributeManagementApis';

const useGetCustomFieldListPossibleParents = (customFieldId: number) => {
    const customer = useAtomValue(customerAtom);
    const toast = useToast();

    return useQuery(
        [queryKeys.getCustomFieldListPossibleParents, String(customer?.clientId), Number(customFieldId)],
        getCustomFieldPossibleParents,
        {
            enabled: false,
            retry: 3,
            refetchOnWindowFocus: false,
            onError: error => {
                console.log(error);
                toast.error('Something went wrong, try again later.');
            },
        }
    );
};

export default useGetCustomFieldListPossibleParents;
