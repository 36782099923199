import * as React from 'react';

import { IconMenuLink } from 'components';

import styles from './Manager.module.scss';

const Manager: React.FC = () => {
    return (
        <>
            <div className={styles.pageHeader}>
                <h2 className={styles.headerTitle}>
                    Custom Field
                    <span className={styles.marker}> Dashboard </span>
                </h2>
            </div>

            <div className={styles.presentText}>
                <IconMenuLink
                    to="/customfields/attributes"
                    icon="k-i-shapes"
                    title="Navigate to Attribute Management page"
                >
                    Attribute Management
                </IconMenuLink>

                <IconMenuLink
                    to="/customfields/groups"
                    icon="k-i-table-unmerge"
                    title="Navigate to Groups Management page"
                >
                    Groups Management
                </IconMenuLink>
            </div>
        </>
    );
};

export default Manager;
