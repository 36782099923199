import { useQuery } from 'react-query';
import { useAtomValue } from 'jotai';

import AttributeTypes from 'enums/AttributeTypes';

import { attributeSettingsAtom } from 'atons/AttributeManagement/atoms';
import { EntityType } from 'services/attributeManagementApis';

import styles from './SingleAttributeValuePlaceholder.module.scss';

/**
 * @todo Think on how to control witch component will appears on the screen, this or the option list. Should
 * we use HOC?
 */

const SingleAttributeValuePlaceholder: React.FC = () => {
    const { name: selectedAttributeName, attributeTypeId: typeId } = useAtomValue(attributeSettingsAtom);
    const typeList = useQuery('customFieldTypes').data as EntityType[];

    return (
        <div className={styles.container}>
            <div className={styles.col}>
                {Number(typeId) === AttributeTypes.Checkbox && (
                    <span className={['k-icon k-i-checkbox-checked', styles.icon].join(' ')}></span>
                )}
                {Number(typeId) === AttributeTypes.FileUpload && (
                    <span className={['k-icon k-i-upload', styles.icon].join(' ')}></span>
                )}
                {Number(typeId) === AttributeTypes.TextBox && (
                    <span className={['k-icon k-i-textbox', styles.icon].join(' ')}></span>
                )}
                {Number(typeId) === AttributeTypes.Paragraph && (
                    <span className={['k-icon k-i-paragraph-add', styles.icon].join(' ')}></span>
                )}
                {Number(typeId) === AttributeTypes.RichTextEditor && (
                    <span className={['k-icon k-i-layout', styles.icon].join(' ')}></span>
                )}
                {Number(typeId) === AttributeTypes.DropDownList && (
                    <span className={['k-icon k-i-list-unordered', styles.icon].join(' ')}></span>
                )}
            </div>
            <div className={styles.col}>
                <span className={styles.propertyValue}>{typeList.find(item => item.id === Number(typeId))?.name}</span>
            </div>
            <div className={styles.col}>
                <span className={styles.propertyLabel}>Name: {selectedAttributeName}</span>
            </div>
        </div>
    );
};

export default SingleAttributeValuePlaceholder;
