import * as React from 'react';

const NotFound = props => {
    const Content = (
        <>
            <div className="container-fluid">
                <div className="row">
                    <h2 className="mx-5 mt-5 mb-3 col-10 offset-2 title-main">
                        Not Found
                        <span className="title-section-marker"> Page </span>
                    </h2>
                    <div className="mx-5 col-10 offset-2">
                        <div className="text-center my-5">
                            <span className="k-icon k-i-warning icon-alert"></span>
                            <p className="mt-3">That page that you trying to access was not found.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    return <div>{props.children ? props.children : Content}</div>;
};

export default NotFound;
