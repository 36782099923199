import { useAtomValue } from 'jotai';
import { useMutation } from 'react-query';

import { selectedEntityTypeIdAtom } from 'atons/AttributeManagement/atoms';
import { Customer, customerAtom } from 'atons/Customer/atoms';
import { postCustomFieldGroup, PostCustomFielGroupPayload } from 'services/groupsApis';
import { useToast } from 'customHooks/useToast';

import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { ReactQueryHookOption } from 'react-query/types';
import { useSetErrorMessage } from 'customHooks/useSetErrorMessage';
import { CustomFieldGroup } from 'services/attributeManagementApis';

type FormPayload = Omit<PostCustomFielGroupPayload, 'entityTypeId'>;

const usePostCustomFieldGroup = (options?: ReactQueryHookOption<CustomFieldGroup>) => {
    const entityTypeId = useAtomValue(selectedEntityTypeIdAtom);
    const customer = useAtomValue(customerAtom);
    const onError = useSetErrorMessage();

    const toast = useToast();

    return useMutation(
        (payload: FormPayload) =>
            postCustomFieldGroup({ ...payload, entityTypeId: entityTypeId! }, customer as Customer),
        {
            onSuccess: data => {
                queryClient.invalidateQueries([queryKeys.customFieldGroups]);
                queryClient.invalidateQueries([queryKeys.getCustomFieldGroup]);
                toast.success('New Group added successfully');
                if (options?.onSuccess) options.onSuccess(data);
            },
            onError: error => {
                onError(error);
                if (options?.onError) options.onError(error);
            },
        }
    );
};

export default usePostCustomFieldGroup;
