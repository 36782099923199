import Constants from '../config/Constants';

export default class LocalStorageService {
    static _getLocalStorageData() {
        const localStorageData = localStorage.getItem(Constants.LocalStorageKey);
        if (!localStorageData) return {};
        return JSON.parse(localStorageData);
    }

    static _commit(newValue) {
        localStorage.setItem(Constants.LocalStorageKey, JSON.stringify(newValue));
    }

    static setItem(key, value) {
        const localStorageData = LocalStorageService._getLocalStorageData();
        localStorageData[key] = value;
        LocalStorageService._commit(localStorageData);
    }

    static getItem(key) {
        const localStorageData = LocalStorageService._getLocalStorageData();
        return localStorageData[key];
    }

    static listAvailableItems() {
        return Object.keys(LocalStorageService._getLocalStorageData());
    }

    static hasItem(key) {
        return LocalStorageService.listAvailableItems().includes(key);
    }
}
