export const usePicklistGridMaxHeight = (): [string, number] => {
    const ATTRIBUTE_MODAL_HEIGHT =
        Math.ceil((document.getElementById('attribute-configuration-modal')?.clientHeight ?? 0) / 10) * 10;
    const PICKLIST_HEADER_SECTION =
        Math.ceil((document.getElementById('picklist-header-content')?.clientHeight ?? 0) / 10) * 10;
    const ADD_NEW_ITEM_SECTION =
        Math.ceil((document.getElementById('add-new-picklist-item')?.clientHeight ?? 0) / 10) * 10;
    const INNER_PADDINGS = 24 + 25;
    const PICKLIST_HEADER_MARGIN_BOTTOM = 32;
    const GRID_MAX_HEIGHT =
        ATTRIBUTE_MODAL_HEIGHT -
        PICKLIST_HEADER_SECTION -
        PICKLIST_HEADER_MARGIN_BOTTOM -
        ADD_NEW_ITEM_SECTION -
        INNER_PADDINGS;
    return [`${GRID_MAX_HEIGHT}px`, GRID_MAX_HEIGHT];
};
