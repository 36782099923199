import * as React from 'react';

import { InputFieldProps } from '../types';
import styles from './Checkbox.module.scss';

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, InputFieldProps> = (
    { disabled, title, className, ...props },
    ref: React.ForwardedRef<HTMLInputElement>
) => {
    const inputTitleProperty = disabled ? 'This field it is disabled' : title;

    return (
        <input
            ref={ref}
            {...props}
            type="checkbox"
            className={[styles.checkboxBaseStyle, className].join(' ')}
            disabled={disabled}
            title={inputTitleProperty}
        />
    );
};

export default React.forwardRef<HTMLInputElement, InputFieldProps>(Checkbox);
