import { AxiosResponse } from 'axios';

export class FileUtils {
    public static generateDownloadTagFromAxiosResponse(response: AxiosResponse<any>, containerElementId: string) {
        try {
            const headers = new Headers(response.headers);
            const contentDisposition = headers.get('content-disposition');
            const filename = contentDisposition?.split('filename=')[1].split(';')[0].replace(/\\|"/g, '');
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename ? filename : 'file.csv');
            const container = document.getElementById(containerElementId);
            if (container) {
                container.appendChild(link);
                link.click();
                container.removeChild(link);
                URL.revokeObjectURL(href);
                return;
            }
            throw new Error('Could not download the dependencies CSV file.');
        } catch (err: any) {
            console.error(err);
        }
    }
}
