import * as React from 'react';
import { useAtom } from 'jotai';

import { Modal } from 'components';

import { groupDetailAtom } from 'atons/Groups/atoms';

import styles from './GroupDetail.module.scss';
import GroupForm from './GroupForm/GroupForm';

const GroupDetail: React.FC = () => {
    const [groupDetail, setGroupDetail] = useAtom(groupDetailAtom);

    const closeModal = () => {
        setGroupDetail(null);
    };

    return (
        <Modal
            title="Group Detail"
            show={groupDetail !== null}
            closeBtn
            closeBtnFunc={closeModal}
            tabModal
            className={styles.attributeConfigurationModal}
        >
            <div
                id="group-detail-modal"
                className={styles.modalContent}
            >
                <GroupForm />
            </div>
        </Modal>
    );
};

export default GroupDetail;
