import * as React from 'react';
import { useAtomValue, useUpdateAtom, selectAtom } from 'jotai/utils';
import { GridCellProps } from '@progress/kendo-react-grid';

import { Checkbox } from '../../../../../components';
import {
    addItemToSelectedList,
    removeItemFromSelectedList,
    selectedListAtom,
} from '../../../../../atons/AttributeManagement/atoms';

import styles from './CheckboxGridColumn.module.scss';

interface CheckboxGridProps extends Omit<GridCellProps, 'dataItem'> {
    dataItem: { id: number };
}

const CheckboxGrid: React.FC<CheckboxGridProps> = ({ dataItem }) => {
    const isChecked = useAtomValue(
        selectAtom(
            selectedListAtom,
            React.useCallback(list => list.some(attId => attId === dataItem.id), [dataItem.id])
        )
    );
    const addToSelectedList = useUpdateAtom(addItemToSelectedList);
    const removeToSelectedList = useUpdateAtom(removeItemFromSelectedList);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                return addToSelectedList(dataItem.id);
            }
            removeToSelectedList(dataItem.id);
        },
        [addToSelectedList, removeToSelectedList, dataItem.id]
    );

    return (
        <td className={styles.center}>
            <Checkbox
                id={String(dataItem.id)}
                checked={isChecked}
                aria-checked={isChecked}
                onChange={onChange}
            />
        </td>
    );
};

export default CheckboxGrid;
